import { FC, useEffect, useMemo } from "react";
import dynamic from "next/dynamic";
import Link from "next/link";
import { PostPinResponse } from "@/web-client/api";

import usePinWithBalloon from "@/features/pin/hooks/usePinWithBalloon";
import PinWithBalloon from "@/features/pin/components/PinWithBalloon";
import NotePin from "@/features/pin/components/NotePin";

import IconWithData from "@/features/pin/components/IconWithData";
import IconMaker from "@/assets/imgs/svg/icon_apartment-outlined.svg";
import IconArrowRight from "@/assets/imgs/svg/icon-arrow-right.svg";

const DynamicPinBalloon = dynamic(
  () => import("@/features/pin/components/PinBalloon"),
);

interface Props {
  pin: PostPinResponse;

  showBalloon?: boolean;
  portalId?: string;
}

const NotePinWithBalloon: FC<Props> = ({ pin, showBalloon, portalId }) => {
  const response = usePinWithBalloon();

  const makerName = useMemo<string>(() => {
    return pin.note_pin.maker?.name || pin.note_pin.maker_text;
  }, [pin]);

  const displayLink = useMemo<boolean>(
    () => pin.note_pin.maker && pin.note_pin.maker.is_contracted,
    [pin],
  );
  const { touchStartHandler, hideBalloon } = response;

  useEffect(() => {
    showBalloon ? touchStartHandler() : hideBalloon(0);
  }, [showBalloon, touchStartHandler, hideBalloon]);

  return (
    <PinWithBalloon
      response={response}
      pin={pin}
      pinElement={
        <NotePin
          onTouchStart={response.touchStartHandler}
          notePin={pin.note_pin}
        />
      }
      balloonElement={
        <DynamicPinBalloon
          onMouseEnter={response.mouseOverHandler}
          onMouseLeave={response.mouseLeaveHandler}
        >
          <div className="relative flex justify-between items-center gap-8">
            <div className="grid gap-4">
              <h2 className="font-bold text-sm">{pin.note_pin.text}</h2>
              {makerName && (
                <IconWithData
                  defineTitle="メーカー"
                  icon={<IconMaker width={16} height={16} />}
                >
                  {displayLink ? (
                    <Link
                      href={`/makers/${pin.note_pin.maker.id}`}
                      className="underline hover:no-underline"
                    >
                      {makerName}
                    </Link>
                  ) : (
                    <>{makerName}</>
                  )}
                </IconWithData>
              )}
            </div>

            {displayLink && (
              <div className="fill-gray-300">
                <IconArrowRight width={16} height={16} />
              </div>
            )}

            {/* 前面に敷くリンク */}
            {displayLink && (
              <Link
                className="absolute inset-0"
                href={`/makers/${pin.note_pin.maker.id}`}
              />
            )}
          </div>
        </DynamicPinBalloon>
      }
      portaiId={portalId}
    />
  );
};

export default NotePinWithBalloon;
