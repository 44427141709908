import clsx from "clsx";
import {
  LikeKind,
  ListItemKind,
  PostPinKind,
  PostResponse,
} from "@/web-client/api";
import ProductVariationPinWithBalloon from "@/features/pin/components/ProductVariationPinWithBalloon";
import { ImageSize } from "@/consts/ImageSize";
import useDevice from "@/hooks/useDevice";
import { CSSProperties, FC, ReactNode, useMemo } from "react";
import NotePinWithBalloon from "@/features/pin/components/NotePinWithBalloon";
import LinkAsModal, { ModalEntityType } from "@/components/LinkAsModal";
import MinimumAddListBtn from "@/features/list/components/MinimumAddListBtn";
import MinimumLikeBtn from "@/features/like/components/MinimumLikeBtn";
import useLike, { LikeMutateHandler } from "@/features/like/hooks/useLike";

export interface PostItemProps {
  post: PostResponse;
  imageSize?: ImageSize;
  optionalBtns?: ReactNode;
  mode?: PostItemMode;
  mutate?: LikeMutateHandler<PostResponse>;
  portalId?: string;
  showButtons?: boolean;

  imgStyle?: CSSProperties;
}

export enum PostItemMode {
  Archive = 1, // 一覧ページなど複数並ぶ場合
  Detail, // 詳細ページなど単一で表示する場合
}

const PostItem: FC<PostItemProps> = ({
  post,
  imageSize = ImageSize.Small,
  mode = PostItemMode.Archive,
  optionalBtns,
  mutate,
  portalId,
  showButtons = true,
  imgStyle,
}): JSX.Element => {
  const { isPc, isSp } = useDevice();

  // ホバーせずともピンと撮影者を表示するかどうか
  const displayPinsAndPhotographer = useMemo(
    () => mode === PostItemMode.Detail && isSp,
    [isSp, mode],
  );

  // ピンをレンダリングさせないためのフラグ
  // スマホであり、あらかじめピンを表示させない場合は、
  // タップを邪魔してしまうのでレンダリングさせない
  const dontRenderPins = useMemo<boolean>(() => {
    return isSp && mode === PostItemMode.Archive;
  }, [mode, isSp]);

  const { likeHandler } = useLike<PostResponse>(
    post,
    LikeKind.POST,
    mutate,
    post.maker_id,
  );

  return (
    <article className="relative laptop:overflow-visible">
      <img
        className="w-full"
        src={
          imageSize === ImageSize.Large
            ? post.upload_image.urls.original
            : post.upload_image.urls.small
        }
        alt=""
        loading="lazy"
        style={imgStyle}
      />
      <div
        className={clsx(
          "absolute inset-0",
          "transition-all duration-200",
          // ホバーした時に表示する
          "opacity-0 hover:opacity-100",
          displayPinsAndPhotographer && isSp ? "opacity-100" : "",
        )}
      >
        {mode === PostItemMode.Archive && (
          <LinkAsModal
            entityId={post.id}
            entityType={ModalEntityType.Post}
            as={`/posts/${post.id}`}
          >
            <a className="block w-full h-full" />
          </LinkAsModal>
        )}

        {post.pins.length > 0 && !dontRenderPins && (
          <div
            className={[
              "absolute inset-0 transition-all duration-200 pointer-events-none",
              "[&>*]:absolute [&>*]:top-0 [&>*]:left-0",
            ].join(" ")}
          >
            {post.pins.map((pin) => {
              if (pin.kind === PostPinKind.NOTE) {
                return (
                  <NotePinWithBalloon
                    key={pin.id}
                    pin={pin}
                    portalId={portalId}
                  />
                );
              }

              if (pin.kind === PostPinKind.PRODUCT_VARIATION) {
                return (
                  <ProductVariationPinWithBalloon
                    key={pin.id}
                    pin={pin}
                    portalId={portalId}
                  />
                );
              }
            })}
          </div>
        )}

        {post.photographer && (
          <small className="absolute bottom-[12px] left-[12px] text-white drop-shadow">
            <span>photo by </span>
            <span className="font-bold">{post.photographer}</span>
          </small>
        )}

        {isPc && showButtons && (
          <div className="absolute top-[12px] right-[12px] flex items-center space-x-8">
            {optionalBtns}
            <MinimumLikeBtn hasLiked={post.has_liked} onClick={likeHandler} />
            <MinimumAddListBtn selectedItem={post} kind={ListItemKind.POST} />
          </div>
        )}
      </div>
    </article>
  );
};
export default PostItem;
