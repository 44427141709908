/* tslint:disable */
/* eslint-disable */
/**
 * tecture Front API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AddressEntity
 */
export interface AddressEntity {
    /**
     * 
     * @type {number}
     * @memberof AddressEntity
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof AddressEntity
     */
    'user_id': number;
    /**
     * 
     * @type {string}
     * @memberof AddressEntity
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AddressEntity
     */
    'postal_code': string;
    /**
     * 
     * @type {number}
     * @memberof AddressEntity
     */
    'prefecture_id': number;
    /**
     * 
     * @type {string}
     * @memberof AddressEntity
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof AddressEntity
     */
    'block': string;
    /**
     * 
     * @type {string}
     * @memberof AddressEntity
     */
    'building'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressEntity
     */
    'room_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressEntity
     */
    'phone_number': string;
    /**
     * 
     * @type {string}
     * @memberof AddressEntity
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof AddressEntity
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface AddressUpsertParameter
 */
export interface AddressUpsertParameter {
    /**
     * 
     * @type {string}
     * @memberof AddressUpsertParameter
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AddressUpsertParameter
     */
    'postal_code': string;
    /**
     * 
     * @type {number}
     * @memberof AddressUpsertParameter
     */
    'prefecture_id': number;
    /**
     * 
     * @type {string}
     * @memberof AddressUpsertParameter
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof AddressUpsertParameter
     */
    'block': string;
    /**
     * 
     * @type {string}
     * @memberof AddressUpsertParameter
     */
    'building'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressUpsertParameter
     */
    'room_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressUpsertParameter
     */
    'phone_number': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AvailablePart = {
    INTERIOR_FLOOR: 1,
    INTERIOR_WALL: 2,
    CEILING: 3,
    INTERIOR_DECORATING: 4,
    WET_AREA: 5,
    ROOF: 6,
    OUTER_WALL: 7,
    EAVE: 8,
    EXTERIOR_FLOOR: 9,
    EXTERIOR_DECORATING: 10,
    OPENING_SECTION: 11
} as const;

export type AvailablePart = typeof AvailablePart[keyof typeof AvailablePart];


/**
 * 
 * @export
 * @interface AvailablePartEntity
 */
export interface AvailablePartEntity {
    /**
     * 
     * @type {number}
     * @memberof AvailablePartEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AvailablePartEntity
     */
    'label': string;
}
/**
 * 
 * @export
 * @interface AwardCategoryEntity
 */
export interface AwardCategoryEntity {
    /**
     * 
     * @type {number}
     * @memberof AwardCategoryEntity
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof AwardCategoryEntity
     */
    'award_id': number;
    /**
     * 
     * @type {AwardCategoryKind}
     * @memberof AwardCategoryEntity
     */
    'kind': AwardCategoryKind;
    /**
     * 
     * @type {string}
     * @memberof AwardCategoryEntity
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof AwardCategoryEntity
     */
    'entries_count': number;
    /**
     * 
     * @type {string}
     * @memberof AwardCategoryEntity
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof AwardCategoryEntity
     */
    'updated_at': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const AwardCategoryKind = {
    ARCHITECTURE: 1,
    INTERIOR_DESIGN: 2
} as const;

export type AwardCategoryKind = typeof AwardCategoryKind[keyof typeof AwardCategoryKind];


/**
 * 
 * @export
 * @interface AwardEntriesResponse
 */
export interface AwardEntriesResponse {
    /**
     * 次のページが存在するか
     * @type {boolean}
     * @memberof AwardEntriesResponse
     */
    'has_next': boolean;
    /**
     * 総ページ数
     * @type {number}
     * @memberof AwardEntriesResponse
     */
    'total_pages'?: number;
    /**
     * 総件数
     * @type {number}
     * @memberof AwardEntriesResponse
     */
    'total_count'?: number;
    /**
     * 
     * @type {NotificationsResponseCursor}
     * @memberof AwardEntriesResponse
     */
    'cursor'?: NotificationsResponseCursor;
    /**
     * 
     * @type {Array<AwardEntryResponse>}
     * @memberof AwardEntriesResponse
     */
    'results'?: Array<AwardEntryResponse>;
}
/**
 * 
 * @export
 * @interface AwardEntryCreateParameter
 */
export interface AwardEntryCreateParameter {
    /**
     * 
     * @type {number}
     * @memberof AwardEntryCreateParameter
     */
    'project_id': number;
    /**
     * 
     * @type {number}
     * @memberof AwardEntryCreateParameter
     */
    'award_category_id': number;
    /**
     * 
     * @type {string}
     * @memberof AwardEntryCreateParameter
     */
    'completion': string;
}
/**
 * 
 * @export
 * @interface AwardEntryEntity
 */
export interface AwardEntryEntity {
    /**
     * 
     * @type {number}
     * @memberof AwardEntryEntity
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof AwardEntryEntity
     */
    'award_id': number;
    /**
     * 
     * @type {number}
     * @memberof AwardEntryEntity
     */
    'award_category_id': number;
    /**
     * 
     * @type {number}
     * @memberof AwardEntryEntity
     */
    'project_id': number;
    /**
     * 
     * @type {number}
     * @memberof AwardEntryEntity
     */
    'votes_count': number;
    /**
     * 
     * @type {string}
     * @memberof AwardEntryEntity
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof AwardEntryEntity
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface AwardEntryResponse
 */
export interface AwardEntryResponse {
    /**
     * 
     * @type {number}
     * @memberof AwardEntryResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof AwardEntryResponse
     */
    'award_id': number;
    /**
     * 
     * @type {number}
     * @memberof AwardEntryResponse
     */
    'award_category_id': number;
    /**
     * 
     * @type {number}
     * @memberof AwardEntryResponse
     */
    'project_id': number;
    /**
     * 
     * @type {number}
     * @memberof AwardEntryResponse
     */
    'votes_count': number;
    /**
     * 
     * @type {string}
     * @memberof AwardEntryResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof AwardEntryResponse
     */
    'updated_at': string;
    /**
     * 
     * @type {ProjectResponse}
     * @memberof AwardEntryResponse
     */
    'project': ProjectResponse;
}
/**
 * 
 * @export
 * @interface AwardPhaseEntity
 */
export interface AwardPhaseEntity {
    /**
     * 
     * @type {number}
     * @memberof AwardPhaseEntity
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof AwardPhaseEntity
     */
    'award_id': number;
    /**
     * 
     * @type {string}
     * @memberof AwardPhaseEntity
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AwardPhaseEntity
     */
    'start_datetime': string;
    /**
     * 
     * @type {string}
     * @memberof AwardPhaseEntity
     */
    'end_datetime': string;
    /**
     * 
     * @type {AwardPhaseKey}
     * @memberof AwardPhaseEntity
     */
    'phase_key': AwardPhaseKey;
    /**
     * 
     * @type {string}
     * @memberof AwardPhaseEntity
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof AwardPhaseEntity
     */
    'updated_at': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const AwardPhaseKey = {
    Entry: 'entry',
    FirstRoundPreparation: 'first_round_preparation',
    FirstRoundVoting: 'first_round_voting',
    FinalRoundPreparation: 'final_round_preparation',
    FinalRoundVoting: 'final_round_voting'
} as const;

export type AwardPhaseKey = typeof AwardPhaseKey[keyof typeof AwardPhaseKey];


/**
 * 
 * @export
 * @interface AwardResponse
 */
export interface AwardResponse {
    /**
     * 
     * @type {number}
     * @memberof AwardResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AwardResponse
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof AwardResponse
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof AwardResponse
     */
    'entry_start_datetime': string;
    /**
     * 
     * @type {string}
     * @memberof AwardResponse
     */
    'entry_end_datetime': string;
    /**
     * 
     * @type {string}
     * @memberof AwardResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof AwardResponse
     */
    'updated_at': string;
    /**
     * 
     * @type {AwardPhaseEntity}
     * @memberof AwardResponse
     */
    'current_phase'?: AwardPhaseEntity;
}
/**
 * 
 * @export
 * @interface BoardDetailResponse
 */
export interface BoardDetailResponse {
    /**
     * 
     * @type {number}
     * @memberof BoardDetailResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof BoardDetailResponse
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof BoardDetailResponse
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof BoardDetailResponse
     */
    'upload_image_id': number;
    /**
     * 
     * @type {string}
     * @memberof BoardDetailResponse
     */
    'magazine_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoardDetailResponse
     */
    'release_datetime': string;
    /**
     * 
     * @type {boolean}
     * @memberof BoardDetailResponse
     */
    'allow_add_all_to_cart': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BoardDetailResponse
     */
    'is_private': boolean;
    /**
     * 
     * @type {string}
     * @memberof BoardDetailResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof BoardDetailResponse
     */
    'updated_at': string;
    /**
     * 
     * @type {UploadImageResponse}
     * @memberof BoardDetailResponse
     */
    'upload_image': UploadImageResponse;
    /**
     * 
     * @type {Array<ProductVariationWithProductResponse>}
     * @memberof BoardDetailResponse
     */
    'product_variations': Array<ProductVariationWithProductResponse>;
}
/**
 * 
 * @export
 * @interface BoardResponse
 */
export interface BoardResponse {
    /**
     * 
     * @type {number}
     * @memberof BoardResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof BoardResponse
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof BoardResponse
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof BoardResponse
     */
    'upload_image_id': number;
    /**
     * 
     * @type {string}
     * @memberof BoardResponse
     */
    'magazine_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoardResponse
     */
    'release_datetime': string;
    /**
     * 
     * @type {boolean}
     * @memberof BoardResponse
     */
    'allow_add_all_to_cart': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BoardResponse
     */
    'is_private': boolean;
    /**
     * 
     * @type {string}
     * @memberof BoardResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof BoardResponse
     */
    'updated_at': string;
    /**
     * 
     * @type {UploadImageResponse}
     * @memberof BoardResponse
     */
    'upload_image': UploadImageResponse;
}
/**
 * 
 * @export
 * @interface BoardsResponse
 */
export interface BoardsResponse {
    /**
     * 次のページが存在するか
     * @type {boolean}
     * @memberof BoardsResponse
     */
    'has_next': boolean;
    /**
     * 総ページ数
     * @type {number}
     * @memberof BoardsResponse
     */
    'total_pages'?: number;
    /**
     * 総件数
     * @type {number}
     * @memberof BoardsResponse
     */
    'total_count'?: number;
    /**
     * 
     * @type {NotificationsResponseCursor}
     * @memberof BoardsResponse
     */
    'cursor'?: NotificationsResponseCursor;
    /**
     * 
     * @type {Array<BoardResponse>}
     * @memberof BoardsResponse
     */
    'results'?: Array<BoardResponse>;
}
/**
 * 
 * @export
 * @interface BrandResponse
 */
export interface BrandResponse {
    /**
     * 
     * @type {number}
     * @memberof BrandResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof BrandResponse
     */
    'upload_image_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'rubi'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BrandResponse
     */
    'keywords'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'updated_at': string;
    /**
     * 
     * @type {UploadImageResponse}
     * @memberof BrandResponse
     */
    'logo'?: UploadImageResponse;
}
/**
 * 
 * @export
 * @interface BrandWithCategoriesResponse
 */
export interface BrandWithCategoriesResponse {
    /**
     * 
     * @type {number}
     * @memberof BrandWithCategoriesResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof BrandWithCategoriesResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BrandWithCategoriesResponse
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof BrandWithCategoriesResponse
     */
    'upload_image_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BrandWithCategoriesResponse
     */
    'rubi'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BrandWithCategoriesResponse
     */
    'keywords'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof BrandWithCategoriesResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof BrandWithCategoriesResponse
     */
    'updated_at': string;
    /**
     * 
     * @type {UploadImageResponse}
     * @memberof BrandWithCategoriesResponse
     */
    'logo'?: UploadImageResponse;
    /**
     * 
     * @type {Array<CategoryResponse>}
     * @memberof BrandWithCategoriesResponse
     */
    'categories': Array<CategoryResponse>;
    /**
     * 
     * @type {number}
     * @memberof BrandWithCategoriesResponse
     */
    'posts_count': number;
    /**
     * 
     * @type {number}
     * @memberof BrandWithCategoriesResponse
     */
    'interior_product_variations_count': number;
    /**
     * 
     * @type {number}
     * @memberof BrandWithCategoriesResponse
     */
    'building_material_product_variations_count': number;
}
/**
 * 
 * @export
 * @interface BuildingTypeEntity
 */
export interface BuildingTypeEntity {
    /**
     * 
     * @type {number}
     * @memberof BuildingTypeEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof BuildingTypeEntity
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof BuildingTypeEntity
     */
    'posts_count': number;
    /**
     * 
     * @type {number}
     * @memberof BuildingTypeEntity
     */
    'projects_count': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof BuildingTypeEntity
     */
    'ancestry': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof BuildingTypeEntity
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof BuildingTypeEntity
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof BuildingTypeEntity
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface BuildingTypeResponse
 */
export interface BuildingTypeResponse {
    /**
     * 
     * @type {number}
     * @memberof BuildingTypeResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof BuildingTypeResponse
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof BuildingTypeResponse
     */
    'posts_count': number;
    /**
     * 
     * @type {number}
     * @memberof BuildingTypeResponse
     */
    'projects_count': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof BuildingTypeResponse
     */
    'ancestry': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof BuildingTypeResponse
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof BuildingTypeResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof BuildingTypeResponse
     */
    'updated_at': string;
    /**
     * 
     * @type {Array<BuildingTypeEntity>}
     * @memberof BuildingTypeResponse
     */
    'children'?: Array<BuildingTypeEntity>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CatalogLayout = {
    PORTRAIT: 1,
    LANDSCAPE: 2,
    SQUARE: 3
} as const;

export type CatalogLayout = typeof CatalogLayout[keyof typeof CatalogLayout];


/**
 * 
 * @export
 * @interface CatalogPageIndexValue
 */
export interface CatalogPageIndexValue {
    /**
     * 
     * @type {string}
     * @memberof CatalogPageIndexValue
     */
    'label': string;
    /**
     * 
     * @type {number}
     * @memberof CatalogPageIndexValue
     */
    'page'?: number;
    /**
     * 
     * @type {Array<CatalogPageIndexValue>}
     * @memberof CatalogPageIndexValue
     */
    'children'?: Array<CatalogPageIndexValue>;
}
/**
 * 
 * @export
 * @interface CatalogPageResponse
 */
export interface CatalogPageResponse {
    /**
     * 
     * @type {number}
     * @memberof CatalogPageResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof CatalogPageResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof CatalogPageResponse
     */
    'upload_image_id': number;
    /**
     * 
     * @type {number}
     * @memberof CatalogPageResponse
     */
    'catalog_id': number;
    /**
     * 
     * @type {string}
     * @memberof CatalogPageResponse
     */
    'created_at': string;
    /**
     * 
     * @type {UploadImageResponse}
     * @memberof CatalogPageResponse
     */
    'upload_image': UploadImageResponse;
    /**
     * 
     * @type {Array<CatalogProductVariationResponse>}
     * @memberof CatalogPageResponse
     */
    'product_variations': Array<CatalogProductVariationResponse>;
}
/**
 * 
 * @export
 * @interface CatalogProductVariationResponse
 */
export interface CatalogProductVariationResponse {
    /**
     * 
     * @type {number}
     * @memberof CatalogProductVariationResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof CatalogProductVariationResponse
     */
    'position_x': number;
    /**
     * 
     * @type {number}
     * @memberof CatalogProductVariationResponse
     */
    'position_y': number;
    /**
     * 
     * @type {number}
     * @memberof CatalogProductVariationResponse
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof CatalogProductVariationResponse
     */
    'height': number;
    /**
     * 
     * @type {number}
     * @memberof CatalogProductVariationResponse
     */
    'product_variation_id': number;
    /**
     * 
     * @type {number}
     * @memberof CatalogProductVariationResponse
     */
    'catalog_page_id': number;
    /**
     * 
     * @type {string}
     * @memberof CatalogProductVariationResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof CatalogProductVariationResponse
     */
    'updated_at': string;
    /**
     * 
     * @type {number}
     * @memberof CatalogProductVariationResponse
     */
    'product_id': number;
}
/**
 * 
 * @export
 * @interface CatalogResponse
 */
export interface CatalogResponse {
    /**
     * 
     * @type {number}
     * @memberof CatalogResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CatalogResponse
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof CatalogResponse
     */
    'maker_id': number;
    /**
     * 
     * @type {Array<CatalogPageIndexValue>}
     * @memberof CatalogResponse
     */
    'toc'?: Array<CatalogPageIndexValue>;
    /**
     * 
     * @type {CatalogWithPagesResponsePdf}
     * @memberof CatalogResponse
     */
    'pdf': CatalogWithPagesResponsePdf;
    /**
     * 
     * @type {string}
     * @memberof CatalogResponse
     */
    'published_date': string;
    /**
     * 
     * @type {CatalogLayout}
     * @memberof CatalogResponse
     */
    'layout': CatalogLayout;
    /**
     * 
     * @type {number}
     * @memberof CatalogResponse
     */
    'first_page_num': number;
    /**
     * 
     * @type {number}
     * @memberof CatalogResponse
     */
    'total_pages': number;
    /**
     * 
     * @type {boolean}
     * @memberof CatalogResponse
     */
    'is_published': boolean;
    /**
     * 
     * @type {CatalogStatus}
     * @memberof CatalogResponse
     */
    'status': CatalogStatus;
    /**
     * 
     * @type {string}
     * @memberof CatalogResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof CatalogResponse
     */
    'updated_at': string;
    /**
     * 
     * @type {UploadImageResponse}
     * @memberof CatalogResponse
     */
    'cover_image'?: UploadImageResponse;
    /**
     * 
     * @type {MakerResponse}
     * @memberof CatalogResponse
     */
    'maker': MakerResponse;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const CatalogStatus = {
    REGISTERED: 0,
    COMPLETED: 1
} as const;

export type CatalogStatus = typeof CatalogStatus[keyof typeof CatalogStatus];


/**
 * 
 * @export
 * @interface CatalogWithPagesResponse
 */
export interface CatalogWithPagesResponse {
    /**
     * 
     * @type {number}
     * @memberof CatalogWithPagesResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CatalogWithPagesResponse
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof CatalogWithPagesResponse
     */
    'maker_id': number;
    /**
     * 
     * @type {Array<CatalogPageIndexValue>}
     * @memberof CatalogWithPagesResponse
     */
    'toc'?: Array<CatalogPageIndexValue>;
    /**
     * 
     * @type {CatalogWithPagesResponsePdf}
     * @memberof CatalogWithPagesResponse
     */
    'pdf': CatalogWithPagesResponsePdf;
    /**
     * 
     * @type {string}
     * @memberof CatalogWithPagesResponse
     */
    'published_date': string;
    /**
     * 
     * @type {CatalogLayout}
     * @memberof CatalogWithPagesResponse
     */
    'layout': CatalogLayout;
    /**
     * 
     * @type {number}
     * @memberof CatalogWithPagesResponse
     */
    'first_page_num': number;
    /**
     * 
     * @type {number}
     * @memberof CatalogWithPagesResponse
     */
    'total_pages': number;
    /**
     * 
     * @type {boolean}
     * @memberof CatalogWithPagesResponse
     */
    'is_published': boolean;
    /**
     * 
     * @type {CatalogStatus}
     * @memberof CatalogWithPagesResponse
     */
    'status': CatalogStatus;
    /**
     * 
     * @type {string}
     * @memberof CatalogWithPagesResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof CatalogWithPagesResponse
     */
    'updated_at': string;
    /**
     * 
     * @type {UploadImageResponse}
     * @memberof CatalogWithPagesResponse
     */
    'cover_image': UploadImageResponse;
    /**
     * 
     * @type {Array<number>}
     * @memberof CatalogWithPagesResponse
     */
    'pages': Array<number>;
}


/**
 * 
 * @export
 * @interface CatalogWithPagesResponsePdf
 */
export interface CatalogWithPagesResponsePdf {
    /**
     * 
     * @type {string}
     * @memberof CatalogWithPagesResponsePdf
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface CatalogsResponse
 */
export interface CatalogsResponse {
    /**
     * 次のページが存在するか
     * @type {boolean}
     * @memberof CatalogsResponse
     */
    'has_next': boolean;
    /**
     * 総ページ数
     * @type {number}
     * @memberof CatalogsResponse
     */
    'total_pages'?: number;
    /**
     * 総件数
     * @type {number}
     * @memberof CatalogsResponse
     */
    'total_count'?: number;
    /**
     * 
     * @type {NotificationsResponseCursor}
     * @memberof CatalogsResponse
     */
    'cursor'?: NotificationsResponseCursor;
    /**
     * 
     * @type {Array<CatalogResponse>}
     * @memberof CatalogsResponse
     */
    'results'?: Array<CatalogResponse>;
}
/**
 * 
 * @export
 * @interface CategoryEntity
 */
export interface CategoryEntity {
    /**
     * 
     * @type {number}
     * @memberof CategoryEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CategoryEntity
     */
    'name': string;
    /**
     * 
     * @type {ProductKind}
     * @memberof CategoryEntity
     */
    'kind': ProductKind;
    /**
     * 
     * @type {number}
     * @memberof CategoryEntity
     */
    'product_variations_count': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof CategoryEntity
     */
    'ancestry': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof CategoryEntity
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryEntity
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryEntity
     */
    'updated_at': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const CategoryKind = {
    INTERIOR: 1,
    BUILDING_MATERIAL: 2
} as const;

export type CategoryKind = typeof CategoryKind[keyof typeof CategoryKind];


/**
 * 
 * @export
 * @interface CategoryResponse
 */
export interface CategoryResponse {
    /**
     * 
     * @type {number}
     * @memberof CategoryResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CategoryResponse
     */
    'name': string;
    /**
     * 
     * @type {ProductKind}
     * @memberof CategoryResponse
     */
    'kind': ProductKind;
    /**
     * 
     * @type {number}
     * @memberof CategoryResponse
     */
    'product_variations_count': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof CategoryResponse
     */
    'ancestry': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof CategoryResponse
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryResponse
     */
    'updated_at': string;
    /**
     * 
     * @type {Array<CategoryEntity>}
     * @memberof CategoryResponse
     */
    'children'?: Array<CategoryEntity>;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ColorType = {
    RED: 1,
    ORANGE: 2,
    YELLOW: 3,
    GREEN: 4,
    BLUE: 5,
    PURPLE: 6,
    PINK: 7,
    DARK_BROWN: 8,
    BROWN: 9,
    BEIGE: 10,
    WHITE: 11,
    GRAY: 12,
    BLACK: 13,
    SILVER: 14,
    GOLD: 15,
    CLEAR: 16,
    MULTI: 17
} as const;

export type ColorType = typeof ColorType[keyof typeof ColorType];


/**
 * 
 * @export
 * @interface ColorTypeEntity
 */
export interface ColorTypeEntity {
    /**
     * 
     * @type {number}
     * @memberof ColorTypeEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ColorTypeEntity
     */
    'label': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ContactMethod = {
    ANY: 1,
    EMAIL: 2,
    PHONE: 3
} as const;

export type ContactMethod = typeof ContactMethod[keyof typeof ContactMethod];


/**
 * 
 * @export
 * @enum {string}
 */

export const DataOperationRequestKind = {
    CREATE: 1,
    EDIT: 2,
    REORDER: 3
} as const;

export type DataOperationRequestKind = typeof DataOperationRequestKind[keyof typeof DataOperationRequestKind];


/**
 * 
 * @export
 * @enum {string}
 */

export const DataOperationRequestStatus = {
    CREATED: 1,
    VALIDATING: 2,
    VALIDATED: 3,
    VALIDATION_FAILED: 4,
    IMPORTING: 5,
    IMPORTED: 6,
    IMPORT_FAILED: 7,
    CANCELLED: 8
} as const;

export type DataOperationRequestStatus = typeof DataOperationRequestStatus[keyof typeof DataOperationRequestStatus];


/**
 * 
 * @export
 * @interface DesignerAccountUpdateParam
 */
export interface DesignerAccountUpdateParam {
    /**
     * 
     * @type {string}
     * @memberof DesignerAccountUpdateParam
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof DesignerAccountUpdateParam
     */
    'last_name': string;
    /**
     * 
     * @type {number}
     * @memberof DesignerAccountUpdateParam
     */
    'industry_id': number;
    /**
     * 
     * @type {number}
     * @memberof DesignerAccountUpdateParam
     */
    'occupation_id': number;
    /**
     * 
     * @type {string}
     * @memberof DesignerAccountUpdateParam
     */
    'company_name': string;
    /**
     * 
     * @type {number}
     * @memberof DesignerAccountUpdateParam
     */
    'company_prefecture_id': number;
    /**
     * 
     * @type {string}
     * @memberof DesignerAccountUpdateParam
     */
    'department'?: string;
    /**
     * 
     * @type {string}
     * @memberof DesignerAccountUpdateParam
     */
    'branch'?: string;
    /**
     * 
     * @type {string}
     * @memberof DesignerAccountUpdateParam
     */
    'official_position'?: string;
    /**
     * 
     * @type {string}
     * @memberof DesignerAccountUpdateParam
     */
    'phone_number': string;
    /**
     * 
     * @type {number}
     * @memberof DesignerAccountUpdateParam
     */
    'birth_year': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof DesignerAccountUpdateParam
     */
    'qualification_ids'?: Array<number>;
}
/**
 * 
 * @export
 * @interface DesignerCreateParam
 */
export interface DesignerCreateParam {
    /**
     * 
     * @type {string}
     * @memberof DesignerCreateParam
     */
    'screen_name': string;
    /**
     * 
     * @type {string}
     * @memberof DesignerCreateParam
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof DesignerCreateParam
     */
    'last_name': string;
    /**
     * 
     * @type {number}
     * @memberof DesignerCreateParam
     */
    'industry_id': number;
    /**
     * 
     * @type {number}
     * @memberof DesignerCreateParam
     */
    'occupation_id': number;
    /**
     * 
     * @type {string}
     * @memberof DesignerCreateParam
     */
    'company_name': string;
    /**
     * 
     * @type {number}
     * @memberof DesignerCreateParam
     */
    'company_prefecture_id': number;
    /**
     * 
     * @type {string}
     * @memberof DesignerCreateParam
     */
    'department'?: string;
    /**
     * 
     * @type {string}
     * @memberof DesignerCreateParam
     */
    'branch'?: string;
    /**
     * 
     * @type {string}
     * @memberof DesignerCreateParam
     */
    'official_position'?: string;
    /**
     * 
     * @type {number}
     * @memberof DesignerCreateParam
     */
    'birth_year': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof DesignerCreateParam
     */
    'qualification_ids'?: Array<number>;
}
/**
 * 
 * @export
 * @interface DesignerResponse
 */
export interface DesignerResponse {
    /**
     * 
     * @type {number}
     * @memberof DesignerResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof DesignerResponse
     */
    'user_id': number;
    /**
     * 
     * @type {string}
     * @memberof DesignerResponse
     */
    'company_name': string;
    /**
     * 
     * @type {number}
     * @memberof DesignerResponse
     */
    'company_prefecture_id': number;
    /**
     * 
     * @type {string}
     * @memberof DesignerResponse
     */
    'department'?: string;
    /**
     * 
     * @type {string}
     * @memberof DesignerResponse
     */
    'branch'?: string;
    /**
     * 
     * @type {string}
     * @memberof DesignerResponse
     */
    'official_position'?: string;
    /**
     * 
     * @type {number}
     * @memberof DesignerResponse
     */
    'birth_year': number;
    /**
     * 
     * @type {Array<QualificationEntity>}
     * @memberof DesignerResponse
     */
    'qualifications'?: Array<QualificationEntity>;
    /**
     * 
     * @type {string}
     * @memberof DesignerResponse
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof DesignerResponse
     */
    'last_name': string;
}
/**
 * 
 * @export
 * @interface Error400Response
 */
export interface Error400Response {
    /**
     * 
     * @type {Array<string>}
     * @memberof Error400Response
     */
    'full_messages': Array<string>;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof Error400Response
     */
    'messages'?: { [key: string]: Array<string>; };
}
/**
 * 
 * @export
 * @interface Error500Response
 */
export interface Error500Response {
    /**
     * 
     * @type {Array<string>}
     * @memberof Error500Response
     */
    'full_messages': Array<string>;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof Error500Response
     */
    'messages'?: { [key: string]: Array<string>; };
}
/**
 * 
 * @export
 * @enum {string}
 */

export const FireProtectionMaterial = {
    FIRE_PROTECTION_MATERIAL: 1,
    SEMI_COMBUSTIBLE: 2,
    RETARDANT: 3
} as const;

export type FireProtectionMaterial = typeof FireProtectionMaterial[keyof typeof FireProtectionMaterial];


/**
 * 
 * @export
 * @enum {string}
 */

export const FireResistance = {
    HOUR_FIREPROOF: 1,
    HALF_HOUR_FIREPROOF: 2,
    HOUR_SEMI_FIREPROOF: 3,
    THREE_QUARTERS_SEMI_FIREPROOF: 4,
    FIRE_PROTECTION: 5
} as const;

export type FireResistance = typeof FireResistance[keyof typeof FireResistance];


/**
 * 
 * @export
 * @enum {string}
 */

export const FlamePreventionRegulation = {
    ANTI_INFLAMMATORY: 1
} as const;

export type FlamePreventionRegulation = typeof FlamePreventionRegulation[keyof typeof FlamePreventionRegulation];


/**
 * 
 * @export
 * @interface FollowCreateParameter
 */
export interface FollowCreateParameter {
    /**
     * 
     * @type {number}
     * @memberof FollowCreateParameter
     */
    'user_id': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Formaldehyde = {
    LEVEL_4: 1,
    LEVEL_3: 2,
    LEVEL_2: 3
} as const;

export type Formaldehyde = typeof Formaldehyde[keyof typeof Formaldehyde];


/**
 * 
 * @export
 * @interface HitCountResponse
 */
export interface HitCountResponse {
    /**
     * 
     * @type {string}
     * @memberof HitCountResponse
     */
    'key': string;
    /**
     * 
     * @type {number}
     * @memberof HitCountResponse
     */
    'count': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ImportFileKind = {
    CSV: 1,
    ZIP: 2,
    DIFF_CSV: 3
} as const;

export type ImportFileKind = typeof ImportFileKind[keyof typeof ImportFileKind];


/**
 * 
 * @export
 * @interface IndustryEntity
 */
export interface IndustryEntity {
    /**
     * 
     * @type {number}
     * @memberof IndustryEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof IndustryEntity
     */
    'label': string;
    /**
     * 
     * @type {boolean}
     * @memberof IndustryEntity
     */
    'is_designer': boolean;
}
/**
 * 
 * @export
 * @interface InitialGroupMakersResponse
 */
export interface InitialGroupMakersResponse {
    /**
     * 
     * @type {string}
     * @memberof InitialGroupMakersResponse
     */
    'initial': string;
    /**
     * 
     * @type {Array<MakerResponse>}
     * @memberof InitialGroupMakersResponse
     */
    'makers': Array<MakerResponse>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const JobStatus = {
    WAIT: 1,
    COMPLETED: 2,
    FAILED: 3
} as const;

export type JobStatus = typeof JobStatus[keyof typeof JobStatus];


/**
 * 
 * @export
 * @interface LatestAndRandomMakersResponse
 */
export interface LatestAndRandomMakersResponse {
    /**
     * 
     * @type {Array<MakerWithCategoriesResponse>}
     * @memberof LatestAndRandomMakersResponse
     */
    'latest_makers'?: Array<MakerWithCategoriesResponse>;
    /**
     * 
     * @type {Array<MakerResponse>}
     * @memberof LatestAndRandomMakersResponse
     */
    'random_makers'?: Array<MakerResponse>;
}
/**
 * 
 * @export
 * @interface LikeCreateParam
 */
export interface LikeCreateParam {
    /**
     * 
     * @type {number}
     * @memberof LikeCreateParam
     */
    'kind': LikeCreateParamKindEnum;
    /**
     * 
     * @type {number}
     * @memberof LikeCreateParam
     */
    'entity_id'?: number;
}

export const LikeCreateParamKindEnum = {
    PROJECT: 1,
    POST: 2,
    INTERIOR: 3,
    BUILDING_MATERIAL: 4
} as const;

export type LikeCreateParamKindEnum = typeof LikeCreateParamKindEnum[keyof typeof LikeCreateParamKindEnum];

/**
 * 
 * @export
 * @interface LikeDeleteParam
 */
export interface LikeDeleteParam {
    /**
     * 
     * @type {number}
     * @memberof LikeDeleteParam
     */
    'kind': LikeDeleteParamKindEnum;
    /**
     * 
     * @type {number}
     * @memberof LikeDeleteParam
     */
    'entity_id'?: number;
}

export const LikeDeleteParamKindEnum = {
    PROJECT: 1,
    POST: 2,
    INTERIOR: 3,
    BUILDING_MATERIAL: 4
} as const;

export type LikeDeleteParamKindEnum = typeof LikeDeleteParamKindEnum[keyof typeof LikeDeleteParamKindEnum];

/**
 * 
 * @export
 * @interface LikeDetailableId
 */
export interface LikeDetailableId {
    /**
     * 
     * @type {number}
     * @memberof LikeDetailableId
     */
    'project_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof LikeDetailableId
     */
    'post_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof LikeDetailableId
     */
    'product_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof LikeDetailableId
     */
    'product_variation_id'?: number;
}
/**
 * 
 * @export
 * @interface LikeItemResponse
 */
export interface LikeItemResponse {
    /**
     * 
     * @type {number}
     * @memberof LikeItemResponse
     */
    'id': number;
    /**
     * 
     * @type {LikeKind}
     * @memberof LikeItemResponse
     */
    'kind': LikeKind;
    /**
     * 
     * @type {string}
     * @memberof LikeItemResponse
     */
    'created_at': string;
    /**
     * 
     * @type {UploadImageResponse}
     * @memberof LikeItemResponse
     */
    'upload_image': UploadImageResponse;
    /**
     * 
     * @type {string}
     * @memberof LikeItemResponse
     */
    'name'?: string;
    /**
     * 
     * @type {LikeItemResponseAuthor}
     * @memberof LikeItemResponse
     */
    'author': LikeItemResponseAuthor;
    /**
     * 
     * @type {LikeDetailableId}
     * @memberof LikeItemResponse
     */
    'detailable_id'?: LikeDetailableId;
}


/**
 * @type LikeItemResponseAuthor
 * @export
 */
export type LikeItemResponseAuthor = MakerResponse | TeamResponse;

/**
 * 
 * @export
 * @enum {string}
 */

export const LikeKind = {
    PROJECT: 1,
    POST: 2,
    INTERIOR: 3,
    BUILDING_MATERIAL: 4
} as const;

export type LikeKind = typeof LikeKind[keyof typeof LikeKind];


/**
 * 
 * @export
 * @interface LikesResponse
 */
export interface LikesResponse {
    /**
     * 次のページが存在するか
     * @type {boolean}
     * @memberof LikesResponse
     */
    'has_next': boolean;
    /**
     * 総ページ数
     * @type {number}
     * @memberof LikesResponse
     */
    'total_pages'?: number;
    /**
     * 総件数
     * @type {number}
     * @memberof LikesResponse
     */
    'total_count'?: number;
    /**
     * 
     * @type {NotificationsResponseCursor}
     * @memberof LikesResponse
     */
    'cursor'?: NotificationsResponseCursor;
    /**
     * 
     * @type {Array<LikeItemResponse>}
     * @memberof LikesResponse
     */
    'results'?: Array<LikeItemResponse>;
}
/**
 * 
 * @export
 * @interface LinksValue
 */
export interface LinksValue {
    /**
     * 
     * @type {string}
     * @memberof LinksValue
     */
    'homepage'?: string;
    /**
     * 
     * @type {string}
     * @memberof LinksValue
     */
    'twitter'?: string;
    /**
     * 
     * @type {string}
     * @memberof LinksValue
     */
    'facebook'?: string;
    /**
     * 
     * @type {string}
     * @memberof LinksValue
     */
    'instagram'?: string;
}
/**
 * 
 * @export
 * @interface ListAddItemParameter
 */
export interface ListAddItemParameter {
    /**
     * 
     * @type {number}
     * @memberof ListAddItemParameter
     */
    'kind': ListAddItemParameterKindEnum;
    /**
     * 
     * @type {number}
     * @memberof ListAddItemParameter
     */
    'item_id': number;
}

export const ListAddItemParameterKindEnum = {
    PROJECT: 1,
    POST: 2,
    INTERIOR: 3,
    BUILDING_MATERIAL: 4
} as const;

export type ListAddItemParameterKindEnum = typeof ListAddItemParameterKindEnum[keyof typeof ListAddItemParameterKindEnum];

/**
 * 
 * @export
 * @interface ListCreateParam
 */
export interface ListCreateParam {
    /**
     * 
     * @type {string}
     * @memberof ListCreateParam
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ListCreateParam
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ListCreateParam
     */
    'is_private': boolean;
    /**
     * 
     * @type {number}
     * @memberof ListCreateParam
     */
    'main_item_id'?: number;
}
/**
 * 
 * @export
 * @interface ListItemDetailableId
 */
export interface ListItemDetailableId {
    /**
     * 
     * @type {number}
     * @memberof ListItemDetailableId
     */
    'project_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListItemDetailableId
     */
    'post_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListItemDetailableId
     */
    'product_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListItemDetailableId
     */
    'product_variation_id'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ListItemKind = {
    PROJECT: 1,
    POST: 2,
    INTERIOR: 3,
    BUILDING_MATERIAL: 4
} as const;

export type ListItemKind = typeof ListItemKind[keyof typeof ListItemKind];


/**
 * 
 * @export
 * @interface ListItemResponse
 */
export interface ListItemResponse {
    /**
     * 
     * @type {number}
     * @memberof ListItemResponse
     */
    'id': number;
    /**
     * 
     * @type {ListItemKind}
     * @memberof ListItemResponse
     */
    'kind': ListItemKind;
    /**
     * 
     * @type {number}
     * @memberof ListItemResponse
     */
    'list_id': number;
    /**
     * 
     * @type {string}
     * @memberof ListItemResponse
     */
    'created_at': string;
    /**
     * 
     * @type {UploadImageResponse}
     * @memberof ListItemResponse
     */
    'upload_image': UploadImageResponse;
    /**
     * 
     * @type {string}
     * @memberof ListItemResponse
     */
    'name'?: string;
    /**
     * 
     * @type {LikeItemResponseAuthor}
     * @memberof ListItemResponse
     */
    'author': LikeItemResponseAuthor;
    /**
     * 
     * @type {ListItemDetailableId}
     * @memberof ListItemResponse
     */
    'detailable_id'?: ListItemDetailableId;
}


/**
 * 
 * @export
 * @interface ListItemsResponse
 */
export interface ListItemsResponse {
    /**
     * 次のページが存在するか
     * @type {boolean}
     * @memberof ListItemsResponse
     */
    'has_next': boolean;
    /**
     * 総ページ数
     * @type {number}
     * @memberof ListItemsResponse
     */
    'total_pages'?: number;
    /**
     * 総件数
     * @type {number}
     * @memberof ListItemsResponse
     */
    'total_count'?: number;
    /**
     * 
     * @type {NotificationsResponseCursor}
     * @memberof ListItemsResponse
     */
    'cursor'?: NotificationsResponseCursor;
    /**
     * 
     * @type {Array<ListItemResponse>}
     * @memberof ListItemsResponse
     */
    'results'?: Array<ListItemResponse>;
}
/**
 * 
 * @export
 * @interface ListResponse
 */
export interface ListResponse {
    /**
     * 
     * @type {number}
     * @memberof ListResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ListResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ListResponse
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ListResponse
     */
    'is_private': boolean;
    /**
     * 
     * @type {number}
     * @memberof ListResponse
     */
    'items_count': number;
    /**
     * 
     * @type {number}
     * @memberof ListResponse
     */
    'user_id': number;
    /**
     * 
     * @type {number}
     * @memberof ListResponse
     */
    'maker_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof ListResponse
     */
    'updated_at': string;
    /**
     * 
     * @type {UserResponse}
     * @memberof ListResponse
     */
    'user': UserResponse;
    /**
     * 
     * @type {Array<UploadImageResponse>}
     * @memberof ListResponse
     */
    'item_images': Array<UploadImageResponse>;
    /**
     * 
     * @type {number}
     * @memberof ListResponse
     */
    'main_item_id'?: number;
    /**
     * 
     * @type {MakerResponse}
     * @memberof ListResponse
     */
    'maker'?: MakerResponse;
}
/**
 * 
 * @export
 * @interface ListUpdateParam
 */
export interface ListUpdateParam {
    /**
     * 
     * @type {string}
     * @memberof ListUpdateParam
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ListUpdateParam
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ListUpdateParam
     */
    'is_private': boolean;
    /**
     * 
     * @type {number}
     * @memberof ListUpdateParam
     */
    'main_item_id'?: number;
}
/**
 * 
 * @export
 * @interface ListsResponse
 */
export interface ListsResponse {
    /**
     * 次のページが存在するか
     * @type {boolean}
     * @memberof ListsResponse
     */
    'has_next': boolean;
    /**
     * 総ページ数
     * @type {number}
     * @memberof ListsResponse
     */
    'total_pages'?: number;
    /**
     * 総件数
     * @type {number}
     * @memberof ListsResponse
     */
    'total_count'?: number;
    /**
     * 
     * @type {NotificationsResponseCursor}
     * @memberof ListsResponse
     */
    'cursor'?: NotificationsResponseCursor;
    /**
     * 
     * @type {Array<ListResponse>}
     * @memberof ListsResponse
     */
    'results'?: Array<ListResponse>;
}
/**
 * 
 * @export
 * @interface LocationValue
 */
export interface LocationValue {
    /**
     * 
     * @type {number}
     * @memberof LocationValue
     */
    'lat': number;
    /**
     * 
     * @type {number}
     * @memberof LocationValue
     */
    'lng': number;
}
/**
 * 
 * @export
 * @interface MagazineResponse
 */
export interface MagazineResponse {
    /**
     * 
     * @type {number}
     * @memberof MagazineResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof MagazineResponse
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof MagazineResponse
     */
    'post_type': string;
    /**
     * 
     * @type {string}
     * @memberof MagazineResponse
     */
    'link': string;
    /**
     * 
     * @type {string}
     * @memberof MagazineResponse
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof MagazineResponse
     */
    'thumbnail_url': string;
}
/**
 * 
 * @export
 * @interface MailMagazineUpdateParam
 */
export interface MailMagazineUpdateParam {
    /**
     * 
     * @type {boolean}
     * @memberof MailMagazineUpdateParam
     */
    'is_subscribed_mail_magazine': boolean;
}
/**
 * 
 * @export
 * @interface MakerContactCreateParam
 */
export interface MakerContactCreateParam {
    /**
     * 
     * @type {number}
     * @memberof MakerContactCreateParam
     */
    'product_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MakerContactCreateParam
     */
    'product_variation_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MakerContactCreateParam
     */
    'kind': MakerContactCreateParamKindEnum;
    /**
     * 
     * @type {string}
     * @memberof MakerContactCreateParam
     */
    'content': string;
}

export const MakerContactCreateParamKindEnum = {
    STOCK: 1,
    PRODUCT: 2,
    CUSTOM: 3,
    QUOTE: 4,
    OTHER: 5
} as const;

export type MakerContactCreateParamKindEnum = typeof MakerContactCreateParamKindEnum[keyof typeof MakerContactCreateParamKindEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const MakerContactKind = {
    STOCK: 1,
    PRODUCT: 2,
    CUSTOM: 3,
    QUOTE: 4,
    OTHER: 5
} as const;

export type MakerContactKind = typeof MakerContactKind[keyof typeof MakerContactKind];


/**
 * 
 * @export
 * @interface MakerContactResponse
 */
export interface MakerContactResponse {
    /**
     * 
     * @type {number}
     * @memberof MakerContactResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof MakerContactResponse
     */
    'code': string;
    /**
     * 
     * @type {number}
     * @memberof MakerContactResponse
     */
    'maker_id': number;
    /**
     * 
     * @type {number}
     * @memberof MakerContactResponse
     */
    'user_id': number;
    /**
     * 
     * @type {number}
     * @memberof MakerContactResponse
     */
    'product_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MakerContactResponse
     */
    'product_variation_id'?: number;
    /**
     * 
     * @type {MakerContactKind}
     * @memberof MakerContactResponse
     */
    'kind': MakerContactKind;
    /**
     * 
     * @type {MakerContactStatus}
     * @memberof MakerContactResponse
     */
    'status': MakerContactStatus;
    /**
     * 
     * @type {string}
     * @memberof MakerContactResponse
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof MakerContactResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof MakerContactResponse
     */
    'updated_at': string;
    /**
     * 
     * @type {UserResponse}
     * @memberof MakerContactResponse
     */
    'user': UserResponse;
    /**
     * 
     * @type {MakerResponse}
     * @memberof MakerContactResponse
     */
    'maker'?: MakerResponse;
    /**
     * 
     * @type {ProductResponse}
     * @memberof MakerContactResponse
     */
    'product'?: ProductResponse;
    /**
     * 
     * @type {ProductVariationResponse}
     * @memberof MakerContactResponse
     */
    'product_variation'?: ProductVariationResponse;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const MakerContactStatus = {
    UNRESOLVED: 1,
    IN_PROGRESS: 2,
    RESOLVED: 3
} as const;

export type MakerContactStatus = typeof MakerContactStatus[keyof typeof MakerContactStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const MakerContractStatus = {
    ACTIVE: 0,
    INACTIVE: 1
} as const;

export type MakerContractStatus = typeof MakerContractStatus[keyof typeof MakerContractStatus];


/**
 * 
 * @export
 * @interface MakerEntity
 */
export interface MakerEntity {
    /**
     * 
     * @type {number}
     * @memberof MakerEntity
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof MakerEntity
     */
    'user_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MakerEntity
     */
    'icon_image_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MakerEntity
     */
    'logo_image_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MakerEntity
     */
    'copy'?: string;
    /**
     * 
     * @type {string}
     * @memberof MakerEntity
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof MakerEntity
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof MakerEntity
     */
    'phone_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof MakerEntity
     */
    'contact_form_url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MakerEntity
     */
    'can_service_contacted': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MakerEntity
     */
    'can_telephone_contacted': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MakerEntity
     */
    'can_form_contacted': boolean;
    /**
     * 
     * @type {LinksValue}
     * @memberof MakerEntity
     */
    'links'?: LinksValue;
    /**
     * 
     * @type {Array<string>}
     * @memberof MakerEntity
     */
    'keywords'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MakerEntity
     */
    'supplier_code'?: string;
    /**
     * 
     * @type {number}
     * @memberof MakerEntity
     */
    'sample_shipping_time'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MakerEntity
     */
    'is_email_auto_send_enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof MakerEntity
     */
    'phonetic_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof MakerEntity
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof MakerEntity
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface MakerIndexResponse
 */
export interface MakerIndexResponse {
    /**
     * 
     * @type {number}
     * @memberof MakerIndexResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof MakerIndexResponse
     */
    'user_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MakerIndexResponse
     */
    'icon_image_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MakerIndexResponse
     */
    'logo_image_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MakerIndexResponse
     */
    'copy'?: string;
    /**
     * 
     * @type {string}
     * @memberof MakerIndexResponse
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof MakerIndexResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof MakerIndexResponse
     */
    'phone_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof MakerIndexResponse
     */
    'contact_form_url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MakerIndexResponse
     */
    'can_service_contacted': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MakerIndexResponse
     */
    'can_telephone_contacted': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MakerIndexResponse
     */
    'can_form_contacted': boolean;
    /**
     * 
     * @type {LinksValue}
     * @memberof MakerIndexResponse
     */
    'links'?: LinksValue;
    /**
     * 
     * @type {Array<string>}
     * @memberof MakerIndexResponse
     */
    'keywords'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MakerIndexResponse
     */
    'supplier_code'?: string;
    /**
     * 
     * @type {number}
     * @memberof MakerIndexResponse
     */
    'sample_shipping_time'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MakerIndexResponse
     */
    'is_email_auto_send_enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof MakerIndexResponse
     */
    'phonetic_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof MakerIndexResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof MakerIndexResponse
     */
    'updated_at': string;
    /**
     * 
     * @type {boolean}
     * @memberof MakerIndexResponse
     */
    'is_contracted': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MakerIndexResponse
     */
    'is_sample_requestable': boolean;
    /**
     * 
     * @type {UploadImageResponse}
     * @memberof MakerIndexResponse
     */
    'icon'?: UploadImageResponse;
    /**
     * 
     * @type {UploadImageResponse}
     * @memberof MakerIndexResponse
     */
    'logo'?: UploadImageResponse;
    /**
     * 
     * @type {Array<MakerVisualImageResponse>}
     * @memberof MakerIndexResponse
     */
    'visual_images'?: Array<MakerVisualImageResponse>;
    /**
     * 
     * @type {Array<MakerSectionResponse>}
     * @memberof MakerIndexResponse
     */
    'sections'?: Array<MakerSectionResponse>;
    /**
     * 
     * @type {Array<CategoryResponse>}
     * @memberof MakerIndexResponse
     */
    'categories'?: Array<CategoryResponse>;
    /**
     * 
     * @type {number}
     * @memberof MakerIndexResponse
     */
    'interior_product_variations_count': number;
    /**
     * 
     * @type {number}
     * @memberof MakerIndexResponse
     */
    'building_material_product_variations_count': number;
}
/**
 * 
 * @export
 * @interface MakerResponse
 */
export interface MakerResponse {
    /**
     * 
     * @type {number}
     * @memberof MakerResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof MakerResponse
     */
    'user_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MakerResponse
     */
    'icon_image_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MakerResponse
     */
    'logo_image_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MakerResponse
     */
    'copy'?: string;
    /**
     * 
     * @type {string}
     * @memberof MakerResponse
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof MakerResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof MakerResponse
     */
    'phone_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof MakerResponse
     */
    'contact_form_url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MakerResponse
     */
    'can_service_contacted': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MakerResponse
     */
    'can_telephone_contacted': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MakerResponse
     */
    'can_form_contacted': boolean;
    /**
     * 
     * @type {LinksValue}
     * @memberof MakerResponse
     */
    'links'?: LinksValue;
    /**
     * 
     * @type {Array<string>}
     * @memberof MakerResponse
     */
    'keywords'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MakerResponse
     */
    'supplier_code'?: string;
    /**
     * 
     * @type {number}
     * @memberof MakerResponse
     */
    'sample_shipping_time'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MakerResponse
     */
    'is_email_auto_send_enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof MakerResponse
     */
    'phonetic_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof MakerResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof MakerResponse
     */
    'updated_at': string;
    /**
     * 
     * @type {UploadImageResponse}
     * @memberof MakerResponse
     */
    'icon'?: UploadImageResponse;
    /**
     * 
     * @type {UploadImageResponse}
     * @memberof MakerResponse
     */
    'logo'?: UploadImageResponse;
    /**
     * 
     * @type {boolean}
     * @memberof MakerResponse
     */
    'is_contracted': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MakerResponse
     */
    'is_sample_requestable': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MakerSectionContentType = {
    PICKUP_PRODUCT_VARIATIONS: 1,
    PICKUP_LISTS: 2,
    PICKUP_POSTS: 3,
    LATEST_PRODUCT_VARIATIONS: 4,
    LATEST_POSTS: 5
} as const;

export type MakerSectionContentType = typeof MakerSectionContentType[keyof typeof MakerSectionContentType];


/**
 * 
 * @export
 * @interface MakerSectionItem
 */
export interface MakerSectionItem {
}
/**
 * 
 * @export
 * @interface MakerSectionItemsResponse
 */
export interface MakerSectionItemsResponse {
    /**
     * 
     * @type {Array<MakerSectionItemsResponseItemsInner>}
     * @memberof MakerSectionItemsResponse
     */
    'items': Array<MakerSectionItemsResponseItemsInner>;
}
/**
 * @type MakerSectionItemsResponseItemsInner
 * @export
 */
export type MakerSectionItemsResponseItemsInner = ListResponse | PostResponse | ProductVariationWithProductResponse;

/**
 * 
 * @export
 * @interface MakerSectionResponse
 */
export interface MakerSectionResponse {
    /**
     * 
     * @type {number}
     * @memberof MakerSectionResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MakerSectionResponse
     */
    'title': string;
    /**
     * 
     * @type {MakerSectionContentType}
     * @memberof MakerSectionResponse
     */
    'content_type': MakerSectionContentType;
    /**
     * 
     * @type {MakerSectionResponseContent}
     * @memberof MakerSectionResponse
     */
    'content': MakerSectionResponseContent;
    /**
     * 
     * @type {string}
     * @memberof MakerSectionResponse
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof MakerSectionResponse
     */
    'updated_at'?: string;
}


/**
 * @type MakerSectionResponseContent
 * @export
 */
export type MakerSectionResponseContent = MakerSectionItemsResponse;

/**
 * 
 * @export
 * @interface MakerVisualImageResponse
 */
export interface MakerVisualImageResponse {
    /**
     * 
     * @type {number}
     * @memberof MakerVisualImageResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof MakerVisualImageResponse
     */
    'maker_id': number;
    /**
     * 
     * @type {number}
     * @memberof MakerVisualImageResponse
     */
    'upload_image_id': number;
    /**
     * 
     * @type {string}
     * @memberof MakerVisualImageResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof MakerVisualImageResponse
     */
    'updated_at': string;
    /**
     * 
     * @type {UploadImageResponse}
     * @memberof MakerVisualImageResponse
     */
    'upload_image': UploadImageResponse;
}
/**
 * 
 * @export
 * @interface MakerWithCategoriesResponse
 */
export interface MakerWithCategoriesResponse {
    /**
     * 
     * @type {number}
     * @memberof MakerWithCategoriesResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof MakerWithCategoriesResponse
     */
    'user_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MakerWithCategoriesResponse
     */
    'icon_image_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MakerWithCategoriesResponse
     */
    'logo_image_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MakerWithCategoriesResponse
     */
    'copy'?: string;
    /**
     * 
     * @type {string}
     * @memberof MakerWithCategoriesResponse
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof MakerWithCategoriesResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof MakerWithCategoriesResponse
     */
    'phone_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof MakerWithCategoriesResponse
     */
    'contact_form_url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MakerWithCategoriesResponse
     */
    'can_service_contacted': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MakerWithCategoriesResponse
     */
    'can_telephone_contacted': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MakerWithCategoriesResponse
     */
    'can_form_contacted': boolean;
    /**
     * 
     * @type {LinksValue}
     * @memberof MakerWithCategoriesResponse
     */
    'links'?: LinksValue;
    /**
     * 
     * @type {Array<string>}
     * @memberof MakerWithCategoriesResponse
     */
    'keywords'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MakerWithCategoriesResponse
     */
    'supplier_code'?: string;
    /**
     * 
     * @type {number}
     * @memberof MakerWithCategoriesResponse
     */
    'sample_shipping_time'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MakerWithCategoriesResponse
     */
    'is_email_auto_send_enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof MakerWithCategoriesResponse
     */
    'phonetic_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof MakerWithCategoriesResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof MakerWithCategoriesResponse
     */
    'updated_at': string;
    /**
     * 
     * @type {UploadImageResponse}
     * @memberof MakerWithCategoriesResponse
     */
    'icon'?: UploadImageResponse;
    /**
     * 
     * @type {UploadImageResponse}
     * @memberof MakerWithCategoriesResponse
     */
    'logo'?: UploadImageResponse;
    /**
     * 
     * @type {Array<CategoryResponse>}
     * @memberof MakerWithCategoriesResponse
     */
    'categories': Array<CategoryResponse>;
    /**
     * 
     * @type {UserEntity}
     * @memberof MakerWithCategoriesResponse
     */
    'user'?: UserEntity;
    /**
     * 
     * @type {number}
     * @memberof MakerWithCategoriesResponse
     */
    'posts_count': number;
    /**
     * 
     * @type {number}
     * @memberof MakerWithCategoriesResponse
     */
    'interior_product_variations_count': number;
    /**
     * 
     * @type {number}
     * @memberof MakerWithCategoriesResponse
     */
    'building_material_product_variations_count': number;
    /**
     * 
     * @type {number}
     * @memberof MakerWithCategoriesResponse
     */
    'catalogs_count': number;
    /**
     * 
     * @type {boolean}
     * @memberof MakerWithCategoriesResponse
     */
    'has_sample_item': boolean;
    /**
     * 
     * @type {Array<MakerVisualImageResponse>}
     * @memberof MakerWithCategoriesResponse
     */
    'visual_images'?: Array<MakerVisualImageResponse>;
    /**
     * 
     * @type {boolean}
     * @memberof MakerWithCategoriesResponse
     */
    'is_contracted': boolean;
}
/**
 * 
 * @export
 * @interface MakersResponse
 */
export interface MakersResponse {
    /**
     * 次のページが存在するか
     * @type {boolean}
     * @memberof MakersResponse
     */
    'has_next': boolean;
    /**
     * 総ページ数
     * @type {number}
     * @memberof MakersResponse
     */
    'total_pages'?: number;
    /**
     * 総件数
     * @type {number}
     * @memberof MakersResponse
     */
    'total_count'?: number;
    /**
     * 
     * @type {NotificationsResponseCursor}
     * @memberof MakersResponse
     */
    'cursor'?: NotificationsResponseCursor;
    /**
     * 
     * @type {Array<MakerResponse>}
     * @memberof MakersResponse
     */
    'results'?: Array<MakerResponse>;
}
/**
 * 
 * @export
 * @interface MaterialPinParameter
 */
export interface MaterialPinParameter {
    /**
     * 
     * @type {number}
     * @memberof MaterialPinParameter
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MaterialPinParameter
     */
    'position_x': number;
    /**
     * 
     * @type {number}
     * @memberof MaterialPinParameter
     */
    'position_y': number;
    /**
     * 
     * @type {number}
     * @memberof MaterialPinParameter
     */
    'material_id': number;
    /**
     * 
     * @type {string}
     * @memberof MaterialPinParameter
     */
    'notes'?: string;
}
/**
 * 
 * @export
 * @interface MaterialPinResponse
 */
export interface MaterialPinResponse {
    /**
     * 
     * @type {number}
     * @memberof MaterialPinResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof MaterialPinResponse
     */
    'color_type_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MaterialPinResponse
     */
    'material_id': number;
    /**
     * 
     * @type {string}
     * @memberof MaterialPinResponse
     */
    'notes'?: string;
    /**
     * 
     * @type {ColorTypeEntity}
     * @memberof MaterialPinResponse
     */
    'color_type'?: ColorTypeEntity;
    /**
     * 
     * @type {AvailablePartEntity}
     * @memberof MaterialPinResponse
     */
    'part'?: AvailablePartEntity;
    /**
     * 
     * @type {Array<ProductMaterialEntity>}
     * @memberof MaterialPinResponse
     */
    'materials': Array<ProductMaterialEntity>;
}
/**
 * 
 * @export
 * @interface MaterialResponse
 */
export interface MaterialResponse {
    /**
     * 
     * @type {number}
     * @memberof MaterialResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof MaterialResponse
     */
    'name': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof MaterialResponse
     */
    'ancestry': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof MaterialResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof MaterialResponse
     */
    'updated_at': string;
    /**
     * 
     * @type {Array<ProductMaterialEntity>}
     * @memberof MaterialResponse
     */
    'children'?: Array<ProductMaterialEntity>;
}
/**
 * 
 * @export
 * @interface MyUserResponse
 */
export interface MyUserResponse {
    /**
     * 
     * @type {number}
     * @memberof MyUserResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof MyUserResponse
     */
    'screen_name': string;
    /**
     * 
     * @type {string}
     * @memberof MyUserResponse
     */
    'profile'?: string;
    /**
     * 
     * @type {UserKind}
     * @memberof MyUserResponse
     */
    'kind': UserKind;
    /**
     * 
     * @type {number}
     * @memberof MyUserResponse
     */
    'following_count': number;
    /**
     * 
     * @type {number}
     * @memberof MyUserResponse
     */
    'followers_count': number;
    /**
     * 
     * @type {LinksValue}
     * @memberof MyUserResponse
     */
    'links'?: LinksValue;
    /**
     * 
     * @type {boolean}
     * @memberof MyUserResponse
     */
    'is_subscribed_mail_magazine': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof MyUserResponse
     */
    'keywords'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MyUserResponse
     */
    'contact_email'?: string;
    /**
     * 
     * @type {string}
     * @memberof MyUserResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof MyUserResponse
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof MyUserResponse
     */
    'email': string;
    /**
     * 
     * @type {UploadImageResponse}
     * @memberof MyUserResponse
     */
    'icon': UploadImageResponse;
    /**
     * 
     * @type {MakerEntity}
     * @memberof MyUserResponse
     */
    'maker'?: MakerEntity;
    /**
     * 
     * @type {Array<TeamMemberEntity>}
     * @memberof MyUserResponse
     */
    'team_members': Array<TeamMemberEntity>;
    /**
     * 
     * @type {boolean}
     * @memberof MyUserResponse
     */
    'has_unread_notification': boolean;
    /**
     * 
     * @type {IndustryEntity}
     * @memberof MyUserResponse
     */
    'industry'?: IndustryEntity;
    /**
     * 
     * @type {OccupationEntity}
     * @memberof MyUserResponse
     */
    'occupation'?: OccupationEntity;
    /**
     * 
     * @type {DesignerResponse}
     * @memberof MyUserResponse
     */
    'designer'?: DesignerResponse;
    /**
     * 
     * @type {Array<NotificationSettingEntity>}
     * @memberof MyUserResponse
     */
    'notification_settings': Array<NotificationSettingEntity>;
    /**
     * 
     * @type {boolean}
     * @memberof MyUserResponse
     */
    'has_created_project': boolean;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const NewsCategory = {
    NEW: 1,
    ANNOUNCEMENT: 2,
    NEW_FEATURE: 3,
    EVENT: 4,
    UPDATE: 5
} as const;

export type NewsCategory = typeof NewsCategory[keyof typeof NewsCategory];


/**
 * 
 * @export
 * @interface NewsEntity
 */
export interface NewsEntity {
    /**
     * 
     * @type {number}
     * @memberof NewsEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof NewsEntity
     */
    'title': string;
    /**
     * 
     * @type {NewsCategory}
     * @memberof NewsEntity
     */
    'category': NewsCategory;
    /**
     * 
     * @type {string}
     * @memberof NewsEntity
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsEntity
     */
    'published_at': string;
    /**
     * 
     * @type {string}
     * @memberof NewsEntity
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof NewsEntity
     */
    'updated_at': string;
}


/**
 * 
 * @export
 * @interface NewsListResponse
 */
export interface NewsListResponse {
    /**
     * 次のページが存在するか
     * @type {boolean}
     * @memberof NewsListResponse
     */
    'has_next': boolean;
    /**
     * 総ページ数
     * @type {number}
     * @memberof NewsListResponse
     */
    'total_pages'?: number;
    /**
     * 総件数
     * @type {number}
     * @memberof NewsListResponse
     */
    'total_count'?: number;
    /**
     * 
     * @type {NotificationsResponseCursor}
     * @memberof NewsListResponse
     */
    'cursor'?: NotificationsResponseCursor;
    /**
     * 
     * @type {Array<NewsEntity>}
     * @memberof NewsListResponse
     */
    'results'?: Array<NewsEntity>;
}
/**
 * 
 * @export
 * @interface NextDeliveryDateResponse
 */
export interface NextDeliveryDateResponse {
    /**
     * 
     * @type {string}
     * @memberof NextDeliveryDateResponse
     */
    'date': string;
}
/**
 * 
 * @export
 * @interface NotePinParameter
 */
export interface NotePinParameter {
    /**
     * 
     * @type {number}
     * @memberof NotePinParameter
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotePinParameter
     */
    'position_x': number;
    /**
     * 
     * @type {number}
     * @memberof NotePinParameter
     */
    'position_y': number;
    /**
     * 
     * @type {number}
     * @memberof NotePinParameter
     */
    'maker_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotePinParameter
     */
    'maker_text'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotePinParameter
     */
    'text'?: string;
}
/**
 * 
 * @export
 * @interface NotePinResponse
 */
export interface NotePinResponse {
    /**
     * 
     * @type {number}
     * @memberof NotePinResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof NotePinResponse
     */
    'maker_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotePinResponse
     */
    'maker_text'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotePinResponse
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotePinResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof NotePinResponse
     */
    'updated_at': string;
    /**
     * 
     * @type {MakerResponse}
     * @memberof NotePinResponse
     */
    'maker'?: MakerResponse;
}
/**
 * 
 * @export
 * @interface NotificationActionEntityValue
 */
export interface NotificationActionEntityValue {
    /**
     * 
     * @type {number}
     * @memberof NotificationActionEntityValue
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof NotificationActionEntityValue
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof NotificationActionEntityValue
     */
    'entity_type': string;
    /**
     * 
     * @type {number}
     * @memberof NotificationActionEntityValue
     */
    'relational_id'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const NotificationActionType = {
    LIKE: 1,
    FOLLOW: 2,
    ADD_LIST: 3,
    JOIN_TEAM: 4,
    REJECT_TEAM: 5,
    APPROVE_TEAM: 6
} as const;

export type NotificationActionType = typeof NotificationActionType[keyof typeof NotificationActionType];


/**
 * 
 * @export
 * @interface NotificationResponse
 */
export interface NotificationResponse {
    /**
     * 
     * @type {number}
     * @memberof NotificationResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof NotificationResponse
     */
    'user_id': number;
    /**
     * 
     * @type {number}
     * @memberof NotificationResponse
     */
    'action_user_id': number;
    /**
     * 
     * @type {NotificationActionType}
     * @memberof NotificationResponse
     */
    'action_type': NotificationActionType;
    /**
     * 
     * @type {Array<NotificationActionEntityValue>}
     * @memberof NotificationResponse
     */
    'action_entities': Array<NotificationActionEntityValue>;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationResponse
     */
    'has_read': boolean;
    /**
     * 
     * @type {string}
     * @memberof NotificationResponse
     */
    'created_at': string;
    /**
     * 
     * @type {UserResponse}
     * @memberof NotificationResponse
     */
    'action_user'?: UserResponse;
}


/**
 * 
 * @export
 * @interface NotificationSettingEntity
 */
export interface NotificationSettingEntity {
    /**
     * 
     * @type {number}
     * @memberof NotificationSettingEntity
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof NotificationSettingEntity
     */
    'user_id': number;
    /**
     * 
     * @type {NotificationType}
     * @memberof NotificationSettingEntity
     */
    'notification_type': NotificationType;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettingEntity
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof NotificationSettingEntity
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof NotificationSettingEntity
     */
    'updated_at': string;
}


/**
 * 
 * @export
 * @interface NotificationSettingsUpdateParameter
 */
export interface NotificationSettingsUpdateParameter {
    /**
     * 
     * @type {NotificationType}
     * @memberof NotificationSettingsUpdateParameter
     */
    'notification_type': NotificationType;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettingsUpdateParameter
     */
    'enabled': boolean;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const NotificationType = {
    EMAIL_LIKE: 1,
    EMAIL_ADD_LIST: 2
} as const;

export type NotificationType = typeof NotificationType[keyof typeof NotificationType];


/**
 * 
 * @export
 * @interface NotificationsResponse
 */
export interface NotificationsResponse {
    /**
     * 次のページが存在するか
     * @type {boolean}
     * @memberof NotificationsResponse
     */
    'has_next': boolean;
    /**
     * 総ページ数
     * @type {number}
     * @memberof NotificationsResponse
     */
    'total_pages'?: number;
    /**
     * 総件数
     * @type {number}
     * @memberof NotificationsResponse
     */
    'total_count'?: number;
    /**
     * 
     * @type {NotificationsResponseCursor}
     * @memberof NotificationsResponse
     */
    'cursor'?: NotificationsResponseCursor;
    /**
     * 
     * @type {Array<NotificationResponse>}
     * @memberof NotificationsResponse
     */
    'results'?: Array<NotificationResponse>;
}
/**
 * @type NotificationsResponseCursor
 * 次のページをリクエストする際に渡すID
 * @export
 */
export type NotificationsResponseCursor = number | string;

/**
 * 
 * @export
 * @interface OccupationEntity
 */
export interface OccupationEntity {
    /**
     * 
     * @type {number}
     * @memberof OccupationEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof OccupationEntity
     */
    'label': string;
    /**
     * 
     * @type {boolean}
     * @memberof OccupationEntity
     */
    'is_designer': boolean;
}
/**
 * 
 * @export
 * @interface OneTimeTokenParameter
 */
export interface OneTimeTokenParameter {
    /**
     * 
     * @type {string}
     * @memberof OneTimeTokenParameter
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface OrderCreateParameter
 */
export interface OrderCreateParameter {
    /**
     * 
     * @type {number}
     * @memberof OrderCreateParameter
     */
    'contact_method'?: OrderCreateParameterContactMethodEnum;
    /**
     * 
     * @type {number}
     * @memberof OrderCreateParameter
     */
    'address_id': number;
    /**
     * 
     * @type {Array<OrderCreateParameterOrderItemsInner>}
     * @memberof OrderCreateParameter
     */
    'order_items': Array<OrderCreateParameterOrderItemsInner>;
    /**
     * 
     * @type {OrderCreateParameterQuestionnaire}
     * @memberof OrderCreateParameter
     */
    'questionnaire'?: OrderCreateParameterQuestionnaire;
}

export const OrderCreateParameterContactMethodEnum = {
    ANY: 1,
    EMAIL: 2,
    PHONE: 3
} as const;

export type OrderCreateParameterContactMethodEnum = typeof OrderCreateParameterContactMethodEnum[keyof typeof OrderCreateParameterContactMethodEnum];

/**
 * 
 * @export
 * @interface OrderCreateParameterOrderItemsInner
 */
export interface OrderCreateParameterOrderItemsInner {
    /**
     * 
     * @type {number}
     * @memberof OrderCreateParameterOrderItemsInner
     */
    'product_variation_id': number;
    /**
     * 
     * @type {number}
     * @memberof OrderCreateParameterOrderItemsInner
     */
    'quantity': number;
    /**
     * 
     * @type {number}
     * @memberof OrderCreateParameterOrderItemsInner
     */
    'board_id'?: number;
    /**
     * 
     * @type {Array<OrderCreateParameterOrderItemsInnerAdditionalDataInner>}
     * @memberof OrderCreateParameterOrderItemsInner
     */
    'additional_data'?: Array<OrderCreateParameterOrderItemsInnerAdditionalDataInner>;
}
/**
 * 
 * @export
 * @interface OrderCreateParameterOrderItemsInnerAdditionalDataInner
 */
export interface OrderCreateParameterOrderItemsInnerAdditionalDataInner {
    /**
     * 
     * @type {string}
     * @memberof OrderCreateParameterOrderItemsInnerAdditionalDataInner
     */
    'label'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderCreateParameterOrderItemsInnerAdditionalDataInner
     */
    'value'?: number;
}
/**
 * 
 * @export
 * @interface OrderCreateParameterQuestionnaire
 */
export interface OrderCreateParameterQuestionnaire {
    /**
     * 
     * @type {number}
     * @memberof OrderCreateParameterQuestionnaire
     */
    'project_status'?: OrderCreateParameterQuestionnaireProjectStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderCreateParameterQuestionnaire
     */
    'project_title'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderCreateParameterQuestionnaire
     */
    'project_prefecture_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderCreateParameterQuestionnaire
     */
    'project_floor_space'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderCreateParameterQuestionnaire
     */
    'project_completion'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderCreateParameterQuestionnaire
     */
    'building_type_id'?: number;
}

export const OrderCreateParameterQuestionnaireProjectStatusEnum = {
    BASIC_PLAN: 1,
    BASIC_DESIGN: 2,
    DETAIL_DESIGN: 3,
    DESIGN_MANAGEMENT: 4,
    NONE: 5
} as const;

export type OrderCreateParameterQuestionnaireProjectStatusEnum = typeof OrderCreateParameterQuestionnaireProjectStatusEnum[keyof typeof OrderCreateParameterQuestionnaireProjectStatusEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const OrderDeliveryMethod = {
    SAGAWA: 1,
    YAMATO_NEKOPOSU: 2
} as const;

export type OrderDeliveryMethod = typeof OrderDeliveryMethod[keyof typeof OrderDeliveryMethod];


/**
 * 
 * @export
 * @interface OrderDeliveryResponse
 */
export interface OrderDeliveryResponse {
    /**
     * 
     * @type {number}
     * @memberof OrderDeliveryResponse
     */
    'id': number;
    /**
     * 
     * @type {OrderDeliveryStatus}
     * @memberof OrderDeliveryResponse
     */
    'delivery_status': OrderDeliveryStatus;
    /**
     * 
     * @type {OrderDeliveryMethod}
     * @memberof OrderDeliveryResponse
     */
    'delivery_method'?: OrderDeliveryMethod;
    /**
     * 
     * @type {string}
     * @memberof OrderDeliveryResponse
     */
    'delivery_tracking_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDeliveryResponse
     */
    'scheduled_shipping_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDeliveryResponse
     */
    'shipped_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDeliveryResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof OrderDeliveryResponse
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof OrderDeliveryResponse
     */
    'delivery_method_label'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDeliveryResponse
     */
    'delivery_tracking_url'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const OrderDeliveryStatus = {
    WAITING_FOR_SHIPMENT: 1,
    WORKING: 2,
    PARTLY_SHIPPED: 3,
    SHIPPED: 4,
    PENDING: 5,
    CANCEL: 6
} as const;

export type OrderDeliveryStatus = typeof OrderDeliveryStatus[keyof typeof OrderDeliveryStatus];


/**
 * 
 * @export
 * @interface OrderGroupResponse
 */
export interface OrderGroupResponse {
    /**
     * 
     * @type {number}
     * @memberof OrderGroupResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof OrderGroupResponse
     */
    'code': string;
    /**
     * 
     * @type {OrderKind}
     * @memberof OrderGroupResponse
     */
    'kind': OrderKind;
    /**
     * 
     * @type {number}
     * @memberof OrderGroupResponse
     */
    'order_id': number;
    /**
     * 
     * @type {string}
     * @memberof OrderGroupResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof OrderGroupResponse
     */
    'updated_at': string;
    /**
     * 
     * @type {Array<OrderMakerGroupResponse>}
     * @memberof OrderGroupResponse
     */
    'maker_groups': Array<OrderMakerGroupResponse>;
    /**
     * 
     * @type {OrderDeliveryResponse}
     * @memberof OrderGroupResponse
     */
    'delivery'?: OrderDeliveryResponse;
}


/**
 * 
 * @export
 * @interface OrderGroupWithOrderResponse
 */
export interface OrderGroupWithOrderResponse {
    /**
     * 
     * @type {number}
     * @memberof OrderGroupWithOrderResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof OrderGroupWithOrderResponse
     */
    'code': string;
    /**
     * 
     * @type {OrderKind}
     * @memberof OrderGroupWithOrderResponse
     */
    'kind': OrderKind;
    /**
     * 
     * @type {number}
     * @memberof OrderGroupWithOrderResponse
     */
    'order_id': number;
    /**
     * 
     * @type {string}
     * @memberof OrderGroupWithOrderResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof OrderGroupWithOrderResponse
     */
    'updated_at': string;
    /**
     * 
     * @type {Array<OrderMakerGroupResponse>}
     * @memberof OrderGroupWithOrderResponse
     */
    'maker_groups': Array<OrderMakerGroupResponse>;
    /**
     * 
     * @type {OrderDeliveryResponse}
     * @memberof OrderGroupWithOrderResponse
     */
    'delivery'?: OrderDeliveryResponse;
    /**
     * 
     * @type {OrderResponse}
     * @memberof OrderGroupWithOrderResponse
     */
    'order': OrderResponse;
}


/**
 * 
 * @export
 * @interface OrderGroupsResponse
 */
export interface OrderGroupsResponse {
    /**
     * 次のページが存在するか
     * @type {boolean}
     * @memberof OrderGroupsResponse
     */
    'has_next': boolean;
    /**
     * 総ページ数
     * @type {number}
     * @memberof OrderGroupsResponse
     */
    'total_pages'?: number;
    /**
     * 総件数
     * @type {number}
     * @memberof OrderGroupsResponse
     */
    'total_count'?: number;
    /**
     * 
     * @type {NotificationsResponseCursor}
     * @memberof OrderGroupsResponse
     */
    'cursor'?: NotificationsResponseCursor;
    /**
     * 
     * @type {Array<OrderGroupWithOrderResponse>}
     * @memberof OrderGroupsResponse
     */
    'results'?: Array<OrderGroupWithOrderResponse>;
}
/**
 * 
 * @export
 * @interface OrderItemNoteResponse
 */
export interface OrderItemNoteResponse {
    /**
     * 
     * @type {number}
     * @memberof OrderItemNoteResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemNoteResponse
     */
    'order_item_id': number;
    /**
     * 
     * @type {string}
     * @memberof OrderItemNoteResponse
     */
    'label': string;
    /**
     * 
     * @type {number}
     * @memberof OrderItemNoteResponse
     */
    'value': number;
    /**
     * 
     * @type {string}
     * @memberof OrderItemNoteResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemNoteResponse
     */
    'option_label': string;
}
/**
 * 
 * @export
 * @interface OrderItemResponse
 */
export interface OrderItemResponse {
    /**
     * 
     * @type {number}
     * @memberof OrderItemResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemResponse
     */
    'sample_item_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemResponse
     */
    'product_variation_id': number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemResponse
     */
    'product_id': number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemResponse
     */
    'upload_image_id': number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemResponse
     */
    'maker_id': number;
    /**
     * 
     * @type {string}
     * @memberof OrderItemResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemResponse
     */
    'item_number': string;
    /**
     * 
     * @type {number}
     * @memberof OrderItemResponse
     */
    'quantity': number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemResponse
     */
    'order_id': number;
    /**
     * 
     * @type {string}
     * @memberof OrderItemResponse
     */
    'created_at': string;
    /**
     * 
     * @type {Array<OrderItemNoteResponse>}
     * @memberof OrderItemResponse
     */
    'notes'?: Array<OrderItemNoteResponse>;
    /**
     * 
     * @type {UploadImageResponse}
     * @memberof OrderItemResponse
     */
    'upload_image': UploadImageResponse;
    /**
     * 
     * @type {MakerResponse}
     * @memberof OrderItemResponse
     */
    'maker': MakerResponse;
    /**
     * 
     * @type {BoardResponse}
     * @memberof OrderItemResponse
     */
    'board'?: BoardResponse;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const OrderKind = {
    INDIRECT: 1,
    DIRECT: 2
} as const;

export type OrderKind = typeof OrderKind[keyof typeof OrderKind];


/**
 * 
 * @export
 * @interface OrderMakerGroupResponse
 */
export interface OrderMakerGroupResponse {
    /**
     * 
     * @type {number}
     * @memberof OrderMakerGroupResponse
     */
    'id': number;
    /**
     * 
     * @type {MakerResponse}
     * @memberof OrderMakerGroupResponse
     */
    'maker': MakerResponse;
    /**
     * 
     * @type {Array<OrderItemResponse>}
     * @memberof OrderMakerGroupResponse
     */
    'items': Array<OrderItemResponse>;
    /**
     * 
     * @type {OrderDeliveryResponse}
     * @memberof OrderMakerGroupResponse
     */
    'delivery'?: OrderDeliveryResponse;
}
/**
 * 
 * @export
 * @interface OrderParameter
 */
export interface OrderParameter {
    /**
     * 
     * @type {number}
     * @memberof OrderParameter
     */
    'position': number;
}
/**
 * 
 * @export
 * @interface OrderQuestionnaireResultEntity
 */
export interface OrderQuestionnaireResultEntity {
    /**
     * 
     * @type {number}
     * @memberof OrderQuestionnaireResultEntity
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof OrderQuestionnaireResultEntity
     */
    'order_id': number;
    /**
     * 
     * @type {ProjectStatus}
     * @memberof OrderQuestionnaireResultEntity
     */
    'project_status'?: ProjectStatus;
    /**
     * 
     * @type {string}
     * @memberof OrderQuestionnaireResultEntity
     */
    'project_title'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderQuestionnaireResultEntity
     */
    'project_prefecture_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderQuestionnaireResultEntity
     */
    'project_floor_space'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderQuestionnaireResultEntity
     */
    'project_completion'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderQuestionnaireResultEntity
     */
    'building_type_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderQuestionnaireResultEntity
     */
    'created_at'?: string;
}


/**
 * 
 * @export
 * @interface OrderQuestionnaireResultResponse
 */
export interface OrderQuestionnaireResultResponse {
    /**
     * 
     * @type {number}
     * @memberof OrderQuestionnaireResultResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof OrderQuestionnaireResultResponse
     */
    'order_id': number;
    /**
     * 
     * @type {ProjectStatus}
     * @memberof OrderQuestionnaireResultResponse
     */
    'project_status'?: ProjectStatus;
    /**
     * 
     * @type {string}
     * @memberof OrderQuestionnaireResultResponse
     */
    'project_title'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderQuestionnaireResultResponse
     */
    'project_prefecture_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderQuestionnaireResultResponse
     */
    'project_floor_space'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderQuestionnaireResultResponse
     */
    'project_completion'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderQuestionnaireResultResponse
     */
    'building_type_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderQuestionnaireResultResponse
     */
    'created_at'?: string;
    /**
     * 
     * @type {BuildingTypeEntity}
     * @memberof OrderQuestionnaireResultResponse
     */
    'building_type'?: BuildingTypeEntity;
}


/**
 * 
 * @export
 * @interface OrderResponse
 */
export interface OrderResponse {
    /**
     * 
     * @type {number}
     * @memberof OrderResponse
     */
    'id': number;
    /**
     * 
     * @type {ContactMethod}
     * @memberof OrderResponse
     */
    'contact_method'?: ContactMethod;
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    'updated_at': string;
    /**
     * 
     * @type {UserResponse}
     * @memberof OrderResponse
     */
    'user': UserResponse;
    /**
     * 
     * @type {OrderShippingDetailEntity}
     * @memberof OrderResponse
     */
    'shipping_detail': OrderShippingDetailEntity;
    /**
     * 
     * @type {OrderQuestionnaireResultResponse}
     * @memberof OrderResponse
     */
    'questionnaire_result'?: OrderQuestionnaireResultResponse;
}


/**
 * 
 * @export
 * @interface OrderShippingDetailEntity
 */
export interface OrderShippingDetailEntity {
    /**
     * 
     * @type {number}
     * @memberof OrderShippingDetailEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof OrderShippingDetailEntity
     */
    'customer_name': string;
    /**
     * 
     * @type {number}
     * @memberof OrderShippingDetailEntity
     */
    'customer_prefecture_id': number;
    /**
     * 
     * @type {string}
     * @memberof OrderShippingDetailEntity
     */
    'customer_organization': string;
    /**
     * 
     * @type {string}
     * @memberof OrderShippingDetailEntity
     */
    'recipient_name': string;
    /**
     * 
     * @type {string}
     * @memberof OrderShippingDetailEntity
     */
    'recipient_postal_code': string;
    /**
     * 
     * @type {number}
     * @memberof OrderShippingDetailEntity
     */
    'recipient_prefecture_id': number;
    /**
     * 
     * @type {string}
     * @memberof OrderShippingDetailEntity
     */
    'recipient_city': string;
    /**
     * 
     * @type {string}
     * @memberof OrderShippingDetailEntity
     */
    'recipient_address_line': string;
    /**
     * 
     * @type {string}
     * @memberof OrderShippingDetailEntity
     */
    'recipient_phone_number': string;
    /**
     * 
     * @type {string}
     * @memberof OrderShippingDetailEntity
     */
    'created_at': string;
}
/**
 * 
 * @export
 * @interface OrderWithGroupsResponse
 */
export interface OrderWithGroupsResponse {
    /**
     * 
     * @type {number}
     * @memberof OrderWithGroupsResponse
     */
    'id': number;
    /**
     * 
     * @type {ContactMethod}
     * @memberof OrderWithGroupsResponse
     */
    'contact_method'?: ContactMethod;
    /**
     * 
     * @type {string}
     * @memberof OrderWithGroupsResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof OrderWithGroupsResponse
     */
    'updated_at': string;
    /**
     * 
     * @type {OrderShippingDetailEntity}
     * @memberof OrderWithGroupsResponse
     */
    'shipping_detail': OrderShippingDetailEntity;
    /**
     * 
     * @type {OrderQuestionnaireResultEntity}
     * @memberof OrderWithGroupsResponse
     */
    'questionnaire_result'?: OrderQuestionnaireResultEntity;
    /**
     * 
     * @type {Array<OrderGroupResponse>}
     * @memberof OrderWithGroupsResponse
     */
    'groups': Array<OrderGroupResponse>;
}


/**
 * 
 * @export
 * @interface PickupResponse
 */
export interface PickupResponse {
    /**
     * 
     * @type {Array<ListResponse>}
     * @memberof PickupResponse
     */
    'lists': Array<ListResponse>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PickupResponse
     */
    'keywords': Array<string>;
    /**
     * 
     * @type {Array<BoardResponse>}
     * @memberof PickupResponse
     */
    'boards': Array<BoardResponse>;
}
/**
 * 
 * @export
 * @interface PointValue
 */
export interface PointValue {
    /**
     * 
     * @type {number}
     * @memberof PointValue
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof PointValue
     */
    'y': number;
}
/**
 * 
 * @export
 * @interface PostCreateParam
 */
export interface PostCreateParam {
    /**
     * 
     * @type {Array<PostPinUpsertParameter>}
     * @memberof PostCreateParam
     */
    'pins': Array<PostPinUpsertParameter>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PostCreateParam
     */
    'use_type_ids'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof PostCreateParam
     */
    'upload_image_id': number;
    /**
     * 
     * @type {string}
     * @memberof PostCreateParam
     */
    'photographer'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostCreateParam
     */
    'caption'?: string;
}
/**
 * 
 * @export
 * @interface PostDetailResponse
 */
export interface PostDetailResponse {
    /**
     * 
     * @type {number}
     * @memberof PostDetailResponse
     */
    'id': number;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof PostDetailResponse
     */
    'product_info'?: { [key: string]: any; };
    /**
     * 
     * @type {string}
     * @memberof PostDetailResponse
     */
    'photographer'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostDetailResponse
     */
    'caption'?: string;
    /**
     * 
     * @type {number}
     * @memberof PostDetailResponse
     */
    'likes_count': number;
    /**
     * 
     * @type {number}
     * @memberof PostDetailResponse
     */
    'weekly_likes_count': number;
    /**
     * 
     * @type {number}
     * @memberof PostDetailResponse
     */
    'user_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostDetailResponse
     */
    'maker_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PostDetailResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof PostDetailResponse
     */
    'updated_at': string;
    /**
     * 
     * @type {Array<PostPinResponse>}
     * @memberof PostDetailResponse
     */
    'pins': Array<PostPinResponse>;
    /**
     * 
     * @type {Array<UseTypeEntity>}
     * @memberof PostDetailResponse
     */
    'use_types': Array<UseTypeEntity>;
    /**
     * 
     * @type {ProjectResponse}
     * @memberof PostDetailResponse
     */
    'project'?: ProjectResponse;
    /**
     * 
     * @type {UploadImageResponse}
     * @memberof PostDetailResponse
     */
    'upload_image': UploadImageResponse;
    /**
     * 
     * @type {UserDetailResponse}
     * @memberof PostDetailResponse
     */
    'user'?: UserDetailResponse;
    /**
     * 
     * @type {MakerResponse}
     * @memberof PostDetailResponse
     */
    'maker'?: MakerResponse;
    /**
     * 
     * @type {boolean}
     * @memberof PostDetailResponse
     */
    'has_liked': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PostPinKind = {
    PRODUCT_VARIATION: 1,
    MATERIAL: 2,
    NOTE: 3
} as const;

export type PostPinKind = typeof PostPinKind[keyof typeof PostPinKind];


/**
 * 
 * @export
 * @interface PostPinResponse
 */
export interface PostPinResponse {
    /**
     * 
     * @type {number}
     * @memberof PostPinResponse
     */
    'id': number;
    /**
     * 
     * @type {PostPinKind}
     * @memberof PostPinResponse
     */
    'kind': PostPinKind;
    /**
     * 
     * @type {PointValue}
     * @memberof PostPinResponse
     */
    'position': PointValue;
    /**
     * 
     * @type {number}
     * @memberof PostPinResponse
     */
    'post_id': number;
    /**
     * 
     * @type {ProductVariationWithProductResponse}
     * @memberof PostPinResponse
     */
    'product_variation'?: ProductVariationWithProductResponse;
    /**
     * 
     * @type {MaterialPinResponse}
     * @memberof PostPinResponse
     */
    'material_pin'?: MaterialPinResponse;
    /**
     * 
     * @type {NotePinResponse}
     * @memberof PostPinResponse
     */
    'note_pin'?: NotePinResponse;
}


/**
 * @type PostPinUpsertParameter
 * @export
 */
export type PostPinUpsertParameter = MaterialPinParameter | NotePinParameter | ProductVariationPinParameter;

/**
 * 
 * @export
 * @interface PostResponse
 */
export interface PostResponse {
    /**
     * 
     * @type {number}
     * @memberof PostResponse
     */
    'id': number;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof PostResponse
     */
    'product_info'?: { [key: string]: any; };
    /**
     * 
     * @type {string}
     * @memberof PostResponse
     */
    'photographer'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostResponse
     */
    'caption'?: string;
    /**
     * 
     * @type {number}
     * @memberof PostResponse
     */
    'likes_count': number;
    /**
     * 
     * @type {number}
     * @memberof PostResponse
     */
    'weekly_likes_count': number;
    /**
     * 
     * @type {number}
     * @memberof PostResponse
     */
    'user_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostResponse
     */
    'maker_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PostResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof PostResponse
     */
    'updated_at': string;
    /**
     * 
     * @type {Array<PostPinResponse>}
     * @memberof PostResponse
     */
    'pins': Array<PostPinResponse>;
    /**
     * 
     * @type {Array<UseTypeEntity>}
     * @memberof PostResponse
     */
    'use_types': Array<UseTypeEntity>;
    /**
     * 
     * @type {UploadImageResponse}
     * @memberof PostResponse
     */
    'upload_image': UploadImageResponse;
    /**
     * 
     * @type {number}
     * @memberof PostResponse
     */
    'position'?: number;
    /**
     * 
     * @type {UserDetailResponse}
     * @memberof PostResponse
     */
    'user'?: UserDetailResponse;
    /**
     * 
     * @type {MakerResponse}
     * @memberof PostResponse
     */
    'maker'?: MakerResponse;
    /**
     * 
     * @type {ProjectResponse}
     * @memberof PostResponse
     */
    'project'?: ProjectResponse;
    /**
     * 
     * @type {boolean}
     * @memberof PostResponse
     */
    'has_liked': boolean;
}
/**
 * 
 * @export
 * @interface PostUpdateParam
 */
export interface PostUpdateParam {
    /**
     * 
     * @type {Array<PostPinUpsertParameter>}
     * @memberof PostUpdateParam
     */
    'pins': Array<PostPinUpsertParameter>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PostUpdateParam
     */
    'use_type_ids'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof PostUpdateParam
     */
    'photographer'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostUpdateParam
     */
    'caption'?: string;
}
/**
 * 
 * @export
 * @interface PostsResponse
 */
export interface PostsResponse {
    /**
     * 次のページが存在するか
     * @type {boolean}
     * @memberof PostsResponse
     */
    'has_next': boolean;
    /**
     * 総ページ数
     * @type {number}
     * @memberof PostsResponse
     */
    'total_pages'?: number;
    /**
     * 総件数
     * @type {number}
     * @memberof PostsResponse
     */
    'total_count'?: number;
    /**
     * 
     * @type {NotificationsResponseCursor}
     * @memberof PostsResponse
     */
    'cursor'?: NotificationsResponseCursor;
    /**
     * 
     * @type {Array<PostResponse>}
     * @memberof PostsResponse
     */
    'results'?: Array<PostResponse>;
}
/**
 * 
 * @export
 * @interface PostsSearchHitCountsResponse
 */
export interface PostsSearchHitCountsResponse {
    /**
     * 
     * @type {Array<HitCountResponse>}
     * @memberof PostsSearchHitCountsResponse
     */
    'building_types': Array<HitCountResponse>;
    /**
     * 
     * @type {Array<HitCountResponse>}
     * @memberof PostsSearchHitCountsResponse
     */
    'construction_types': Array<HitCountResponse>;
    /**
     * 
     * @type {Array<HitCountResponse>}
     * @memberof PostsSearchHitCountsResponse
     */
    'structure_types': Array<HitCountResponse>;
    /**
     * 
     * @type {Array<HitCountResponse>}
     * @memberof PostsSearchHitCountsResponse
     */
    'use_types': Array<HitCountResponse>;
    /**
     * 
     * @type {Array<HitCountResponse>}
     * @memberof PostsSearchHitCountsResponse
     */
    'floor_spaces': Array<HitCountResponse>;
}
/**
 * 
 * @export
 * @interface PostsSearchResponse
 */
export interface PostsSearchResponse {
    /**
     * 
     * @type {Array<PostResponse>}
     * @memberof PostsSearchResponse
     */
    'results'?: Array<PostResponse>;
    /**
     * 次のページが存在するか
     * @type {boolean}
     * @memberof PostsSearchResponse
     */
    'has_next': boolean;
}
/**
 * 
 * @export
 * @interface ProductDetailEntity
 */
export interface ProductDetailEntity {
    /**
     * 
     * @type {number}
     * @memberof ProductDetailEntity
     */
    'product_id': number;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailEntity
     */
    'series'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailEntity
     */
    'designer'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailEntity
     */
    'fire_protection_material'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailEntity
     */
    'flame_prevention_regulation'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailEntity
     */
    'formaldehyde'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailEntity
     */
    'fire_resistance'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailEntity
     */
    'remarks'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailEntity
     */
    'catalog_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailEntity
     */
    'product_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailEntity
     */
    'sample_request_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailEntity
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailEntity
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface ProductDetailResponse
 */
export interface ProductDetailResponse {
    /**
     * 
     * @type {number}
     * @memberof ProductDetailResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailResponse
     */
    'name_primary': string;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailResponse
     */
    'name_secondary'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailResponse
     */
    'full_name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailResponse
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailResponse
     */
    'updated_at': string;
    /**
     * 
     * @type {ProductStatus}
     * @memberof ProductDetailResponse
     */
    'status': ProductStatus;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailResponse
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailResponse
     */
    'maker_id': number;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailResponse
     */
    'brand_id': number;
    /**
     * 
     * @type {ProductKind}
     * @memberof ProductDetailResponse
     */
    'kind': ProductKind;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailResponse
     */
    'sample_requested_users_count': number;
    /**
     * 
     * @type {ProductDetailEntity}
     * @memberof ProductDetailResponse
     */
    'detail'?: ProductDetailEntity;
    /**
     * 
     * @type {MakerResponse}
     * @memberof ProductDetailResponse
     */
    'maker': MakerResponse;
    /**
     * 
     * @type {BrandResponse}
     * @memberof ProductDetailResponse
     */
    'brand': BrandResponse;
    /**
     * 
     * @type {ThemeEntity}
     * @memberof ProductDetailResponse
     */
    'theme'?: ThemeEntity;
    /**
     * 
     * @type {Array<CategoryEntity>}
     * @memberof ProductDetailResponse
     */
    'categories': Array<CategoryEntity>;
    /**
     * 
     * @type {Array<ProductVariationWithCatalogPagesResponse>}
     * @memberof ProductDetailResponse
     */
    'variations': Array<ProductVariationWithCatalogPagesResponse>;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ProductKind = {
    INTERIOR: 1,
    BUILDING_MATERIAL: 2
} as const;

export type ProductKind = typeof ProductKind[keyof typeof ProductKind];


/**
 * 
 * @export
 * @interface ProductMaterialEntity
 */
export interface ProductMaterialEntity {
    /**
     * 
     * @type {number}
     * @memberof ProductMaterialEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProductMaterialEntity
     */
    'name': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductMaterialEntity
     */
    'ancestry': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof ProductMaterialEntity
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof ProductMaterialEntity
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface ProductResponse
 */
export interface ProductResponse {
    /**
     * 
     * @type {number}
     * @memberof ProductResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProductResponse
     */
    'name_primary': string;
    /**
     * 
     * @type {string}
     * @memberof ProductResponse
     */
    'name_secondary'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductResponse
     */
    'full_name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductResponse
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof ProductResponse
     */
    'updated_at': string;
    /**
     * 
     * @type {ProductStatus}
     * @memberof ProductResponse
     */
    'status': ProductStatus;
    /**
     * 
     * @type {number}
     * @memberof ProductResponse
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof ProductResponse
     */
    'maker_id': number;
    /**
     * 
     * @type {number}
     * @memberof ProductResponse
     */
    'brand_id': number;
    /**
     * 
     * @type {ProductKind}
     * @memberof ProductResponse
     */
    'kind': ProductKind;
    /**
     * 
     * @type {number}
     * @memberof ProductResponse
     */
    'sample_requested_users_count': number;
    /**
     * 
     * @type {MakerResponse}
     * @memberof ProductResponse
     */
    'maker': MakerResponse;
    /**
     * 
     * @type {Array<CategoryEntity>}
     * @memberof ProductResponse
     */
    'categories': Array<CategoryEntity>;
}


/**
 * 
 * @export
 * @interface ProductSizeVariationEntity
 */
export interface ProductSizeVariationEntity {
    /**
     * 
     * @type {number}
     * @memberof ProductSizeVariationEntity
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ProductSizeVariationEntity
     */
    'product_variation_id': number;
    /**
     * 
     * @type {number}
     * @memberof ProductSizeVariationEntity
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductSizeVariationEntity
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductSizeVariationEntity
     */
    'depth'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSizeVariationEntity
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSizeVariationEntity
     */
    'item_number'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductSizeVariationEntity
     */
    'position': number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductSizeVariationEntity
     */
    'is_discontinued': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductSizeVariationEntity
     */
    'is_long_delivery_time': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductSizeVariationEntity
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof ProductSizeVariationEntity
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ProductStatus = {
    PUBLIC: 1,
    DRAFT: 2
} as const;

export type ProductStatus = typeof ProductStatus[keyof typeof ProductStatus];


/**
 * 
 * @export
 * @interface ProductVariationPinParameter
 */
export interface ProductVariationPinParameter {
    /**
     * 
     * @type {number}
     * @memberof ProductVariationPinParameter
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationPinParameter
     */
    'position_x': number;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationPinParameter
     */
    'position_y': number;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationPinParameter
     */
    'product_variation_id': number;
}
/**
 * 
 * @export
 * @interface ProductVariationResponse
 */
export interface ProductVariationResponse {
    /**
     * 
     * @type {number}
     * @memberof ProductVariationResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProductVariationResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductVariationResponse
     */
    'group_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductVariationResponse
     */
    'full_name': string;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationResponse
     */
    'min_price'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationResponse
     */
    'max_price'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductVariationResponse
     */
    'unit'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationResponse
     */
    'product_id': number;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationResponse
     */
    'upload_image_id': number;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationResponse
     */
    'color_type_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductVariationResponse
     */
    'color_text'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationResponse
     */
    'material_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductVariationResponse
     */
    'material_text'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationResponse
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationResponse
     */
    'texture_type_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductVariationResponse
     */
    'item_number'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductVariationResponse
     */
    'is_discontinued': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductVariationResponse
     */
    'is_long_delivery_time': boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationResponse
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationResponse
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationResponse
     */
    'depth'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductVariationResponse
     */
    'size_text'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationResponse
     */
    'fire_protection_material'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationResponse
     */
    'flame_prevention_regulation'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationResponse
     */
    'formaldehyde'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationResponse
     */
    'fire_resistance'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductVariationResponse
     */
    'remarks'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductVariationResponse
     */
    'catalog_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductVariationResponse
     */
    'product_url'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationResponse
     */
    'likes_count': number;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationResponse
     */
    'weekly_likes_count': number;
    /**
     * 
     * @type {SampleRequestKind}
     * @memberof ProductVariationResponse
     */
    'sample_request_kind': SampleRequestKind;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationResponse
     */
    'posts_count': number;
    /**
     * 
     * @type {string}
     * @memberof ProductVariationResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof ProductVariationResponse
     */
    'updated_at': string;
    /**
     * 
     * @type {UploadImageResponse}
     * @memberof ProductVariationResponse
     */
    'upload_image': UploadImageResponse;
    /**
     * 
     * @type {ColorTypeEntity}
     * @memberof ProductVariationResponse
     */
    'color_type'?: ColorTypeEntity;
    /**
     * 
     * @type {Array<ProductMaterialEntity>}
     * @memberof ProductVariationResponse
     */
    'materials'?: Array<ProductMaterialEntity>;
    /**
     * 
     * @type {TextureTypeEntity}
     * @memberof ProductVariationResponse
     */
    'texture_type'?: TextureTypeEntity;
    /**
     * 
     * @type {Array<ProductSizeVariationEntity>}
     * @memberof ProductVariationResponse
     */
    'size_variations'?: Array<ProductSizeVariationEntity>;
    /**
     * 
     * @type {boolean}
     * @memberof ProductVariationResponse
     */
    'has_liked': boolean;
}


/**
 * 
 * @export
 * @interface ProductVariationWithCatalogPagesResponse
 */
export interface ProductVariationWithCatalogPagesResponse {
    /**
     * 
     * @type {number}
     * @memberof ProductVariationWithCatalogPagesResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProductVariationWithCatalogPagesResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductVariationWithCatalogPagesResponse
     */
    'group_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductVariationWithCatalogPagesResponse
     */
    'full_name': string;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationWithCatalogPagesResponse
     */
    'min_price'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationWithCatalogPagesResponse
     */
    'max_price'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductVariationWithCatalogPagesResponse
     */
    'unit'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationWithCatalogPagesResponse
     */
    'product_id': number;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationWithCatalogPagesResponse
     */
    'upload_image_id': number;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationWithCatalogPagesResponse
     */
    'color_type_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductVariationWithCatalogPagesResponse
     */
    'color_text'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationWithCatalogPagesResponse
     */
    'material_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductVariationWithCatalogPagesResponse
     */
    'material_text'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationWithCatalogPagesResponse
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationWithCatalogPagesResponse
     */
    'texture_type_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductVariationWithCatalogPagesResponse
     */
    'item_number'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductVariationWithCatalogPagesResponse
     */
    'is_discontinued': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductVariationWithCatalogPagesResponse
     */
    'is_long_delivery_time': boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationWithCatalogPagesResponse
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationWithCatalogPagesResponse
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationWithCatalogPagesResponse
     */
    'depth'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductVariationWithCatalogPagesResponse
     */
    'size_text'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationWithCatalogPagesResponse
     */
    'fire_protection_material'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationWithCatalogPagesResponse
     */
    'flame_prevention_regulation'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationWithCatalogPagesResponse
     */
    'formaldehyde'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationWithCatalogPagesResponse
     */
    'fire_resistance'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductVariationWithCatalogPagesResponse
     */
    'remarks'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductVariationWithCatalogPagesResponse
     */
    'catalog_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductVariationWithCatalogPagesResponse
     */
    'product_url'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationWithCatalogPagesResponse
     */
    'likes_count': number;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationWithCatalogPagesResponse
     */
    'weekly_likes_count': number;
    /**
     * 
     * @type {SampleRequestKind}
     * @memberof ProductVariationWithCatalogPagesResponse
     */
    'sample_request_kind': SampleRequestKind;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationWithCatalogPagesResponse
     */
    'posts_count': number;
    /**
     * 
     * @type {string}
     * @memberof ProductVariationWithCatalogPagesResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof ProductVariationWithCatalogPagesResponse
     */
    'updated_at': string;
    /**
     * 
     * @type {UploadImageResponse}
     * @memberof ProductVariationWithCatalogPagesResponse
     */
    'upload_image': UploadImageResponse;
    /**
     * 
     * @type {ColorTypeEntity}
     * @memberof ProductVariationWithCatalogPagesResponse
     */
    'color_type'?: ColorTypeEntity;
    /**
     * 
     * @type {Array<ProductMaterialEntity>}
     * @memberof ProductVariationWithCatalogPagesResponse
     */
    'materials'?: Array<ProductMaterialEntity>;
    /**
     * 
     * @type {TextureTypeEntity}
     * @memberof ProductVariationWithCatalogPagesResponse
     */
    'texture_type'?: TextureTypeEntity;
    /**
     * 
     * @type {Array<CatalogWithPagesResponse>}
     * @memberof ProductVariationWithCatalogPagesResponse
     */
    'catalogs'?: Array<CatalogWithPagesResponse>;
    /**
     * 
     * @type {boolean}
     * @memberof ProductVariationWithCatalogPagesResponse
     */
    'has_liked': boolean;
    /**
     * 
     * @type {Array<SearchConditionGroupResponse>}
     * @memberof ProductVariationWithCatalogPagesResponse
     */
    'search_condition_groups'?: Array<SearchConditionGroupResponse>;
    /**
     * 
     * @type {SampleItemEntity}
     * @memberof ProductVariationWithCatalogPagesResponse
     */
    'sample_item'?: SampleItemEntity;
}


/**
 * 
 * @export
 * @interface ProductVariationWithProductResponse
 */
export interface ProductVariationWithProductResponse {
    /**
     * 
     * @type {number}
     * @memberof ProductVariationWithProductResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProductVariationWithProductResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductVariationWithProductResponse
     */
    'group_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductVariationWithProductResponse
     */
    'full_name': string;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationWithProductResponse
     */
    'min_price'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationWithProductResponse
     */
    'max_price'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductVariationWithProductResponse
     */
    'unit'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationWithProductResponse
     */
    'product_id': number;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationWithProductResponse
     */
    'upload_image_id': number;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationWithProductResponse
     */
    'color_type_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductVariationWithProductResponse
     */
    'color_text'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationWithProductResponse
     */
    'material_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductVariationWithProductResponse
     */
    'material_text'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationWithProductResponse
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationWithProductResponse
     */
    'texture_type_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductVariationWithProductResponse
     */
    'item_number'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductVariationWithProductResponse
     */
    'is_discontinued': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductVariationWithProductResponse
     */
    'is_long_delivery_time': boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationWithProductResponse
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationWithProductResponse
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationWithProductResponse
     */
    'depth'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductVariationWithProductResponse
     */
    'size_text'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationWithProductResponse
     */
    'fire_protection_material'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationWithProductResponse
     */
    'flame_prevention_regulation'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationWithProductResponse
     */
    'formaldehyde'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationWithProductResponse
     */
    'fire_resistance'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductVariationWithProductResponse
     */
    'remarks'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductVariationWithProductResponse
     */
    'catalog_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductVariationWithProductResponse
     */
    'product_url'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationWithProductResponse
     */
    'likes_count': number;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationWithProductResponse
     */
    'weekly_likes_count': number;
    /**
     * 
     * @type {SampleRequestKind}
     * @memberof ProductVariationWithProductResponse
     */
    'sample_request_kind': SampleRequestKind;
    /**
     * 
     * @type {number}
     * @memberof ProductVariationWithProductResponse
     */
    'posts_count': number;
    /**
     * 
     * @type {string}
     * @memberof ProductVariationWithProductResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof ProductVariationWithProductResponse
     */
    'updated_at': string;
    /**
     * 
     * @type {UploadImageResponse}
     * @memberof ProductVariationWithProductResponse
     */
    'upload_image': UploadImageResponse;
    /**
     * 
     * @type {ProductResponse}
     * @memberof ProductVariationWithProductResponse
     */
    'product': ProductResponse;
    /**
     * 
     * @type {boolean}
     * @memberof ProductVariationWithProductResponse
     */
    'has_liked': boolean;
    /**
     * 
     * @type {SampleItemEntity}
     * @memberof ProductVariationWithProductResponse
     */
    'sample_item'?: SampleItemEntity;
}


/**
 * 
 * @export
 * @interface ProductVariationsResponse
 */
export interface ProductVariationsResponse {
    /**
     * 次のページが存在するか
     * @type {boolean}
     * @memberof ProductVariationsResponse
     */
    'has_next': boolean;
    /**
     * 総ページ数
     * @type {number}
     * @memberof ProductVariationsResponse
     */
    'total_pages'?: number;
    /**
     * 総件数
     * @type {number}
     * @memberof ProductVariationsResponse
     */
    'total_count'?: number;
    /**
     * 
     * @type {NotificationsResponseCursor}
     * @memberof ProductVariationsResponse
     */
    'cursor'?: NotificationsResponseCursor;
    /**
     * 
     * @type {Array<ProductVariationWithProductResponse>}
     * @memberof ProductVariationsResponse
     */
    'results'?: Array<ProductVariationWithProductResponse>;
}
/**
 * 
 * @export
 * @interface ProductVariationsSearchHitCountsResponse
 */
export interface ProductVariationsSearchHitCountsResponse {
    /**
     * 
     * @type {Array<HitCountResponse>}
     * @memberof ProductVariationsSearchHitCountsResponse
     */
    'categories': Array<HitCountResponse>;
    /**
     * 
     * @type {Array<HitCountResponse>}
     * @memberof ProductVariationsSearchHitCountsResponse
     */
    'materials': Array<HitCountResponse>;
    /**
     * 
     * @type {Array<HitCountResponse>}
     * @memberof ProductVariationsSearchHitCountsResponse
     */
    'color_types': Array<HitCountResponse>;
    /**
     * 
     * @type {Array<HitCountResponse>}
     * @memberof ProductVariationsSearchHitCountsResponse
     */
    'formaldehyde': Array<HitCountResponse>;
    /**
     * 
     * @type {Array<HitCountResponse>}
     * @memberof ProductVariationsSearchHitCountsResponse
     */
    'fire_resistance': Array<HitCountResponse>;
    /**
     * 
     * @type {Array<HitCountResponse>}
     * @memberof ProductVariationsSearchHitCountsResponse
     */
    'flame_prevention_regulation': Array<HitCountResponse>;
    /**
     * 
     * @type {Array<HitCountResponse>}
     * @memberof ProductVariationsSearchHitCountsResponse
     */
    'fire_protection_material': Array<HitCountResponse>;
}
/**
 * 
 * @export
 * @interface ProjectAttachment
 */
export interface ProjectAttachment {
    /**
     * 
     * @type {number}
     * @memberof ProjectAttachment
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectAttachment
     */
    'project_id': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectAttachment
     */
    'user_id': number;
    /**
     * attachment title
     * @type {string}
     * @memberof ProjectAttachment
     */
    'title': string;
    /**
     * 
     * @type {ProjectAttachmentType}
     * @memberof ProjectAttachment
     */
    'attachment_type': ProjectAttachmentType;
    /**
     * 
     * @type {number}
     * @memberof ProjectAttachment
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectAttachment
     */
    'upload_image_id': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectAttachment
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectAttachment
     */
    'updated_at': string;
    /**
     * 
     * @type {UploadImageResponse}
     * @memberof ProjectAttachment
     */
    'upload_image': UploadImageResponse;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ProjectAttachmentType = {
    CONCEPT_IMAGE: 1,
    DIAGRAM: 2,
    DRAWING: 3,
    MODEL_PHOTO: 4,
    RENDERING: 5,
    OTHER: 6
} as const;

export type ProjectAttachmentType = typeof ProjectAttachmentType[keyof typeof ProjectAttachmentType];


/**
 * 
 * @export
 * @enum {string}
 */

export const ProjectConstructionType = {
    NEW: 1,
    RENOVATION: 2
} as const;

export type ProjectConstructionType = typeof ProjectConstructionType[keyof typeof ProjectConstructionType];


/**
 * 
 * @export
 * @interface ProjectCreateParam
 */
export interface ProjectCreateParam {
    /**
     * 
     * @type {string}
     * @memberof ProjectCreateParam
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCreateParam
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof ProjectCreateParam
     */
    'building_type_id': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProjectCreateParam
     */
    'structure_type_ids'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof ProjectCreateParam
     */
    'construction_type'?: ProjectCreateParamConstructionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ProjectCreateParam
     */
    'completion'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectCreateParam
     */
    'floor_space'?: number;
    /**
     * 
     * @type {Array<ProjectCreditValue>}
     * @memberof ProjectCreateParam
     */
    'credit'?: Array<ProjectCreditValue>;
    /**
     * 
     * @type {LocationValue}
     * @memberof ProjectCreateParam
     */
    'location'?: LocationValue;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectCreateParam
     */
    'tags'?: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProjectCreateParam
     */
    'user_ids': Array<number>;
    /**
     * 
     * @type {number}
     * @memberof ProjectCreateParam
     */
    'team_id': number;
}

export const ProjectCreateParamConstructionTypeEnum = {
    NEW: 1,
    RENOVATION: 2
} as const;

export type ProjectCreateParamConstructionTypeEnum = typeof ProjectCreateParamConstructionTypeEnum[keyof typeof ProjectCreateParamConstructionTypeEnum];

/**
 * 
 * @export
 * @interface ProjectCreditValue
 */
export interface ProjectCreditValue {
    /**
     * 
     * @type {string}
     * @memberof ProjectCreditValue
     */
    'label': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectCreditValue
     */
    'values': Array<string>;
}
/**
 * 
 * @export
 * @interface ProjectDetailResponse
 */
export interface ProjectDetailResponse {
    /**
     * 
     * @type {number}
     * @memberof ProjectDetailResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailResponse
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailResponse
     */
    'description': string;
    /**
     * 
     * @type {ProjectConstructionType}
     * @memberof ProjectDetailResponse
     */
    'construction_type'?: ProjectConstructionType;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailResponse
     */
    'completion'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectDetailResponse
     */
    'floor_space'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailResponse
     */
    'magazine_slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailResponse
     */
    'magazine_link_label'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectDetailResponse
     */
    'posts_count': number;
    /**
     * 
     * @type {Array<ProjectCreditValue>}
     * @memberof ProjectDetailResponse
     */
    'credit'?: Array<ProjectCreditValue>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectDetailResponse
     */
    'tags'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectDetailResponse
     */
    'is_published': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailResponse
     */
    'published_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectDetailResponse
     */
    'likes_count': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDetailResponse
     */
    'weekly_likes_count': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDetailResponse
     */
    'page_views_count': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailResponse
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailResponse
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailResponse
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailResponse
     */
    'updated_at': string;
    /**
     * 
     * @type {Array<UserResponse>}
     * @memberof ProjectDetailResponse
     */
    'users': Array<UserResponse>;
    /**
     * 
     * @type {TeamResponse}
     * @memberof ProjectDetailResponse
     */
    'team': TeamResponse;
    /**
     * 
     * @type {BuildingTypeEntity}
     * @memberof ProjectDetailResponse
     */
    'building_type': BuildingTypeEntity;
    /**
     * 
     * @type {Array<StructureTypeEntity>}
     * @memberof ProjectDetailResponse
     */
    'structure_types'?: Array<StructureTypeEntity>;
    /**
     * 
     * @type {UploadImageResponse}
     * @memberof ProjectDetailResponse
     */
    'main_image'?: UploadImageResponse;
    /**
     * 
     * @type {LocationValue}
     * @memberof ProjectDetailResponse
     */
    'location'?: LocationValue;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectDetailResponse
     */
    'has_liked': boolean;
    /**
     * 
     * @type {Array<ProjectAttachment>}
     * @memberof ProjectDetailResponse
     */
    'attachments'?: Array<ProjectAttachment>;
    /**
     * 
     * @type {AwardEntryEntity}
     * @memberof ProjectDetailResponse
     */
    'award_entry'?: AwardEntryEntity;
}


/**
 * 
 * @export
 * @interface ProjectResponse
 */
export interface ProjectResponse {
    /**
     * 
     * @type {number}
     * @memberof ProjectResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponse
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponse
     */
    'description': string;
    /**
     * 
     * @type {ProjectConstructionType}
     * @memberof ProjectResponse
     */
    'construction_type'?: ProjectConstructionType;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponse
     */
    'completion'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectResponse
     */
    'floor_space'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponse
     */
    'magazine_slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponse
     */
    'magazine_link_label'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectResponse
     */
    'posts_count': number;
    /**
     * 
     * @type {Array<ProjectCreditValue>}
     * @memberof ProjectResponse
     */
    'credit'?: Array<ProjectCreditValue>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectResponse
     */
    'tags'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectResponse
     */
    'is_published': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponse
     */
    'published_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectResponse
     */
    'likes_count': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectResponse
     */
    'weekly_likes_count': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectResponse
     */
    'page_views_count': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponse
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponse
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponse
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponse
     */
    'updated_at': string;
    /**
     * 
     * @type {TeamResponse}
     * @memberof ProjectResponse
     */
    'team': TeamResponse;
    /**
     * 
     * @type {BuildingTypeEntity}
     * @memberof ProjectResponse
     */
    'building_type': BuildingTypeEntity;
    /**
     * 
     * @type {UploadImageResponse}
     * @memberof ProjectResponse
     */
    'main_image'?: UploadImageResponse;
    /**
     * 
     * @type {LocationValue}
     * @memberof ProjectResponse
     */
    'location'?: LocationValue;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectResponse
     */
    'has_liked': boolean;
    /**
     * 
     * @type {AwardEntryEntity}
     * @memberof ProjectResponse
     */
    'award_entry'?: AwardEntryEntity;
}


/**
 * 
 * @export
 * @interface ProjectShareTokenEntity
 */
export interface ProjectShareTokenEntity {
    /**
     * 
     * @type {number}
     * @memberof ProjectShareTokenEntity
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectShareTokenEntity
     */
    'project_id': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectShareTokenEntity
     */
    'user_id': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectShareTokenEntity
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectShareTokenEntity
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectShareTokenEntity
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ProjectStatus = {
    BASIC_PLAN: 1,
    BASIC_DESIGN: 2,
    DETAIL_DESIGN: 3,
    DESIGN_MANAGEMENT: 4,
    NONE: 5
} as const;

export type ProjectStatus = typeof ProjectStatus[keyof typeof ProjectStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const ProjectStructureType = {
    WOODEN: 1,
    STEEL: 2,
    REINFORCED_CONCRETE: 3,
    STEEL_REINFORCED_CONCRETE: 4,
    MASONRY: 5,
    MIX: 6,
    OTHER: 7
} as const;

export type ProjectStructureType = typeof ProjectStructureType[keyof typeof ProjectStructureType];


/**
 * 
 * @export
 * @interface ProjectUpdateParam
 */
export interface ProjectUpdateParam {
    /**
     * 
     * @type {string}
     * @memberof ProjectUpdateParam
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectUpdateParam
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof ProjectUpdateParam
     */
    'building_type_id': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProjectUpdateParam
     */
    'structure_type_ids'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof ProjectUpdateParam
     */
    'construction_type'?: ProjectUpdateParamConstructionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ProjectUpdateParam
     */
    'completion'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectUpdateParam
     */
    'floor_space'?: number;
    /**
     * 
     * @type {Array<ProjectCreditValue>}
     * @memberof ProjectUpdateParam
     */
    'credit'?: Array<ProjectCreditValue>;
    /**
     * 
     * @type {LocationValue}
     * @memberof ProjectUpdateParam
     */
    'location'?: LocationValue;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectUpdateParam
     */
    'tags'?: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProjectUpdateParam
     */
    'user_ids': Array<number>;
}

export const ProjectUpdateParamConstructionTypeEnum = {
    NEW: 1,
    RENOVATION: 2
} as const;

export type ProjectUpdateParamConstructionTypeEnum = typeof ProjectUpdateParamConstructionTypeEnum[keyof typeof ProjectUpdateParamConstructionTypeEnum];

/**
 * 
 * @export
 * @interface ProjectWithProductVariationsResponse
 */
export interface ProjectWithProductVariationsResponse {
    /**
     * 
     * @type {Array<ProductVariationWithProductResponse>}
     * @memberof ProjectWithProductVariationsResponse
     */
    'product_variations': Array<ProductVariationWithProductResponse>;
    /**
     * 
     * @type {number}
     * @memberof ProjectWithProductVariationsResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithProductVariationsResponse
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithProductVariationsResponse
     */
    'description': string;
    /**
     * 
     * @type {ProjectConstructionType}
     * @memberof ProjectWithProductVariationsResponse
     */
    'construction_type'?: ProjectConstructionType;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithProductVariationsResponse
     */
    'completion'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectWithProductVariationsResponse
     */
    'floor_space'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithProductVariationsResponse
     */
    'magazine_slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithProductVariationsResponse
     */
    'magazine_link_label'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectWithProductVariationsResponse
     */
    'posts_count': number;
    /**
     * 
     * @type {Array<ProjectCreditValue>}
     * @memberof ProjectWithProductVariationsResponse
     */
    'credit'?: Array<ProjectCreditValue>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectWithProductVariationsResponse
     */
    'tags'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectWithProductVariationsResponse
     */
    'is_published': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithProductVariationsResponse
     */
    'published_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectWithProductVariationsResponse
     */
    'likes_count': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectWithProductVariationsResponse
     */
    'weekly_likes_count': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectWithProductVariationsResponse
     */
    'page_views_count': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithProductVariationsResponse
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithProductVariationsResponse
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithProductVariationsResponse
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithProductVariationsResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithProductVariationsResponse
     */
    'updated_at': string;
    /**
     * 
     * @type {TeamResponse}
     * @memberof ProjectWithProductVariationsResponse
     */
    'team': TeamResponse;
    /**
     * 
     * @type {BuildingTypeEntity}
     * @memberof ProjectWithProductVariationsResponse
     */
    'building_type': BuildingTypeEntity;
    /**
     * 
     * @type {UploadImageResponse}
     * @memberof ProjectWithProductVariationsResponse
     */
    'main_image'?: UploadImageResponse;
    /**
     * 
     * @type {LocationValue}
     * @memberof ProjectWithProductVariationsResponse
     */
    'location'?: LocationValue;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectWithProductVariationsResponse
     */
    'has_liked': boolean;
    /**
     * 
     * @type {AwardEntryEntity}
     * @memberof ProjectWithProductVariationsResponse
     */
    'award_entry'?: AwardEntryEntity;
}


/**
 * 
 * @export
 * @interface ProjectsResponse
 */
export interface ProjectsResponse {
    /**
     * 次のページが存在するか
     * @type {boolean}
     * @memberof ProjectsResponse
     */
    'has_next': boolean;
    /**
     * 総ページ数
     * @type {number}
     * @memberof ProjectsResponse
     */
    'total_pages'?: number;
    /**
     * 総件数
     * @type {number}
     * @memberof ProjectsResponse
     */
    'total_count'?: number;
    /**
     * 
     * @type {NotificationsResponseCursor}
     * @memberof ProjectsResponse
     */
    'cursor'?: NotificationsResponseCursor;
    /**
     * 
     * @type {Array<ProjectResponse>}
     * @memberof ProjectsResponse
     */
    'results'?: Array<ProjectResponse>;
}
/**
 * 
 * @export
 * @interface ProjectsSearchHitCountsResponse
 */
export interface ProjectsSearchHitCountsResponse {
    /**
     * 
     * @type {Array<HitCountResponse>}
     * @memberof ProjectsSearchHitCountsResponse
     */
    'building_types': Array<HitCountResponse>;
    /**
     * 
     * @type {Array<HitCountResponse>}
     * @memberof ProjectsSearchHitCountsResponse
     */
    'construction_types': Array<HitCountResponse>;
    /**
     * 
     * @type {Array<HitCountResponse>}
     * @memberof ProjectsSearchHitCountsResponse
     */
    'structure_types': Array<HitCountResponse>;
    /**
     * 
     * @type {Array<HitCountResponse>}
     * @memberof ProjectsSearchHitCountsResponse
     */
    'use_types': Array<HitCountResponse>;
    /**
     * 
     * @type {Array<HitCountResponse>}
     * @memberof ProjectsSearchHitCountsResponse
     */
    'floor_spaces': Array<HitCountResponse>;
}
/**
 * 
 * @export
 * @interface QualificationEntity
 */
export interface QualificationEntity {
    /**
     * 
     * @type {number}
     * @memberof QualificationEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof QualificationEntity
     */
    'label': string;
}
/**
 * 
 * @export
 * @interface SampleItemEntity
 */
export interface SampleItemEntity {
    /**
     * 
     * @type {number}
     * @memberof SampleItemEntity
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof SampleItemEntity
     */
    'maker_id': number;
    /**
     * 
     * @type {string}
     * @memberof SampleItemEntity
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof SampleItemEntity
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof SampleItemEntity
     */
    'stock_count': number;
    /**
     * 
     * @type {string}
     * @memberof SampleItemEntity
     */
    'release_datetime': string;
    /**
     * 
     * @type {string}
     * @memberof SampleItemEntity
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof SampleItemEntity
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof SampleItemEntity
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SampleRequestKind = {
    UNABLE: 0,
    INDIRECT: 1,
    DIRECT: 2
} as const;

export type SampleRequestKind = typeof SampleRequestKind[keyof typeof SampleRequestKind];


/**
 * 
 * @export
 * @interface SearchConditionGroupResponse
 */
export interface SearchConditionGroupResponse {
    /**
     * 
     * @type {number}
     * @memberof SearchConditionGroupResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SearchConditionGroupResponse
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof SearchConditionGroupResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof SearchConditionGroupResponse
     */
    'updated_at': string;
    /**
     * 
     * @type {Array<SearchConditionValueEntity>}
     * @memberof SearchConditionGroupResponse
     */
    'values': Array<SearchConditionValueEntity>;
}
/**
 * 
 * @export
 * @interface SearchConditionValueEntity
 */
export interface SearchConditionValueEntity {
    /**
     * 
     * @type {number}
     * @memberof SearchConditionValueEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SearchConditionValueEntity
     */
    'label': string;
    /**
     * 
     * @type {number}
     * @memberof SearchConditionValueEntity
     */
    'search_condition_group_id': number;
    /**
     * 
     * @type {string}
     * @memberof SearchConditionValueEntity
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof SearchConditionValueEntity
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface SearchIndexResponse
 */
export interface SearchIndexResponse {
    /**
     * 
     * @type {number}
     * @memberof SearchIndexResponse
     */
    'posts_total_count': number;
    /**
     * 
     * @type {number}
     * @memberof SearchIndexResponse
     */
    'projects_total_count': number;
    /**
     * 
     * @type {number}
     * @memberof SearchIndexResponse
     */
    'makers_total_count': number;
    /**
     * 
     * @type {number}
     * @memberof SearchIndexResponse
     */
    'lists_total_count': number;
    /**
     * 
     * @type {number}
     * @memberof SearchIndexResponse
     */
    'interiors_total_count': number;
    /**
     * 
     * @type {number}
     * @memberof SearchIndexResponse
     */
    'building_materials_total_count': number;
    /**
     * 
     * @type {number}
     * @memberof SearchIndexResponse
     */
    'boards_total_count': number;
    /**
     * 
     * @type {number}
     * @memberof SearchIndexResponse
     */
    'teams_total_count': number;
    /**
     * 
     * @type {Array<PostResponse>}
     * @memberof SearchIndexResponse
     */
    'posts'?: Array<PostResponse>;
    /**
     * 
     * @type {Array<ProjectResponse>}
     * @memberof SearchIndexResponse
     */
    'projects'?: Array<ProjectResponse>;
    /**
     * 
     * @type {Array<MakerResponse>}
     * @memberof SearchIndexResponse
     */
    'makers'?: Array<MakerResponse>;
    /**
     * 
     * @type {Array<ListResponse>}
     * @memberof SearchIndexResponse
     */
    'lists'?: Array<ListResponse>;
    /**
     * 
     * @type {Array<ProductVariationWithProductResponse>}
     * @memberof SearchIndexResponse
     */
    'interiors'?: Array<ProductVariationWithProductResponse>;
    /**
     * 
     * @type {Array<ProductVariationWithProductResponse>}
     * @memberof SearchIndexResponse
     */
    'building_materials'?: Array<ProductVariationWithProductResponse>;
    /**
     * 
     * @type {Array<BoardResponse>}
     * @memberof SearchIndexResponse
     */
    'boards'?: Array<BoardResponse>;
    /**
     * 
     * @type {Array<TeamResponse>}
     * @memberof SearchIndexResponse
     */
    'teams'?: Array<TeamResponse>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SearchKind = {
    POST: 1,
    PRODUCT_VARIATION: 2,
    LIST: 3,
    PROJECT: 4
} as const;

export type SearchKind = typeof SearchKind[keyof typeof SearchKind];


/**
 * @type SettingsAccountPostRequest
 * @export
 */
export type SettingsAccountPostRequest = DesignerAccountUpdateParam | UserAccountUpdateParam;

/**
 * 
 * @export
 * @enum {string}
 */

export const SimilarityAxis = {
    PRICE: 1,
    MATERIAL: 2,
    SIZE: 3
} as const;

export type SimilarityAxis = typeof SimilarityAxis[keyof typeof SimilarityAxis];


/**
 * 
 * @export
 * @interface StructureTypeEntity
 */
export interface StructureTypeEntity {
    /**
     * 
     * @type {number}
     * @memberof StructureTypeEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof StructureTypeEntity
     */
    'label': string;
}
/**
 * 
 * @export
 * @interface SuggestKeywordResponse
 */
export interface SuggestKeywordResponse {
    /**
     * 
     * @type {number}
     * @memberof SuggestKeywordResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof SuggestKeywordResponse
     */
    'detailable_id': number;
    /**
     * 
     * @type {SuggestKeywordType}
     * @memberof SuggestKeywordResponse
     */
    'detailable_type': SuggestKeywordType;
    /**
     * 
     * @type {string}
     * @memberof SuggestKeywordResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof SuggestKeywordResponse
     */
    'updated_at': string;
    /**
     * 
     * @type {SuggestKeywordResponseDetailable}
     * @memberof SuggestKeywordResponse
     */
    'detailable': SuggestKeywordResponseDetailable;
}


/**
 * @type SuggestKeywordResponseDetailable
 * @export
 */
export type SuggestKeywordResponseDetailable = BuildingTypeResponse | CategoryResponse | MakerResponse | ProductVariationWithProductResponse | ProjectResponse | TeamResponse;

/**
 * 
 * @export
 * @enum {string}
 */

export const SuggestKeywordType = {
    MAKER: 'Maker',
    USER: 'User',
    CATEGORY: 'Category',
    BUILDING_TYPE: 'BuildingType',
    PROJECT: 'Project',
    PRODUCT_VARIATION: 'ProductVariation',
    TEAM: 'Team'
} as const;

export type SuggestKeywordType = typeof SuggestKeywordType[keyof typeof SuggestKeywordType];


/**
 * 
 * @export
 * @interface TeamCreateParam
 */
export interface TeamCreateParam {
    /**
     * 
     * @type {string}
     * @memberof TeamCreateParam
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TeamCreateParam
     */
    'website_url': string;
}
/**
 * 
 * @export
 * @interface TeamJoinRequestResponse
 */
export interface TeamJoinRequestResponse {
    /**
     * 
     * @type {number}
     * @memberof TeamJoinRequestResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof TeamJoinRequestResponse
     */
    'team_id': number;
    /**
     * 
     * @type {number}
     * @memberof TeamJoinRequestResponse
     */
    'user_id': number;
    /**
     * 
     * @type {string}
     * @memberof TeamJoinRequestResponse
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof TeamJoinRequestResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof TeamJoinRequestResponse
     */
    'updated_at': string;
    /**
     * 
     * @type {TeamResponse}
     * @memberof TeamJoinRequestResponse
     */
    'team': TeamResponse;
    /**
     * 
     * @type {UserResponse}
     * @memberof TeamJoinRequestResponse
     */
    'user': UserResponse;
}
/**
 * 
 * @export
 * @interface TeamMemberEntity
 */
export interface TeamMemberEntity {
    /**
     * 
     * @type {number}
     * @memberof TeamMemberEntity
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof TeamMemberEntity
     */
    'team_id': number;
    /**
     * 
     * @type {number}
     * @memberof TeamMemberEntity
     */
    'user_id': number;
    /**
     * 
     * @type {TeamMemberKind}
     * @memberof TeamMemberEntity
     */
    'kind': TeamMemberKind;
    /**
     * 
     * @type {string}
     * @memberof TeamMemberEntity
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof TeamMemberEntity
     */
    'updated_at': string;
}


/**
 * 
 * @export
 * @interface TeamMemberInviteParameter
 */
export interface TeamMemberInviteParameter {
    /**
     * 
     * @type {string}
     * @memberof TeamMemberInviteParameter
     */
    'email': string;
    /**
     * 
     * @type {number}
     * @memberof TeamMemberInviteParameter
     */
    'kind': TeamMemberInviteParameterKindEnum;
}

export const TeamMemberInviteParameterKindEnum = {
    OWNER: 1,
    MEMBER: 2
} as const;

export type TeamMemberInviteParameterKindEnum = typeof TeamMemberInviteParameterKindEnum[keyof typeof TeamMemberInviteParameterKindEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const TeamMemberKind = {
    OWNER: 1,
    MEMBER: 2
} as const;

export type TeamMemberKind = typeof TeamMemberKind[keyof typeof TeamMemberKind];


/**
 * 
 * @export
 * @interface TeamMemberPutParameter
 */
export interface TeamMemberPutParameter {
    /**
     * 
     * @type {number}
     * @memberof TeamMemberPutParameter
     */
    'kind': TeamMemberPutParameterKindEnum;
}

export const TeamMemberPutParameterKindEnum = {
    OWNER: 1,
    MEMBER: 2
} as const;

export type TeamMemberPutParameterKindEnum = typeof TeamMemberPutParameterKindEnum[keyof typeof TeamMemberPutParameterKindEnum];

/**
 * 
 * @export
 * @interface TeamMemberResponse
 */
export interface TeamMemberResponse {
    /**
     * 
     * @type {number}
     * @memberof TeamMemberResponse
     */
    'id': number;
    /**
     * 
     * @type {TeamMemberKind}
     * @memberof TeamMemberResponse
     */
    'kind': TeamMemberKind;
    /**
     * 
     * @type {UserResponse}
     * @memberof TeamMemberResponse
     */
    'user': UserResponse;
    /**
     * 
     * @type {TeamResponse}
     * @memberof TeamMemberResponse
     */
    'team': TeamResponse;
}


/**
 * 
 * @export
 * @interface TeamMembersResponse
 */
export interface TeamMembersResponse {
    /**
     * 次のページが存在するか
     * @type {boolean}
     * @memberof TeamMembersResponse
     */
    'has_next': boolean;
    /**
     * 総ページ数
     * @type {number}
     * @memberof TeamMembersResponse
     */
    'total_pages'?: number;
    /**
     * 総件数
     * @type {number}
     * @memberof TeamMembersResponse
     */
    'total_count'?: number;
    /**
     * 
     * @type {NotificationsResponseCursor}
     * @memberof TeamMembersResponse
     */
    'cursor'?: NotificationsResponseCursor;
    /**
     * 
     * @type {Array<TeamMemberResponse>}
     * @memberof TeamMembersResponse
     */
    'results'?: Array<TeamMemberResponse>;
}
/**
 * 
 * @export
 * @interface TeamResponse
 */
export interface TeamResponse {
    /**
     * 
     * @type {number}
     * @memberof TeamResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TeamResponse
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof TeamResponse
     */
    'upload_image_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TeamResponse
     */
    'profile'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamResponse
     */
    'contact_email'?: string;
    /**
     * 
     * @type {TeamStatus}
     * @memberof TeamResponse
     */
    'status': TeamStatus;
    /**
     * 
     * @type {number}
     * @memberof TeamResponse
     */
    'projects_count': number;
    /**
     * 
     * @type {string}
     * @memberof TeamResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof TeamResponse
     */
    'updated_at': string;
    /**
     * 
     * @type {UploadImageResponse}
     * @memberof TeamResponse
     */
    'logo'?: UploadImageResponse;
    /**
     * 
     * @type {LinksValue}
     * @memberof TeamResponse
     */
    'links'?: LinksValue;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const TeamStatus = {
    PENDING: 1,
    APPROVED: 2,
    REJECTED: 3
} as const;

export type TeamStatus = typeof TeamStatus[keyof typeof TeamStatus];


/**
 * 
 * @export
 * @interface TeamUpdateParam
 */
export interface TeamUpdateParam {
    /**
     * 
     * @type {string}
     * @memberof TeamUpdateParam
     */
    'image'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamUpdateParam
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TeamUpdateParam
     */
    'profile'?: string;
    /**
     * 
     * @type {LinksValue}
     * @memberof TeamUpdateParam
     */
    'links'?: LinksValue;
    /**
     * 
     * @type {string}
     * @memberof TeamUpdateParam
     */
    'contact_email'?: string;
}
/**
 * 
 * @export
 * @interface TeamsResponse
 */
export interface TeamsResponse {
    /**
     * 次のページが存在するか
     * @type {boolean}
     * @memberof TeamsResponse
     */
    'has_next': boolean;
    /**
     * 総ページ数
     * @type {number}
     * @memberof TeamsResponse
     */
    'total_pages'?: number;
    /**
     * 総件数
     * @type {number}
     * @memberof TeamsResponse
     */
    'total_count'?: number;
    /**
     * 
     * @type {NotificationsResponseCursor}
     * @memberof TeamsResponse
     */
    'cursor'?: NotificationsResponseCursor;
    /**
     * 
     * @type {Array<TeamResponse>}
     * @memberof TeamsResponse
     */
    'results'?: Array<TeamResponse>;
}
/**
 * 
 * @export
 * @interface TextureTypeEntity
 */
export interface TextureTypeEntity {
    /**
     * 
     * @type {number}
     * @memberof TextureTypeEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TextureTypeEntity
     */
    'label': string;
}
/**
 * 
 * @export
 * @interface ThemeEntity
 */
export interface ThemeEntity {
    /**
     * 
     * @type {number}
     * @memberof ThemeEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ThemeEntity
     */
    'label': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TopBannerKind = {
    TECTURE_MAGAZINE: 1,
    NEWS: 2,
    GUIDE: 3,
    PROJECT: 4,
    POST: 5,
    MATERIALS: 6,
    EVENT: 7
} as const;

export type TopBannerKind = typeof TopBannerKind[keyof typeof TopBannerKind];


/**
 * 
 * @export
 * @interface TopBannerResponse
 */
export interface TopBannerResponse {
    /**
     * 
     * @type {number}
     * @memberof TopBannerResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TopBannerResponse
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof TopBannerResponse
     */
    'upload_image_id': number;
    /**
     * 
     * @type {TopBannerKind}
     * @memberof TopBannerResponse
     */
    'kind': TopBannerKind;
    /**
     * 
     * @type {string}
     * @memberof TopBannerResponse
     */
    'link_url': string;
    /**
     * 
     * @type {string}
     * @memberof TopBannerResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof TopBannerResponse
     */
    'updated_at': string;
    /**
     * 
     * @type {UploadImageResponse}
     * @memberof TopBannerResponse
     */
    'upload_image': UploadImageResponse;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const TopSectionContentType = {
    TITLE: 1,
    SUBTITLE: 2,
    PICKUP_POSTS: 3,
    PICKUP_PROJECTS: 4,
    PICKUP_BOARDS: 5,
    PICKUP_PRODUCT_VARIATIONS: 6,
    PICKUP_LISTS: 7,
    LATEST_PROJECTS: 8,
    POPULAR_PROJECTS: 9,
    LATEST_AND_RANDOM_MAKERS: 10,
    PICKUP_TEAMS: 11,
    PICKUP_POSTS_WITH_PIN: 12
} as const;

export type TopSectionContentType = typeof TopSectionContentType[keyof typeof TopSectionContentType];


/**
 * 
 * @export
 * @interface TopSectionPickupContentResponse
 */
export interface TopSectionPickupContentResponse {
    /**
     * 
     * @type {number}
     * @memberof TopSectionPickupContentResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TopSectionPickupContentResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof TopSectionPickupContentResponse
     */
    'updated_at': string;
    /**
     * 
     * @type {Array<TopSectionPickupContentResponseEntitiesInner>}
     * @memberof TopSectionPickupContentResponse
     */
    'entities': Array<TopSectionPickupContentResponseEntitiesInner>;
}
/**
 * 
 * @export
 * @interface TopSectionPickupContentResponseEntitiesInner
 */
export interface TopSectionPickupContentResponseEntitiesInner {
    /**
     * 
     * @type {number}
     * @memberof TopSectionPickupContentResponseEntitiesInner
     */
    'entity_id': number;
}
/**
 * 
 * @export
 * @interface TopSectionResponse
 */
export interface TopSectionResponse {
    /**
     * 
     * @type {number}
     * @memberof TopSectionResponse
     */
    'id'?: number;
    /**
     * 
     * @type {TopSectionContentType}
     * @memberof TopSectionResponse
     */
    'content_type': TopSectionContentType;
    /**
     * 
     * @type {TopSectionResponseContent}
     * @memberof TopSectionResponse
     */
    'content'?: TopSectionResponseContent;
    /**
     * 
     * @type {string}
     * @memberof TopSectionResponse
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof TopSectionResponse
     */
    'updated_at'?: string;
}


/**
 * @type TopSectionResponseContent
 * @export
 */
export type TopSectionResponseContent = TopSectionPickupContentResponse | TopSectionTitleContentEntity;

/**
 * 
 * @export
 * @interface TopSectionTitleContentEntity
 */
export interface TopSectionTitleContentEntity {
    /**
     * 
     * @type {number}
     * @memberof TopSectionTitleContentEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TopSectionTitleContentEntity
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof TopSectionTitleContentEntity
     */
    'link_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof TopSectionTitleContentEntity
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof TopSectionTitleContentEntity
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface TopSettingsResponse
 */
export interface TopSettingsResponse {
    /**
     * 
     * @type {Array<PostResponse>}
     * @memberof TopSettingsResponse
     */
    'pickup_posts'?: Array<PostResponse>;
    /**
     * 
     * @type {Array<TopBannerResponse>}
     * @memberof TopSettingsResponse
     */
    'banners'?: Array<TopBannerResponse>;
    /**
     * 
     * @type {Array<TopSectionResponse>}
     * @memberof TopSettingsResponse
     */
    'sections'?: Array<TopSectionResponse>;
}
/**
 * 
 * @export
 * @interface UploadImageResponse
 */
export interface UploadImageResponse {
    /**
     * 
     * @type {number}
     * @memberof UploadImageResponse
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof UploadImageResponse
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof UploadImageResponse
     */
    'height': number;
    /**
     * 
     * @type {string}
     * @memberof UploadImageResponse
     */
    'image_hash'?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadImageResponse
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadImageResponse
     */
    'updated_at'?: string;
    /**
     * 
     * @type {UploadImageResponseUrls}
     * @memberof UploadImageResponse
     */
    'urls': UploadImageResponseUrls;
}
/**
 * 
 * @export
 * @interface UploadImageResponseUrls
 */
export interface UploadImageResponseUrls {
    /**
     * 
     * @type {string}
     * @memberof UploadImageResponseUrls
     */
    'original': string;
    /**
     * 
     * @type {string}
     * @memberof UploadImageResponseUrls
     */
    'small': string;
}
/**
 * 
 * @export
 * @interface UseTypeEntity
 */
export interface UseTypeEntity {
    /**
     * 
     * @type {number}
     * @memberof UseTypeEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UseTypeEntity
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UseTypeEntity
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof UseTypeEntity
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface UserAccountUpdateParam
 */
export interface UserAccountUpdateParam {
    /**
     * 
     * @type {number}
     * @memberof UserAccountUpdateParam
     */
    'industry_id': number;
    /**
     * 
     * @type {number}
     * @memberof UserAccountUpdateParam
     */
    'occupation_id': number;
}
/**
 * 
 * @export
 * @interface UserAttributesResponse
 */
export interface UserAttributesResponse {
    /**
     * 
     * @type {Array<IndustryEntity>}
     * @memberof UserAttributesResponse
     */
    'industries': Array<IndustryEntity>;
    /**
     * 
     * @type {Array<OccupationEntity>}
     * @memberof UserAttributesResponse
     */
    'occupations': Array<OccupationEntity>;
}
/**
 * 
 * @export
 * @interface UserCreateParam
 */
export interface UserCreateParam {
    /**
     * 
     * @type {string}
     * @memberof UserCreateParam
     */
    'screen_name': string;
    /**
     * 
     * @type {number}
     * @memberof UserCreateParam
     */
    'industry_id': number;
    /**
     * 
     * @type {number}
     * @memberof UserCreateParam
     */
    'occupation_id': number;
}
/**
 * 
 * @export
 * @interface UserDetailResponse
 */
export interface UserDetailResponse {
    /**
     * 
     * @type {number}
     * @memberof UserDetailResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UserDetailResponse
     */
    'screen_name': string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailResponse
     */
    'profile'?: string;
    /**
     * 
     * @type {UserKind}
     * @memberof UserDetailResponse
     */
    'kind': UserKind;
    /**
     * 
     * @type {number}
     * @memberof UserDetailResponse
     */
    'following_count': number;
    /**
     * 
     * @type {number}
     * @memberof UserDetailResponse
     */
    'followers_count': number;
    /**
     * 
     * @type {LinksValue}
     * @memberof UserDetailResponse
     */
    'links'?: LinksValue;
    /**
     * 
     * @type {boolean}
     * @memberof UserDetailResponse
     */
    'is_subscribed_mail_magazine': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserDetailResponse
     */
    'keywords'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UserDetailResponse
     */
    'contact_email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailResponse
     */
    'updated_at': string;
    /**
     * 
     * @type {UploadImageResponse}
     * @memberof UserDetailResponse
     */
    'icon': UploadImageResponse;
    /**
     * ログインしていない場合 or 自分自身の場合はfalseが返る
     * @type {boolean}
     * @memberof UserDetailResponse
     */
    'is_following': boolean;
    /**
     * 
     * @type {MakerEntity}
     * @memberof UserDetailResponse
     */
    'maker'?: MakerEntity;
}


/**
 * 
 * @export
 * @interface UserDetailsResponse
 */
export interface UserDetailsResponse {
    /**
     * 次のページが存在するか
     * @type {boolean}
     * @memberof UserDetailsResponse
     */
    'has_next': boolean;
    /**
     * 総ページ数
     * @type {number}
     * @memberof UserDetailsResponse
     */
    'total_pages'?: number;
    /**
     * 総件数
     * @type {number}
     * @memberof UserDetailsResponse
     */
    'total_count'?: number;
    /**
     * 
     * @type {NotificationsResponseCursor}
     * @memberof UserDetailsResponse
     */
    'cursor'?: NotificationsResponseCursor;
    /**
     * 
     * @type {Array<UserDetailResponse>}
     * @memberof UserDetailsResponse
     */
    'results'?: Array<UserDetailResponse>;
}
/**
 * 
 * @export
 * @interface UserEntity
 */
export interface UserEntity {
    /**
     * 
     * @type {number}
     * @memberof UserEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UserEntity
     */
    'screen_name': string;
    /**
     * 
     * @type {string}
     * @memberof UserEntity
     */
    'profile'?: string;
    /**
     * 
     * @type {UserKind}
     * @memberof UserEntity
     */
    'kind': UserKind;
    /**
     * 
     * @type {number}
     * @memberof UserEntity
     */
    'following_count': number;
    /**
     * 
     * @type {number}
     * @memberof UserEntity
     */
    'followers_count': number;
    /**
     * 
     * @type {LinksValue}
     * @memberof UserEntity
     */
    'links'?: LinksValue;
    /**
     * 
     * @type {boolean}
     * @memberof UserEntity
     */
    'is_subscribed_mail_magazine': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserEntity
     */
    'keywords'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UserEntity
     */
    'contact_email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserEntity
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof UserEntity
     */
    'updated_at': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const UserKind = {
    VIEWER: 1,
    EDITOR: 2,
    PRO: 3,
    ADMIN: 4
} as const;

export type UserKind = typeof UserKind[keyof typeof UserKind];


/**
 * 
 * @export
 * @interface UserProfileUpdateParam
 */
export interface UserProfileUpdateParam {
    /**
     * 
     * @type {number}
     * @memberof UserProfileUpdateParam
     */
    'icon_image_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserProfileUpdateParam
     */
    'screen_name': string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileUpdateParam
     */
    'profile'?: string;
    /**
     * 
     * @type {LinksValue}
     * @memberof UserProfileUpdateParam
     */
    'links'?: LinksValue;
    /**
     * 
     * @type {string}
     * @memberof UserProfileUpdateParam
     */
    'contact_email'?: string;
}
/**
 * 
 * @export
 * @interface UserResponse
 */
export interface UserResponse {
    /**
     * 
     * @type {number}
     * @memberof UserResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'screen_name': string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'profile'?: string;
    /**
     * 
     * @type {UserKind}
     * @memberof UserResponse
     */
    'kind': UserKind;
    /**
     * 
     * @type {number}
     * @memberof UserResponse
     */
    'following_count': number;
    /**
     * 
     * @type {number}
     * @memberof UserResponse
     */
    'followers_count': number;
    /**
     * 
     * @type {LinksValue}
     * @memberof UserResponse
     */
    'links'?: LinksValue;
    /**
     * 
     * @type {boolean}
     * @memberof UserResponse
     */
    'is_subscribed_mail_magazine': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserResponse
     */
    'keywords'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'contact_email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'updated_at': string;
    /**
     * 
     * @type {UploadImageResponse}
     * @memberof UserResponse
     */
    'icon': UploadImageResponse;
}


/**
 * 
 * @export
 * @interface UsersIdGet301Response
 */
export interface UsersIdGet301Response {
    /**
     * 
     * @type {number}
     * @memberof UsersIdGet301Response
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface ZipCodeResponse
 */
export interface ZipCodeResponse {
    /**
     * 
     * @type {string}
     * @memberof ZipCodeResponse
     */
    'zipcode': string;
    /**
     * 
     * @type {string}
     * @memberof ZipCodeResponse
     */
    'prefecture': string;
    /**
     * 
     * @type {string}
     * @memberof ZipCodeResponse
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof ZipCodeResponse
     */
    'town'?: string;
    /**
     * 
     * @type {number}
     * @memberof ZipCodeResponse
     */
    'prefecture_code': number;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary ログインユーザーの住所を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressesGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/addresses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  住所を削除します。
         * @summary 住所削除
         * @param {number} id Address ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressesIdDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addressesIdDelete', 'id', id)
            const localVarPath = `/addresses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ログインユーザーの住所を更新する
         * @param {number} id Address ID
         * @param {AddressUpsertParameter} addressUpsertParameter AddressUpsertパラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressesIdPut: async (id: number, addressUpsertParameter: AddressUpsertParameter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addressesIdPut', 'id', id)
            // verify required parameter 'addressUpsertParameter' is not null or undefined
            assertParamExists('addressesIdPut', 'addressUpsertParameter', addressUpsertParameter)
            const localVarPath = `/addresses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addressUpsertParameter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ログインユーザーの住所を登録する
         * @param {AddressUpsertParameter} addressUpsertParameter AddressUpsertパラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressesPost: async (addressUpsertParameter: AddressUpsertParameter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addressUpsertParameter' is not null or undefined
            assertParamExists('addressesPost', 'addressUpsertParameter', addressUpsertParameter)
            const localVarPath = `/addresses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addressUpsertParameter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 現在開催中のAward取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        awardsCurrentGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/awards/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  指定されたアワードのカテゴリ一覧を取得します
         * @summary 特定のアワードのカテゴリリストを取得する
         * @param {string} slug アワードのスラッグ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        awardsSlugCategoriesGet: async (slug: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('awardsSlugCategoriesGet', 'slug', slug)
            const localVarPath = `/awards/{slug}/categories`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Award応募作品への投票
         * @param {string} slug 応募するAwardのSlug
         * @param {number} entryId 応募作品のEntryID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        awardsSlugEntriesEntryIdVotesPost: async (slug: string, entryId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('awardsSlugEntriesEntryIdVotesPost', 'slug', slug)
            // verify required parameter 'entryId' is not null or undefined
            assertParamExists('awardsSlugEntriesEntryIdVotesPost', 'entryId', entryId)
            const localVarPath = `/awards/{slug}/entries/{entry_id}/votes`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)))
                .replace(`{${"entry_id"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Awardへの応募作品の取得
         * @param {string} slug 取得対象のAwardのSlug
         * @param {number} [awardCategoryId] カテゴリID
         * @param {AwardsSlugEntriesGetSortEnum} [sort] ソート条件
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [offset] オフセット
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        awardsSlugEntriesGet: async (slug: string, awardCategoryId?: number, sort?: AwardsSlugEntriesGetSortEnum, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('awardsSlugEntriesGet', 'slug', slug)
            const localVarPath = `/awards/{slug}/entries`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (awardCategoryId !== undefined) {
                localVarQueryParameter['award_category_id'] = awardCategoryId;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Awardへの作品応募
         * @param {string} slug 応募するAwardのSlug
         * @param {AwardEntryCreateParameter} awardEntryCreateParameter 応募パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        awardsSlugEntriesPost: async (slug: string, awardEntryCreateParameter: AwardEntryCreateParameter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('awardsSlugEntriesPost', 'slug', slug)
            // verify required parameter 'awardEntryCreateParameter' is not null or undefined
            assertParamExists('awardsSlugEntriesPost', 'awardEntryCreateParameter', awardEntryCreateParameter)
            const localVarPath = `/awards/{slug}/entries`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(awardEntryCreateParameter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 現在開催中のAward取得
         * @param {string} slug 応募するAwardのSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        awardsSlugGet: async (slug: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('awardsSlugGet', 'slug', slug)
            const localVarPath = `/awards/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 投票済みの応募作品ID一覧取得
         * @param {string} slug 応募するAwardのSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        awardsSlugVotedEntryIdsGet: async (slug: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('awardsSlugVotedEntryIdsGet', 'slug', slug)
            const localVarPath = `/awards/{slug}/voted_entry_ids`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  ボードの詳細を取得します。
         * @summary ボードの詳細を取得
         * @param {number} id Board ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        boardsIdGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('boardsIdGet', 'id', id)
            const localVarPath = `/boards/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  ボードに紐づくプロダクトバリエーションから事例写真を取得します
         * @summary ボードの事例写真を取得
         * @param {number} id Board ID
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [page] ページ(default: 1)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        boardsIdPostsGet: async (id: number, limit?: number, page?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('boardsIdPostsGet', 'id', id)
            const localVarPath = `/boards/{id}/posts`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ID指定ボード一覧
         * @param {string} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        boardsSearchByIDsGet: async (ids: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('boardsSearchByIDsGet', 'ids', ids)
            const localVarPath = `/boards/searchByIDs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ids !== undefined) {
                localVarQueryParameter['ids'] = ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  ボードの一覧を取得します。
         * @summary ボードの検索
         * @param {string} [keyword] 検索キーワード
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [offset] ページ取得位置(default: 0)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        boardsSearchGet: async (keyword?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/boards/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  指定したIDのブランドを取得します
         * @summary 指定したIDのブランドを取得
         * @param {number} id Brand ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandsIdGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('brandsIdGet', 'id', id)
            const localVarPath = `/brands/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  指定したIDのブランドの検索条件にマッチした製品が紐づく事例写真を返します 並び順はElasticsearchのマッチ率順になるので、cursor形式のページネーションは使えず、 limit, offset形式になります
         * @summary 指定したIDのブランドの製品が紐づく事例を取得
         * @param {number} id Brand ID
         * @param {string} [keyword] 検索キーワード
         * @param {BrandsIdPostsGetKindEnum} [kind] 種類
         * @param {number} [categoryId] カテゴリID
         * @param {Array<BrandsIdPostsGetColorTypeIdsEnum>} [colorTypeIds] カラーIDの配列
         * @param {BrandsIdPostsGetFireProtectionMaterialEnum} [fireProtectionMaterial] 防火材料
         * @param {BrandsIdPostsGetFlamePreventionRegulationEnum} [flamePreventionRegulation] 防炎規制
         * @param {BrandsIdPostsGetFormaldehydeEnum} [formaldehyde] ホルムアルデヒド等級
         * @param {number} [materialId] 素材ID
         * @param {number} [minWidth] 最小幅
         * @param {number} [maxWidth] 最小幅
         * @param {number} [minHeight] 最小高さ
         * @param {number} [maxHeight] 最小高さ
         * @param {number} [minDepth] 最小奥行き
         * @param {number} [maxDepth] 最小奥行き
         * @param {number} [minPrice] 最低価格
         * @param {number} [maxPrice] 最高価格
         * @param {BrandsIdPostsGetFireResistanceEnum} [fireResistance] 耐火性能
         * @param {boolean} [inStock] 在庫あり
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [offset] ページ取得位置(default: 0)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandsIdPostsGet: async (id: number, keyword?: string, kind?: BrandsIdPostsGetKindEnum, categoryId?: number, colorTypeIds?: Array<BrandsIdPostsGetColorTypeIdsEnum>, fireProtectionMaterial?: BrandsIdPostsGetFireProtectionMaterialEnum, flamePreventionRegulation?: BrandsIdPostsGetFlamePreventionRegulationEnum, formaldehyde?: BrandsIdPostsGetFormaldehydeEnum, materialId?: number, minWidth?: number, maxWidth?: number, minHeight?: number, maxHeight?: number, minDepth?: number, maxDepth?: number, minPrice?: number, maxPrice?: number, fireResistance?: BrandsIdPostsGetFireResistanceEnum, inStock?: boolean, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('brandsIdPostsGet', 'id', id)
            const localVarPath = `/brands/{id}/posts`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (kind !== undefined) {
                localVarQueryParameter['kind'] = kind;
            }

            if (categoryId !== undefined) {
                localVarQueryParameter['category_id'] = categoryId;
            }

            if (colorTypeIds) {
                localVarQueryParameter['color_type_ids[]'] = colorTypeIds;
            }

            if (fireProtectionMaterial !== undefined) {
                localVarQueryParameter['fire_protection_material'] = fireProtectionMaterial;
            }

            if (flamePreventionRegulation !== undefined) {
                localVarQueryParameter['flame_prevention_regulation'] = flamePreventionRegulation;
            }

            if (formaldehyde !== undefined) {
                localVarQueryParameter['formaldehyde'] = formaldehyde;
            }

            if (materialId !== undefined) {
                localVarQueryParameter['material_id'] = materialId;
            }

            if (minWidth !== undefined) {
                localVarQueryParameter['min_width'] = minWidth;
            }

            if (maxWidth !== undefined) {
                localVarQueryParameter['max_width'] = maxWidth;
            }

            if (minHeight !== undefined) {
                localVarQueryParameter['min_height'] = minHeight;
            }

            if (maxHeight !== undefined) {
                localVarQueryParameter['max_height'] = maxHeight;
            }

            if (minDepth !== undefined) {
                localVarQueryParameter['min_depth'] = minDepth;
            }

            if (maxDepth !== undefined) {
                localVarQueryParameter['max_depth'] = maxDepth;
            }

            if (minPrice !== undefined) {
                localVarQueryParameter['min_price'] = minPrice;
            }

            if (maxPrice !== undefined) {
                localVarQueryParameter['max_price'] = maxPrice;
            }

            if (fireResistance !== undefined) {
                localVarQueryParameter['fire_resistance'] = fireResistance;
            }

            if (inStock !== undefined) {
                localVarQueryParameter['in_stock'] = inStock;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  指定したIDのブランドの検索条件にマッチした商品バリエーションの一覧を取得します 並び順はElasticsearchのマッチ率順になるので、cursor形式のページネーションは使えず、 limit, offset形式になります 1つのプロダクトに付き、1つのバリエーションを返します
         * @summary 指定したIDのブランドの製品バリエーションを取得
         * @param {number} id Brand ID
         * @param {string} [keyword] 検索キーワード
         * @param {BrandsIdProductVariationsGetKindEnum} [kind] 種類
         * @param {number} [categoryId] カテゴリID
         * @param {Array<BrandsIdProductVariationsGetColorTypeIdsEnum>} [colorTypeIds] カラーIDの配列
         * @param {BrandsIdProductVariationsGetFireProtectionMaterialEnum} [fireProtectionMaterial] 防火材料
         * @param {BrandsIdProductVariationsGetFlamePreventionRegulationEnum} [flamePreventionRegulation] 防炎規制
         * @param {BrandsIdProductVariationsGetFormaldehydeEnum} [formaldehyde] ホルムアルデヒド等級
         * @param {number} [materialId] 素材ID
         * @param {number} [minWidth] 最小幅
         * @param {number} [maxWidth] 最小幅
         * @param {number} [minHeight] 最小高さ
         * @param {number} [maxHeight] 最小高さ
         * @param {number} [minDepth] 最小奥行き
         * @param {number} [maxDepth] 最小奥行き
         * @param {number} [minPrice] 最低価格
         * @param {number} [maxPrice] 最高価格
         * @param {BrandsIdProductVariationsGetFireResistanceEnum} [fireResistance] 耐火性能
         * @param {boolean} [inStock] 在庫あり
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [offset] ページ取得位置(default: 0)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandsIdProductVariationsGet: async (id: number, keyword?: string, kind?: BrandsIdProductVariationsGetKindEnum, categoryId?: number, colorTypeIds?: Array<BrandsIdProductVariationsGetColorTypeIdsEnum>, fireProtectionMaterial?: BrandsIdProductVariationsGetFireProtectionMaterialEnum, flamePreventionRegulation?: BrandsIdProductVariationsGetFlamePreventionRegulationEnum, formaldehyde?: BrandsIdProductVariationsGetFormaldehydeEnum, materialId?: number, minWidth?: number, maxWidth?: number, minHeight?: number, maxHeight?: number, minDepth?: number, maxDepth?: number, minPrice?: number, maxPrice?: number, fireResistance?: BrandsIdProductVariationsGetFireResistanceEnum, inStock?: boolean, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('brandsIdProductVariationsGet', 'id', id)
            const localVarPath = `/brands/{id}/product_variations`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (kind !== undefined) {
                localVarQueryParameter['kind'] = kind;
            }

            if (categoryId !== undefined) {
                localVarQueryParameter['category_id'] = categoryId;
            }

            if (colorTypeIds) {
                localVarQueryParameter['color_type_ids[]'] = colorTypeIds;
            }

            if (fireProtectionMaterial !== undefined) {
                localVarQueryParameter['fire_protection_material'] = fireProtectionMaterial;
            }

            if (flamePreventionRegulation !== undefined) {
                localVarQueryParameter['flame_prevention_regulation'] = flamePreventionRegulation;
            }

            if (formaldehyde !== undefined) {
                localVarQueryParameter['formaldehyde'] = formaldehyde;
            }

            if (materialId !== undefined) {
                localVarQueryParameter['material_id'] = materialId;
            }

            if (minWidth !== undefined) {
                localVarQueryParameter['min_width'] = minWidth;
            }

            if (maxWidth !== undefined) {
                localVarQueryParameter['max_width'] = maxWidth;
            }

            if (minHeight !== undefined) {
                localVarQueryParameter['min_height'] = minHeight;
            }

            if (maxHeight !== undefined) {
                localVarQueryParameter['max_height'] = maxHeight;
            }

            if (minDepth !== undefined) {
                localVarQueryParameter['min_depth'] = minDepth;
            }

            if (maxDepth !== undefined) {
                localVarQueryParameter['max_depth'] = maxDepth;
            }

            if (minPrice !== undefined) {
                localVarQueryParameter['min_price'] = minPrice;
            }

            if (maxPrice !== undefined) {
                localVarQueryParameter['max_price'] = maxPrice;
            }

            if (fireResistance !== undefined) {
                localVarQueryParameter['fire_resistance'] = fireResistance;
            }

            if (inStock !== undefined) {
                localVarQueryParameter['in_stock'] = inStock;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  ビルディングタイプの一覧を取得します
         * @summary ビルディングタイプ取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildingTypesGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/building_types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  ビルディングタイプに紐づく用途の一覧を取得します
         * @summary ビルディングタイプに紐づく用途一覧
         * @param {number} id BuildingType ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildingTypesIdUseTypesGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('buildingTypesIdUseTypesGet', 'id', id)
            const localVarPath = `/building_types/{id}/use_types`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  指定したIDのカタログ情報を返します
         * @summary 指定したIDのカタログを取得
         * @param {number} id Catalog ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogsIdGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('catalogsIdGet', 'id', id)
            const localVarPath = `/catalogs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  指定したIDのカタログのページ情報を返します
         * @summary 指定したIDのカタログのページ情報を取得
         * @param {number} id Catalog ID
         * @param {number} fromPage 何ページ目からの取得か
         * @param {number} toPage 何ページ目までの取得か
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogsIdPagesGet: async (id: number, fromPage: number, toPage: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('catalogsIdPagesGet', 'id', id)
            // verify required parameter 'fromPage' is not null or undefined
            assertParamExists('catalogsIdPagesGet', 'fromPage', fromPage)
            // verify required parameter 'toPage' is not null or undefined
            assertParamExists('catalogsIdPagesGet', 'toPage', toPage)
            const localVarPath = `/catalogs/{id}/pages`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fromPage !== undefined) {
                localVarQueryParameter['from_page'] = fromPage;
            }

            if (toPage !== undefined) {
                localVarQueryParameter['to_page'] = toPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  カテゴリの一覧を取得します
         * @summary カテゴリの一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  カテゴリの詳細検索項目を取得します
         * @summary カテゴリの詳細検索項目
         * @param {number} id Category ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesIdSearchConditionGroupsGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('categoriesIdSearchConditionGroupsGet', 'id', id)
            const localVarPath = `/categories/{id}/search_condition_groups`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  設計者アカウントを新規登録します。
         * @summary 設計者アカウント新規登録
         * @param {DesignerCreateParam} designerCreateParam 設計者アカウント登録パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        designersPost: async (designerCreateParam: DesignerCreateParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'designerCreateParam' is not null or undefined
            assertParamExists('designersPost', 'designerCreateParam', designerCreateParam)
            const localVarPath = `/designers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(designerCreateParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  ユーザーをフォローします  ## validation  - user_id: 必須
         * @summary ユーザーをフォローする
         * @param {FollowCreateParameter} followCreateParameter フォローパラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        followsPost: async (followCreateParameter: FollowCreateParameter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'followCreateParameter' is not null or undefined
            assertParamExists('followsPost', 'followCreateParameter', followCreateParameter)
            const localVarPath = `/follows`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(followCreateParameter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  ユーザーをアンフォローします
         * @summary ユーザーをアンフォローする
         * @param {number} userId User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        followsUserIdDelete: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('followsUserIdDelete', 'userId', userId)
            const localVarPath = `/follows/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  画像をアップロードします
         * @summary 画像アップロード
         * @param {File} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesPost: async (image?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/images`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  指定したEntityのLikeを削除します
         * @summary Likeを削除する
         * @param {LikeDeleteParam} likeDeleteParam Like削除パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        likesDelete: async (likeDeleteParam: LikeDeleteParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'likeDeleteParam' is not null or undefined
            assertParamExists('likesDelete', 'likeDeleteParam', likeDeleteParam)
            const localVarPath = `/likes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(likeDeleteParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  指定したEntityをLikeします
         * @summary Likeする
         * @param {LikeCreateParam} likeCreateParam Like作成パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        likesPost: async (likeCreateParam: LikeCreateParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'likeCreateParam' is not null or undefined
            assertParamExists('likesPost', 'likeCreateParam', likeCreateParam)
            const localVarPath = `/likes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(likeCreateParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  リストを削除します。
         * @summary リストを削除
         * @param {number} id List ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listsIdDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('listsIdDelete', 'id', id)
            const localVarPath = `/lists/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  指定したIDのリストを取得します。   対象のリストが非公開だった場合、本人でない場合は403を返します。
         * @summary リストを取得
         * @param {number} id List ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listsIdGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('listsIdGet', 'id', id)
            const localVarPath = `/lists/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  指定したIDのリストに追加されているアイテムの一覧を取得します。   対象のリストが非公開だった場合、本人でない場合は403を返します。
         * @summary リストのアイテムを取得
         * @param {number} id List ID
         * @param {ListItemKind} [kind] List Item KInd
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [cursor] 取得カーソル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listsIdItemsGet: async (id: number, kind?: ListItemKind, limit?: number, cursor?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('listsIdItemsGet', 'id', id)
            const localVarPath = `/lists/{id}/items`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kind !== undefined) {
                localVarQueryParameter['kind'] = kind;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  指定したIDのリストに、アイテムを追加します。  ## logic  * すでにリストに追加済みの場合は400を返します。
         * @summary リストにアイテムを追加
         * @param {number} id List ID
         * @param {ListAddItemParameter} listAddItemParameter 風景写真登録パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listsIdItemsPost: async (id: number, listAddItemParameter: ListAddItemParameter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('listsIdItemsPost', 'id', id)
            // verify required parameter 'listAddItemParameter' is not null or undefined
            assertParamExists('listsIdItemsPost', 'listAddItemParameter', listAddItemParameter)
            const localVarPath = `/lists/{id}/items`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listAddItemParameter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  リストを更新します。  ## validation  - name: 必須, 255文字以内 - is_praivate: 必須
         * @summary リストを更新
         * @param {number} id List ID
         * @param {ListUpdateParam} listUpdateParam リスト作成パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listsIdPut: async (id: number, listUpdateParam: ListUpdateParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('listsIdPut', 'id', id)
            // verify required parameter 'listUpdateParam' is not null or undefined
            assertParamExists('listsIdPut', 'listUpdateParam', listUpdateParam)
            const localVarPath = `/lists/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listUpdateParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  指定したIDのリストから、アイテムを削除します。
         * @summary リストからアイテムを削除
         * @param {number} listId List ID
         * @param {number} itemId Item ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listsListIdItemsItemIdDelete: async (listId: number, itemId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listId' is not null or undefined
            assertParamExists('listsListIdItemsItemIdDelete', 'listId', listId)
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('listsListIdItemsItemIdDelete', 'itemId', itemId)
            const localVarPath = `/lists/{list_id}/items/{item_id}`
                .replace(`{${"list_id"}}`, encodeURIComponent(String(listId)))
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  リストを作成します。  ## validation  - name: 必須, 255文字以内 - is_praivate: 必須
         * @summary リストの作成
         * @param {ListCreateParam} listCreateParam リスト作成パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listsPost: async (listCreateParam: ListCreateParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listCreateParam' is not null or undefined
            assertParamExists('listsPost', 'listCreateParam', listCreateParam)
            const localVarPath = `/lists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listCreateParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ID指定リスト一覧
         * @param {string} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listsSearchByIDsGet: async (ids: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('listsSearchByIDsGet', 'ids', ids)
            const localVarPath = `/lists/searchByIDs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ids !== undefined) {
                localVarQueryParameter['ids'] = ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary リストの検索
         * @param {string} [keyword] 検索キーワード
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [offset] offset(default: 0)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listsSearchGet: async (keyword?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/lists/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 記事を新着順で取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        magazinesGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/magazines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary タグに紐づく記事を取得する
         * @param {string} slug tagのslug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        magazinesTagsSlugGet: async (slug: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('magazinesTagsSlugGet', 'slug', slug)
            const localVarPath = `/magazines/tags/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  すべてのメーカーを取得します
         * @summary すべてのメーカーを取得
         * @param {boolean} [availableSampleRequest] サンプル請求可能なメーカーに絞り込む
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makersAllGet: async (availableSampleRequest?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/makers/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (availableSampleRequest !== undefined) {
                localVarQueryParameter['available_sample_request'] = availableSampleRequest;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  指定したIDのメーカーのカタログを取得します
         * @summary 指定したIDのメーカーのカタログを取得
         * @param {number} id Maker ID
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {string} [cursor] 取得カーソル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makersIdCatalogsGet: async (id: number, limit?: number, cursor?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('makersIdCatalogsGet', 'id', id)
            const localVarPath = `/makers/{id}/catalogs`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  指定したIDのメーカーを取得します
         * @summary 指定したIDのメーカーをカテゴリも含めて取得
         * @param {number} id Maker ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makersIdCategoriesGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('makersIdCategoriesGet', 'id', id)
            const localVarPath = `/makers/{id}/categories`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  指定したIDのメーカーに対して問い合わせを行う
         * @summary メーカー問い合わせ
         * @param {number} id Maker ID
         * @param {MakerContactCreateParam} makerContactCreateParam メーカー問い合わせパラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makersIdContactsPost: async (id: number, makerContactCreateParam: MakerContactCreateParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('makersIdContactsPost', 'id', id)
            // verify required parameter 'makerContactCreateParam' is not null or undefined
            assertParamExists('makersIdContactsPost', 'makerContactCreateParam', makerContactCreateParam)
            const localVarPath = `/makers/{id}/contacts`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(makerContactCreateParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  指定したIDのメーカーを取得します
         * @summary 指定したIDのメーカーを取得
         * @param {number} id Maker ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makersIdGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('makersIdGet', 'id', id)
            const localVarPath = `/makers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 指定したIDのメーカーのリストを取得
         * @param {number} id Maker ID
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [offset] ページ取得位置(default: 0)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makersIdListsGet: async (id: number, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('makersIdListsGet', 'id', id)
            const localVarPath = `/makers/{id}/lists`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  指定したIDのメーカーの検索条件にマッチした製品が紐づく事例写真を返します 並び順はElasticsearchのマッチ率順になるので、cursor形式のページネーションは使えず、 limit, offset形式になります
         * @summary 指定したIDのメーカーの製品が紐づく事例を取得
         * @param {number} id Maker ID
         * @param {string} [keyword] 検索キーワード
         * @param {MakersIdPostsGetKindEnum} [kind] 種類
         * @param {number} [categoryId] カテゴリID
         * @param {Array<MakersIdPostsGetColorTypeIdsEnum>} [colorTypeIds] カラーIDの配列
         * @param {MakersIdPostsGetFireProtectionMaterialEnum} [fireProtectionMaterial] 防火材料
         * @param {MakersIdPostsGetFlamePreventionRegulationEnum} [flamePreventionRegulation] 防炎規制
         * @param {MakersIdPostsGetFormaldehydeEnum} [formaldehyde] ホルムアルデヒド等級
         * @param {number} [materialId] 素材ID
         * @param {number} [minWidth] 最小幅
         * @param {number} [maxWidth] 最小幅
         * @param {number} [minHeight] 最小高さ
         * @param {number} [maxHeight] 最小高さ
         * @param {number} [minDepth] 最小奥行き
         * @param {number} [maxDepth] 最小奥行き
         * @param {number} [minPrice] 最低価格
         * @param {number} [maxPrice] 最高価格
         * @param {MakersIdPostsGetFireResistanceEnum} [fireResistance] 耐火性能
         * @param {boolean} [inStock] 在庫あり
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [offset] ページ取得位置(default: 0)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makersIdPostsGet: async (id: number, keyword?: string, kind?: MakersIdPostsGetKindEnum, categoryId?: number, colorTypeIds?: Array<MakersIdPostsGetColorTypeIdsEnum>, fireProtectionMaterial?: MakersIdPostsGetFireProtectionMaterialEnum, flamePreventionRegulation?: MakersIdPostsGetFlamePreventionRegulationEnum, formaldehyde?: MakersIdPostsGetFormaldehydeEnum, materialId?: number, minWidth?: number, maxWidth?: number, minHeight?: number, maxHeight?: number, minDepth?: number, maxDepth?: number, minPrice?: number, maxPrice?: number, fireResistance?: MakersIdPostsGetFireResistanceEnum, inStock?: boolean, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('makersIdPostsGet', 'id', id)
            const localVarPath = `/makers/{id}/posts`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (kind !== undefined) {
                localVarQueryParameter['kind'] = kind;
            }

            if (categoryId !== undefined) {
                localVarQueryParameter['category_id'] = categoryId;
            }

            if (colorTypeIds) {
                localVarQueryParameter['color_type_ids[]'] = colorTypeIds;
            }

            if (fireProtectionMaterial !== undefined) {
                localVarQueryParameter['fire_protection_material'] = fireProtectionMaterial;
            }

            if (flamePreventionRegulation !== undefined) {
                localVarQueryParameter['flame_prevention_regulation'] = flamePreventionRegulation;
            }

            if (formaldehyde !== undefined) {
                localVarQueryParameter['formaldehyde'] = formaldehyde;
            }

            if (materialId !== undefined) {
                localVarQueryParameter['material_id'] = materialId;
            }

            if (minWidth !== undefined) {
                localVarQueryParameter['min_width'] = minWidth;
            }

            if (maxWidth !== undefined) {
                localVarQueryParameter['max_width'] = maxWidth;
            }

            if (minHeight !== undefined) {
                localVarQueryParameter['min_height'] = minHeight;
            }

            if (maxHeight !== undefined) {
                localVarQueryParameter['max_height'] = maxHeight;
            }

            if (minDepth !== undefined) {
                localVarQueryParameter['min_depth'] = minDepth;
            }

            if (maxDepth !== undefined) {
                localVarQueryParameter['max_depth'] = maxDepth;
            }

            if (minPrice !== undefined) {
                localVarQueryParameter['min_price'] = minPrice;
            }

            if (maxPrice !== undefined) {
                localVarQueryParameter['max_price'] = maxPrice;
            }

            if (fireResistance !== undefined) {
                localVarQueryParameter['fire_resistance'] = fireResistance;
            }

            if (inStock !== undefined) {
                localVarQueryParameter['in_stock'] = inStock;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  指定したIDのメーカーの検索条件にマッチした商品バリエーションの一覧を取得します 並び順はElasticsearchのマッチ率順になるので、cursor形式のページネーションは使えず、 limit, offset形式になります 1つのプロダクトに付き、1つのバリエーションを返します
         * @summary 指定したIDのメーカーの製品バリエーションを取得
         * @param {number} id Maker ID
         * @param {string} [keyword] 検索キーワード
         * @param {MakersIdProductVariationsGetKindEnum} [kind] 種類
         * @param {number} [categoryId] カテゴリID
         * @param {Array<MakersIdProductVariationsGetColorTypeIdsEnum>} [colorTypeIds] カラーIDの配列
         * @param {MakersIdProductVariationsGetFireProtectionMaterialEnum} [fireProtectionMaterial] 防火材料
         * @param {MakersIdProductVariationsGetFlamePreventionRegulationEnum} [flamePreventionRegulation] 防炎規制
         * @param {MakersIdProductVariationsGetFormaldehydeEnum} [formaldehyde] ホルムアルデヒド等級
         * @param {number} [materialId] 素材ID
         * @param {number} [minWidth] 最小幅
         * @param {number} [maxWidth] 最小幅
         * @param {number} [minHeight] 最小高さ
         * @param {number} [maxHeight] 最小高さ
         * @param {number} [minDepth] 最小奥行き
         * @param {number} [maxDepth] 最小奥行き
         * @param {number} [minPrice] 最低価格
         * @param {number} [maxPrice] 最高価格
         * @param {MakersIdProductVariationsGetFireResistanceEnum} [fireResistance] 耐火性能
         * @param {boolean} [inStock] 在庫あり
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [offset] ページ取得位置(default: 0)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makersIdProductVariationsGet: async (id: number, keyword?: string, kind?: MakersIdProductVariationsGetKindEnum, categoryId?: number, colorTypeIds?: Array<MakersIdProductVariationsGetColorTypeIdsEnum>, fireProtectionMaterial?: MakersIdProductVariationsGetFireProtectionMaterialEnum, flamePreventionRegulation?: MakersIdProductVariationsGetFlamePreventionRegulationEnum, formaldehyde?: MakersIdProductVariationsGetFormaldehydeEnum, materialId?: number, minWidth?: number, maxWidth?: number, minHeight?: number, maxHeight?: number, minDepth?: number, maxDepth?: number, minPrice?: number, maxPrice?: number, fireResistance?: MakersIdProductVariationsGetFireResistanceEnum, inStock?: boolean, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('makersIdProductVariationsGet', 'id', id)
            const localVarPath = `/makers/{id}/product_variations`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (kind !== undefined) {
                localVarQueryParameter['kind'] = kind;
            }

            if (categoryId !== undefined) {
                localVarQueryParameter['category_id'] = categoryId;
            }

            if (colorTypeIds) {
                localVarQueryParameter['color_type_ids[]'] = colorTypeIds;
            }

            if (fireProtectionMaterial !== undefined) {
                localVarQueryParameter['fire_protection_material'] = fireProtectionMaterial;
            }

            if (flamePreventionRegulation !== undefined) {
                localVarQueryParameter['flame_prevention_regulation'] = flamePreventionRegulation;
            }

            if (formaldehyde !== undefined) {
                localVarQueryParameter['formaldehyde'] = formaldehyde;
            }

            if (materialId !== undefined) {
                localVarQueryParameter['material_id'] = materialId;
            }

            if (minWidth !== undefined) {
                localVarQueryParameter['min_width'] = minWidth;
            }

            if (maxWidth !== undefined) {
                localVarQueryParameter['max_width'] = maxWidth;
            }

            if (minHeight !== undefined) {
                localVarQueryParameter['min_height'] = minHeight;
            }

            if (maxHeight !== undefined) {
                localVarQueryParameter['max_height'] = maxHeight;
            }

            if (minDepth !== undefined) {
                localVarQueryParameter['min_depth'] = minDepth;
            }

            if (maxDepth !== undefined) {
                localVarQueryParameter['max_depth'] = maxDepth;
            }

            if (minPrice !== undefined) {
                localVarQueryParameter['min_price'] = minPrice;
            }

            if (maxPrice !== undefined) {
                localVarQueryParameter['max_price'] = maxPrice;
            }

            if (fireResistance !== undefined) {
                localVarQueryParameter['fire_resistance'] = fireResistance;
            }

            if (inStock !== undefined) {
                localVarQueryParameter['in_stock'] = inStock;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  指定したIDのメーカーの検索条件にマッチした製品が紐づくプロジェクトを返します 並び順はElasticsearchのマッチ率順になるので、cursor形式のページネーションは使えず、 limit, offset形式になります
         * @summary 指定したIDのメーカーの製品が紐づくプロジェクトを取得
         * @param {number} id Maker ID
         * @param {string} [keyword] 検索キーワード
         * @param {MakersIdProjectsGetKindEnum} [kind] 種類
         * @param {number} [categoryId] カテゴリID
         * @param {Array<MakersIdProjectsGetColorTypeIdsEnum>} [colorTypeIds] カラーIDの配列
         * @param {MakersIdProjectsGetFireProtectionMaterialEnum} [fireProtectionMaterial] 防火材料
         * @param {MakersIdProjectsGetFlamePreventionRegulationEnum} [flamePreventionRegulation] 防炎規制
         * @param {MakersIdProjectsGetFormaldehydeEnum} [formaldehyde] ホルムアルデヒド等級
         * @param {number} [materialId] 素材ID
         * @param {number} [minWidth] 最小幅
         * @param {number} [maxWidth] 最小幅
         * @param {number} [minHeight] 最小高さ
         * @param {number} [maxHeight] 最小高さ
         * @param {number} [minDepth] 最小奥行き
         * @param {number} [maxDepth] 最小奥行き
         * @param {number} [minPrice] 最低価格
         * @param {number} [maxPrice] 最高価格
         * @param {MakersIdProjectsGetFireResistanceEnum} [fireResistance] 耐火性能
         * @param {boolean} [inStock] 在庫あり
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [offset] ページ取得位置(default: 0)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makersIdProjectsGet: async (id: number, keyword?: string, kind?: MakersIdProjectsGetKindEnum, categoryId?: number, colorTypeIds?: Array<MakersIdProjectsGetColorTypeIdsEnum>, fireProtectionMaterial?: MakersIdProjectsGetFireProtectionMaterialEnum, flamePreventionRegulation?: MakersIdProjectsGetFlamePreventionRegulationEnum, formaldehyde?: MakersIdProjectsGetFormaldehydeEnum, materialId?: number, minWidth?: number, maxWidth?: number, minHeight?: number, maxHeight?: number, minDepth?: number, maxDepth?: number, minPrice?: number, maxPrice?: number, fireResistance?: MakersIdProjectsGetFireResistanceEnum, inStock?: boolean, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('makersIdProjectsGet', 'id', id)
            const localVarPath = `/makers/{id}/projects`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (kind !== undefined) {
                localVarQueryParameter['kind'] = kind;
            }

            if (categoryId !== undefined) {
                localVarQueryParameter['category_id'] = categoryId;
            }

            if (colorTypeIds) {
                localVarQueryParameter['color_type_ids[]'] = colorTypeIds;
            }

            if (fireProtectionMaterial !== undefined) {
                localVarQueryParameter['fire_protection_material'] = fireProtectionMaterial;
            }

            if (flamePreventionRegulation !== undefined) {
                localVarQueryParameter['flame_prevention_regulation'] = flamePreventionRegulation;
            }

            if (formaldehyde !== undefined) {
                localVarQueryParameter['formaldehyde'] = formaldehyde;
            }

            if (materialId !== undefined) {
                localVarQueryParameter['material_id'] = materialId;
            }

            if (minWidth !== undefined) {
                localVarQueryParameter['min_width'] = minWidth;
            }

            if (maxWidth !== undefined) {
                localVarQueryParameter['max_width'] = maxWidth;
            }

            if (minHeight !== undefined) {
                localVarQueryParameter['min_height'] = minHeight;
            }

            if (maxHeight !== undefined) {
                localVarQueryParameter['max_height'] = maxHeight;
            }

            if (minDepth !== undefined) {
                localVarQueryParameter['min_depth'] = minDepth;
            }

            if (maxDepth !== undefined) {
                localVarQueryParameter['max_depth'] = maxDepth;
            }

            if (minPrice !== undefined) {
                localVarQueryParameter['min_price'] = minPrice;
            }

            if (maxPrice !== undefined) {
                localVarQueryParameter['max_price'] = maxPrice;
            }

            if (fireResistance !== undefined) {
                localVarQueryParameter['fire_resistance'] = fireResistance;
            }

            if (inStock !== undefined) {
                localVarQueryParameter['in_stock'] = inStock;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary メーカーインクリメンタル検索
         * @param {string} [keyword] 
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [offset] offset(default: 0)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makersIncrementalSearchGet: async (keyword?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/makers/incremental_search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary メーカーの最新とランダムを取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makersLatestAndRandomGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/makers/latest_and_random`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary メーカー検索
         * @param {string} [keyword] 
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [offset] offset(default: 0)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makersSearchGet: async (keyword?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/makers/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  マテリアルの一覧を取得します
         * @summary マテリアルの一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/materials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  NEWSの一覧を取得します
         * @summary NEWS取得API
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {string} [cursor] 取得カーソル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsGet: async (limit?: number, cursor?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/news`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Notificationのリスト
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [cursor] 取得カーソル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsGet: async (limit?: number, cursor?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 特定の通知のreadフラグを更新する
         * @param {number} id Notification ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsIdReadPost: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('notificationsIdReadPost', 'id', id)
            const localVarPath = `/notifications/{id}/read`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Notificationのreadフラグをたてる
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsReadPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notifications/read`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary readしていないteamのrejectのnotificationをすべて取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsUnreadTeamRejectsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notifications/unread_team_rejects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  オーダーグループをキャンセルします。
         * @summary オーダーグループキャンセル
         * @param {string} code Order code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderGroupsCodeCancelPost: async (code: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('orderGroupsCodeCancelPost', 'code', code)
            const localVarPath = `/order_groups/{code}/cancel`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  オーダーグループを取得します。
         * @summary オーダーグループ取得
         * @param {string} code Order code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderGroupsCodeGet: async (code: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('orderGroupsCodeGet', 'code', code)
            const localVarPath = `/order_groups/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  オーダーグループ一覧を取得します。
         * @summary オーダーグループ一覧取得
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [cursor] 取得カーソル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderGroupsGet: async (limit?: number, cursor?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/order_groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  直近3件のリード情報のリスト取得します。
         * @summary 直近のリード情報のリスト取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderQuestionnaireResultsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/order_questionnaire_results`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  オーダーを作成します。
         * @summary オーダー作成
         * @param {OrderCreateParameter} orderCreateParameter オーダー作成パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersPost: async (orderCreateParameter: OrderCreateParameter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderCreateParameter' is not null or undefined
            assertParamExists('ordersPost', 'orderCreateParameter', orderCreateParameter)
            const localVarPath = `/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderCreateParameter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary PICKUPコンテンツを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pickupsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pickups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary PICKUPユーザーを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pickupsUsersGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pickups/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  自分がフォローしているユーザーが投稿した風景写真の一覧を取得します
         * @summary 自分がフォローしているユーザーの風景写真一覧
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [cursor] 取得カーソル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postsFollowingGet: async (limit?: number, cursor?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/posts/following`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  指定したIDの風景写真を取得します
         * @summary 風景写真を取得
         * @param {number} id Post ID
         * @param {string} [token] 非公開プロジェクトを閲覧するための共有トークン
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postsIdGet: async (id: number, token?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postsIdGet', 'id', id)
            const localVarPath = `/posts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  指定したIDの風景写真にひもづく、おすすめの風景写真を取得します
         * @summary 風景写真のおすすめを取得
         * @param {number} id Post ID
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [page] ページ(default: 1)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postsIdRecommendsGet: async (id: number, limit?: number, page?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postsIdRecommendsGet', 'id', id)
            const localVarPath = `/posts/{id}/recommends`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  IDを指定して風景写真の一覧を取得します
         * @summary ID指定風景写真一覧
         * @param {string} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postsSearchByIDsGet: async (ids: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('postsSearchByIDsGet', 'ids', ids)
            const localVarPath = `/posts/searchByIDs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ids !== undefined) {
                localVarQueryParameter['ids'] = ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  検索条件にマッチした風景写真の一覧を取得します 並び順はElasticsearchのマッチ率順になるので、cursor形式のページネーションは使えず、 limit, offset形式になります
         * @summary 風景写真の検索
         * @param {string} [keyword] 検索キーワード
         * @param {number} [buildingTypeId] building_typeのID
         * @param {number} [structureTypeId] 構造種別のID
         * @param {PostsSearchGetConstructionTypeEnum} [constructionType] 工事種別
         * @param {number} [minFloorSpace] 最小床面積
         * @param {number} [maxFloorSpace] 最大床面積
         * @param {number} [materialId] 素材ID
         * @param {Array<PostsSearchGetColorTypeIdsEnum>} [colorTypeIds] カラーIDの配列
         * @param {Array<PostsSearchGetPartIdsEnum>} [partIds] 使用箇所の配列
         * @param {number} [useTypeId] 用途のID
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [offset] ページ取得位置(default: 0)
         * @param {PostsSearchGetSortEnum} [sort] ソート条件
         * @param {number} [excludeProjectId] 除外するProjectID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postsSearchGet: async (keyword?: string, buildingTypeId?: number, structureTypeId?: number, constructionType?: PostsSearchGetConstructionTypeEnum, minFloorSpace?: number, maxFloorSpace?: number, materialId?: number, colorTypeIds?: Array<PostsSearchGetColorTypeIdsEnum>, partIds?: Array<PostsSearchGetPartIdsEnum>, useTypeId?: number, limit?: number, offset?: number, sort?: PostsSearchGetSortEnum, excludeProjectId?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/posts/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (buildingTypeId !== undefined) {
                localVarQueryParameter['building_type_id'] = buildingTypeId;
            }

            if (structureTypeId !== undefined) {
                localVarQueryParameter['structure_type_id'] = structureTypeId;
            }

            if (constructionType !== undefined) {
                localVarQueryParameter['construction_type'] = constructionType;
            }

            if (minFloorSpace !== undefined) {
                localVarQueryParameter['min_floor_space'] = minFloorSpace;
            }

            if (maxFloorSpace !== undefined) {
                localVarQueryParameter['max_floor_space'] = maxFloorSpace;
            }

            if (materialId !== undefined) {
                localVarQueryParameter['material_id'] = materialId;
            }

            if (colorTypeIds) {
                localVarQueryParameter['color_type_ids[]'] = colorTypeIds;
            }

            if (partIds) {
                localVarQueryParameter['part_ids[]'] = partIds;
            }

            if (useTypeId !== undefined) {
                localVarQueryParameter['use_type_id'] = useTypeId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (excludeProjectId !== undefined) {
                localVarQueryParameter['exclude_project_id'] = excludeProjectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 風景写真の検索のヒット数
         * @param {string} [keyword] 検索キーワード
         * @param {number} [buildingTypeId] building_typeのID
         * @param {number} [structureTypeId] 構造種別のID
         * @param {PostsSearchHitCountsGetConstructionTypeEnum} [constructionType] 工事種別
         * @param {number} [minFloorSpace] 最小床面積
         * @param {number} [maxFloorSpace] 最大床面積
         * @param {number} [materialId] 素材ID
         * @param {Array<PostsSearchHitCountsGetColorTypeIdsEnum>} [colorTypeIds] カラーIDの配列
         * @param {Array<PostsSearchHitCountsGetPartIdsEnum>} [partIds] 使用箇所の配列
         * @param {number} [useTypeId] 用途のID
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [offset] ページ取得位置(default: 0)
         * @param {PostsSearchHitCountsGetSortEnum} [sort] ソート条件
         * @param {number} [excludeProjectId] 除外するProjectID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postsSearchHitCountsGet: async (keyword?: string, buildingTypeId?: number, structureTypeId?: number, constructionType?: PostsSearchHitCountsGetConstructionTypeEnum, minFloorSpace?: number, maxFloorSpace?: number, materialId?: number, colorTypeIds?: Array<PostsSearchHitCountsGetColorTypeIdsEnum>, partIds?: Array<PostsSearchHitCountsGetPartIdsEnum>, useTypeId?: number, limit?: number, offset?: number, sort?: PostsSearchHitCountsGetSortEnum, excludeProjectId?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/posts/search_hit_counts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (buildingTypeId !== undefined) {
                localVarQueryParameter['building_type_id'] = buildingTypeId;
            }

            if (structureTypeId !== undefined) {
                localVarQueryParameter['structure_type_id'] = structureTypeId;
            }

            if (constructionType !== undefined) {
                localVarQueryParameter['construction_type'] = constructionType;
            }

            if (minFloorSpace !== undefined) {
                localVarQueryParameter['min_floor_space'] = minFloorSpace;
            }

            if (maxFloorSpace !== undefined) {
                localVarQueryParameter['max_floor_space'] = maxFloorSpace;
            }

            if (materialId !== undefined) {
                localVarQueryParameter['material_id'] = materialId;
            }

            if (colorTypeIds) {
                localVarQueryParameter['color_type_ids[]'] = colorTypeIds;
            }

            if (partIds) {
                localVarQueryParameter['part_ids[]'] = partIds;
            }

            if (useTypeId !== undefined) {
                localVarQueryParameter['use_type_id'] = useTypeId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (excludeProjectId !== undefined) {
                localVarQueryParameter['exclude_project_id'] = excludeProjectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary プロダクトバリエーションが紐づいている事例写真の一覧
         * @param {number} id ProductVariation ID
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [page] ページ(default: 1)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productVariationsIdPostsGet: async (id: number, limit?: number, page?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productVariationsIdPostsGet', 'id', id)
            const localVarPath = `/product_variations/{id}/posts`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 類似製品の検索
         * @param {number} id ProductVariation ID
         * @param {SimilarityAxis} [axis] 検索軸
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productVariationsIdSimilaritiesGet: async (id: number, axis?: SimilarityAxis, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productVariationsIdSimilaritiesGet', 'id', id)
            const localVarPath = `/product_variations/{id}/similarities`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (axis !== undefined) {
                localVarQueryParameter['axis'] = axis;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  前後一致で検索します
         * @summary 商品バリエーションのインクリメンタル検索
         * @param {number} [makerId] メーカーID
         * @param {string} [keyword] 検索キーワード
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [offset] ページ取得位置(default: 0)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productVariationsIncrementalSearchGet: async (makerId?: number, keyword?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/product_variations/incremental_search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (makerId !== undefined) {
                localVarQueryParameter['maker_id'] = makerId;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  対象のProductVariationIDを複数投げ、おすすめのProductVariationを返します
         * @summary 商品バリエーションのおすすめ
         * @param {Array<number>} productVariationIds ProductVariationIDの配列
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productVariationsRecommendationsGet: async (productVariationIds: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productVariationIds' is not null or undefined
            assertParamExists('productVariationsRecommendationsGet', 'productVariationIds', productVariationIds)
            const localVarPath = `/product_variations/recommendations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (productVariationIds) {
                localVarQueryParameter['product_variation_ids[]'] = productVariationIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  IDを指定してプロダクトバリエーションの一覧を取得します
         * @summary ID指定プロダクトバリエーション一覧
         * @param {string} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productVariationsSearchByIDsGet: async (ids: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('productVariationsSearchByIDsGet', 'ids', ids)
            const localVarPath = `/product_variations/searchByIDs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ids !== undefined) {
                localVarQueryParameter['ids'] = ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  検索条件にマッチした商品バリエーションの一覧を取得します 並び順はElasticsearchのマッチ率順になるので、cursor形式のページネーションは使えず、 limit, offset形式になります
         * @summary 商品バリエーションの検索
         * @param {string} [keyword] 検索キーワード
         * @param {ProductVariationsSearchGetKindEnum} [kind] 種類
         * @param {number} [categoryId] カテゴリID
         * @param {Array<ProductVariationsSearchGetColorTypeIdsEnum>} [colorTypeIds] カラーIDの配列
         * @param {ProductVariationsSearchGetFireProtectionMaterialEnum} [fireProtectionMaterial] 防火材料
         * @param {ProductVariationsSearchGetFlamePreventionRegulationEnum} [flamePreventionRegulation] 防炎規制
         * @param {ProductVariationsSearchGetFormaldehydeEnum} [formaldehyde] ホルムアルデヒド等級
         * @param {number} [materialId] 素材ID
         * @param {number} [minWidth] 最小幅
         * @param {number} [maxWidth] 最小幅
         * @param {number} [minHeight] 最小高さ
         * @param {number} [maxHeight] 最小高さ
         * @param {number} [minDepth] 最小奥行き
         * @param {number} [maxDepth] 最小奥行き
         * @param {number} [minPrice] 最低価格
         * @param {number} [maxPrice] 最高価格
         * @param {ProductVariationsSearchGetFireResistanceEnum} [fireResistance] 耐火性能
         * @param {boolean} [inStock] 在庫あり
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [offset] ページ取得位置(default: 0)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productVariationsSearchGet: async (keyword?: string, kind?: ProductVariationsSearchGetKindEnum, categoryId?: number, colorTypeIds?: Array<ProductVariationsSearchGetColorTypeIdsEnum>, fireProtectionMaterial?: ProductVariationsSearchGetFireProtectionMaterialEnum, flamePreventionRegulation?: ProductVariationsSearchGetFlamePreventionRegulationEnum, formaldehyde?: ProductVariationsSearchGetFormaldehydeEnum, materialId?: number, minWidth?: number, maxWidth?: number, minHeight?: number, maxHeight?: number, minDepth?: number, maxDepth?: number, minPrice?: number, maxPrice?: number, fireResistance?: ProductVariationsSearchGetFireResistanceEnum, inStock?: boolean, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/product_variations/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (kind !== undefined) {
                localVarQueryParameter['kind'] = kind;
            }

            if (categoryId !== undefined) {
                localVarQueryParameter['category_id'] = categoryId;
            }

            if (colorTypeIds) {
                localVarQueryParameter['color_type_ids[]'] = colorTypeIds;
            }

            if (fireProtectionMaterial !== undefined) {
                localVarQueryParameter['fire_protection_material'] = fireProtectionMaterial;
            }

            if (flamePreventionRegulation !== undefined) {
                localVarQueryParameter['flame_prevention_regulation'] = flamePreventionRegulation;
            }

            if (formaldehyde !== undefined) {
                localVarQueryParameter['formaldehyde'] = formaldehyde;
            }

            if (materialId !== undefined) {
                localVarQueryParameter['material_id'] = materialId;
            }

            if (minWidth !== undefined) {
                localVarQueryParameter['min_width'] = minWidth;
            }

            if (maxWidth !== undefined) {
                localVarQueryParameter['max_width'] = maxWidth;
            }

            if (minHeight !== undefined) {
                localVarQueryParameter['min_height'] = minHeight;
            }

            if (maxHeight !== undefined) {
                localVarQueryParameter['max_height'] = maxHeight;
            }

            if (minDepth !== undefined) {
                localVarQueryParameter['min_depth'] = minDepth;
            }

            if (maxDepth !== undefined) {
                localVarQueryParameter['max_depth'] = maxDepth;
            }

            if (minPrice !== undefined) {
                localVarQueryParameter['min_price'] = minPrice;
            }

            if (maxPrice !== undefined) {
                localVarQueryParameter['max_price'] = maxPrice;
            }

            if (fireResistance !== undefined) {
                localVarQueryParameter['fire_resistance'] = fireResistance;
            }

            if (inStock !== undefined) {
                localVarQueryParameter['in_stock'] = inStock;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ProductVariationの検索のヒット数
         * @param {string} [keyword] 検索キーワード
         * @param {ProductVariationsSearchHitCountsGetKindEnum} [kind] 種類
         * @param {number} [categoryId] カテゴリID
         * @param {Array<ProductVariationsSearchHitCountsGetColorTypeIdsEnum>} [colorTypeIds] カラーIDの配列
         * @param {ProductVariationsSearchHitCountsGetFireProtectionMaterialEnum} [fireProtectionMaterial] 防火材料
         * @param {ProductVariationsSearchHitCountsGetFlamePreventionRegulationEnum} [flamePreventionRegulation] 防炎規制
         * @param {ProductVariationsSearchHitCountsGetFormaldehydeEnum} [formaldehyde] ホルムアルデヒド等級
         * @param {number} [materialId] 素材ID
         * @param {number} [minWidth] 最小幅
         * @param {number} [maxWidth] 最小幅
         * @param {number} [minHeight] 最小高さ
         * @param {number} [maxHeight] 最小高さ
         * @param {number} [minDepth] 最小奥行き
         * @param {number} [maxDepth] 最小奥行き
         * @param {number} [minPrice] 最低価格
         * @param {number} [maxPrice] 最高価格
         * @param {ProductVariationsSearchHitCountsGetFireResistanceEnum} [fireResistance] 耐火性能
         * @param {boolean} [inStock] 在庫あり
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [offset] ページ取得位置(default: 0)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productVariationsSearchHitCountsGet: async (keyword?: string, kind?: ProductVariationsSearchHitCountsGetKindEnum, categoryId?: number, colorTypeIds?: Array<ProductVariationsSearchHitCountsGetColorTypeIdsEnum>, fireProtectionMaterial?: ProductVariationsSearchHitCountsGetFireProtectionMaterialEnum, flamePreventionRegulation?: ProductVariationsSearchHitCountsGetFlamePreventionRegulationEnum, formaldehyde?: ProductVariationsSearchHitCountsGetFormaldehydeEnum, materialId?: number, minWidth?: number, maxWidth?: number, minHeight?: number, maxHeight?: number, minDepth?: number, maxDepth?: number, minPrice?: number, maxPrice?: number, fireResistance?: ProductVariationsSearchHitCountsGetFireResistanceEnum, inStock?: boolean, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/product_variations/search_hit_counts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (kind !== undefined) {
                localVarQueryParameter['kind'] = kind;
            }

            if (categoryId !== undefined) {
                localVarQueryParameter['category_id'] = categoryId;
            }

            if (colorTypeIds) {
                localVarQueryParameter['color_type_ids[]'] = colorTypeIds;
            }

            if (fireProtectionMaterial !== undefined) {
                localVarQueryParameter['fire_protection_material'] = fireProtectionMaterial;
            }

            if (flamePreventionRegulation !== undefined) {
                localVarQueryParameter['flame_prevention_regulation'] = flamePreventionRegulation;
            }

            if (formaldehyde !== undefined) {
                localVarQueryParameter['formaldehyde'] = formaldehyde;
            }

            if (materialId !== undefined) {
                localVarQueryParameter['material_id'] = materialId;
            }

            if (minWidth !== undefined) {
                localVarQueryParameter['min_width'] = minWidth;
            }

            if (maxWidth !== undefined) {
                localVarQueryParameter['max_width'] = maxWidth;
            }

            if (minHeight !== undefined) {
                localVarQueryParameter['min_height'] = minHeight;
            }

            if (maxHeight !== undefined) {
                localVarQueryParameter['max_height'] = maxHeight;
            }

            if (minDepth !== undefined) {
                localVarQueryParameter['min_depth'] = minDepth;
            }

            if (maxDepth !== undefined) {
                localVarQueryParameter['max_depth'] = maxDepth;
            }

            if (minPrice !== undefined) {
                localVarQueryParameter['min_price'] = minPrice;
            }

            if (maxPrice !== undefined) {
                localVarQueryParameter['max_price'] = maxPrice;
            }

            if (fireResistance !== undefined) {
                localVarQueryParameter['fire_resistance'] = fireResistance;
            }

            if (inStock !== undefined) {
                localVarQueryParameter['in_stock'] = inStock;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  ピンに対しての検索を行っているため、ヒットしたピンのみ返却される
         * @summary 商品バリエーションを元に事例写真の検索
         * @param {string} [keyword] 検索キーワード
         * @param {ProductVariationsSearchPostsGetKindEnum} [kind] 種類
         * @param {number} [categoryId] カテゴリID
         * @param {Array<ProductVariationsSearchPostsGetColorTypeIdsEnum>} [colorTypeIds] カラーIDの配列
         * @param {ProductVariationsSearchPostsGetFireProtectionMaterialEnum} [fireProtectionMaterial] 防火材料
         * @param {ProductVariationsSearchPostsGetFlamePreventionRegulationEnum} [flamePreventionRegulation] 防炎規制
         * @param {ProductVariationsSearchPostsGetFormaldehydeEnum} [formaldehyde] ホルムアルデヒド等級
         * @param {number} [materialId] 素材ID
         * @param {number} [minWidth] 最小幅
         * @param {number} [maxWidth] 最小幅
         * @param {number} [minHeight] 最小高さ
         * @param {number} [maxHeight] 最小高さ
         * @param {number} [minDepth] 最小奥行き
         * @param {number} [maxDepth] 最小奥行き
         * @param {number} [minPrice] 最低価格
         * @param {number} [maxPrice] 最高価格
         * @param {ProductVariationsSearchPostsGetFireResistanceEnum} [fireResistance] 耐火性能
         * @param {boolean} [inStock] 在庫あり
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [offset] ページ取得位置(default: 0)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productVariationsSearchPostsGet: async (keyword?: string, kind?: ProductVariationsSearchPostsGetKindEnum, categoryId?: number, colorTypeIds?: Array<ProductVariationsSearchPostsGetColorTypeIdsEnum>, fireProtectionMaterial?: ProductVariationsSearchPostsGetFireProtectionMaterialEnum, flamePreventionRegulation?: ProductVariationsSearchPostsGetFlamePreventionRegulationEnum, formaldehyde?: ProductVariationsSearchPostsGetFormaldehydeEnum, materialId?: number, minWidth?: number, maxWidth?: number, minHeight?: number, maxHeight?: number, minDepth?: number, maxDepth?: number, minPrice?: number, maxPrice?: number, fireResistance?: ProductVariationsSearchPostsGetFireResistanceEnum, inStock?: boolean, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/product_variations/search/posts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (kind !== undefined) {
                localVarQueryParameter['kind'] = kind;
            }

            if (categoryId !== undefined) {
                localVarQueryParameter['category_id'] = categoryId;
            }

            if (colorTypeIds) {
                localVarQueryParameter['color_type_ids[]'] = colorTypeIds;
            }

            if (fireProtectionMaterial !== undefined) {
                localVarQueryParameter['fire_protection_material'] = fireProtectionMaterial;
            }

            if (flamePreventionRegulation !== undefined) {
                localVarQueryParameter['flame_prevention_regulation'] = flamePreventionRegulation;
            }

            if (formaldehyde !== undefined) {
                localVarQueryParameter['formaldehyde'] = formaldehyde;
            }

            if (materialId !== undefined) {
                localVarQueryParameter['material_id'] = materialId;
            }

            if (minWidth !== undefined) {
                localVarQueryParameter['min_width'] = minWidth;
            }

            if (maxWidth !== undefined) {
                localVarQueryParameter['max_width'] = maxWidth;
            }

            if (minHeight !== undefined) {
                localVarQueryParameter['min_height'] = minHeight;
            }

            if (maxHeight !== undefined) {
                localVarQueryParameter['max_height'] = maxHeight;
            }

            if (minDepth !== undefined) {
                localVarQueryParameter['min_depth'] = minDepth;
            }

            if (maxDepth !== undefined) {
                localVarQueryParameter['max_depth'] = maxDepth;
            }

            if (minPrice !== undefined) {
                localVarQueryParameter['min_price'] = minPrice;
            }

            if (maxPrice !== undefined) {
                localVarQueryParameter['max_price'] = maxPrice;
            }

            if (fireResistance !== undefined) {
                localVarQueryParameter['fire_resistance'] = fireResistance;
            }

            if (inStock !== undefined) {
                localVarQueryParameter['in_stock'] = inStock;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  指定したIDの製品を取得します
         * @summary 製品を取得
         * @param {number} id Product ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsIdGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productsIdGet', 'id', id)
            const localVarPath = `/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 製品の風景写真一覧
         * @param {number} id Product ID
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [cursor] 取得カーソル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsIdPostsGet: async (id: number, limit?: number, cursor?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productsIdPostsGet', 'id', id)
            const localVarPath = `/products/{id}/posts`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  指定したIDのプロダクトが持つサンプル商品のリストを取得します
         * @summary サンプル商品を取得
         * @param {number} id Product ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsIdSampleItemsGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productsIdSampleItemsGet', 'id', id)
            const localVarPath = `/products/{id}/sample_items`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 自分がフォローしているユーザーのプロジェクト一覧
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [cursor] 取得カーソル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsFollowingGet: async (limit?: number, cursor?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects/following`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary プロジェクトの添付ファイルを削除する
         * @param {number} id Project ID
         * @param {number} attachmentId attachment ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsIdAttachmentsAttachmentIdDelete: async (id: number, attachmentId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectsIdAttachmentsAttachmentIdDelete', 'id', id)
            // verify required parameter 'attachmentId' is not null or undefined
            assertParamExists('projectsIdAttachmentsAttachmentIdDelete', 'attachmentId', attachmentId)
            const localVarPath = `/projects/{id}/attachments/{attachment_id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"attachment_id"}}`, encodeURIComponent(String(attachmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 追加資料を並べ替える
         * @param {number} id Project ID
         * @param {number} attachmentId attachment ID
         * @param {OrderParameter} orderParameter 並び替えパラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsIdAttachmentsAttachmentIdOrderPatch: async (id: number, attachmentId: number, orderParameter: OrderParameter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectsIdAttachmentsAttachmentIdOrderPatch', 'id', id)
            // verify required parameter 'attachmentId' is not null or undefined
            assertParamExists('projectsIdAttachmentsAttachmentIdOrderPatch', 'attachmentId', attachmentId)
            // verify required parameter 'orderParameter' is not null or undefined
            assertParamExists('projectsIdAttachmentsAttachmentIdOrderPatch', 'orderParameter', orderParameter)
            const localVarPath = `/projects/{id}/attachments/{attachment_id}/order`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"attachment_id"}}`, encodeURIComponent(String(attachmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderParameter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary プロジェクトの添付ファイルを更新する
         * @param {number} id Project ID
         * @param {number} attachmentId attachment ID
         * @param {string} title attachment title
         * @param {ProjectAttachmentType} [attachmentType] 
         * @param {File} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsIdAttachmentsAttachmentIdPut: async (id: number, attachmentId: number, title: string, attachmentType?: ProjectAttachmentType, image?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectsIdAttachmentsAttachmentIdPut', 'id', id)
            // verify required parameter 'attachmentId' is not null or undefined
            assertParamExists('projectsIdAttachmentsAttachmentIdPut', 'attachmentId', attachmentId)
            // verify required parameter 'title' is not null or undefined
            assertParamExists('projectsIdAttachmentsAttachmentIdPut', 'title', title)
            const localVarPath = `/projects/{id}/attachments/{attachment_id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"attachment_id"}}`, encodeURIComponent(String(attachmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (title !== undefined) { 
                localVarFormParams.append('title', title as any);
            }
    
            if (attachmentType !== undefined) { 
                localVarFormParams.append('attachment_type', attachmentType as any);
            }
    
            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary プロジェクトに添付ファイルを追加する
         * @param {number} id Project ID
         * @param {string} title attachment title
         * @param {File} image 
         * @param {ProjectAttachmentType} [attachmentType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsIdAttachmentsPost: async (id: number, title: string, image: File, attachmentType?: ProjectAttachmentType, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectsIdAttachmentsPost', 'id', id)
            // verify required parameter 'title' is not null or undefined
            assertParamExists('projectsIdAttachmentsPost', 'title', title)
            // verify required parameter 'image' is not null or undefined
            assertParamExists('projectsIdAttachmentsPost', 'image', image)
            const localVarPath = `/projects/{id}/attachments`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (title !== undefined) { 
                localVarFormParams.append('title', title as any);
            }
    
            if (attachmentType !== undefined) { 
                localVarFormParams.append('attachment_type', attachmentType as any);
            }
    
            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  指定したIDのプロジェクトを削除します
         * @summary プロジェクトを削除
         * @param {number} id Project ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsIdDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectsIdDelete', 'id', id)
            const localVarPath = `/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  指定したIDのプロジェクトを取得します
         * @summary プロジェクトを取得
         * @param {number} id Project ID
         * @param {string} [token] 非公開プロジェクトを閲覧するための共有トークン
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsIdGet: async (id: number, token?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectsIdGet', 'id', id)
            const localVarPath = `/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  プロジェクトに紐づくピンをすべて返します。重複しているアイテムはまとめます。
         * @summary プロジェクトに紐づくピンをすべて返す
         * @param {number} id Project ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsIdPinsGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectsIdPinsGet', 'id', id)
            const localVarPath = `/projects/{id}/pins`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  プロジェクトに紐づく風景写真の一覧を取得します
         * @summary プロジェクトに紐づく風景写真一覧
         * @param {number} id Project ID
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [cursor] 取得カーソル
         * @param {string} [token] 非公開プロジェクトを閲覧するための共有トークン
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsIdPostsGet: async (id: number, limit?: number, cursor?: number, token?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectsIdPostsGet', 'id', id)
            const localVarPath = `/projects/{id}/posts`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  プロジェクトに風景写真を追加します
         * @summary プロジェクトに風景写真を追加する
         * @param {number} id Project ID
         * @param {PostCreateParam} postCreateParam 事例写真投稿パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsIdPostsPost: async (id: number, postCreateParam: PostCreateParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectsIdPostsPost', 'id', id)
            // verify required parameter 'postCreateParam' is not null or undefined
            assertParamExists('projectsIdPostsPost', 'postCreateParam', postCreateParam)
            const localVarPath = `/projects/{id}/posts`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postCreateParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  指定したIDのプロジェクトを公開します
         * @summary 指定したIDのプロジェクトを公開する
         * @param {number} id Project ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsIdPublishPost: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectsIdPublishPost', 'id', id)
            const localVarPath = `/projects/{id}/publish`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  指定したIDのプロジェクトを更新します  ## validation  - title: 必須, 255文字以内 - description: 必須, 文字列 - building_type_id: 必須, 数値 - design: 任意, 255文字以内 - staff: 任意, 255文字以内 - structural_design: 任意, 255文字以内 - construction: 任意, 255文字以内 - structure_type: 任意, 数値 - completion: 任意, 年月 YYYY-mm - floor_space: 任意, 数値 - construction_type: 任意, 数値 - photograph: 任意, 255文字以内 - location: 任意 - location.lat: 必須, 数値 - location.lng: 必須, 数値
         * @summary プロジェクトを更新
         * @param {number} id Project ID
         * @param {ProjectUpdateParam} projectUpdateParam プロジェクト更新パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsIdPut: async (id: number, projectUpdateParam: ProjectUpdateParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectsIdPut', 'id', id)
            // verify required parameter 'projectUpdateParam' is not null or undefined
            assertParamExists('projectsIdPut', 'projectUpdateParam', projectUpdateParam)
            const localVarPath = `/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectUpdateParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  非公開プロジェクトの共有用URLに必要なトークンを生成します
         * @summary プロジェクトの共有用トークンを生成する
         * @param {number} id プロジェクトID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsIdSharePost: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectsIdSharePost', 'id', id)
            const localVarPath = `/projects/{id}/share`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  指定したIDのプロジェクトを非公開にします
         * @summary 指定したIDのプロジェクトを非公開にする
         * @param {number} id Project ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsIdUnpublishPost: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectsIdUnpublishPost', 'id', id)
            const localVarPath = `/projects/{id}/unpublish`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  プロジェクトを投稿します
         * @summary プロジェクト投稿
         * @param {ProjectCreateParam} projectCreateParam プロジェクト投稿パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsPost: async (projectCreateParam: ProjectCreateParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectCreateParam' is not null or undefined
            assertParamExists('projectsPost', 'projectCreateParam', projectCreateParam)
            const localVarPath = `/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectCreateParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  プロジェクトの風景写真を削除します
         * @summary プロジェクトの風景写真を削除
         * @param {number} projectId Project ID
         * @param {number} id Post ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsProjectIdPostsIdDelete: async (projectId: number, id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectsProjectIdPostsIdDelete', 'projectId', projectId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectsProjectIdPostsIdDelete', 'id', id)
            const localVarPath = `/projects/{project_id}/posts/{id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  プロジェクトの風景写真のposition値を変更します  ## logic - positionは前後のPost.positionの中間値を投げる
         * @summary プロジェクトの風景写真を並べ替える
         * @param {number} projectId Project ID
         * @param {number} id Post ID
         * @param {OrderParameter} orderParameter 並び替えパラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsProjectIdPostsIdOrderPatch: async (projectId: number, id: number, orderParameter: OrderParameter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectsProjectIdPostsIdOrderPatch', 'projectId', projectId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectsProjectIdPostsIdOrderPatch', 'id', id)
            // verify required parameter 'orderParameter' is not null or undefined
            assertParamExists('projectsProjectIdPostsIdOrderPatch', 'orderParameter', orderParameter)
            const localVarPath = `/projects/{project_id}/posts/{id}/order`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderParameter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  プロジェクトの風景写真を編集します  ## logic - positionは前後のPost.positionの中間値を投げる
         * @summary プロジェクトの風景写真を編集する
         * @param {number} projectId Project ID
         * @param {number} id Post ID
         * @param {PostUpdateParam} postUpdateParam 事例写真更新パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsProjectIdPostsIdPut: async (projectId: number, id: number, postUpdateParam: PostUpdateParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectsProjectIdPostsIdPut', 'projectId', projectId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectsProjectIdPostsIdPut', 'id', id)
            // verify required parameter 'postUpdateParam' is not null or undefined
            assertParamExists('projectsProjectIdPostsIdPut', 'postUpdateParam', postUpdateParam)
            const localVarPath = `/projects/{project_id}/posts/{id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postUpdateParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ID指定プロジェクト一覧
         * @param {string} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsSearchByIDsGet: async (ids: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('projectsSearchByIDsGet', 'ids', ids)
            const localVarPath = `/projects/searchByIDs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ids !== undefined) {
                localVarQueryParameter['ids'] = ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  検索条件にマッチしたプロジェクトの一覧を取得します 並び順はElasticsearchのマッチ率順になるので、cursor形式のページネーションは使えず、 limit, offset形式になります
         * @summary プロジェクトの検索
         * @param {string} [keyword] 検索キーワード
         * @param {number} [buildingTypeId] building_typeのID
         * @param {number} [structureTypeId] 構造種別のID
         * @param {ProjectsSearchGetConstructionTypeEnum} [constructionType] 工事種別
         * @param {number} [minFloorSpace] 最小床面積
         * @param {number} [maxFloorSpace] 最大床面積
         * @param {number} [materialId] 素材ID
         * @param {Array<ProjectsSearchGetColorTypeIdsEnum>} [colorTypeIds] カラーIDの配列
         * @param {Array<ProjectsSearchGetPartIdsEnum>} [partIds] 使用箇所の配列
         * @param {number} [useTypeId] 用途のID
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [offset] ページ取得位置(default: 0)
         * @param {ProjectsSearchGetSortEnum} [sort] ソート条件
         * @param {number} [excludeProjectId] 除外するProjectID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsSearchGet: async (keyword?: string, buildingTypeId?: number, structureTypeId?: number, constructionType?: ProjectsSearchGetConstructionTypeEnum, minFloorSpace?: number, maxFloorSpace?: number, materialId?: number, colorTypeIds?: Array<ProjectsSearchGetColorTypeIdsEnum>, partIds?: Array<ProjectsSearchGetPartIdsEnum>, useTypeId?: number, limit?: number, offset?: number, sort?: ProjectsSearchGetSortEnum, excludeProjectId?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (buildingTypeId !== undefined) {
                localVarQueryParameter['building_type_id'] = buildingTypeId;
            }

            if (structureTypeId !== undefined) {
                localVarQueryParameter['structure_type_id'] = structureTypeId;
            }

            if (constructionType !== undefined) {
                localVarQueryParameter['construction_type'] = constructionType;
            }

            if (minFloorSpace !== undefined) {
                localVarQueryParameter['min_floor_space'] = minFloorSpace;
            }

            if (maxFloorSpace !== undefined) {
                localVarQueryParameter['max_floor_space'] = maxFloorSpace;
            }

            if (materialId !== undefined) {
                localVarQueryParameter['material_id'] = materialId;
            }

            if (colorTypeIds) {
                localVarQueryParameter['color_type_ids[]'] = colorTypeIds;
            }

            if (partIds) {
                localVarQueryParameter['part_ids[]'] = partIds;
            }

            if (useTypeId !== undefined) {
                localVarQueryParameter['use_type_id'] = useTypeId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (excludeProjectId !== undefined) {
                localVarQueryParameter['exclude_project_id'] = excludeProjectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary プロジェクトの検索のヒット数
         * @param {string} [keyword] 検索キーワード
         * @param {number} [buildingTypeId] building_typeのID
         * @param {number} [structureTypeId] 構造種別のID
         * @param {ProjectsSearchHitCountsGetConstructionTypeEnum} [constructionType] 工事種別
         * @param {number} [minFloorSpace] 最小床面積
         * @param {number} [maxFloorSpace] 最大床面積
         * @param {number} [materialId] 素材ID
         * @param {Array<ProjectsSearchHitCountsGetColorTypeIdsEnum>} [colorTypeIds] カラーIDの配列
         * @param {Array<ProjectsSearchHitCountsGetPartIdsEnum>} [partIds] 使用箇所の配列
         * @param {number} [useTypeId] 用途のID
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [offset] ページ取得位置(default: 0)
         * @param {ProjectsSearchHitCountsGetSortEnum} [sort] ソート条件
         * @param {number} [excludeProjectId] 除外するProjectID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsSearchHitCountsGet: async (keyword?: string, buildingTypeId?: number, structureTypeId?: number, constructionType?: ProjectsSearchHitCountsGetConstructionTypeEnum, minFloorSpace?: number, maxFloorSpace?: number, materialId?: number, colorTypeIds?: Array<ProjectsSearchHitCountsGetColorTypeIdsEnum>, partIds?: Array<ProjectsSearchHitCountsGetPartIdsEnum>, useTypeId?: number, limit?: number, offset?: number, sort?: ProjectsSearchHitCountsGetSortEnum, excludeProjectId?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects/search_hit_counts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (buildingTypeId !== undefined) {
                localVarQueryParameter['building_type_id'] = buildingTypeId;
            }

            if (structureTypeId !== undefined) {
                localVarQueryParameter['structure_type_id'] = structureTypeId;
            }

            if (constructionType !== undefined) {
                localVarQueryParameter['construction_type'] = constructionType;
            }

            if (minFloorSpace !== undefined) {
                localVarQueryParameter['min_floor_space'] = minFloorSpace;
            }

            if (maxFloorSpace !== undefined) {
                localVarQueryParameter['max_floor_space'] = maxFloorSpace;
            }

            if (materialId !== undefined) {
                localVarQueryParameter['material_id'] = materialId;
            }

            if (colorTypeIds) {
                localVarQueryParameter['color_type_ids[]'] = colorTypeIds;
            }

            if (partIds) {
                localVarQueryParameter['part_ids[]'] = partIds;
            }

            if (useTypeId !== undefined) {
                localVarQueryParameter['use_type_id'] = useTypeId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (excludeProjectId !== undefined) {
                localVarQueryParameter['exclude_project_id'] = excludeProjectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 資格のマスターデータを取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        qualificationsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/qualifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary キーワード検索を行う
         * @param {string} keyword 検索キーワード
         * @param {number} [limit] 取得件数(default: 8, max: 8)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGet: async (keyword: string, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'keyword' is not null or undefined
            assertParamExists('searchGet', 'keyword', keyword)
            const localVarPath = `/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary アカウント情報を変更
         * @param {SettingsAccountPostRequest} settingsAccountPostRequest アカウント設定パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingsAccountPost: async (settingsAccountPostRequest: SettingsAccountPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'settingsAccountPostRequest' is not null or undefined
            assertParamExists('settingsAccountPost', 'settingsAccountPostRequest', settingsAccountPostRequest)
            const localVarPath = `/settings/account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(settingsAccountPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary メルマガ設定を変更
         * @param {MailMagazineUpdateParam} mailMagazineUpdateParam メルマガ設定パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingsMailMagazinePost: async (mailMagazineUpdateParam: MailMagazineUpdateParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailMagazineUpdateParam' is not null or undefined
            assertParamExists('settingsMailMagazinePost', 'mailMagazineUpdateParam', mailMagazineUpdateParam)
            const localVarPath = `/settings/mail_magazine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mailMagazineUpdateParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 通知のON/OFFを設定
         * @param {NotificationSettingsUpdateParameter} notificationSettingsUpdateParameter 通知設定パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingsNotificationPost: async (notificationSettingsUpdateParameter: NotificationSettingsUpdateParameter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationSettingsUpdateParameter' is not null or undefined
            assertParamExists('settingsNotificationPost', 'notificationSettingsUpdateParameter', notificationSettingsUpdateParameter)
            const localVarPath = `/settings/notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationSettingsUpdateParameter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary プロフィール情報を変更
         * @param {UserProfileUpdateParam} userProfileUpdateParam プロフィール設定パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingsProfilePost: async (userProfileUpdateParam: UserProfileUpdateParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userProfileUpdateParam' is not null or undefined
            assertParamExists('settingsProfilePost', 'userProfileUpdateParam', userProfileUpdateParam)
            const localVarPath = `/settings/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userProfileUpdateParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  現在時刻から次の配送予定日を取得します。
         * @summary 次の配送予定日を取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shipmentsNextDeliveryDateGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/shipments/next_delivery_date`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  SuggestKeywordの一覧を取得します
         * @summary SuggestKeyword取得API
         * @param {string} [keyword] 検索キーワード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestKeywordsGet: async (keyword?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/suggest_keywords`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  指定したIDのチームへの参加申請を許可します。
         * @summary 参加申請を許可
         * @param {number} id Team ID
         * @param {OneTimeTokenParameter} oneTimeTokenParameter tokenパラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teamsIdApproveJoinPost: async (id: number, oneTimeTokenParameter: OneTimeTokenParameter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('teamsIdApproveJoinPost', 'id', id)
            // verify required parameter 'oneTimeTokenParameter' is not null or undefined
            assertParamExists('teamsIdApproveJoinPost', 'oneTimeTokenParameter', oneTimeTokenParameter)
            const localVarPath = `/teams/{id}/approve_join`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(oneTimeTokenParameter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  チームを削除します。
         * @summary チーム削除
         * @param {number} id Team ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teamsIdDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('teamsIdDelete', 'id', id)
            const localVarPath = `/teams/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  チームを取得します。
         * @summary チーム詳細取得
         * @param {number} id Team ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teamsIdGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('teamsIdGet', 'id', id)
            const localVarPath = `/teams/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  チームメンバーを招待します。
         * @summary チームメンバー招待
         * @param {number} id Team ID
         * @param {TeamMemberInviteParameter} teamMemberInviteParameter チームメンバー招待パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teamsIdInvitePost: async (id: number, teamMemberInviteParameter: TeamMemberInviteParameter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('teamsIdInvitePost', 'id', id)
            // verify required parameter 'teamMemberInviteParameter' is not null or undefined
            assertParamExists('teamsIdInvitePost', 'teamMemberInviteParameter', teamMemberInviteParameter)
            const localVarPath = `/teams/{id}/invite`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(teamMemberInviteParameter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  チームに参加します
         * @summary チームに参加
         * @param {number} id Team ID
         * @param {OneTimeTokenParameter} oneTimeTokenParameter tokenパラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teamsIdJoinPost: async (id: number, oneTimeTokenParameter: OneTimeTokenParameter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('teamsIdJoinPost', 'id', id)
            // verify required parameter 'oneTimeTokenParameter' is not null or undefined
            assertParamExists('teamsIdJoinPost', 'oneTimeTokenParameter', oneTimeTokenParameter)
            const localVarPath = `/teams/{id}/join`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(oneTimeTokenParameter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  チームメンバーを一括で取得します。
         * @summary チームメンバー取得
         * @param {number} id Team ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teamsIdMembersGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('teamsIdMembersGet', 'id', id)
            const localVarPath = `/teams/{id}/members`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  チームのプロジェクトを取得します。
         * @summary チームのプロジェクト取得
         * @param {number} id Team ID
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [page] ページ(default: 1)
         * @param {number} [excludeProjectId] 除外するProjectID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teamsIdProjectsGet: async (id: number, limit?: number, page?: number, excludeProjectId?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('teamsIdProjectsGet', 'id', id)
            const localVarPath = `/teams/{id}/projects`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (excludeProjectId !== undefined) {
                localVarQueryParameter['exclude_project_id'] = excludeProjectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  チームを更新します。
         * @summary チーム更新
         * @param {number} id Team ID
         * @param {TeamUpdateParam} teamUpdateParam チーム更新パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teamsIdPut: async (id: number, teamUpdateParam: TeamUpdateParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('teamsIdPut', 'id', id)
            // verify required parameter 'teamUpdateParam' is not null or undefined
            assertParamExists('teamsIdPut', 'teamUpdateParam', teamUpdateParam)
            const localVarPath = `/teams/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(teamUpdateParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  指定したIDのチームへの参加をリクエストします。
         * @summary チームへの参加をリクエスト
         * @param {number} id Team ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teamsIdRequestJoinPost: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('teamsIdRequestJoinPost', 'id', id)
            const localVarPath = `/teams/{id}/request_join`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  チームメンバー招待のTokenを確認します。
         * @summary チームメンバー招待のToken確認
         * @param {number} id Team ID
         * @param {OneTimeTokenParameter} oneTimeTokenParameter tokenパラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teamsIdVerifyInvitationPost: async (id: number, oneTimeTokenParameter: OneTimeTokenParameter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('teamsIdVerifyInvitationPost', 'id', id)
            // verify required parameter 'oneTimeTokenParameter' is not null or undefined
            assertParamExists('teamsIdVerifyInvitationPost', 'oneTimeTokenParameter', oneTimeTokenParameter)
            const localVarPath = `/teams/{id}/verify_invitation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(oneTimeTokenParameter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  チームから離脱します
         * @summary チームから離脱
         * @param {number} id Team ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teamsIdWithdrawalPost: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('teamsIdWithdrawalPost', 'id', id)
            const localVarPath = `/teams/{id}/withdrawal`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  チームを新規登録します。
         * @summary チーム新規登録
         * @param {TeamCreateParam} teamCreateParam チーム登録パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teamsPost: async (teamCreateParam: TeamCreateParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamCreateParam' is not null or undefined
            assertParamExists('teamsPost', 'teamCreateParam', teamCreateParam)
            const localVarPath = `/teams`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(teamCreateParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ID指定チーム一覧
         * @param {string} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teamsSearchByIDsGet: async (ids: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('teamsSearchByIDsGet', 'ids', ids)
            const localVarPath = `/teams/searchByIDs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ids !== undefined) {
                localVarQueryParameter['ids'] = ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary チームの検索
         * @param {string} keyword 検索キーワード
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [offset] 取得開始位置(default: 0)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teamsSearchGet: async (keyword: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'keyword' is not null or undefined
            assertParamExists('teamsSearchGet', 'keyword', keyword)
            const localVarPath = `/teams/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  チームメンバーを削除します。
         * @summary チームメンバー削除
         * @param {number} teamId Team ID
         * @param {number} id ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teamsTeamIdMembersIdDelete: async (teamId: number, id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('teamsTeamIdMembersIdDelete', 'teamId', teamId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('teamsTeamIdMembersIdDelete', 'id', id)
            const localVarPath = `/teams/{team_id}/members/{id}`
                .replace(`{${"team_id"}}`, encodeURIComponent(String(teamId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  チームメンバーを更新します。
         * @summary チームメンバー更新
         * @param {number} teamId Team ID
         * @param {number} id ID
         * @param {TeamMemberPutParameter} teamMemberPutParameter チームメンバー更新パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teamsTeamIdMembersIdPut: async (teamId: number, id: number, teamMemberPutParameter: TeamMemberPutParameter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('teamsTeamIdMembersIdPut', 'teamId', teamId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('teamsTeamIdMembersIdPut', 'id', id)
            // verify required parameter 'teamMemberPutParameter' is not null or undefined
            assertParamExists('teamsTeamIdMembersIdPut', 'teamMemberPutParameter', teamMemberPutParameter)
            const localVarPath = `/teams/{team_id}/members/{id}`
                .replace(`{${"team_id"}}`, encodeURIComponent(String(teamId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(teamMemberPutParameter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary TOPページの設定を取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        topSettingsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/top/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  すべての用途の一覧を取得します
         * @summary すべての用途一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        useTypesGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/use_types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  ユーザー属性の一覧を取得します
         * @summary ユーザー属性取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAttributesGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user_attributes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  指定したIDのユーザーをフォローしているユーザーの一覧を取得します
         * @summary 指定したIDのユーザーをフォローしているユーザーの一覧
         * @param {number} id User ID
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [cursor] 取得カーソル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdFollowersGet: async (id: number, limit?: number, cursor?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersIdFollowersGet', 'id', id)
            const localVarPath = `/users/{id}/followers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  指定したIDのユーザーがフォローしているユーザーの一覧を取得します
         * @summary 指定したIDのユーザーがフォローしているユーザーの一覧
         * @param {number} id User ID
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [cursor] 取得カーソル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdFollowingGet: async (id: number, limit?: number, cursor?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersIdFollowingGet', 'id', id)
            const localVarPath = `/users/{id}/following`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  指定したIDのユーザーをフォロー数も含めて取得します
         * @summary 指定したIDのユーザーを取得
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersIdGet', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  指定したIDのユーザーがLikeしたプロジェクトの一覧を取得します
         * @summary 指定したIDのユーザーがLikeしたプロジェクトの一覧
         * @param {number} id User ID
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [cursor] 取得カーソル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdLikesGet: async (id: number, limit?: number, cursor?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersIdLikesGet', 'id', id)
            const localVarPath = `/users/{id}/likes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  指定したIDのユーザーが作成したリストの一覧を取得します。   非公開のリストは、自分自身の場合以外はレスポンスに含まれません。
         * @summary 指定したIDのユーザーが作成したリストの一覧
         * @param {number} id User ID
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {string} [cursor] 取得カーソル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdListsGet: async (id: number, limit?: number, cursor?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersIdListsGet', 'id', id)
            const localVarPath = `/users/{id}/lists`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  指定したIDのユーザーが投稿した風景写真の一覧を取得します
         * @summary 指定したIDのユーザーが投稿した風景写真の一覧
         * @param {number} id User ID
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [cursor] 取得カーソル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdPostsGet: async (id: number, limit?: number, cursor?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersIdPostsGet', 'id', id)
            const localVarPath = `/users/{id}/posts`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  指定したIDのユーザーが投稿したプロジェクトの一覧を取得します
         * @summary 指定したIDのユーザーが投稿したプロジェクトの一覧
         * @param {number} id User ID
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [page] ページ(default: 1)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdProjectsGet: async (id: number, limit?: number, page?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersIdProjectsGet', 'id', id)
            const localVarPath = `/users/{id}/projects`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  指定したIDのユーザーのチームを取得します
         * @summary 指定したIDのユーザーのチームを取得
         * @param {number} id User ID
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {string} [cursor] 取得カーソル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdTeamsGet: async (id: number, limit?: number, cursor?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersIdTeamsGet', 'id', id)
            const localVarPath = `/users/{id}/teams`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 設計者アカウントへの変更
         * @param {DesignerAccountUpdateParam} designerAccountUpdateParam アカウント設定パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersMeChangeDesignerAccountPost: async (designerAccountUpdateParam: DesignerAccountUpdateParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'designerAccountUpdateParam' is not null or undefined
            assertParamExists('usersMeChangeDesignerAccountPost', 'designerAccountUpdateParam', designerAccountUpdateParam)
            const localVarPath = `/users/me/change_designer_account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(designerAccountUpdateParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  ログインしているユーザーをフォロー数も含めて取得します
         * @summary ログインユーザーを取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersMeGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  ログインしているユーザーが作成したリスト一覧を取得します
         * @summary ログインユーザーのリスト一覧
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {string} [cursor] 取得カーソル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersMeListsGet: async (limit?: number, cursor?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me/lists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ログインユーザーのプロジェクト一覧
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [page] ページ(default: 1)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersMeProjectsGet: async (limit?: number, page?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  一般ユーザーを新規登録します。
         * @summary 一般ユーザー新規登録
         * @param {UserCreateParam} userCreateParam ユーザー登録パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersPost: async (userCreateParam: UserCreateParam, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userCreateParam' is not null or undefined
            assertParamExists('usersPost', 'userCreateParam', userCreateParam)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreateParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ## summary  指定した郵便番号の住所を取得します
         * @summary 指定した郵便番号の住所を取得
         * @param {string} zipCode 郵便番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zipCodesZipCodeGet: async (zipCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'zipCode' is not null or undefined
            assertParamExists('zipCodesZipCodeGet', 'zipCode', zipCode)
            const localVarPath = `/zip_codes/{zip_code}`
                .replace(`{${"zip_code"}}`, encodeURIComponent(String(zipCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary ログインユーザーの住所を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressesGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AddressEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addressesGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.addressesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  住所を削除します。
         * @summary 住所削除
         * @param {number} id Address ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressesIdDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addressesIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.addressesIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary ログインユーザーの住所を更新する
         * @param {number} id Address ID
         * @param {AddressUpsertParameter} addressUpsertParameter AddressUpsertパラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressesIdPut(id: number, addressUpsertParameter: AddressUpsertParameter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addressesIdPut(id, addressUpsertParameter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.addressesIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary ログインユーザーの住所を登録する
         * @param {AddressUpsertParameter} addressUpsertParameter AddressUpsertパラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressesPost(addressUpsertParameter: AddressUpsertParameter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addressesPost(addressUpsertParameter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.addressesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary 現在開催中のAward取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async awardsCurrentGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AwardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.awardsCurrentGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.awardsCurrentGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  指定されたアワードのカテゴリ一覧を取得します
         * @summary 特定のアワードのカテゴリリストを取得する
         * @param {string} slug アワードのスラッグ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async awardsSlugCategoriesGet(slug: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AwardCategoryEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.awardsSlugCategoriesGet(slug, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.awardsSlugCategoriesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Award応募作品への投票
         * @param {string} slug 応募するAwardのSlug
         * @param {number} entryId 応募作品のEntryID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async awardsSlugEntriesEntryIdVotesPost(slug: string, entryId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.awardsSlugEntriesEntryIdVotesPost(slug, entryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.awardsSlugEntriesEntryIdVotesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Awardへの応募作品の取得
         * @param {string} slug 取得対象のAwardのSlug
         * @param {number} [awardCategoryId] カテゴリID
         * @param {AwardsSlugEntriesGetSortEnum} [sort] ソート条件
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [offset] オフセット
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async awardsSlugEntriesGet(slug: string, awardCategoryId?: number, sort?: AwardsSlugEntriesGetSortEnum, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AwardEntriesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.awardsSlugEntriesGet(slug, awardCategoryId, sort, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.awardsSlugEntriesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Awardへの作品応募
         * @param {string} slug 応募するAwardのSlug
         * @param {AwardEntryCreateParameter} awardEntryCreateParameter 応募パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async awardsSlugEntriesPost(slug: string, awardEntryCreateParameter: AwardEntryCreateParameter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.awardsSlugEntriesPost(slug, awardEntryCreateParameter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.awardsSlugEntriesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary 現在開催中のAward取得
         * @param {string} slug 応募するAwardのSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async awardsSlugGet(slug: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AwardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.awardsSlugGet(slug, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.awardsSlugGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary 投票済みの応募作品ID一覧取得
         * @param {string} slug 応募するAwardのSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async awardsSlugVotedEntryIdsGet(slug: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.awardsSlugVotedEntryIdsGet(slug, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.awardsSlugVotedEntryIdsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  ボードの詳細を取得します。
         * @summary ボードの詳細を取得
         * @param {number} id Board ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async boardsIdGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoardDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.boardsIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.boardsIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  ボードに紐づくプロダクトバリエーションから事例写真を取得します
         * @summary ボードの事例写真を取得
         * @param {number} id Board ID
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [page] ページ(default: 1)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async boardsIdPostsGet(id: number, limit?: number, page?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.boardsIdPostsGet(id, limit, page, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.boardsIdPostsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary ID指定ボード一覧
         * @param {string} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async boardsSearchByIDsGet(ids: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BoardResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.boardsSearchByIDsGet(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.boardsSearchByIDsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  ボードの一覧を取得します。
         * @summary ボードの検索
         * @param {string} [keyword] 検索キーワード
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [offset] ページ取得位置(default: 0)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async boardsSearchGet(keyword?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoardsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.boardsSearchGet(keyword, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.boardsSearchGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  指定したIDのブランドを取得します
         * @summary 指定したIDのブランドを取得
         * @param {number} id Brand ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brandsIdGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrandWithCategoriesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brandsIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.brandsIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  指定したIDのブランドの検索条件にマッチした製品が紐づく事例写真を返します 並び順はElasticsearchのマッチ率順になるので、cursor形式のページネーションは使えず、 limit, offset形式になります
         * @summary 指定したIDのブランドの製品が紐づく事例を取得
         * @param {number} id Brand ID
         * @param {string} [keyword] 検索キーワード
         * @param {BrandsIdPostsGetKindEnum} [kind] 種類
         * @param {number} [categoryId] カテゴリID
         * @param {Array<BrandsIdPostsGetColorTypeIdsEnum>} [colorTypeIds] カラーIDの配列
         * @param {BrandsIdPostsGetFireProtectionMaterialEnum} [fireProtectionMaterial] 防火材料
         * @param {BrandsIdPostsGetFlamePreventionRegulationEnum} [flamePreventionRegulation] 防炎規制
         * @param {BrandsIdPostsGetFormaldehydeEnum} [formaldehyde] ホルムアルデヒド等級
         * @param {number} [materialId] 素材ID
         * @param {number} [minWidth] 最小幅
         * @param {number} [maxWidth] 最小幅
         * @param {number} [minHeight] 最小高さ
         * @param {number} [maxHeight] 最小高さ
         * @param {number} [minDepth] 最小奥行き
         * @param {number} [maxDepth] 最小奥行き
         * @param {number} [minPrice] 最低価格
         * @param {number} [maxPrice] 最高価格
         * @param {BrandsIdPostsGetFireResistanceEnum} [fireResistance] 耐火性能
         * @param {boolean} [inStock] 在庫あり
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [offset] ページ取得位置(default: 0)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brandsIdPostsGet(id: number, keyword?: string, kind?: BrandsIdPostsGetKindEnum, categoryId?: number, colorTypeIds?: Array<BrandsIdPostsGetColorTypeIdsEnum>, fireProtectionMaterial?: BrandsIdPostsGetFireProtectionMaterialEnum, flamePreventionRegulation?: BrandsIdPostsGetFlamePreventionRegulationEnum, formaldehyde?: BrandsIdPostsGetFormaldehydeEnum, materialId?: number, minWidth?: number, maxWidth?: number, minHeight?: number, maxHeight?: number, minDepth?: number, maxDepth?: number, minPrice?: number, maxPrice?: number, fireResistance?: BrandsIdPostsGetFireResistanceEnum, inStock?: boolean, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brandsIdPostsGet(id, keyword, kind, categoryId, colorTypeIds, fireProtectionMaterial, flamePreventionRegulation, formaldehyde, materialId, minWidth, maxWidth, minHeight, maxHeight, minDepth, maxDepth, minPrice, maxPrice, fireResistance, inStock, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.brandsIdPostsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  指定したIDのブランドの検索条件にマッチした商品バリエーションの一覧を取得します 並び順はElasticsearchのマッチ率順になるので、cursor形式のページネーションは使えず、 limit, offset形式になります 1つのプロダクトに付き、1つのバリエーションを返します
         * @summary 指定したIDのブランドの製品バリエーションを取得
         * @param {number} id Brand ID
         * @param {string} [keyword] 検索キーワード
         * @param {BrandsIdProductVariationsGetKindEnum} [kind] 種類
         * @param {number} [categoryId] カテゴリID
         * @param {Array<BrandsIdProductVariationsGetColorTypeIdsEnum>} [colorTypeIds] カラーIDの配列
         * @param {BrandsIdProductVariationsGetFireProtectionMaterialEnum} [fireProtectionMaterial] 防火材料
         * @param {BrandsIdProductVariationsGetFlamePreventionRegulationEnum} [flamePreventionRegulation] 防炎規制
         * @param {BrandsIdProductVariationsGetFormaldehydeEnum} [formaldehyde] ホルムアルデヒド等級
         * @param {number} [materialId] 素材ID
         * @param {number} [minWidth] 最小幅
         * @param {number} [maxWidth] 最小幅
         * @param {number} [minHeight] 最小高さ
         * @param {number} [maxHeight] 最小高さ
         * @param {number} [minDepth] 最小奥行き
         * @param {number} [maxDepth] 最小奥行き
         * @param {number} [minPrice] 最低価格
         * @param {number} [maxPrice] 最高価格
         * @param {BrandsIdProductVariationsGetFireResistanceEnum} [fireResistance] 耐火性能
         * @param {boolean} [inStock] 在庫あり
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [offset] ページ取得位置(default: 0)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brandsIdProductVariationsGet(id: number, keyword?: string, kind?: BrandsIdProductVariationsGetKindEnum, categoryId?: number, colorTypeIds?: Array<BrandsIdProductVariationsGetColorTypeIdsEnum>, fireProtectionMaterial?: BrandsIdProductVariationsGetFireProtectionMaterialEnum, flamePreventionRegulation?: BrandsIdProductVariationsGetFlamePreventionRegulationEnum, formaldehyde?: BrandsIdProductVariationsGetFormaldehydeEnum, materialId?: number, minWidth?: number, maxWidth?: number, minHeight?: number, maxHeight?: number, minDepth?: number, maxDepth?: number, minPrice?: number, maxPrice?: number, fireResistance?: BrandsIdProductVariationsGetFireResistanceEnum, inStock?: boolean, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductVariationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brandsIdProductVariationsGet(id, keyword, kind, categoryId, colorTypeIds, fireProtectionMaterial, flamePreventionRegulation, formaldehyde, materialId, minWidth, maxWidth, minHeight, maxHeight, minDepth, maxDepth, minPrice, maxPrice, fireResistance, inStock, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.brandsIdProductVariationsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  ビルディングタイプの一覧を取得します
         * @summary ビルディングタイプ取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buildingTypesGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BuildingTypeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buildingTypesGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.buildingTypesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  ビルディングタイプに紐づく用途の一覧を取得します
         * @summary ビルディングタイプに紐づく用途一覧
         * @param {number} id BuildingType ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buildingTypesIdUseTypesGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UseTypeEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buildingTypesIdUseTypesGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.buildingTypesIdUseTypesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  指定したIDのカタログ情報を返します
         * @summary 指定したIDのカタログを取得
         * @param {number} id Catalog ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async catalogsIdGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.catalogsIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.catalogsIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  指定したIDのカタログのページ情報を返します
         * @summary 指定したIDのカタログのページ情報を取得
         * @param {number} id Catalog ID
         * @param {number} fromPage 何ページ目からの取得か
         * @param {number} toPage 何ページ目までの取得か
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async catalogsIdPagesGet(id: number, fromPage: number, toPage: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CatalogPageResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.catalogsIdPagesGet(id, fromPage, toPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.catalogsIdPagesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  カテゴリの一覧を取得します
         * @summary カテゴリの一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CategoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.categoriesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  カテゴリの詳細検索項目を取得します
         * @summary カテゴリの詳細検索項目
         * @param {number} id Category ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesIdSearchConditionGroupsGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SearchConditionGroupResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesIdSearchConditionGroupsGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.categoriesIdSearchConditionGroupsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  設計者アカウントを新規登録します。
         * @summary 設計者アカウント新規登録
         * @param {DesignerCreateParam} designerCreateParam 設計者アカウント登録パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async designersPost(designerCreateParam: DesignerCreateParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.designersPost(designerCreateParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.designersPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  ユーザーをフォローします  ## validation  - user_id: 必須
         * @summary ユーザーをフォローする
         * @param {FollowCreateParameter} followCreateParameter フォローパラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async followsPost(followCreateParameter: FollowCreateParameter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.followsPost(followCreateParameter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.followsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  ユーザーをアンフォローします
         * @summary ユーザーをアンフォローする
         * @param {number} userId User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async followsUserIdDelete(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.followsUserIdDelete(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.followsUserIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  画像をアップロードします
         * @summary 画像アップロード
         * @param {File} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imagesPost(image?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadImageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imagesPost(image, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.imagesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  指定したEntityのLikeを削除します
         * @summary Likeを削除する
         * @param {LikeDeleteParam} likeDeleteParam Like削除パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async likesDelete(likeDeleteParam: LikeDeleteParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.likesDelete(likeDeleteParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.likesDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  指定したEntityをLikeします
         * @summary Likeする
         * @param {LikeCreateParam} likeCreateParam Like作成パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async likesPost(likeCreateParam: LikeCreateParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.likesPost(likeCreateParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.likesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  リストを削除します。
         * @summary リストを削除
         * @param {number} id List ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listsIdDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listsIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listsIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  指定したIDのリストを取得します。   対象のリストが非公開だった場合、本人でない場合は403を返します。
         * @summary リストを取得
         * @param {number} id List ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listsIdGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listsIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listsIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  指定したIDのリストに追加されているアイテムの一覧を取得します。   対象のリストが非公開だった場合、本人でない場合は403を返します。
         * @summary リストのアイテムを取得
         * @param {number} id List ID
         * @param {ListItemKind} [kind] List Item KInd
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [cursor] 取得カーソル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listsIdItemsGet(id: number, kind?: ListItemKind, limit?: number, cursor?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListItemsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listsIdItemsGet(id, kind, limit, cursor, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listsIdItemsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  指定したIDのリストに、アイテムを追加します。  ## logic  * すでにリストに追加済みの場合は400を返します。
         * @summary リストにアイテムを追加
         * @param {number} id List ID
         * @param {ListAddItemParameter} listAddItemParameter 風景写真登録パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listsIdItemsPost(id: number, listAddItemParameter: ListAddItemParameter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listsIdItemsPost(id, listAddItemParameter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listsIdItemsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  リストを更新します。  ## validation  - name: 必須, 255文字以内 - is_praivate: 必須
         * @summary リストを更新
         * @param {number} id List ID
         * @param {ListUpdateParam} listUpdateParam リスト作成パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listsIdPut(id: number, listUpdateParam: ListUpdateParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listsIdPut(id, listUpdateParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listsIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  指定したIDのリストから、アイテムを削除します。
         * @summary リストからアイテムを削除
         * @param {number} listId List ID
         * @param {number} itemId Item ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listsListIdItemsItemIdDelete(listId: number, itemId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listsListIdItemsItemIdDelete(listId, itemId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listsListIdItemsItemIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  リストを作成します。  ## validation  - name: 必須, 255文字以内 - is_praivate: 必須
         * @summary リストの作成
         * @param {ListCreateParam} listCreateParam リスト作成パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listsPost(listCreateParam: ListCreateParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listsPost(listCreateParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary ID指定リスト一覧
         * @param {string} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listsSearchByIDsGet(ids: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listsSearchByIDsGet(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listsSearchByIDsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary リストの検索
         * @param {string} [keyword] 検索キーワード
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [offset] offset(default: 0)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listsSearchGet(keyword?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listsSearchGet(keyword, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listsSearchGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary 記事を新着順で取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async magazinesGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MagazineResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.magazinesGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.magazinesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary タグに紐づく記事を取得する
         * @param {string} slug tagのslug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async magazinesTagsSlugGet(slug: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MagazineResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.magazinesTagsSlugGet(slug, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.magazinesTagsSlugGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  すべてのメーカーを取得します
         * @summary すべてのメーカーを取得
         * @param {boolean} [availableSampleRequest] サンプル請求可能なメーカーに絞り込む
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async makersAllGet(availableSampleRequest?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InitialGroupMakersResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.makersAllGet(availableSampleRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.makersAllGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  指定したIDのメーカーのカタログを取得します
         * @summary 指定したIDのメーカーのカタログを取得
         * @param {number} id Maker ID
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {string} [cursor] 取得カーソル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async makersIdCatalogsGet(id: number, limit?: number, cursor?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.makersIdCatalogsGet(id, limit, cursor, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.makersIdCatalogsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  指定したIDのメーカーを取得します
         * @summary 指定したIDのメーカーをカテゴリも含めて取得
         * @param {number} id Maker ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async makersIdCategoriesGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MakerWithCategoriesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.makersIdCategoriesGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.makersIdCategoriesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  指定したIDのメーカーに対して問い合わせを行う
         * @summary メーカー問い合わせ
         * @param {number} id Maker ID
         * @param {MakerContactCreateParam} makerContactCreateParam メーカー問い合わせパラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async makersIdContactsPost(id: number, makerContactCreateParam: MakerContactCreateParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MakerContactResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.makersIdContactsPost(id, makerContactCreateParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.makersIdContactsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  指定したIDのメーカーを取得します
         * @summary 指定したIDのメーカーを取得
         * @param {number} id Maker ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async makersIdGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MakerIndexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.makersIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.makersIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary 指定したIDのメーカーのリストを取得
         * @param {number} id Maker ID
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [offset] ページ取得位置(default: 0)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async makersIdListsGet(id: number, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.makersIdListsGet(id, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.makersIdListsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  指定したIDのメーカーの検索条件にマッチした製品が紐づく事例写真を返します 並び順はElasticsearchのマッチ率順になるので、cursor形式のページネーションは使えず、 limit, offset形式になります
         * @summary 指定したIDのメーカーの製品が紐づく事例を取得
         * @param {number} id Maker ID
         * @param {string} [keyword] 検索キーワード
         * @param {MakersIdPostsGetKindEnum} [kind] 種類
         * @param {number} [categoryId] カテゴリID
         * @param {Array<MakersIdPostsGetColorTypeIdsEnum>} [colorTypeIds] カラーIDの配列
         * @param {MakersIdPostsGetFireProtectionMaterialEnum} [fireProtectionMaterial] 防火材料
         * @param {MakersIdPostsGetFlamePreventionRegulationEnum} [flamePreventionRegulation] 防炎規制
         * @param {MakersIdPostsGetFormaldehydeEnum} [formaldehyde] ホルムアルデヒド等級
         * @param {number} [materialId] 素材ID
         * @param {number} [minWidth] 最小幅
         * @param {number} [maxWidth] 最小幅
         * @param {number} [minHeight] 最小高さ
         * @param {number} [maxHeight] 最小高さ
         * @param {number} [minDepth] 最小奥行き
         * @param {number} [maxDepth] 最小奥行き
         * @param {number} [minPrice] 最低価格
         * @param {number} [maxPrice] 最高価格
         * @param {MakersIdPostsGetFireResistanceEnum} [fireResistance] 耐火性能
         * @param {boolean} [inStock] 在庫あり
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [offset] ページ取得位置(default: 0)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async makersIdPostsGet(id: number, keyword?: string, kind?: MakersIdPostsGetKindEnum, categoryId?: number, colorTypeIds?: Array<MakersIdPostsGetColorTypeIdsEnum>, fireProtectionMaterial?: MakersIdPostsGetFireProtectionMaterialEnum, flamePreventionRegulation?: MakersIdPostsGetFlamePreventionRegulationEnum, formaldehyde?: MakersIdPostsGetFormaldehydeEnum, materialId?: number, minWidth?: number, maxWidth?: number, minHeight?: number, maxHeight?: number, minDepth?: number, maxDepth?: number, minPrice?: number, maxPrice?: number, fireResistance?: MakersIdPostsGetFireResistanceEnum, inStock?: boolean, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.makersIdPostsGet(id, keyword, kind, categoryId, colorTypeIds, fireProtectionMaterial, flamePreventionRegulation, formaldehyde, materialId, minWidth, maxWidth, minHeight, maxHeight, minDepth, maxDepth, minPrice, maxPrice, fireResistance, inStock, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.makersIdPostsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  指定したIDのメーカーの検索条件にマッチした商品バリエーションの一覧を取得します 並び順はElasticsearchのマッチ率順になるので、cursor形式のページネーションは使えず、 limit, offset形式になります 1つのプロダクトに付き、1つのバリエーションを返します
         * @summary 指定したIDのメーカーの製品バリエーションを取得
         * @param {number} id Maker ID
         * @param {string} [keyword] 検索キーワード
         * @param {MakersIdProductVariationsGetKindEnum} [kind] 種類
         * @param {number} [categoryId] カテゴリID
         * @param {Array<MakersIdProductVariationsGetColorTypeIdsEnum>} [colorTypeIds] カラーIDの配列
         * @param {MakersIdProductVariationsGetFireProtectionMaterialEnum} [fireProtectionMaterial] 防火材料
         * @param {MakersIdProductVariationsGetFlamePreventionRegulationEnum} [flamePreventionRegulation] 防炎規制
         * @param {MakersIdProductVariationsGetFormaldehydeEnum} [formaldehyde] ホルムアルデヒド等級
         * @param {number} [materialId] 素材ID
         * @param {number} [minWidth] 最小幅
         * @param {number} [maxWidth] 最小幅
         * @param {number} [minHeight] 最小高さ
         * @param {number} [maxHeight] 最小高さ
         * @param {number} [minDepth] 最小奥行き
         * @param {number} [maxDepth] 最小奥行き
         * @param {number} [minPrice] 最低価格
         * @param {number} [maxPrice] 最高価格
         * @param {MakersIdProductVariationsGetFireResistanceEnum} [fireResistance] 耐火性能
         * @param {boolean} [inStock] 在庫あり
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [offset] ページ取得位置(default: 0)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async makersIdProductVariationsGet(id: number, keyword?: string, kind?: MakersIdProductVariationsGetKindEnum, categoryId?: number, colorTypeIds?: Array<MakersIdProductVariationsGetColorTypeIdsEnum>, fireProtectionMaterial?: MakersIdProductVariationsGetFireProtectionMaterialEnum, flamePreventionRegulation?: MakersIdProductVariationsGetFlamePreventionRegulationEnum, formaldehyde?: MakersIdProductVariationsGetFormaldehydeEnum, materialId?: number, minWidth?: number, maxWidth?: number, minHeight?: number, maxHeight?: number, minDepth?: number, maxDepth?: number, minPrice?: number, maxPrice?: number, fireResistance?: MakersIdProductVariationsGetFireResistanceEnum, inStock?: boolean, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductVariationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.makersIdProductVariationsGet(id, keyword, kind, categoryId, colorTypeIds, fireProtectionMaterial, flamePreventionRegulation, formaldehyde, materialId, minWidth, maxWidth, minHeight, maxHeight, minDepth, maxDepth, minPrice, maxPrice, fireResistance, inStock, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.makersIdProductVariationsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  指定したIDのメーカーの検索条件にマッチした製品が紐づくプロジェクトを返します 並び順はElasticsearchのマッチ率順になるので、cursor形式のページネーションは使えず、 limit, offset形式になります
         * @summary 指定したIDのメーカーの製品が紐づくプロジェクトを取得
         * @param {number} id Maker ID
         * @param {string} [keyword] 検索キーワード
         * @param {MakersIdProjectsGetKindEnum} [kind] 種類
         * @param {number} [categoryId] カテゴリID
         * @param {Array<MakersIdProjectsGetColorTypeIdsEnum>} [colorTypeIds] カラーIDの配列
         * @param {MakersIdProjectsGetFireProtectionMaterialEnum} [fireProtectionMaterial] 防火材料
         * @param {MakersIdProjectsGetFlamePreventionRegulationEnum} [flamePreventionRegulation] 防炎規制
         * @param {MakersIdProjectsGetFormaldehydeEnum} [formaldehyde] ホルムアルデヒド等級
         * @param {number} [materialId] 素材ID
         * @param {number} [minWidth] 最小幅
         * @param {number} [maxWidth] 最小幅
         * @param {number} [minHeight] 最小高さ
         * @param {number} [maxHeight] 最小高さ
         * @param {number} [minDepth] 最小奥行き
         * @param {number} [maxDepth] 最小奥行き
         * @param {number} [minPrice] 最低価格
         * @param {number} [maxPrice] 最高価格
         * @param {MakersIdProjectsGetFireResistanceEnum} [fireResistance] 耐火性能
         * @param {boolean} [inStock] 在庫あり
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [offset] ページ取得位置(default: 0)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async makersIdProjectsGet(id: number, keyword?: string, kind?: MakersIdProjectsGetKindEnum, categoryId?: number, colorTypeIds?: Array<MakersIdProjectsGetColorTypeIdsEnum>, fireProtectionMaterial?: MakersIdProjectsGetFireProtectionMaterialEnum, flamePreventionRegulation?: MakersIdProjectsGetFlamePreventionRegulationEnum, formaldehyde?: MakersIdProjectsGetFormaldehydeEnum, materialId?: number, minWidth?: number, maxWidth?: number, minHeight?: number, maxHeight?: number, minDepth?: number, maxDepth?: number, minPrice?: number, maxPrice?: number, fireResistance?: MakersIdProjectsGetFireResistanceEnum, inStock?: boolean, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.makersIdProjectsGet(id, keyword, kind, categoryId, colorTypeIds, fireProtectionMaterial, flamePreventionRegulation, formaldehyde, materialId, minWidth, maxWidth, minHeight, maxHeight, minDepth, maxDepth, minPrice, maxPrice, fireResistance, inStock, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.makersIdProjectsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary メーカーインクリメンタル検索
         * @param {string} [keyword] 
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [offset] offset(default: 0)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async makersIncrementalSearchGet(keyword?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MakersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.makersIncrementalSearchGet(keyword, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.makersIncrementalSearchGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary メーカーの最新とランダムを取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async makersLatestAndRandomGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LatestAndRandomMakersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.makersLatestAndRandomGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.makersLatestAndRandomGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary メーカー検索
         * @param {string} [keyword] 
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [offset] offset(default: 0)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async makersSearchGet(keyword?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MakersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.makersSearchGet(keyword, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.makersSearchGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  マテリアルの一覧を取得します
         * @summary マテリアルの一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MaterialResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.materialsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  NEWSの一覧を取得します
         * @summary NEWS取得API
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {string} [cursor] 取得カーソル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsGet(limit?: number, cursor?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsGet(limit, cursor, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.newsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Notificationのリスト
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [cursor] 取得カーソル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationsGet(limit?: number, cursor?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationsGet(limit, cursor, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.notificationsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary 特定の通知のreadフラグを更新する
         * @param {number} id Notification ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationsIdReadPost(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationsIdReadPost(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.notificationsIdReadPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Notificationのreadフラグをたてる
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationsReadPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationsReadPost(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.notificationsReadPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary readしていないteamのrejectのnotificationをすべて取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationsUnreadTeamRejectsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NotificationResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationsUnreadTeamRejectsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.notificationsUnreadTeamRejectsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  オーダーグループをキャンセルします。
         * @summary オーダーグループキャンセル
         * @param {string} code Order code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderGroupsCodeCancelPost(code: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderGroupWithOrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderGroupsCodeCancelPost(code, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.orderGroupsCodeCancelPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  オーダーグループを取得します。
         * @summary オーダーグループ取得
         * @param {string} code Order code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderGroupsCodeGet(code: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderGroupWithOrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderGroupsCodeGet(code, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.orderGroupsCodeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  オーダーグループ一覧を取得します。
         * @summary オーダーグループ一覧取得
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [cursor] 取得カーソル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderGroupsGet(limit?: number, cursor?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderGroupsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderGroupsGet(limit, cursor, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.orderGroupsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  直近3件のリード情報のリスト取得します。
         * @summary 直近のリード情報のリスト取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderQuestionnaireResultsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderQuestionnaireResultEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderQuestionnaireResultsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.orderQuestionnaireResultsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  オーダーを作成します。
         * @summary オーダー作成
         * @param {OrderCreateParameter} orderCreateParameter オーダー作成パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersPost(orderCreateParameter: OrderCreateParameter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderWithGroupsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersPost(orderCreateParameter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.ordersPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary PICKUPコンテンツを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pickupsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PickupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pickupsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.pickupsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary PICKUPユーザーを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pickupsUsersGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDetailResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pickupsUsersGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.pickupsUsersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  自分がフォローしているユーザーが投稿した風景写真の一覧を取得します
         * @summary 自分がフォローしているユーザーの風景写真一覧
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [cursor] 取得カーソル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postsFollowingGet(limit?: number, cursor?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postsFollowingGet(limit, cursor, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.postsFollowingGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  指定したIDの風景写真を取得します
         * @summary 風景写真を取得
         * @param {number} id Post ID
         * @param {string} [token] 非公開プロジェクトを閲覧するための共有トークン
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postsIdGet(id: number, token?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postsIdGet(id, token, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.postsIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  指定したIDの風景写真にひもづく、おすすめの風景写真を取得します
         * @summary 風景写真のおすすめを取得
         * @param {number} id Post ID
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [page] ページ(default: 1)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postsIdRecommendsGet(id: number, limit?: number, page?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postsIdRecommendsGet(id, limit, page, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.postsIdRecommendsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  IDを指定して風景写真の一覧を取得します
         * @summary ID指定風景写真一覧
         * @param {string} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postsSearchByIDsGet(ids: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PostResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postsSearchByIDsGet(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.postsSearchByIDsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  検索条件にマッチした風景写真の一覧を取得します 並び順はElasticsearchのマッチ率順になるので、cursor形式のページネーションは使えず、 limit, offset形式になります
         * @summary 風景写真の検索
         * @param {string} [keyword] 検索キーワード
         * @param {number} [buildingTypeId] building_typeのID
         * @param {number} [structureTypeId] 構造種別のID
         * @param {PostsSearchGetConstructionTypeEnum} [constructionType] 工事種別
         * @param {number} [minFloorSpace] 最小床面積
         * @param {number} [maxFloorSpace] 最大床面積
         * @param {number} [materialId] 素材ID
         * @param {Array<PostsSearchGetColorTypeIdsEnum>} [colorTypeIds] カラーIDの配列
         * @param {Array<PostsSearchGetPartIdsEnum>} [partIds] 使用箇所の配列
         * @param {number} [useTypeId] 用途のID
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [offset] ページ取得位置(default: 0)
         * @param {PostsSearchGetSortEnum} [sort] ソート条件
         * @param {number} [excludeProjectId] 除外するProjectID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postsSearchGet(keyword?: string, buildingTypeId?: number, structureTypeId?: number, constructionType?: PostsSearchGetConstructionTypeEnum, minFloorSpace?: number, maxFloorSpace?: number, materialId?: number, colorTypeIds?: Array<PostsSearchGetColorTypeIdsEnum>, partIds?: Array<PostsSearchGetPartIdsEnum>, useTypeId?: number, limit?: number, offset?: number, sort?: PostsSearchGetSortEnum, excludeProjectId?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostsSearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postsSearchGet(keyword, buildingTypeId, structureTypeId, constructionType, minFloorSpace, maxFloorSpace, materialId, colorTypeIds, partIds, useTypeId, limit, offset, sort, excludeProjectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.postsSearchGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary 風景写真の検索のヒット数
         * @param {string} [keyword] 検索キーワード
         * @param {number} [buildingTypeId] building_typeのID
         * @param {number} [structureTypeId] 構造種別のID
         * @param {PostsSearchHitCountsGetConstructionTypeEnum} [constructionType] 工事種別
         * @param {number} [minFloorSpace] 最小床面積
         * @param {number} [maxFloorSpace] 最大床面積
         * @param {number} [materialId] 素材ID
         * @param {Array<PostsSearchHitCountsGetColorTypeIdsEnum>} [colorTypeIds] カラーIDの配列
         * @param {Array<PostsSearchHitCountsGetPartIdsEnum>} [partIds] 使用箇所の配列
         * @param {number} [useTypeId] 用途のID
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [offset] ページ取得位置(default: 0)
         * @param {PostsSearchHitCountsGetSortEnum} [sort] ソート条件
         * @param {number} [excludeProjectId] 除外するProjectID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postsSearchHitCountsGet(keyword?: string, buildingTypeId?: number, structureTypeId?: number, constructionType?: PostsSearchHitCountsGetConstructionTypeEnum, minFloorSpace?: number, maxFloorSpace?: number, materialId?: number, colorTypeIds?: Array<PostsSearchHitCountsGetColorTypeIdsEnum>, partIds?: Array<PostsSearchHitCountsGetPartIdsEnum>, useTypeId?: number, limit?: number, offset?: number, sort?: PostsSearchHitCountsGetSortEnum, excludeProjectId?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostsSearchHitCountsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postsSearchHitCountsGet(keyword, buildingTypeId, structureTypeId, constructionType, minFloorSpace, maxFloorSpace, materialId, colorTypeIds, partIds, useTypeId, limit, offset, sort, excludeProjectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.postsSearchHitCountsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary プロダクトバリエーションが紐づいている事例写真の一覧
         * @param {number} id ProductVariation ID
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [page] ページ(default: 1)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productVariationsIdPostsGet(id: number, limit?: number, page?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productVariationsIdPostsGet(id, limit, page, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.productVariationsIdPostsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary 類似製品の検索
         * @param {number} id ProductVariation ID
         * @param {SimilarityAxis} [axis] 検索軸
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productVariationsIdSimilaritiesGet(id: number, axis?: SimilarityAxis, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductVariationWithProductResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productVariationsIdSimilaritiesGet(id, axis, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.productVariationsIdSimilaritiesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  前後一致で検索します
         * @summary 商品バリエーションのインクリメンタル検索
         * @param {number} [makerId] メーカーID
         * @param {string} [keyword] 検索キーワード
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [offset] ページ取得位置(default: 0)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productVariationsIncrementalSearchGet(makerId?: number, keyword?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductVariationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productVariationsIncrementalSearchGet(makerId, keyword, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.productVariationsIncrementalSearchGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  対象のProductVariationIDを複数投げ、おすすめのProductVariationを返します
         * @summary 商品バリエーションのおすすめ
         * @param {Array<number>} productVariationIds ProductVariationIDの配列
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productVariationsRecommendationsGet(productVariationIds: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductVariationWithProductResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productVariationsRecommendationsGet(productVariationIds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.productVariationsRecommendationsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  IDを指定してプロダクトバリエーションの一覧を取得します
         * @summary ID指定プロダクトバリエーション一覧
         * @param {string} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productVariationsSearchByIDsGet(ids: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductVariationWithProductResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productVariationsSearchByIDsGet(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.productVariationsSearchByIDsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  検索条件にマッチした商品バリエーションの一覧を取得します 並び順はElasticsearchのマッチ率順になるので、cursor形式のページネーションは使えず、 limit, offset形式になります
         * @summary 商品バリエーションの検索
         * @param {string} [keyword] 検索キーワード
         * @param {ProductVariationsSearchGetKindEnum} [kind] 種類
         * @param {number} [categoryId] カテゴリID
         * @param {Array<ProductVariationsSearchGetColorTypeIdsEnum>} [colorTypeIds] カラーIDの配列
         * @param {ProductVariationsSearchGetFireProtectionMaterialEnum} [fireProtectionMaterial] 防火材料
         * @param {ProductVariationsSearchGetFlamePreventionRegulationEnum} [flamePreventionRegulation] 防炎規制
         * @param {ProductVariationsSearchGetFormaldehydeEnum} [formaldehyde] ホルムアルデヒド等級
         * @param {number} [materialId] 素材ID
         * @param {number} [minWidth] 最小幅
         * @param {number} [maxWidth] 最小幅
         * @param {number} [minHeight] 最小高さ
         * @param {number} [maxHeight] 最小高さ
         * @param {number} [minDepth] 最小奥行き
         * @param {number} [maxDepth] 最小奥行き
         * @param {number} [minPrice] 最低価格
         * @param {number} [maxPrice] 最高価格
         * @param {ProductVariationsSearchGetFireResistanceEnum} [fireResistance] 耐火性能
         * @param {boolean} [inStock] 在庫あり
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [offset] ページ取得位置(default: 0)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productVariationsSearchGet(keyword?: string, kind?: ProductVariationsSearchGetKindEnum, categoryId?: number, colorTypeIds?: Array<ProductVariationsSearchGetColorTypeIdsEnum>, fireProtectionMaterial?: ProductVariationsSearchGetFireProtectionMaterialEnum, flamePreventionRegulation?: ProductVariationsSearchGetFlamePreventionRegulationEnum, formaldehyde?: ProductVariationsSearchGetFormaldehydeEnum, materialId?: number, minWidth?: number, maxWidth?: number, minHeight?: number, maxHeight?: number, minDepth?: number, maxDepth?: number, minPrice?: number, maxPrice?: number, fireResistance?: ProductVariationsSearchGetFireResistanceEnum, inStock?: boolean, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductVariationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productVariationsSearchGet(keyword, kind, categoryId, colorTypeIds, fireProtectionMaterial, flamePreventionRegulation, formaldehyde, materialId, minWidth, maxWidth, minHeight, maxHeight, minDepth, maxDepth, minPrice, maxPrice, fireResistance, inStock, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.productVariationsSearchGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary ProductVariationの検索のヒット数
         * @param {string} [keyword] 検索キーワード
         * @param {ProductVariationsSearchHitCountsGetKindEnum} [kind] 種類
         * @param {number} [categoryId] カテゴリID
         * @param {Array<ProductVariationsSearchHitCountsGetColorTypeIdsEnum>} [colorTypeIds] カラーIDの配列
         * @param {ProductVariationsSearchHitCountsGetFireProtectionMaterialEnum} [fireProtectionMaterial] 防火材料
         * @param {ProductVariationsSearchHitCountsGetFlamePreventionRegulationEnum} [flamePreventionRegulation] 防炎規制
         * @param {ProductVariationsSearchHitCountsGetFormaldehydeEnum} [formaldehyde] ホルムアルデヒド等級
         * @param {number} [materialId] 素材ID
         * @param {number} [minWidth] 最小幅
         * @param {number} [maxWidth] 最小幅
         * @param {number} [minHeight] 最小高さ
         * @param {number} [maxHeight] 最小高さ
         * @param {number} [minDepth] 最小奥行き
         * @param {number} [maxDepth] 最小奥行き
         * @param {number} [minPrice] 最低価格
         * @param {number} [maxPrice] 最高価格
         * @param {ProductVariationsSearchHitCountsGetFireResistanceEnum} [fireResistance] 耐火性能
         * @param {boolean} [inStock] 在庫あり
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [offset] ページ取得位置(default: 0)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productVariationsSearchHitCountsGet(keyword?: string, kind?: ProductVariationsSearchHitCountsGetKindEnum, categoryId?: number, colorTypeIds?: Array<ProductVariationsSearchHitCountsGetColorTypeIdsEnum>, fireProtectionMaterial?: ProductVariationsSearchHitCountsGetFireProtectionMaterialEnum, flamePreventionRegulation?: ProductVariationsSearchHitCountsGetFlamePreventionRegulationEnum, formaldehyde?: ProductVariationsSearchHitCountsGetFormaldehydeEnum, materialId?: number, minWidth?: number, maxWidth?: number, minHeight?: number, maxHeight?: number, minDepth?: number, maxDepth?: number, minPrice?: number, maxPrice?: number, fireResistance?: ProductVariationsSearchHitCountsGetFireResistanceEnum, inStock?: boolean, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductVariationsSearchHitCountsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productVariationsSearchHitCountsGet(keyword, kind, categoryId, colorTypeIds, fireProtectionMaterial, flamePreventionRegulation, formaldehyde, materialId, minWidth, maxWidth, minHeight, maxHeight, minDepth, maxDepth, minPrice, maxPrice, fireResistance, inStock, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.productVariationsSearchHitCountsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  ピンに対しての検索を行っているため、ヒットしたピンのみ返却される
         * @summary 商品バリエーションを元に事例写真の検索
         * @param {string} [keyword] 検索キーワード
         * @param {ProductVariationsSearchPostsGetKindEnum} [kind] 種類
         * @param {number} [categoryId] カテゴリID
         * @param {Array<ProductVariationsSearchPostsGetColorTypeIdsEnum>} [colorTypeIds] カラーIDの配列
         * @param {ProductVariationsSearchPostsGetFireProtectionMaterialEnum} [fireProtectionMaterial] 防火材料
         * @param {ProductVariationsSearchPostsGetFlamePreventionRegulationEnum} [flamePreventionRegulation] 防炎規制
         * @param {ProductVariationsSearchPostsGetFormaldehydeEnum} [formaldehyde] ホルムアルデヒド等級
         * @param {number} [materialId] 素材ID
         * @param {number} [minWidth] 最小幅
         * @param {number} [maxWidth] 最小幅
         * @param {number} [minHeight] 最小高さ
         * @param {number} [maxHeight] 最小高さ
         * @param {number} [minDepth] 最小奥行き
         * @param {number} [maxDepth] 最小奥行き
         * @param {number} [minPrice] 最低価格
         * @param {number} [maxPrice] 最高価格
         * @param {ProductVariationsSearchPostsGetFireResistanceEnum} [fireResistance] 耐火性能
         * @param {boolean} [inStock] 在庫あり
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [offset] ページ取得位置(default: 0)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productVariationsSearchPostsGet(keyword?: string, kind?: ProductVariationsSearchPostsGetKindEnum, categoryId?: number, colorTypeIds?: Array<ProductVariationsSearchPostsGetColorTypeIdsEnum>, fireProtectionMaterial?: ProductVariationsSearchPostsGetFireProtectionMaterialEnum, flamePreventionRegulation?: ProductVariationsSearchPostsGetFlamePreventionRegulationEnum, formaldehyde?: ProductVariationsSearchPostsGetFormaldehydeEnum, materialId?: number, minWidth?: number, maxWidth?: number, minHeight?: number, maxHeight?: number, minDepth?: number, maxDepth?: number, minPrice?: number, maxPrice?: number, fireResistance?: ProductVariationsSearchPostsGetFireResistanceEnum, inStock?: boolean, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productVariationsSearchPostsGet(keyword, kind, categoryId, colorTypeIds, fireProtectionMaterial, flamePreventionRegulation, formaldehyde, materialId, minWidth, maxWidth, minHeight, maxHeight, minDepth, maxDepth, minPrice, maxPrice, fireResistance, inStock, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.productVariationsSearchPostsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  指定したIDの製品を取得します
         * @summary 製品を取得
         * @param {number} id Product ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsIdGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.productsIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary 製品の風景写真一覧
         * @param {number} id Product ID
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [cursor] 取得カーソル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsIdPostsGet(id: number, limit?: number, cursor?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsIdPostsGet(id, limit, cursor, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.productsIdPostsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  指定したIDのプロダクトが持つサンプル商品のリストを取得します
         * @summary サンプル商品を取得
         * @param {number} id Product ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsIdSampleItemsGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SampleItemEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsIdSampleItemsGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.productsIdSampleItemsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary 自分がフォローしているユーザーのプロジェクト一覧
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [cursor] 取得カーソル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsFollowingGet(limit?: number, cursor?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsFollowingGet(limit, cursor, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.projectsFollowingGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary プロジェクトの添付ファイルを削除する
         * @param {number} id Project ID
         * @param {number} attachmentId attachment ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsIdAttachmentsAttachmentIdDelete(id: number, attachmentId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsIdAttachmentsAttachmentIdDelete(id, attachmentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.projectsIdAttachmentsAttachmentIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary 追加資料を並べ替える
         * @param {number} id Project ID
         * @param {number} attachmentId attachment ID
         * @param {OrderParameter} orderParameter 並び替えパラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsIdAttachmentsAttachmentIdOrderPatch(id: number, attachmentId: number, orderParameter: OrderParameter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsIdAttachmentsAttachmentIdOrderPatch(id, attachmentId, orderParameter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.projectsIdAttachmentsAttachmentIdOrderPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary プロジェクトの添付ファイルを更新する
         * @param {number} id Project ID
         * @param {number} attachmentId attachment ID
         * @param {string} title attachment title
         * @param {ProjectAttachmentType} [attachmentType] 
         * @param {File} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsIdAttachmentsAttachmentIdPut(id: number, attachmentId: number, title: string, attachmentType?: ProjectAttachmentType, image?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectAttachment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsIdAttachmentsAttachmentIdPut(id, attachmentId, title, attachmentType, image, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.projectsIdAttachmentsAttachmentIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary プロジェクトに添付ファイルを追加する
         * @param {number} id Project ID
         * @param {string} title attachment title
         * @param {File} image 
         * @param {ProjectAttachmentType} [attachmentType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsIdAttachmentsPost(id: number, title: string, image: File, attachmentType?: ProjectAttachmentType, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectAttachment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsIdAttachmentsPost(id, title, image, attachmentType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.projectsIdAttachmentsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  指定したIDのプロジェクトを削除します
         * @summary プロジェクトを削除
         * @param {number} id Project ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsIdDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.projectsIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  指定したIDのプロジェクトを取得します
         * @summary プロジェクトを取得
         * @param {number} id Project ID
         * @param {string} [token] 非公開プロジェクトを閲覧するための共有トークン
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsIdGet(id: number, token?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsIdGet(id, token, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.projectsIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  プロジェクトに紐づくピンをすべて返します。重複しているアイテムはまとめます。
         * @summary プロジェクトに紐づくピンをすべて返す
         * @param {number} id Project ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsIdPinsGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PostPinResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsIdPinsGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.projectsIdPinsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  プロジェクトに紐づく風景写真の一覧を取得します
         * @summary プロジェクトに紐づく風景写真一覧
         * @param {number} id Project ID
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [cursor] 取得カーソル
         * @param {string} [token] 非公開プロジェクトを閲覧するための共有トークン
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsIdPostsGet(id: number, limit?: number, cursor?: number, token?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsIdPostsGet(id, limit, cursor, token, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.projectsIdPostsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  プロジェクトに風景写真を追加します
         * @summary プロジェクトに風景写真を追加する
         * @param {number} id Project ID
         * @param {PostCreateParam} postCreateParam 事例写真投稿パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsIdPostsPost(id: number, postCreateParam: PostCreateParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsIdPostsPost(id, postCreateParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.projectsIdPostsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  指定したIDのプロジェクトを公開します
         * @summary 指定したIDのプロジェクトを公開する
         * @param {number} id Project ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsIdPublishPost(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsIdPublishPost(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.projectsIdPublishPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  指定したIDのプロジェクトを更新します  ## validation  - title: 必須, 255文字以内 - description: 必須, 文字列 - building_type_id: 必須, 数値 - design: 任意, 255文字以内 - staff: 任意, 255文字以内 - structural_design: 任意, 255文字以内 - construction: 任意, 255文字以内 - structure_type: 任意, 数値 - completion: 任意, 年月 YYYY-mm - floor_space: 任意, 数値 - construction_type: 任意, 数値 - photograph: 任意, 255文字以内 - location: 任意 - location.lat: 必須, 数値 - location.lng: 必須, 数値
         * @summary プロジェクトを更新
         * @param {number} id Project ID
         * @param {ProjectUpdateParam} projectUpdateParam プロジェクト更新パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsIdPut(id: number, projectUpdateParam: ProjectUpdateParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsIdPut(id, projectUpdateParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.projectsIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  非公開プロジェクトの共有用URLに必要なトークンを生成します
         * @summary プロジェクトの共有用トークンを生成する
         * @param {number} id プロジェクトID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsIdSharePost(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectShareTokenEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsIdSharePost(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.projectsIdSharePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  指定したIDのプロジェクトを非公開にします
         * @summary 指定したIDのプロジェクトを非公開にする
         * @param {number} id Project ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsIdUnpublishPost(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsIdUnpublishPost(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.projectsIdUnpublishPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  プロジェクトを投稿します
         * @summary プロジェクト投稿
         * @param {ProjectCreateParam} projectCreateParam プロジェクト投稿パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsPost(projectCreateParam: ProjectCreateParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsPost(projectCreateParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.projectsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  プロジェクトの風景写真を削除します
         * @summary プロジェクトの風景写真を削除
         * @param {number} projectId Project ID
         * @param {number} id Post ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsProjectIdPostsIdDelete(projectId: number, id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsProjectIdPostsIdDelete(projectId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.projectsProjectIdPostsIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  プロジェクトの風景写真のposition値を変更します  ## logic - positionは前後のPost.positionの中間値を投げる
         * @summary プロジェクトの風景写真を並べ替える
         * @param {number} projectId Project ID
         * @param {number} id Post ID
         * @param {OrderParameter} orderParameter 並び替えパラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsProjectIdPostsIdOrderPatch(projectId: number, id: number, orderParameter: OrderParameter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsProjectIdPostsIdOrderPatch(projectId, id, orderParameter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.projectsProjectIdPostsIdOrderPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  プロジェクトの風景写真を編集します  ## logic - positionは前後のPost.positionの中間値を投げる
         * @summary プロジェクトの風景写真を編集する
         * @param {number} projectId Project ID
         * @param {number} id Post ID
         * @param {PostUpdateParam} postUpdateParam 事例写真更新パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsProjectIdPostsIdPut(projectId: number, id: number, postUpdateParam: PostUpdateParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsProjectIdPostsIdPut(projectId, id, postUpdateParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.projectsProjectIdPostsIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary ID指定プロジェクト一覧
         * @param {string} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsSearchByIDsGet(ids: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectWithProductVariationsResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsSearchByIDsGet(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.projectsSearchByIDsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  検索条件にマッチしたプロジェクトの一覧を取得します 並び順はElasticsearchのマッチ率順になるので、cursor形式のページネーションは使えず、 limit, offset形式になります
         * @summary プロジェクトの検索
         * @param {string} [keyword] 検索キーワード
         * @param {number} [buildingTypeId] building_typeのID
         * @param {number} [structureTypeId] 構造種別のID
         * @param {ProjectsSearchGetConstructionTypeEnum} [constructionType] 工事種別
         * @param {number} [minFloorSpace] 最小床面積
         * @param {number} [maxFloorSpace] 最大床面積
         * @param {number} [materialId] 素材ID
         * @param {Array<ProjectsSearchGetColorTypeIdsEnum>} [colorTypeIds] カラーIDの配列
         * @param {Array<ProjectsSearchGetPartIdsEnum>} [partIds] 使用箇所の配列
         * @param {number} [useTypeId] 用途のID
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [offset] ページ取得位置(default: 0)
         * @param {ProjectsSearchGetSortEnum} [sort] ソート条件
         * @param {number} [excludeProjectId] 除外するProjectID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsSearchGet(keyword?: string, buildingTypeId?: number, structureTypeId?: number, constructionType?: ProjectsSearchGetConstructionTypeEnum, minFloorSpace?: number, maxFloorSpace?: number, materialId?: number, colorTypeIds?: Array<ProjectsSearchGetColorTypeIdsEnum>, partIds?: Array<ProjectsSearchGetPartIdsEnum>, useTypeId?: number, limit?: number, offset?: number, sort?: ProjectsSearchGetSortEnum, excludeProjectId?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsSearchGet(keyword, buildingTypeId, structureTypeId, constructionType, minFloorSpace, maxFloorSpace, materialId, colorTypeIds, partIds, useTypeId, limit, offset, sort, excludeProjectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.projectsSearchGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary プロジェクトの検索のヒット数
         * @param {string} [keyword] 検索キーワード
         * @param {number} [buildingTypeId] building_typeのID
         * @param {number} [structureTypeId] 構造種別のID
         * @param {ProjectsSearchHitCountsGetConstructionTypeEnum} [constructionType] 工事種別
         * @param {number} [minFloorSpace] 最小床面積
         * @param {number} [maxFloorSpace] 最大床面積
         * @param {number} [materialId] 素材ID
         * @param {Array<ProjectsSearchHitCountsGetColorTypeIdsEnum>} [colorTypeIds] カラーIDの配列
         * @param {Array<ProjectsSearchHitCountsGetPartIdsEnum>} [partIds] 使用箇所の配列
         * @param {number} [useTypeId] 用途のID
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [offset] ページ取得位置(default: 0)
         * @param {ProjectsSearchHitCountsGetSortEnum} [sort] ソート条件
         * @param {number} [excludeProjectId] 除外するProjectID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsSearchHitCountsGet(keyword?: string, buildingTypeId?: number, structureTypeId?: number, constructionType?: ProjectsSearchHitCountsGetConstructionTypeEnum, minFloorSpace?: number, maxFloorSpace?: number, materialId?: number, colorTypeIds?: Array<ProjectsSearchHitCountsGetColorTypeIdsEnum>, partIds?: Array<ProjectsSearchHitCountsGetPartIdsEnum>, useTypeId?: number, limit?: number, offset?: number, sort?: ProjectsSearchHitCountsGetSortEnum, excludeProjectId?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectsSearchHitCountsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsSearchHitCountsGet(keyword, buildingTypeId, structureTypeId, constructionType, minFloorSpace, maxFloorSpace, materialId, colorTypeIds, partIds, useTypeId, limit, offset, sort, excludeProjectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.projectsSearchHitCountsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary 資格のマスターデータを取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async qualificationsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<QualificationEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.qualificationsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.qualificationsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary キーワード検索を行う
         * @param {string} keyword 検索キーワード
         * @param {number} [limit] 取得件数(default: 8, max: 8)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchGet(keyword: string, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchIndexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchGet(keyword, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.searchGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary アカウント情報を変更
         * @param {SettingsAccountPostRequest} settingsAccountPostRequest アカウント設定パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingsAccountPost(settingsAccountPostRequest: SettingsAccountPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingsAccountPost(settingsAccountPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.settingsAccountPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary メルマガ設定を変更
         * @param {MailMagazineUpdateParam} mailMagazineUpdateParam メルマガ設定パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingsMailMagazinePost(mailMagazineUpdateParam: MailMagazineUpdateParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingsMailMagazinePost(mailMagazineUpdateParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.settingsMailMagazinePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary 通知のON/OFFを設定
         * @param {NotificationSettingsUpdateParameter} notificationSettingsUpdateParameter 通知設定パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingsNotificationPost(notificationSettingsUpdateParameter: NotificationSettingsUpdateParameter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingsNotificationPost(notificationSettingsUpdateParameter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.settingsNotificationPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary プロフィール情報を変更
         * @param {UserProfileUpdateParam} userProfileUpdateParam プロフィール設定パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingsProfilePost(userProfileUpdateParam: UserProfileUpdateParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingsProfilePost(userProfileUpdateParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.settingsProfilePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  現在時刻から次の配送予定日を取得します。
         * @summary 次の配送予定日を取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shipmentsNextDeliveryDateGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NextDeliveryDateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shipmentsNextDeliveryDateGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.shipmentsNextDeliveryDateGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  SuggestKeywordの一覧を取得します
         * @summary SuggestKeyword取得API
         * @param {string} [keyword] 検索キーワード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async suggestKeywordsGet(keyword?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SuggestKeywordResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.suggestKeywordsGet(keyword, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.suggestKeywordsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  指定したIDのチームへの参加申請を許可します。
         * @summary 参加申請を許可
         * @param {number} id Team ID
         * @param {OneTimeTokenParameter} oneTimeTokenParameter tokenパラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teamsIdApproveJoinPost(id: number, oneTimeTokenParameter: OneTimeTokenParameter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamJoinRequestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teamsIdApproveJoinPost(id, oneTimeTokenParameter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.teamsIdApproveJoinPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  チームを削除します。
         * @summary チーム削除
         * @param {number} id Team ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teamsIdDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teamsIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.teamsIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  チームを取得します。
         * @summary チーム詳細取得
         * @param {number} id Team ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teamsIdGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teamsIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.teamsIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  チームメンバーを招待します。
         * @summary チームメンバー招待
         * @param {number} id Team ID
         * @param {TeamMemberInviteParameter} teamMemberInviteParameter チームメンバー招待パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teamsIdInvitePost(id: number, teamMemberInviteParameter: TeamMemberInviteParameter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teamsIdInvitePost(id, teamMemberInviteParameter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.teamsIdInvitePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  チームに参加します
         * @summary チームに参加
         * @param {number} id Team ID
         * @param {OneTimeTokenParameter} oneTimeTokenParameter tokenパラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teamsIdJoinPost(id: number, oneTimeTokenParameter: OneTimeTokenParameter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teamsIdJoinPost(id, oneTimeTokenParameter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.teamsIdJoinPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  チームメンバーを一括で取得します。
         * @summary チームメンバー取得
         * @param {number} id Team ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teamsIdMembersGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TeamMemberResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teamsIdMembersGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.teamsIdMembersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  チームのプロジェクトを取得します。
         * @summary チームのプロジェクト取得
         * @param {number} id Team ID
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [page] ページ(default: 1)
         * @param {number} [excludeProjectId] 除外するProjectID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teamsIdProjectsGet(id: number, limit?: number, page?: number, excludeProjectId?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teamsIdProjectsGet(id, limit, page, excludeProjectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.teamsIdProjectsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  チームを更新します。
         * @summary チーム更新
         * @param {number} id Team ID
         * @param {TeamUpdateParam} teamUpdateParam チーム更新パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teamsIdPut(id: number, teamUpdateParam: TeamUpdateParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teamsIdPut(id, teamUpdateParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.teamsIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  指定したIDのチームへの参加をリクエストします。
         * @summary チームへの参加をリクエスト
         * @param {number} id Team ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teamsIdRequestJoinPost(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teamsIdRequestJoinPost(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.teamsIdRequestJoinPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  チームメンバー招待のTokenを確認します。
         * @summary チームメンバー招待のToken確認
         * @param {number} id Team ID
         * @param {OneTimeTokenParameter} oneTimeTokenParameter tokenパラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teamsIdVerifyInvitationPost(id: number, oneTimeTokenParameter: OneTimeTokenParameter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teamsIdVerifyInvitationPost(id, oneTimeTokenParameter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.teamsIdVerifyInvitationPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  チームから離脱します
         * @summary チームから離脱
         * @param {number} id Team ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teamsIdWithdrawalPost(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teamsIdWithdrawalPost(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.teamsIdWithdrawalPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  チームを新規登録します。
         * @summary チーム新規登録
         * @param {TeamCreateParam} teamCreateParam チーム登録パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teamsPost(teamCreateParam: TeamCreateParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teamsPost(teamCreateParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.teamsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary ID指定チーム一覧
         * @param {string} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teamsSearchByIDsGet(ids: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TeamResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teamsSearchByIDsGet(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.teamsSearchByIDsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary チームの検索
         * @param {string} keyword 検索キーワード
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [offset] 取得開始位置(default: 0)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teamsSearchGet(keyword: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teamsSearchGet(keyword, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.teamsSearchGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  チームメンバーを削除します。
         * @summary チームメンバー削除
         * @param {number} teamId Team ID
         * @param {number} id ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teamsTeamIdMembersIdDelete(teamId: number, id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teamsTeamIdMembersIdDelete(teamId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.teamsTeamIdMembersIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  チームメンバーを更新します。
         * @summary チームメンバー更新
         * @param {number} teamId Team ID
         * @param {number} id ID
         * @param {TeamMemberPutParameter} teamMemberPutParameter チームメンバー更新パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teamsTeamIdMembersIdPut(teamId: number, id: number, teamMemberPutParameter: TeamMemberPutParameter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamMemberResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teamsTeamIdMembersIdPut(teamId, id, teamMemberPutParameter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.teamsTeamIdMembersIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary TOPページの設定を取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async topSettingsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TopSettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.topSettingsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.topSettingsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  すべての用途の一覧を取得します
         * @summary すべての用途一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async useTypesGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UseTypeEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.useTypesGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.useTypesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  ユーザー属性の一覧を取得します
         * @summary ユーザー属性取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userAttributesGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAttributesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userAttributesGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.userAttributesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  指定したIDのユーザーをフォローしているユーザーの一覧を取得します
         * @summary 指定したIDのユーザーをフォローしているユーザーの一覧
         * @param {number} id User ID
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [cursor] 取得カーソル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersIdFollowersGet(id: number, limit?: number, cursor?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersIdFollowersGet(id, limit, cursor, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.usersIdFollowersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  指定したIDのユーザーがフォローしているユーザーの一覧を取得します
         * @summary 指定したIDのユーザーがフォローしているユーザーの一覧
         * @param {number} id User ID
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [cursor] 取得カーソル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersIdFollowingGet(id: number, limit?: number, cursor?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersIdFollowingGet(id, limit, cursor, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.usersIdFollowingGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  指定したIDのユーザーをフォロー数も含めて取得します
         * @summary 指定したIDのユーザーを取得
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersIdGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.usersIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  指定したIDのユーザーがLikeしたプロジェクトの一覧を取得します
         * @summary 指定したIDのユーザーがLikeしたプロジェクトの一覧
         * @param {number} id User ID
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [cursor] 取得カーソル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersIdLikesGet(id: number, limit?: number, cursor?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LikesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersIdLikesGet(id, limit, cursor, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.usersIdLikesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  指定したIDのユーザーが作成したリストの一覧を取得します。   非公開のリストは、自分自身の場合以外はレスポンスに含まれません。
         * @summary 指定したIDのユーザーが作成したリストの一覧
         * @param {number} id User ID
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {string} [cursor] 取得カーソル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersIdListsGet(id: number, limit?: number, cursor?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersIdListsGet(id, limit, cursor, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.usersIdListsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  指定したIDのユーザーが投稿した風景写真の一覧を取得します
         * @summary 指定したIDのユーザーが投稿した風景写真の一覧
         * @param {number} id User ID
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [cursor] 取得カーソル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersIdPostsGet(id: number, limit?: number, cursor?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersIdPostsGet(id, limit, cursor, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.usersIdPostsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  指定したIDのユーザーが投稿したプロジェクトの一覧を取得します
         * @summary 指定したIDのユーザーが投稿したプロジェクトの一覧
         * @param {number} id User ID
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [page] ページ(default: 1)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersIdProjectsGet(id: number, limit?: number, page?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersIdProjectsGet(id, limit, page, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.usersIdProjectsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  指定したIDのユーザーのチームを取得します
         * @summary 指定したIDのユーザーのチームを取得
         * @param {number} id User ID
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {string} [cursor] 取得カーソル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersIdTeamsGet(id: number, limit?: number, cursor?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamMembersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersIdTeamsGet(id, limit, cursor, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.usersIdTeamsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary 設計者アカウントへの変更
         * @param {DesignerAccountUpdateParam} designerAccountUpdateParam アカウント設定パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersMeChangeDesignerAccountPost(designerAccountUpdateParam: DesignerAccountUpdateParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersMeChangeDesignerAccountPost(designerAccountUpdateParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.usersMeChangeDesignerAccountPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  ログインしているユーザーをフォロー数も含めて取得します
         * @summary ログインユーザーを取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersMeGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersMeGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.usersMeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  ログインしているユーザーが作成したリスト一覧を取得します
         * @summary ログインユーザーのリスト一覧
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {string} [cursor] 取得カーソル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersMeListsGet(limit?: number, cursor?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersMeListsGet(limit, cursor, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.usersMeListsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary ログインユーザーのプロジェクト一覧
         * @param {number} [limit] 1ページあたりの表示件数(default: 20)
         * @param {number} [page] ページ(default: 1)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersMeProjectsGet(limit?: number, page?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersMeProjectsGet(limit, page, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.usersMeProjectsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  一般ユーザーを新規登録します。
         * @summary 一般ユーザー新規登録
         * @param {UserCreateParam} userCreateParam ユーザー登録パラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersPost(userCreateParam: UserCreateParam, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersPost(userCreateParam, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.usersPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ## summary  指定した郵便番号の住所を取得します
         * @summary 指定した郵便番号の住所を取得
         * @param {string} zipCode 郵便番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zipCodesZipCodeGet(zipCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ZipCodeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zipCodesZipCodeGet(zipCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.zipCodesZipCodeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary ログインユーザーの住所を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressesGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<AddressEntity>> {
            return localVarFp.addressesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  住所を削除します。
         * @summary 住所削除
         * @param {DefaultApiAddressesIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressesIdDelete(requestParameters: DefaultApiAddressesIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.addressesIdDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ログインユーザーの住所を更新する
         * @param {DefaultApiAddressesIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressesIdPut(requestParameters: DefaultApiAddressesIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<AddressEntity> {
            return localVarFp.addressesIdPut(requestParameters.id, requestParameters.addressUpsertParameter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ログインユーザーの住所を登録する
         * @param {DefaultApiAddressesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressesPost(requestParameters: DefaultApiAddressesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<AddressEntity> {
            return localVarFp.addressesPost(requestParameters.addressUpsertParameter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 現在開催中のAward取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        awardsCurrentGet(options?: RawAxiosRequestConfig): AxiosPromise<AwardResponse> {
            return localVarFp.awardsCurrentGet(options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  指定されたアワードのカテゴリ一覧を取得します
         * @summary 特定のアワードのカテゴリリストを取得する
         * @param {DefaultApiAwardsSlugCategoriesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        awardsSlugCategoriesGet(requestParameters: DefaultApiAwardsSlugCategoriesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<AwardCategoryEntity>> {
            return localVarFp.awardsSlugCategoriesGet(requestParameters.slug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Award応募作品への投票
         * @param {DefaultApiAwardsSlugEntriesEntryIdVotesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        awardsSlugEntriesEntryIdVotesPost(requestParameters: DefaultApiAwardsSlugEntriesEntryIdVotesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.awardsSlugEntriesEntryIdVotesPost(requestParameters.slug, requestParameters.entryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Awardへの応募作品の取得
         * @param {DefaultApiAwardsSlugEntriesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        awardsSlugEntriesGet(requestParameters: DefaultApiAwardsSlugEntriesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<AwardEntriesResponse> {
            return localVarFp.awardsSlugEntriesGet(requestParameters.slug, requestParameters.awardCategoryId, requestParameters.sort, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Awardへの作品応募
         * @param {DefaultApiAwardsSlugEntriesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        awardsSlugEntriesPost(requestParameters: DefaultApiAwardsSlugEntriesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.awardsSlugEntriesPost(requestParameters.slug, requestParameters.awardEntryCreateParameter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 現在開催中のAward取得
         * @param {DefaultApiAwardsSlugGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        awardsSlugGet(requestParameters: DefaultApiAwardsSlugGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<AwardResponse> {
            return localVarFp.awardsSlugGet(requestParameters.slug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 投票済みの応募作品ID一覧取得
         * @param {DefaultApiAwardsSlugVotedEntryIdsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        awardsSlugVotedEntryIdsGet(requestParameters: DefaultApiAwardsSlugVotedEntryIdsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<number>> {
            return localVarFp.awardsSlugVotedEntryIdsGet(requestParameters.slug, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  ボードの詳細を取得します。
         * @summary ボードの詳細を取得
         * @param {DefaultApiBoardsIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        boardsIdGet(requestParameters: DefaultApiBoardsIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<BoardDetailResponse> {
            return localVarFp.boardsIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  ボードに紐づくプロダクトバリエーションから事例写真を取得します
         * @summary ボードの事例写真を取得
         * @param {DefaultApiBoardsIdPostsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        boardsIdPostsGet(requestParameters: DefaultApiBoardsIdPostsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PostsResponse> {
            return localVarFp.boardsIdPostsGet(requestParameters.id, requestParameters.limit, requestParameters.page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ID指定ボード一覧
         * @param {DefaultApiBoardsSearchByIDsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        boardsSearchByIDsGet(requestParameters: DefaultApiBoardsSearchByIDsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<BoardResponse>> {
            return localVarFp.boardsSearchByIDsGet(requestParameters.ids, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  ボードの一覧を取得します。
         * @summary ボードの検索
         * @param {DefaultApiBoardsSearchGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        boardsSearchGet(requestParameters: DefaultApiBoardsSearchGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<BoardsResponse> {
            return localVarFp.boardsSearchGet(requestParameters.keyword, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  指定したIDのブランドを取得します
         * @summary 指定したIDのブランドを取得
         * @param {DefaultApiBrandsIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandsIdGet(requestParameters: DefaultApiBrandsIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<BrandWithCategoriesResponse> {
            return localVarFp.brandsIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  指定したIDのブランドの検索条件にマッチした製品が紐づく事例写真を返します 並び順はElasticsearchのマッチ率順になるので、cursor形式のページネーションは使えず、 limit, offset形式になります
         * @summary 指定したIDのブランドの製品が紐づく事例を取得
         * @param {DefaultApiBrandsIdPostsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandsIdPostsGet(requestParameters: DefaultApiBrandsIdPostsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PostsResponse> {
            return localVarFp.brandsIdPostsGet(requestParameters.id, requestParameters.keyword, requestParameters.kind, requestParameters.categoryId, requestParameters.colorTypeIds, requestParameters.fireProtectionMaterial, requestParameters.flamePreventionRegulation, requestParameters.formaldehyde, requestParameters.materialId, requestParameters.minWidth, requestParameters.maxWidth, requestParameters.minHeight, requestParameters.maxHeight, requestParameters.minDepth, requestParameters.maxDepth, requestParameters.minPrice, requestParameters.maxPrice, requestParameters.fireResistance, requestParameters.inStock, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  指定したIDのブランドの検索条件にマッチした商品バリエーションの一覧を取得します 並び順はElasticsearchのマッチ率順になるので、cursor形式のページネーションは使えず、 limit, offset形式になります 1つのプロダクトに付き、1つのバリエーションを返します
         * @summary 指定したIDのブランドの製品バリエーションを取得
         * @param {DefaultApiBrandsIdProductVariationsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandsIdProductVariationsGet(requestParameters: DefaultApiBrandsIdProductVariationsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProductVariationsResponse> {
            return localVarFp.brandsIdProductVariationsGet(requestParameters.id, requestParameters.keyword, requestParameters.kind, requestParameters.categoryId, requestParameters.colorTypeIds, requestParameters.fireProtectionMaterial, requestParameters.flamePreventionRegulation, requestParameters.formaldehyde, requestParameters.materialId, requestParameters.minWidth, requestParameters.maxWidth, requestParameters.minHeight, requestParameters.maxHeight, requestParameters.minDepth, requestParameters.maxDepth, requestParameters.minPrice, requestParameters.maxPrice, requestParameters.fireResistance, requestParameters.inStock, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  ビルディングタイプの一覧を取得します
         * @summary ビルディングタイプ取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildingTypesGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<BuildingTypeResponse>> {
            return localVarFp.buildingTypesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  ビルディングタイプに紐づく用途の一覧を取得します
         * @summary ビルディングタイプに紐づく用途一覧
         * @param {DefaultApiBuildingTypesIdUseTypesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildingTypesIdUseTypesGet(requestParameters: DefaultApiBuildingTypesIdUseTypesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<UseTypeEntity>> {
            return localVarFp.buildingTypesIdUseTypesGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  指定したIDのカタログ情報を返します
         * @summary 指定したIDのカタログを取得
         * @param {DefaultApiCatalogsIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogsIdGet(requestParameters: DefaultApiCatalogsIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<CatalogResponse> {
            return localVarFp.catalogsIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  指定したIDのカタログのページ情報を返します
         * @summary 指定したIDのカタログのページ情報を取得
         * @param {DefaultApiCatalogsIdPagesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogsIdPagesGet(requestParameters: DefaultApiCatalogsIdPagesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<CatalogPageResponse>> {
            return localVarFp.catalogsIdPagesGet(requestParameters.id, requestParameters.fromPage, requestParameters.toPage, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  カテゴリの一覧を取得します
         * @summary カテゴリの一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<CategoryResponse>> {
            return localVarFp.categoriesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  カテゴリの詳細検索項目を取得します
         * @summary カテゴリの詳細検索項目
         * @param {DefaultApiCategoriesIdSearchConditionGroupsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesIdSearchConditionGroupsGet(requestParameters: DefaultApiCategoriesIdSearchConditionGroupsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<SearchConditionGroupResponse>> {
            return localVarFp.categoriesIdSearchConditionGroupsGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  設計者アカウントを新規登録します。
         * @summary 設計者アカウント新規登録
         * @param {DefaultApiDesignersPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        designersPost(requestParameters: DefaultApiDesignersPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<MyUserResponse> {
            return localVarFp.designersPost(requestParameters.designerCreateParam, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  ユーザーをフォローします  ## validation  - user_id: 必須
         * @summary ユーザーをフォローする
         * @param {DefaultApiFollowsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        followsPost(requestParameters: DefaultApiFollowsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.followsPost(requestParameters.followCreateParameter, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  ユーザーをアンフォローします
         * @summary ユーザーをアンフォローする
         * @param {DefaultApiFollowsUserIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        followsUserIdDelete(requestParameters: DefaultApiFollowsUserIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.followsUserIdDelete(requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  画像をアップロードします
         * @summary 画像アップロード
         * @param {DefaultApiImagesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesPost(requestParameters: DefaultApiImagesPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<UploadImageResponse> {
            return localVarFp.imagesPost(requestParameters.image, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  指定したEntityのLikeを削除します
         * @summary Likeを削除する
         * @param {DefaultApiLikesDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        likesDelete(requestParameters: DefaultApiLikesDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.likesDelete(requestParameters.likeDeleteParam, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  指定したEntityをLikeします
         * @summary Likeする
         * @param {DefaultApiLikesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        likesPost(requestParameters: DefaultApiLikesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.likesPost(requestParameters.likeCreateParam, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  リストを削除します。
         * @summary リストを削除
         * @param {DefaultApiListsIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listsIdDelete(requestParameters: DefaultApiListsIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.listsIdDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  指定したIDのリストを取得します。   対象のリストが非公開だった場合、本人でない場合は403を返します。
         * @summary リストを取得
         * @param {DefaultApiListsIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listsIdGet(requestParameters: DefaultApiListsIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ListResponse> {
            return localVarFp.listsIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  指定したIDのリストに追加されているアイテムの一覧を取得します。   対象のリストが非公開だった場合、本人でない場合は403を返します。
         * @summary リストのアイテムを取得
         * @param {DefaultApiListsIdItemsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listsIdItemsGet(requestParameters: DefaultApiListsIdItemsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ListItemsResponse> {
            return localVarFp.listsIdItemsGet(requestParameters.id, requestParameters.kind, requestParameters.limit, requestParameters.cursor, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  指定したIDのリストに、アイテムを追加します。  ## logic  * すでにリストに追加済みの場合は400を返します。
         * @summary リストにアイテムを追加
         * @param {DefaultApiListsIdItemsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listsIdItemsPost(requestParameters: DefaultApiListsIdItemsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.listsIdItemsPost(requestParameters.id, requestParameters.listAddItemParameter, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  リストを更新します。  ## validation  - name: 必須, 255文字以内 - is_praivate: 必須
         * @summary リストを更新
         * @param {DefaultApiListsIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listsIdPut(requestParameters: DefaultApiListsIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<ListResponse> {
            return localVarFp.listsIdPut(requestParameters.id, requestParameters.listUpdateParam, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  指定したIDのリストから、アイテムを削除します。
         * @summary リストからアイテムを削除
         * @param {DefaultApiListsListIdItemsItemIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listsListIdItemsItemIdDelete(requestParameters: DefaultApiListsListIdItemsItemIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.listsListIdItemsItemIdDelete(requestParameters.listId, requestParameters.itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  リストを作成します。  ## validation  - name: 必須, 255文字以内 - is_praivate: 必須
         * @summary リストの作成
         * @param {DefaultApiListsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listsPost(requestParameters: DefaultApiListsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ListResponse> {
            return localVarFp.listsPost(requestParameters.listCreateParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ID指定リスト一覧
         * @param {DefaultApiListsSearchByIDsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listsSearchByIDsGet(requestParameters: DefaultApiListsSearchByIDsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<ListResponse>> {
            return localVarFp.listsSearchByIDsGet(requestParameters.ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary リストの検索
         * @param {DefaultApiListsSearchGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listsSearchGet(requestParameters: DefaultApiListsSearchGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListsResponse> {
            return localVarFp.listsSearchGet(requestParameters.keyword, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 記事を新着順で取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        magazinesGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<MagazineResponse>> {
            return localVarFp.magazinesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary タグに紐づく記事を取得する
         * @param {DefaultApiMagazinesTagsSlugGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        magazinesTagsSlugGet(requestParameters: DefaultApiMagazinesTagsSlugGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<MagazineResponse>> {
            return localVarFp.magazinesTagsSlugGet(requestParameters.slug, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  すべてのメーカーを取得します
         * @summary すべてのメーカーを取得
         * @param {DefaultApiMakersAllGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makersAllGet(requestParameters: DefaultApiMakersAllGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<InitialGroupMakersResponse>> {
            return localVarFp.makersAllGet(requestParameters.availableSampleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  指定したIDのメーカーのカタログを取得します
         * @summary 指定したIDのメーカーのカタログを取得
         * @param {DefaultApiMakersIdCatalogsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makersIdCatalogsGet(requestParameters: DefaultApiMakersIdCatalogsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<CatalogsResponse> {
            return localVarFp.makersIdCatalogsGet(requestParameters.id, requestParameters.limit, requestParameters.cursor, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  指定したIDのメーカーを取得します
         * @summary 指定したIDのメーカーをカテゴリも含めて取得
         * @param {DefaultApiMakersIdCategoriesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makersIdCategoriesGet(requestParameters: DefaultApiMakersIdCategoriesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<MakerWithCategoriesResponse> {
            return localVarFp.makersIdCategoriesGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  指定したIDのメーカーに対して問い合わせを行う
         * @summary メーカー問い合わせ
         * @param {DefaultApiMakersIdContactsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makersIdContactsPost(requestParameters: DefaultApiMakersIdContactsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<MakerContactResponse> {
            return localVarFp.makersIdContactsPost(requestParameters.id, requestParameters.makerContactCreateParam, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  指定したIDのメーカーを取得します
         * @summary 指定したIDのメーカーを取得
         * @param {DefaultApiMakersIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makersIdGet(requestParameters: DefaultApiMakersIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<MakerIndexResponse> {
            return localVarFp.makersIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 指定したIDのメーカーのリストを取得
         * @param {DefaultApiMakersIdListsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makersIdListsGet(requestParameters: DefaultApiMakersIdListsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ListsResponse> {
            return localVarFp.makersIdListsGet(requestParameters.id, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  指定したIDのメーカーの検索条件にマッチした製品が紐づく事例写真を返します 並び順はElasticsearchのマッチ率順になるので、cursor形式のページネーションは使えず、 limit, offset形式になります
         * @summary 指定したIDのメーカーの製品が紐づく事例を取得
         * @param {DefaultApiMakersIdPostsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makersIdPostsGet(requestParameters: DefaultApiMakersIdPostsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PostsResponse> {
            return localVarFp.makersIdPostsGet(requestParameters.id, requestParameters.keyword, requestParameters.kind, requestParameters.categoryId, requestParameters.colorTypeIds, requestParameters.fireProtectionMaterial, requestParameters.flamePreventionRegulation, requestParameters.formaldehyde, requestParameters.materialId, requestParameters.minWidth, requestParameters.maxWidth, requestParameters.minHeight, requestParameters.maxHeight, requestParameters.minDepth, requestParameters.maxDepth, requestParameters.minPrice, requestParameters.maxPrice, requestParameters.fireResistance, requestParameters.inStock, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  指定したIDのメーカーの検索条件にマッチした商品バリエーションの一覧を取得します 並び順はElasticsearchのマッチ率順になるので、cursor形式のページネーションは使えず、 limit, offset形式になります 1つのプロダクトに付き、1つのバリエーションを返します
         * @summary 指定したIDのメーカーの製品バリエーションを取得
         * @param {DefaultApiMakersIdProductVariationsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makersIdProductVariationsGet(requestParameters: DefaultApiMakersIdProductVariationsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProductVariationsResponse> {
            return localVarFp.makersIdProductVariationsGet(requestParameters.id, requestParameters.keyword, requestParameters.kind, requestParameters.categoryId, requestParameters.colorTypeIds, requestParameters.fireProtectionMaterial, requestParameters.flamePreventionRegulation, requestParameters.formaldehyde, requestParameters.materialId, requestParameters.minWidth, requestParameters.maxWidth, requestParameters.minHeight, requestParameters.maxHeight, requestParameters.minDepth, requestParameters.maxDepth, requestParameters.minPrice, requestParameters.maxPrice, requestParameters.fireResistance, requestParameters.inStock, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  指定したIDのメーカーの検索条件にマッチした製品が紐づくプロジェクトを返します 並び順はElasticsearchのマッチ率順になるので、cursor形式のページネーションは使えず、 limit, offset形式になります
         * @summary 指定したIDのメーカーの製品が紐づくプロジェクトを取得
         * @param {DefaultApiMakersIdProjectsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makersIdProjectsGet(requestParameters: DefaultApiMakersIdProjectsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProjectsResponse> {
            return localVarFp.makersIdProjectsGet(requestParameters.id, requestParameters.keyword, requestParameters.kind, requestParameters.categoryId, requestParameters.colorTypeIds, requestParameters.fireProtectionMaterial, requestParameters.flamePreventionRegulation, requestParameters.formaldehyde, requestParameters.materialId, requestParameters.minWidth, requestParameters.maxWidth, requestParameters.minHeight, requestParameters.maxHeight, requestParameters.minDepth, requestParameters.maxDepth, requestParameters.minPrice, requestParameters.maxPrice, requestParameters.fireResistance, requestParameters.inStock, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary メーカーインクリメンタル検索
         * @param {DefaultApiMakersIncrementalSearchGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makersIncrementalSearchGet(requestParameters: DefaultApiMakersIncrementalSearchGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<MakersResponse> {
            return localVarFp.makersIncrementalSearchGet(requestParameters.keyword, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary メーカーの最新とランダムを取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makersLatestAndRandomGet(options?: RawAxiosRequestConfig): AxiosPromise<LatestAndRandomMakersResponse> {
            return localVarFp.makersLatestAndRandomGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary メーカー検索
         * @param {DefaultApiMakersSearchGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makersSearchGet(requestParameters: DefaultApiMakersSearchGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<MakersResponse> {
            return localVarFp.makersSearchGet(requestParameters.keyword, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  マテリアルの一覧を取得します
         * @summary マテリアルの一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialsGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<MaterialResponse>> {
            return localVarFp.materialsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  NEWSの一覧を取得します
         * @summary NEWS取得API
         * @param {DefaultApiNewsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsGet(requestParameters: DefaultApiNewsGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<NewsListResponse> {
            return localVarFp.newsGet(requestParameters.limit, requestParameters.cursor, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Notificationのリスト
         * @param {DefaultApiNotificationsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsGet(requestParameters: DefaultApiNotificationsGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<NotificationsResponse> {
            return localVarFp.notificationsGet(requestParameters.limit, requestParameters.cursor, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 特定の通知のreadフラグを更新する
         * @param {DefaultApiNotificationsIdReadPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsIdReadPost(requestParameters: DefaultApiNotificationsIdReadPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.notificationsIdReadPost(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Notificationのreadフラグをたてる
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsReadPost(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.notificationsReadPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary readしていないteamのrejectのnotificationをすべて取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsUnreadTeamRejectsGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<NotificationResponse>> {
            return localVarFp.notificationsUnreadTeamRejectsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  オーダーグループをキャンセルします。
         * @summary オーダーグループキャンセル
         * @param {DefaultApiOrderGroupsCodeCancelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderGroupsCodeCancelPost(requestParameters: DefaultApiOrderGroupsCodeCancelPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<OrderGroupWithOrderResponse> {
            return localVarFp.orderGroupsCodeCancelPost(requestParameters.code, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  オーダーグループを取得します。
         * @summary オーダーグループ取得
         * @param {DefaultApiOrderGroupsCodeGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderGroupsCodeGet(requestParameters: DefaultApiOrderGroupsCodeGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<OrderGroupWithOrderResponse> {
            return localVarFp.orderGroupsCodeGet(requestParameters.code, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  オーダーグループ一覧を取得します。
         * @summary オーダーグループ一覧取得
         * @param {DefaultApiOrderGroupsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderGroupsGet(requestParameters: DefaultApiOrderGroupsGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<OrderGroupsResponse> {
            return localVarFp.orderGroupsGet(requestParameters.limit, requestParameters.cursor, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  直近3件のリード情報のリスト取得します。
         * @summary 直近のリード情報のリスト取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderQuestionnaireResultsGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<OrderQuestionnaireResultEntity>> {
            return localVarFp.orderQuestionnaireResultsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  オーダーを作成します。
         * @summary オーダー作成
         * @param {DefaultApiOrdersPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersPost(requestParameters: DefaultApiOrdersPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<OrderWithGroupsResponse> {
            return localVarFp.ordersPost(requestParameters.orderCreateParameter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary PICKUPコンテンツを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pickupsGet(options?: RawAxiosRequestConfig): AxiosPromise<PickupResponse> {
            return localVarFp.pickupsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary PICKUPユーザーを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pickupsUsersGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<UserDetailResponse>> {
            return localVarFp.pickupsUsersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  自分がフォローしているユーザーが投稿した風景写真の一覧を取得します
         * @summary 自分がフォローしているユーザーの風景写真一覧
         * @param {DefaultApiPostsFollowingGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postsFollowingGet(requestParameters: DefaultApiPostsFollowingGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PostsResponse> {
            return localVarFp.postsFollowingGet(requestParameters.limit, requestParameters.cursor, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  指定したIDの風景写真を取得します
         * @summary 風景写真を取得
         * @param {DefaultApiPostsIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postsIdGet(requestParameters: DefaultApiPostsIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PostDetailResponse> {
            return localVarFp.postsIdGet(requestParameters.id, requestParameters.token, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  指定したIDの風景写真にひもづく、おすすめの風景写真を取得します
         * @summary 風景写真のおすすめを取得
         * @param {DefaultApiPostsIdRecommendsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postsIdRecommendsGet(requestParameters: DefaultApiPostsIdRecommendsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PostsResponse> {
            return localVarFp.postsIdRecommendsGet(requestParameters.id, requestParameters.limit, requestParameters.page, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  IDを指定して風景写真の一覧を取得します
         * @summary ID指定風景写真一覧
         * @param {DefaultApiPostsSearchByIDsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postsSearchByIDsGet(requestParameters: DefaultApiPostsSearchByIDsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<PostResponse>> {
            return localVarFp.postsSearchByIDsGet(requestParameters.ids, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  検索条件にマッチした風景写真の一覧を取得します 並び順はElasticsearchのマッチ率順になるので、cursor形式のページネーションは使えず、 limit, offset形式になります
         * @summary 風景写真の検索
         * @param {DefaultApiPostsSearchGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postsSearchGet(requestParameters: DefaultApiPostsSearchGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PostsSearchResponse> {
            return localVarFp.postsSearchGet(requestParameters.keyword, requestParameters.buildingTypeId, requestParameters.structureTypeId, requestParameters.constructionType, requestParameters.minFloorSpace, requestParameters.maxFloorSpace, requestParameters.materialId, requestParameters.colorTypeIds, requestParameters.partIds, requestParameters.useTypeId, requestParameters.limit, requestParameters.offset, requestParameters.sort, requestParameters.excludeProjectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 風景写真の検索のヒット数
         * @param {DefaultApiPostsSearchHitCountsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postsSearchHitCountsGet(requestParameters: DefaultApiPostsSearchHitCountsGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PostsSearchHitCountsResponse> {
            return localVarFp.postsSearchHitCountsGet(requestParameters.keyword, requestParameters.buildingTypeId, requestParameters.structureTypeId, requestParameters.constructionType, requestParameters.minFloorSpace, requestParameters.maxFloorSpace, requestParameters.materialId, requestParameters.colorTypeIds, requestParameters.partIds, requestParameters.useTypeId, requestParameters.limit, requestParameters.offset, requestParameters.sort, requestParameters.excludeProjectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary プロダクトバリエーションが紐づいている事例写真の一覧
         * @param {DefaultApiProductVariationsIdPostsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productVariationsIdPostsGet(requestParameters: DefaultApiProductVariationsIdPostsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PostsResponse> {
            return localVarFp.productVariationsIdPostsGet(requestParameters.id, requestParameters.limit, requestParameters.page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 類似製品の検索
         * @param {DefaultApiProductVariationsIdSimilaritiesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productVariationsIdSimilaritiesGet(requestParameters: DefaultApiProductVariationsIdSimilaritiesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<ProductVariationWithProductResponse>> {
            return localVarFp.productVariationsIdSimilaritiesGet(requestParameters.id, requestParameters.axis, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  前後一致で検索します
         * @summary 商品バリエーションのインクリメンタル検索
         * @param {DefaultApiProductVariationsIncrementalSearchGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productVariationsIncrementalSearchGet(requestParameters: DefaultApiProductVariationsIncrementalSearchGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ProductVariationsResponse> {
            return localVarFp.productVariationsIncrementalSearchGet(requestParameters.makerId, requestParameters.keyword, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  対象のProductVariationIDを複数投げ、おすすめのProductVariationを返します
         * @summary 商品バリエーションのおすすめ
         * @param {DefaultApiProductVariationsRecommendationsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productVariationsRecommendationsGet(requestParameters: DefaultApiProductVariationsRecommendationsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<ProductVariationWithProductResponse>> {
            return localVarFp.productVariationsRecommendationsGet(requestParameters.productVariationIds, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  IDを指定してプロダクトバリエーションの一覧を取得します
         * @summary ID指定プロダクトバリエーション一覧
         * @param {DefaultApiProductVariationsSearchByIDsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productVariationsSearchByIDsGet(requestParameters: DefaultApiProductVariationsSearchByIDsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<ProductVariationWithProductResponse>> {
            return localVarFp.productVariationsSearchByIDsGet(requestParameters.ids, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  検索条件にマッチした商品バリエーションの一覧を取得します 並び順はElasticsearchのマッチ率順になるので、cursor形式のページネーションは使えず、 limit, offset形式になります
         * @summary 商品バリエーションの検索
         * @param {DefaultApiProductVariationsSearchGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productVariationsSearchGet(requestParameters: DefaultApiProductVariationsSearchGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ProductVariationsResponse> {
            return localVarFp.productVariationsSearchGet(requestParameters.keyword, requestParameters.kind, requestParameters.categoryId, requestParameters.colorTypeIds, requestParameters.fireProtectionMaterial, requestParameters.flamePreventionRegulation, requestParameters.formaldehyde, requestParameters.materialId, requestParameters.minWidth, requestParameters.maxWidth, requestParameters.minHeight, requestParameters.maxHeight, requestParameters.minDepth, requestParameters.maxDepth, requestParameters.minPrice, requestParameters.maxPrice, requestParameters.fireResistance, requestParameters.inStock, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ProductVariationの検索のヒット数
         * @param {DefaultApiProductVariationsSearchHitCountsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productVariationsSearchHitCountsGet(requestParameters: DefaultApiProductVariationsSearchHitCountsGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ProductVariationsSearchHitCountsResponse> {
            return localVarFp.productVariationsSearchHitCountsGet(requestParameters.keyword, requestParameters.kind, requestParameters.categoryId, requestParameters.colorTypeIds, requestParameters.fireProtectionMaterial, requestParameters.flamePreventionRegulation, requestParameters.formaldehyde, requestParameters.materialId, requestParameters.minWidth, requestParameters.maxWidth, requestParameters.minHeight, requestParameters.maxHeight, requestParameters.minDepth, requestParameters.maxDepth, requestParameters.minPrice, requestParameters.maxPrice, requestParameters.fireResistance, requestParameters.inStock, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  ピンに対しての検索を行っているため、ヒットしたピンのみ返却される
         * @summary 商品バリエーションを元に事例写真の検索
         * @param {DefaultApiProductVariationsSearchPostsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productVariationsSearchPostsGet(requestParameters: DefaultApiProductVariationsSearchPostsGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PostsResponse> {
            return localVarFp.productVariationsSearchPostsGet(requestParameters.keyword, requestParameters.kind, requestParameters.categoryId, requestParameters.colorTypeIds, requestParameters.fireProtectionMaterial, requestParameters.flamePreventionRegulation, requestParameters.formaldehyde, requestParameters.materialId, requestParameters.minWidth, requestParameters.maxWidth, requestParameters.minHeight, requestParameters.maxHeight, requestParameters.minDepth, requestParameters.maxDepth, requestParameters.minPrice, requestParameters.maxPrice, requestParameters.fireResistance, requestParameters.inStock, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  指定したIDの製品を取得します
         * @summary 製品を取得
         * @param {DefaultApiProductsIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsIdGet(requestParameters: DefaultApiProductsIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProductDetailResponse> {
            return localVarFp.productsIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 製品の風景写真一覧
         * @param {DefaultApiProductsIdPostsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsIdPostsGet(requestParameters: DefaultApiProductsIdPostsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PostsResponse> {
            return localVarFp.productsIdPostsGet(requestParameters.id, requestParameters.limit, requestParameters.cursor, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  指定したIDのプロダクトが持つサンプル商品のリストを取得します
         * @summary サンプル商品を取得
         * @param {DefaultApiProductsIdSampleItemsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsIdSampleItemsGet(requestParameters: DefaultApiProductsIdSampleItemsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<SampleItemEntity>> {
            return localVarFp.productsIdSampleItemsGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 自分がフォローしているユーザーのプロジェクト一覧
         * @param {DefaultApiProjectsFollowingGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsFollowingGet(requestParameters: DefaultApiProjectsFollowingGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ProjectsResponse> {
            return localVarFp.projectsFollowingGet(requestParameters.limit, requestParameters.cursor, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary プロジェクトの添付ファイルを削除する
         * @param {DefaultApiProjectsIdAttachmentsAttachmentIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsIdAttachmentsAttachmentIdDelete(requestParameters: DefaultApiProjectsIdAttachmentsAttachmentIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.projectsIdAttachmentsAttachmentIdDelete(requestParameters.id, requestParameters.attachmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 追加資料を並べ替える
         * @param {DefaultApiProjectsIdAttachmentsAttachmentIdOrderPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsIdAttachmentsAttachmentIdOrderPatch(requestParameters: DefaultApiProjectsIdAttachmentsAttachmentIdOrderPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.projectsIdAttachmentsAttachmentIdOrderPatch(requestParameters.id, requestParameters.attachmentId, requestParameters.orderParameter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary プロジェクトの添付ファイルを更新する
         * @param {DefaultApiProjectsIdAttachmentsAttachmentIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsIdAttachmentsAttachmentIdPut(requestParameters: DefaultApiProjectsIdAttachmentsAttachmentIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProjectAttachment> {
            return localVarFp.projectsIdAttachmentsAttachmentIdPut(requestParameters.id, requestParameters.attachmentId, requestParameters.title, requestParameters.attachmentType, requestParameters.image, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary プロジェクトに添付ファイルを追加する
         * @param {DefaultApiProjectsIdAttachmentsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsIdAttachmentsPost(requestParameters: DefaultApiProjectsIdAttachmentsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProjectAttachment> {
            return localVarFp.projectsIdAttachmentsPost(requestParameters.id, requestParameters.title, requestParameters.image, requestParameters.attachmentType, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  指定したIDのプロジェクトを削除します
         * @summary プロジェクトを削除
         * @param {DefaultApiProjectsIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsIdDelete(requestParameters: DefaultApiProjectsIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.projectsIdDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  指定したIDのプロジェクトを取得します
         * @summary プロジェクトを取得
         * @param {DefaultApiProjectsIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsIdGet(requestParameters: DefaultApiProjectsIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProjectDetailResponse> {
            return localVarFp.projectsIdGet(requestParameters.id, requestParameters.token, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  プロジェクトに紐づくピンをすべて返します。重複しているアイテムはまとめます。
         * @summary プロジェクトに紐づくピンをすべて返す
         * @param {DefaultApiProjectsIdPinsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsIdPinsGet(requestParameters: DefaultApiProjectsIdPinsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<PostPinResponse>> {
            return localVarFp.projectsIdPinsGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  プロジェクトに紐づく風景写真の一覧を取得します
         * @summary プロジェクトに紐づく風景写真一覧
         * @param {DefaultApiProjectsIdPostsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsIdPostsGet(requestParameters: DefaultApiProjectsIdPostsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PostsResponse> {
            return localVarFp.projectsIdPostsGet(requestParameters.id, requestParameters.limit, requestParameters.cursor, requestParameters.token, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  プロジェクトに風景写真を追加します
         * @summary プロジェクトに風景写真を追加する
         * @param {DefaultApiProjectsIdPostsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsIdPostsPost(requestParameters: DefaultApiProjectsIdPostsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PostResponse> {
            return localVarFp.projectsIdPostsPost(requestParameters.id, requestParameters.postCreateParam, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  指定したIDのプロジェクトを公開します
         * @summary 指定したIDのプロジェクトを公開する
         * @param {DefaultApiProjectsIdPublishPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsIdPublishPost(requestParameters: DefaultApiProjectsIdPublishPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProjectDetailResponse> {
            return localVarFp.projectsIdPublishPost(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  指定したIDのプロジェクトを更新します  ## validation  - title: 必須, 255文字以内 - description: 必須, 文字列 - building_type_id: 必須, 数値 - design: 任意, 255文字以内 - staff: 任意, 255文字以内 - structural_design: 任意, 255文字以内 - construction: 任意, 255文字以内 - structure_type: 任意, 数値 - completion: 任意, 年月 YYYY-mm - floor_space: 任意, 数値 - construction_type: 任意, 数値 - photograph: 任意, 255文字以内 - location: 任意 - location.lat: 必須, 数値 - location.lng: 必須, 数値
         * @summary プロジェクトを更新
         * @param {DefaultApiProjectsIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsIdPut(requestParameters: DefaultApiProjectsIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProjectDetailResponse> {
            return localVarFp.projectsIdPut(requestParameters.id, requestParameters.projectUpdateParam, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  非公開プロジェクトの共有用URLに必要なトークンを生成します
         * @summary プロジェクトの共有用トークンを生成する
         * @param {DefaultApiProjectsIdSharePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsIdSharePost(requestParameters: DefaultApiProjectsIdSharePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProjectShareTokenEntity> {
            return localVarFp.projectsIdSharePost(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  指定したIDのプロジェクトを非公開にします
         * @summary 指定したIDのプロジェクトを非公開にする
         * @param {DefaultApiProjectsIdUnpublishPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsIdUnpublishPost(requestParameters: DefaultApiProjectsIdUnpublishPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProjectDetailResponse> {
            return localVarFp.projectsIdUnpublishPost(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  プロジェクトを投稿します
         * @summary プロジェクト投稿
         * @param {DefaultApiProjectsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsPost(requestParameters: DefaultApiProjectsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProjectDetailResponse> {
            return localVarFp.projectsPost(requestParameters.projectCreateParam, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  プロジェクトの風景写真を削除します
         * @summary プロジェクトの風景写真を削除
         * @param {DefaultApiProjectsProjectIdPostsIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsProjectIdPostsIdDelete(requestParameters: DefaultApiProjectsProjectIdPostsIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.projectsProjectIdPostsIdDelete(requestParameters.projectId, requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  プロジェクトの風景写真のposition値を変更します  ## logic - positionは前後のPost.positionの中間値を投げる
         * @summary プロジェクトの風景写真を並べ替える
         * @param {DefaultApiProjectsProjectIdPostsIdOrderPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsProjectIdPostsIdOrderPatch(requestParameters: DefaultApiProjectsProjectIdPostsIdOrderPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.projectsProjectIdPostsIdOrderPatch(requestParameters.projectId, requestParameters.id, requestParameters.orderParameter, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  プロジェクトの風景写真を編集します  ## logic - positionは前後のPost.positionの中間値を投げる
         * @summary プロジェクトの風景写真を編集する
         * @param {DefaultApiProjectsProjectIdPostsIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsProjectIdPostsIdPut(requestParameters: DefaultApiProjectsProjectIdPostsIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<PostResponse> {
            return localVarFp.projectsProjectIdPostsIdPut(requestParameters.projectId, requestParameters.id, requestParameters.postUpdateParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ID指定プロジェクト一覧
         * @param {DefaultApiProjectsSearchByIDsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsSearchByIDsGet(requestParameters: DefaultApiProjectsSearchByIDsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<ProjectWithProductVariationsResponse>> {
            return localVarFp.projectsSearchByIDsGet(requestParameters.ids, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  検索条件にマッチしたプロジェクトの一覧を取得します 並び順はElasticsearchのマッチ率順になるので、cursor形式のページネーションは使えず、 limit, offset形式になります
         * @summary プロジェクトの検索
         * @param {DefaultApiProjectsSearchGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsSearchGet(requestParameters: DefaultApiProjectsSearchGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ProjectsResponse> {
            return localVarFp.projectsSearchGet(requestParameters.keyword, requestParameters.buildingTypeId, requestParameters.structureTypeId, requestParameters.constructionType, requestParameters.minFloorSpace, requestParameters.maxFloorSpace, requestParameters.materialId, requestParameters.colorTypeIds, requestParameters.partIds, requestParameters.useTypeId, requestParameters.limit, requestParameters.offset, requestParameters.sort, requestParameters.excludeProjectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary プロジェクトの検索のヒット数
         * @param {DefaultApiProjectsSearchHitCountsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsSearchHitCountsGet(requestParameters: DefaultApiProjectsSearchHitCountsGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ProjectsSearchHitCountsResponse> {
            return localVarFp.projectsSearchHitCountsGet(requestParameters.keyword, requestParameters.buildingTypeId, requestParameters.structureTypeId, requestParameters.constructionType, requestParameters.minFloorSpace, requestParameters.maxFloorSpace, requestParameters.materialId, requestParameters.colorTypeIds, requestParameters.partIds, requestParameters.useTypeId, requestParameters.limit, requestParameters.offset, requestParameters.sort, requestParameters.excludeProjectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 資格のマスターデータを取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        qualificationsGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<QualificationEntity>> {
            return localVarFp.qualificationsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary キーワード検索を行う
         * @param {DefaultApiSearchGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGet(requestParameters: DefaultApiSearchGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<SearchIndexResponse> {
            return localVarFp.searchGet(requestParameters.keyword, requestParameters.limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary アカウント情報を変更
         * @param {DefaultApiSettingsAccountPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingsAccountPost(requestParameters: DefaultApiSettingsAccountPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<MyUserResponse> {
            return localVarFp.settingsAccountPost(requestParameters.settingsAccountPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary メルマガ設定を変更
         * @param {DefaultApiSettingsMailMagazinePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingsMailMagazinePost(requestParameters: DefaultApiSettingsMailMagazinePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<MyUserResponse> {
            return localVarFp.settingsMailMagazinePost(requestParameters.mailMagazineUpdateParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 通知のON/OFFを設定
         * @param {DefaultApiSettingsNotificationPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingsNotificationPost(requestParameters: DefaultApiSettingsNotificationPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<MyUserResponse> {
            return localVarFp.settingsNotificationPost(requestParameters.notificationSettingsUpdateParameter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary プロフィール情報を変更
         * @param {DefaultApiSettingsProfilePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingsProfilePost(requestParameters: DefaultApiSettingsProfilePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<MyUserResponse> {
            return localVarFp.settingsProfilePost(requestParameters.userProfileUpdateParam, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  現在時刻から次の配送予定日を取得します。
         * @summary 次の配送予定日を取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shipmentsNextDeliveryDateGet(options?: RawAxiosRequestConfig): AxiosPromise<NextDeliveryDateResponse> {
            return localVarFp.shipmentsNextDeliveryDateGet(options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  SuggestKeywordの一覧を取得します
         * @summary SuggestKeyword取得API
         * @param {DefaultApiSuggestKeywordsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestKeywordsGet(requestParameters: DefaultApiSuggestKeywordsGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<SuggestKeywordResponse>> {
            return localVarFp.suggestKeywordsGet(requestParameters.keyword, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  指定したIDのチームへの参加申請を許可します。
         * @summary 参加申請を許可
         * @param {DefaultApiTeamsIdApproveJoinPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teamsIdApproveJoinPost(requestParameters: DefaultApiTeamsIdApproveJoinPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<TeamJoinRequestResponse> {
            return localVarFp.teamsIdApproveJoinPost(requestParameters.id, requestParameters.oneTimeTokenParameter, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  チームを削除します。
         * @summary チーム削除
         * @param {DefaultApiTeamsIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teamsIdDelete(requestParameters: DefaultApiTeamsIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.teamsIdDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  チームを取得します。
         * @summary チーム詳細取得
         * @param {DefaultApiTeamsIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teamsIdGet(requestParameters: DefaultApiTeamsIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<TeamResponse> {
            return localVarFp.teamsIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  チームメンバーを招待します。
         * @summary チームメンバー招待
         * @param {DefaultApiTeamsIdInvitePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teamsIdInvitePost(requestParameters: DefaultApiTeamsIdInvitePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.teamsIdInvitePost(requestParameters.id, requestParameters.teamMemberInviteParameter, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  チームに参加します
         * @summary チームに参加
         * @param {DefaultApiTeamsIdJoinPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teamsIdJoinPost(requestParameters: DefaultApiTeamsIdJoinPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.teamsIdJoinPost(requestParameters.id, requestParameters.oneTimeTokenParameter, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  チームメンバーを一括で取得します。
         * @summary チームメンバー取得
         * @param {DefaultApiTeamsIdMembersGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teamsIdMembersGet(requestParameters: DefaultApiTeamsIdMembersGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<TeamMemberResponse>> {
            return localVarFp.teamsIdMembersGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  チームのプロジェクトを取得します。
         * @summary チームのプロジェクト取得
         * @param {DefaultApiTeamsIdProjectsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teamsIdProjectsGet(requestParameters: DefaultApiTeamsIdProjectsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProjectsResponse> {
            return localVarFp.teamsIdProjectsGet(requestParameters.id, requestParameters.limit, requestParameters.page, requestParameters.excludeProjectId, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  チームを更新します。
         * @summary チーム更新
         * @param {DefaultApiTeamsIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teamsIdPut(requestParameters: DefaultApiTeamsIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<TeamResponse> {
            return localVarFp.teamsIdPut(requestParameters.id, requestParameters.teamUpdateParam, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  指定したIDのチームへの参加をリクエストします。
         * @summary チームへの参加をリクエスト
         * @param {DefaultApiTeamsIdRequestJoinPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teamsIdRequestJoinPost(requestParameters: DefaultApiTeamsIdRequestJoinPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.teamsIdRequestJoinPost(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  チームメンバー招待のTokenを確認します。
         * @summary チームメンバー招待のToken確認
         * @param {DefaultApiTeamsIdVerifyInvitationPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teamsIdVerifyInvitationPost(requestParameters: DefaultApiTeamsIdVerifyInvitationPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<TeamResponse> {
            return localVarFp.teamsIdVerifyInvitationPost(requestParameters.id, requestParameters.oneTimeTokenParameter, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  チームから離脱します
         * @summary チームから離脱
         * @param {DefaultApiTeamsIdWithdrawalPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teamsIdWithdrawalPost(requestParameters: DefaultApiTeamsIdWithdrawalPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.teamsIdWithdrawalPost(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  チームを新規登録します。
         * @summary チーム新規登録
         * @param {DefaultApiTeamsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teamsPost(requestParameters: DefaultApiTeamsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<TeamResponse> {
            return localVarFp.teamsPost(requestParameters.teamCreateParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ID指定チーム一覧
         * @param {DefaultApiTeamsSearchByIDsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teamsSearchByIDsGet(requestParameters: DefaultApiTeamsSearchByIDsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<TeamResponse>> {
            return localVarFp.teamsSearchByIDsGet(requestParameters.ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary チームの検索
         * @param {DefaultApiTeamsSearchGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teamsSearchGet(requestParameters: DefaultApiTeamsSearchGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<TeamsResponse> {
            return localVarFp.teamsSearchGet(requestParameters.keyword, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  チームメンバーを削除します。
         * @summary チームメンバー削除
         * @param {DefaultApiTeamsTeamIdMembersIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teamsTeamIdMembersIdDelete(requestParameters: DefaultApiTeamsTeamIdMembersIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.teamsTeamIdMembersIdDelete(requestParameters.teamId, requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  チームメンバーを更新します。
         * @summary チームメンバー更新
         * @param {DefaultApiTeamsTeamIdMembersIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teamsTeamIdMembersIdPut(requestParameters: DefaultApiTeamsTeamIdMembersIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<TeamMemberResponse> {
            return localVarFp.teamsTeamIdMembersIdPut(requestParameters.teamId, requestParameters.id, requestParameters.teamMemberPutParameter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary TOPページの設定を取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        topSettingsGet(options?: RawAxiosRequestConfig): AxiosPromise<TopSettingsResponse> {
            return localVarFp.topSettingsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  すべての用途の一覧を取得します
         * @summary すべての用途一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        useTypesGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<UseTypeEntity>> {
            return localVarFp.useTypesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  ユーザー属性の一覧を取得します
         * @summary ユーザー属性取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAttributesGet(options?: RawAxiosRequestConfig): AxiosPromise<UserAttributesResponse> {
            return localVarFp.userAttributesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  指定したIDのユーザーをフォローしているユーザーの一覧を取得します
         * @summary 指定したIDのユーザーをフォローしているユーザーの一覧
         * @param {DefaultApiUsersIdFollowersGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdFollowersGet(requestParameters: DefaultApiUsersIdFollowersGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserDetailsResponse> {
            return localVarFp.usersIdFollowersGet(requestParameters.id, requestParameters.limit, requestParameters.cursor, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  指定したIDのユーザーがフォローしているユーザーの一覧を取得します
         * @summary 指定したIDのユーザーがフォローしているユーザーの一覧
         * @param {DefaultApiUsersIdFollowingGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdFollowingGet(requestParameters: DefaultApiUsersIdFollowingGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserDetailsResponse> {
            return localVarFp.usersIdFollowingGet(requestParameters.id, requestParameters.limit, requestParameters.cursor, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  指定したIDのユーザーをフォロー数も含めて取得します
         * @summary 指定したIDのユーザーを取得
         * @param {DefaultApiUsersIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdGet(requestParameters: DefaultApiUsersIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserDetailResponse> {
            return localVarFp.usersIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  指定したIDのユーザーがLikeしたプロジェクトの一覧を取得します
         * @summary 指定したIDのユーザーがLikeしたプロジェクトの一覧
         * @param {DefaultApiUsersIdLikesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdLikesGet(requestParameters: DefaultApiUsersIdLikesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<LikesResponse> {
            return localVarFp.usersIdLikesGet(requestParameters.id, requestParameters.limit, requestParameters.cursor, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  指定したIDのユーザーが作成したリストの一覧を取得します。   非公開のリストは、自分自身の場合以外はレスポンスに含まれません。
         * @summary 指定したIDのユーザーが作成したリストの一覧
         * @param {DefaultApiUsersIdListsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdListsGet(requestParameters: DefaultApiUsersIdListsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ListsResponse> {
            return localVarFp.usersIdListsGet(requestParameters.id, requestParameters.limit, requestParameters.cursor, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  指定したIDのユーザーが投稿した風景写真の一覧を取得します
         * @summary 指定したIDのユーザーが投稿した風景写真の一覧
         * @param {DefaultApiUsersIdPostsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdPostsGet(requestParameters: DefaultApiUsersIdPostsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PostsResponse> {
            return localVarFp.usersIdPostsGet(requestParameters.id, requestParameters.limit, requestParameters.cursor, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  指定したIDのユーザーが投稿したプロジェクトの一覧を取得します
         * @summary 指定したIDのユーザーが投稿したプロジェクトの一覧
         * @param {DefaultApiUsersIdProjectsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdProjectsGet(requestParameters: DefaultApiUsersIdProjectsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProjectsResponse> {
            return localVarFp.usersIdProjectsGet(requestParameters.id, requestParameters.limit, requestParameters.page, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  指定したIDのユーザーのチームを取得します
         * @summary 指定したIDのユーザーのチームを取得
         * @param {DefaultApiUsersIdTeamsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdTeamsGet(requestParameters: DefaultApiUsersIdTeamsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<TeamMembersResponse> {
            return localVarFp.usersIdTeamsGet(requestParameters.id, requestParameters.limit, requestParameters.cursor, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 設計者アカウントへの変更
         * @param {DefaultApiUsersMeChangeDesignerAccountPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersMeChangeDesignerAccountPost(requestParameters: DefaultApiUsersMeChangeDesignerAccountPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<MyUserResponse> {
            return localVarFp.usersMeChangeDesignerAccountPost(requestParameters.designerAccountUpdateParam, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  ログインしているユーザーをフォロー数も含めて取得します
         * @summary ログインユーザーを取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersMeGet(options?: RawAxiosRequestConfig): AxiosPromise<MyUserResponse> {
            return localVarFp.usersMeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  ログインしているユーザーが作成したリスト一覧を取得します
         * @summary ログインユーザーのリスト一覧
         * @param {DefaultApiUsersMeListsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersMeListsGet(requestParameters: DefaultApiUsersMeListsGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListsResponse> {
            return localVarFp.usersMeListsGet(requestParameters.limit, requestParameters.cursor, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ログインユーザーのプロジェクト一覧
         * @param {DefaultApiUsersMeProjectsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersMeProjectsGet(requestParameters: DefaultApiUsersMeProjectsGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ProjectsResponse> {
            return localVarFp.usersMeProjectsGet(requestParameters.limit, requestParameters.page, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  一般ユーザーを新規登録します。
         * @summary 一般ユーザー新規登録
         * @param {DefaultApiUsersPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersPost(requestParameters: DefaultApiUsersPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<MyUserResponse> {
            return localVarFp.usersPost(requestParameters.userCreateParam, options).then((request) => request(axios, basePath));
        },
        /**
         * ## summary  指定した郵便番号の住所を取得します
         * @summary 指定した郵便番号の住所を取得
         * @param {DefaultApiZipCodesZipCodeGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zipCodesZipCodeGet(requestParameters: DefaultApiZipCodesZipCodeGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<ZipCodeResponse>> {
            return localVarFp.zipCodesZipCodeGet(requestParameters.zipCode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addressesIdDelete operation in DefaultApi.
 * @export
 * @interface DefaultApiAddressesIdDeleteRequest
 */
export interface DefaultApiAddressesIdDeleteRequest {
    /**
     * Address ID
     * @type {number}
     * @memberof DefaultApiAddressesIdDelete
     */
    readonly id: number
}

/**
 * Request parameters for addressesIdPut operation in DefaultApi.
 * @export
 * @interface DefaultApiAddressesIdPutRequest
 */
export interface DefaultApiAddressesIdPutRequest {
    /**
     * Address ID
     * @type {number}
     * @memberof DefaultApiAddressesIdPut
     */
    readonly id: number

    /**
     * AddressUpsertパラメータ
     * @type {AddressUpsertParameter}
     * @memberof DefaultApiAddressesIdPut
     */
    readonly addressUpsertParameter: AddressUpsertParameter
}

/**
 * Request parameters for addressesPost operation in DefaultApi.
 * @export
 * @interface DefaultApiAddressesPostRequest
 */
export interface DefaultApiAddressesPostRequest {
    /**
     * AddressUpsertパラメータ
     * @type {AddressUpsertParameter}
     * @memberof DefaultApiAddressesPost
     */
    readonly addressUpsertParameter: AddressUpsertParameter
}

/**
 * Request parameters for awardsSlugCategoriesGet operation in DefaultApi.
 * @export
 * @interface DefaultApiAwardsSlugCategoriesGetRequest
 */
export interface DefaultApiAwardsSlugCategoriesGetRequest {
    /**
     * アワードのスラッグ
     * @type {string}
     * @memberof DefaultApiAwardsSlugCategoriesGet
     */
    readonly slug: string
}

/**
 * Request parameters for awardsSlugEntriesEntryIdVotesPost operation in DefaultApi.
 * @export
 * @interface DefaultApiAwardsSlugEntriesEntryIdVotesPostRequest
 */
export interface DefaultApiAwardsSlugEntriesEntryIdVotesPostRequest {
    /**
     * 応募するAwardのSlug
     * @type {string}
     * @memberof DefaultApiAwardsSlugEntriesEntryIdVotesPost
     */
    readonly slug: string

    /**
     * 応募作品のEntryID
     * @type {number}
     * @memberof DefaultApiAwardsSlugEntriesEntryIdVotesPost
     */
    readonly entryId: number
}

/**
 * Request parameters for awardsSlugEntriesGet operation in DefaultApi.
 * @export
 * @interface DefaultApiAwardsSlugEntriesGetRequest
 */
export interface DefaultApiAwardsSlugEntriesGetRequest {
    /**
     * 取得対象のAwardのSlug
     * @type {string}
     * @memberof DefaultApiAwardsSlugEntriesGet
     */
    readonly slug: string

    /**
     * カテゴリID
     * @type {number}
     * @memberof DefaultApiAwardsSlugEntriesGet
     */
    readonly awardCategoryId?: number

    /**
     * ソート条件
     * @type {'newest' | 'popular' | 'random'}
     * @memberof DefaultApiAwardsSlugEntriesGet
     */
    readonly sort?: AwardsSlugEntriesGetSortEnum

    /**
     * 1ページあたりの表示件数(default: 20)
     * @type {number}
     * @memberof DefaultApiAwardsSlugEntriesGet
     */
    readonly limit?: number

    /**
     * オフセット
     * @type {number}
     * @memberof DefaultApiAwardsSlugEntriesGet
     */
    readonly offset?: number
}

/**
 * Request parameters for awardsSlugEntriesPost operation in DefaultApi.
 * @export
 * @interface DefaultApiAwardsSlugEntriesPostRequest
 */
export interface DefaultApiAwardsSlugEntriesPostRequest {
    /**
     * 応募するAwardのSlug
     * @type {string}
     * @memberof DefaultApiAwardsSlugEntriesPost
     */
    readonly slug: string

    /**
     * 応募パラメータ
     * @type {AwardEntryCreateParameter}
     * @memberof DefaultApiAwardsSlugEntriesPost
     */
    readonly awardEntryCreateParameter: AwardEntryCreateParameter
}

/**
 * Request parameters for awardsSlugGet operation in DefaultApi.
 * @export
 * @interface DefaultApiAwardsSlugGetRequest
 */
export interface DefaultApiAwardsSlugGetRequest {
    /**
     * 応募するAwardのSlug
     * @type {string}
     * @memberof DefaultApiAwardsSlugGet
     */
    readonly slug: string
}

/**
 * Request parameters for awardsSlugVotedEntryIdsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiAwardsSlugVotedEntryIdsGetRequest
 */
export interface DefaultApiAwardsSlugVotedEntryIdsGetRequest {
    /**
     * 応募するAwardのSlug
     * @type {string}
     * @memberof DefaultApiAwardsSlugVotedEntryIdsGet
     */
    readonly slug: string
}

/**
 * Request parameters for boardsIdGet operation in DefaultApi.
 * @export
 * @interface DefaultApiBoardsIdGetRequest
 */
export interface DefaultApiBoardsIdGetRequest {
    /**
     * Board ID
     * @type {number}
     * @memberof DefaultApiBoardsIdGet
     */
    readonly id: number
}

/**
 * Request parameters for boardsIdPostsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiBoardsIdPostsGetRequest
 */
export interface DefaultApiBoardsIdPostsGetRequest {
    /**
     * Board ID
     * @type {number}
     * @memberof DefaultApiBoardsIdPostsGet
     */
    readonly id: number

    /**
     * 1ページあたりの表示件数(default: 20)
     * @type {number}
     * @memberof DefaultApiBoardsIdPostsGet
     */
    readonly limit?: number

    /**
     * ページ(default: 1)
     * @type {number}
     * @memberof DefaultApiBoardsIdPostsGet
     */
    readonly page?: number
}

/**
 * Request parameters for boardsSearchByIDsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiBoardsSearchByIDsGetRequest
 */
export interface DefaultApiBoardsSearchByIDsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiBoardsSearchByIDsGet
     */
    readonly ids: string
}

/**
 * Request parameters for boardsSearchGet operation in DefaultApi.
 * @export
 * @interface DefaultApiBoardsSearchGetRequest
 */
export interface DefaultApiBoardsSearchGetRequest {
    /**
     * 検索キーワード
     * @type {string}
     * @memberof DefaultApiBoardsSearchGet
     */
    readonly keyword?: string

    /**
     * 1ページあたりの表示件数(default: 20)
     * @type {number}
     * @memberof DefaultApiBoardsSearchGet
     */
    readonly limit?: number

    /**
     * ページ取得位置(default: 0)
     * @type {number}
     * @memberof DefaultApiBoardsSearchGet
     */
    readonly offset?: number
}

/**
 * Request parameters for brandsIdGet operation in DefaultApi.
 * @export
 * @interface DefaultApiBrandsIdGetRequest
 */
export interface DefaultApiBrandsIdGetRequest {
    /**
     * Brand ID
     * @type {number}
     * @memberof DefaultApiBrandsIdGet
     */
    readonly id: number
}

/**
 * Request parameters for brandsIdPostsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiBrandsIdPostsGetRequest
 */
export interface DefaultApiBrandsIdPostsGetRequest {
    /**
     * Brand ID
     * @type {number}
     * @memberof DefaultApiBrandsIdPostsGet
     */
    readonly id: number

    /**
     * 検索キーワード
     * @type {string}
     * @memberof DefaultApiBrandsIdPostsGet
     */
    readonly keyword?: string

    /**
     * 種類
     * @type {1 | 2}
     * @memberof DefaultApiBrandsIdPostsGet
     */
    readonly kind?: BrandsIdPostsGetKindEnum

    /**
     * カテゴリID
     * @type {number}
     * @memberof DefaultApiBrandsIdPostsGet
     */
    readonly categoryId?: number

    /**
     * カラーIDの配列
     * @type {Array<1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17>}
     * @memberof DefaultApiBrandsIdPostsGet
     */
    readonly colorTypeIds?: Array<BrandsIdPostsGetColorTypeIdsEnum>

    /**
     * 防火材料
     * @type {1 | 2 | 3}
     * @memberof DefaultApiBrandsIdPostsGet
     */
    readonly fireProtectionMaterial?: BrandsIdPostsGetFireProtectionMaterialEnum

    /**
     * 防炎規制
     * @type {1}
     * @memberof DefaultApiBrandsIdPostsGet
     */
    readonly flamePreventionRegulation?: BrandsIdPostsGetFlamePreventionRegulationEnum

    /**
     * ホルムアルデヒド等級
     * @type {1 | 2 | 3}
     * @memberof DefaultApiBrandsIdPostsGet
     */
    readonly formaldehyde?: BrandsIdPostsGetFormaldehydeEnum

    /**
     * 素材ID
     * @type {number}
     * @memberof DefaultApiBrandsIdPostsGet
     */
    readonly materialId?: number

    /**
     * 最小幅
     * @type {number}
     * @memberof DefaultApiBrandsIdPostsGet
     */
    readonly minWidth?: number

    /**
     * 最小幅
     * @type {number}
     * @memberof DefaultApiBrandsIdPostsGet
     */
    readonly maxWidth?: number

    /**
     * 最小高さ
     * @type {number}
     * @memberof DefaultApiBrandsIdPostsGet
     */
    readonly minHeight?: number

    /**
     * 最小高さ
     * @type {number}
     * @memberof DefaultApiBrandsIdPostsGet
     */
    readonly maxHeight?: number

    /**
     * 最小奥行き
     * @type {number}
     * @memberof DefaultApiBrandsIdPostsGet
     */
    readonly minDepth?: number

    /**
     * 最小奥行き
     * @type {number}
     * @memberof DefaultApiBrandsIdPostsGet
     */
    readonly maxDepth?: number

    /**
     * 最低価格
     * @type {number}
     * @memberof DefaultApiBrandsIdPostsGet
     */
    readonly minPrice?: number

    /**
     * 最高価格
     * @type {number}
     * @memberof DefaultApiBrandsIdPostsGet
     */
    readonly maxPrice?: number

    /**
     * 耐火性能
     * @type {1 | 2 | 3 | 4 | 5}
     * @memberof DefaultApiBrandsIdPostsGet
     */
    readonly fireResistance?: BrandsIdPostsGetFireResistanceEnum

    /**
     * 在庫あり
     * @type {boolean}
     * @memberof DefaultApiBrandsIdPostsGet
     */
    readonly inStock?: boolean

    /**
     * 1ページあたりの表示件数(default: 20)
     * @type {number}
     * @memberof DefaultApiBrandsIdPostsGet
     */
    readonly limit?: number

    /**
     * ページ取得位置(default: 0)
     * @type {number}
     * @memberof DefaultApiBrandsIdPostsGet
     */
    readonly offset?: number
}

/**
 * Request parameters for brandsIdProductVariationsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiBrandsIdProductVariationsGetRequest
 */
export interface DefaultApiBrandsIdProductVariationsGetRequest {
    /**
     * Brand ID
     * @type {number}
     * @memberof DefaultApiBrandsIdProductVariationsGet
     */
    readonly id: number

    /**
     * 検索キーワード
     * @type {string}
     * @memberof DefaultApiBrandsIdProductVariationsGet
     */
    readonly keyword?: string

    /**
     * 種類
     * @type {1 | 2}
     * @memberof DefaultApiBrandsIdProductVariationsGet
     */
    readonly kind?: BrandsIdProductVariationsGetKindEnum

    /**
     * カテゴリID
     * @type {number}
     * @memberof DefaultApiBrandsIdProductVariationsGet
     */
    readonly categoryId?: number

    /**
     * カラーIDの配列
     * @type {Array<1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17>}
     * @memberof DefaultApiBrandsIdProductVariationsGet
     */
    readonly colorTypeIds?: Array<BrandsIdProductVariationsGetColorTypeIdsEnum>

    /**
     * 防火材料
     * @type {1 | 2 | 3}
     * @memberof DefaultApiBrandsIdProductVariationsGet
     */
    readonly fireProtectionMaterial?: BrandsIdProductVariationsGetFireProtectionMaterialEnum

    /**
     * 防炎規制
     * @type {1}
     * @memberof DefaultApiBrandsIdProductVariationsGet
     */
    readonly flamePreventionRegulation?: BrandsIdProductVariationsGetFlamePreventionRegulationEnum

    /**
     * ホルムアルデヒド等級
     * @type {1 | 2 | 3}
     * @memberof DefaultApiBrandsIdProductVariationsGet
     */
    readonly formaldehyde?: BrandsIdProductVariationsGetFormaldehydeEnum

    /**
     * 素材ID
     * @type {number}
     * @memberof DefaultApiBrandsIdProductVariationsGet
     */
    readonly materialId?: number

    /**
     * 最小幅
     * @type {number}
     * @memberof DefaultApiBrandsIdProductVariationsGet
     */
    readonly minWidth?: number

    /**
     * 最小幅
     * @type {number}
     * @memberof DefaultApiBrandsIdProductVariationsGet
     */
    readonly maxWidth?: number

    /**
     * 最小高さ
     * @type {number}
     * @memberof DefaultApiBrandsIdProductVariationsGet
     */
    readonly minHeight?: number

    /**
     * 最小高さ
     * @type {number}
     * @memberof DefaultApiBrandsIdProductVariationsGet
     */
    readonly maxHeight?: number

    /**
     * 最小奥行き
     * @type {number}
     * @memberof DefaultApiBrandsIdProductVariationsGet
     */
    readonly minDepth?: number

    /**
     * 最小奥行き
     * @type {number}
     * @memberof DefaultApiBrandsIdProductVariationsGet
     */
    readonly maxDepth?: number

    /**
     * 最低価格
     * @type {number}
     * @memberof DefaultApiBrandsIdProductVariationsGet
     */
    readonly minPrice?: number

    /**
     * 最高価格
     * @type {number}
     * @memberof DefaultApiBrandsIdProductVariationsGet
     */
    readonly maxPrice?: number

    /**
     * 耐火性能
     * @type {1 | 2 | 3 | 4 | 5}
     * @memberof DefaultApiBrandsIdProductVariationsGet
     */
    readonly fireResistance?: BrandsIdProductVariationsGetFireResistanceEnum

    /**
     * 在庫あり
     * @type {boolean}
     * @memberof DefaultApiBrandsIdProductVariationsGet
     */
    readonly inStock?: boolean

    /**
     * 1ページあたりの表示件数(default: 20)
     * @type {number}
     * @memberof DefaultApiBrandsIdProductVariationsGet
     */
    readonly limit?: number

    /**
     * ページ取得位置(default: 0)
     * @type {number}
     * @memberof DefaultApiBrandsIdProductVariationsGet
     */
    readonly offset?: number
}

/**
 * Request parameters for buildingTypesIdUseTypesGet operation in DefaultApi.
 * @export
 * @interface DefaultApiBuildingTypesIdUseTypesGetRequest
 */
export interface DefaultApiBuildingTypesIdUseTypesGetRequest {
    /**
     * BuildingType ID
     * @type {number}
     * @memberof DefaultApiBuildingTypesIdUseTypesGet
     */
    readonly id: number
}

/**
 * Request parameters for catalogsIdGet operation in DefaultApi.
 * @export
 * @interface DefaultApiCatalogsIdGetRequest
 */
export interface DefaultApiCatalogsIdGetRequest {
    /**
     * Catalog ID
     * @type {number}
     * @memberof DefaultApiCatalogsIdGet
     */
    readonly id: number
}

/**
 * Request parameters for catalogsIdPagesGet operation in DefaultApi.
 * @export
 * @interface DefaultApiCatalogsIdPagesGetRequest
 */
export interface DefaultApiCatalogsIdPagesGetRequest {
    /**
     * Catalog ID
     * @type {number}
     * @memberof DefaultApiCatalogsIdPagesGet
     */
    readonly id: number

    /**
     * 何ページ目からの取得か
     * @type {number}
     * @memberof DefaultApiCatalogsIdPagesGet
     */
    readonly fromPage: number

    /**
     * 何ページ目までの取得か
     * @type {number}
     * @memberof DefaultApiCatalogsIdPagesGet
     */
    readonly toPage: number
}

/**
 * Request parameters for categoriesIdSearchConditionGroupsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiCategoriesIdSearchConditionGroupsGetRequest
 */
export interface DefaultApiCategoriesIdSearchConditionGroupsGetRequest {
    /**
     * Category ID
     * @type {number}
     * @memberof DefaultApiCategoriesIdSearchConditionGroupsGet
     */
    readonly id: number
}

/**
 * Request parameters for designersPost operation in DefaultApi.
 * @export
 * @interface DefaultApiDesignersPostRequest
 */
export interface DefaultApiDesignersPostRequest {
    /**
     * 設計者アカウント登録パラメータ
     * @type {DesignerCreateParam}
     * @memberof DefaultApiDesignersPost
     */
    readonly designerCreateParam: DesignerCreateParam
}

/**
 * Request parameters for followsPost operation in DefaultApi.
 * @export
 * @interface DefaultApiFollowsPostRequest
 */
export interface DefaultApiFollowsPostRequest {
    /**
     * フォローパラメータ
     * @type {FollowCreateParameter}
     * @memberof DefaultApiFollowsPost
     */
    readonly followCreateParameter: FollowCreateParameter
}

/**
 * Request parameters for followsUserIdDelete operation in DefaultApi.
 * @export
 * @interface DefaultApiFollowsUserIdDeleteRequest
 */
export interface DefaultApiFollowsUserIdDeleteRequest {
    /**
     * User ID
     * @type {number}
     * @memberof DefaultApiFollowsUserIdDelete
     */
    readonly userId: number
}

/**
 * Request parameters for imagesPost operation in DefaultApi.
 * @export
 * @interface DefaultApiImagesPostRequest
 */
export interface DefaultApiImagesPostRequest {
    /**
     * 
     * @type {File}
     * @memberof DefaultApiImagesPost
     */
    readonly image?: File
}

/**
 * Request parameters for likesDelete operation in DefaultApi.
 * @export
 * @interface DefaultApiLikesDeleteRequest
 */
export interface DefaultApiLikesDeleteRequest {
    /**
     * Like削除パラメータ
     * @type {LikeDeleteParam}
     * @memberof DefaultApiLikesDelete
     */
    readonly likeDeleteParam: LikeDeleteParam
}

/**
 * Request parameters for likesPost operation in DefaultApi.
 * @export
 * @interface DefaultApiLikesPostRequest
 */
export interface DefaultApiLikesPostRequest {
    /**
     * Like作成パラメータ
     * @type {LikeCreateParam}
     * @memberof DefaultApiLikesPost
     */
    readonly likeCreateParam: LikeCreateParam
}

/**
 * Request parameters for listsIdDelete operation in DefaultApi.
 * @export
 * @interface DefaultApiListsIdDeleteRequest
 */
export interface DefaultApiListsIdDeleteRequest {
    /**
     * List ID
     * @type {number}
     * @memberof DefaultApiListsIdDelete
     */
    readonly id: number
}

/**
 * Request parameters for listsIdGet operation in DefaultApi.
 * @export
 * @interface DefaultApiListsIdGetRequest
 */
export interface DefaultApiListsIdGetRequest {
    /**
     * List ID
     * @type {number}
     * @memberof DefaultApiListsIdGet
     */
    readonly id: number
}

/**
 * Request parameters for listsIdItemsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiListsIdItemsGetRequest
 */
export interface DefaultApiListsIdItemsGetRequest {
    /**
     * List ID
     * @type {number}
     * @memberof DefaultApiListsIdItemsGet
     */
    readonly id: number

    /**
     * List Item KInd
     * @type {ListItemKind}
     * @memberof DefaultApiListsIdItemsGet
     */
    readonly kind?: ListItemKind

    /**
     * 1ページあたりの表示件数(default: 20)
     * @type {number}
     * @memberof DefaultApiListsIdItemsGet
     */
    readonly limit?: number

    /**
     * 取得カーソル
     * @type {number}
     * @memberof DefaultApiListsIdItemsGet
     */
    readonly cursor?: number
}

/**
 * Request parameters for listsIdItemsPost operation in DefaultApi.
 * @export
 * @interface DefaultApiListsIdItemsPostRequest
 */
export interface DefaultApiListsIdItemsPostRequest {
    /**
     * List ID
     * @type {number}
     * @memberof DefaultApiListsIdItemsPost
     */
    readonly id: number

    /**
     * 風景写真登録パラメータ
     * @type {ListAddItemParameter}
     * @memberof DefaultApiListsIdItemsPost
     */
    readonly listAddItemParameter: ListAddItemParameter
}

/**
 * Request parameters for listsIdPut operation in DefaultApi.
 * @export
 * @interface DefaultApiListsIdPutRequest
 */
export interface DefaultApiListsIdPutRequest {
    /**
     * List ID
     * @type {number}
     * @memberof DefaultApiListsIdPut
     */
    readonly id: number

    /**
     * リスト作成パラメータ
     * @type {ListUpdateParam}
     * @memberof DefaultApiListsIdPut
     */
    readonly listUpdateParam: ListUpdateParam
}

/**
 * Request parameters for listsListIdItemsItemIdDelete operation in DefaultApi.
 * @export
 * @interface DefaultApiListsListIdItemsItemIdDeleteRequest
 */
export interface DefaultApiListsListIdItemsItemIdDeleteRequest {
    /**
     * List ID
     * @type {number}
     * @memberof DefaultApiListsListIdItemsItemIdDelete
     */
    readonly listId: number

    /**
     * Item ID
     * @type {number}
     * @memberof DefaultApiListsListIdItemsItemIdDelete
     */
    readonly itemId: number
}

/**
 * Request parameters for listsPost operation in DefaultApi.
 * @export
 * @interface DefaultApiListsPostRequest
 */
export interface DefaultApiListsPostRequest {
    /**
     * リスト作成パラメータ
     * @type {ListCreateParam}
     * @memberof DefaultApiListsPost
     */
    readonly listCreateParam: ListCreateParam
}

/**
 * Request parameters for listsSearchByIDsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiListsSearchByIDsGetRequest
 */
export interface DefaultApiListsSearchByIDsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiListsSearchByIDsGet
     */
    readonly ids: string
}

/**
 * Request parameters for listsSearchGet operation in DefaultApi.
 * @export
 * @interface DefaultApiListsSearchGetRequest
 */
export interface DefaultApiListsSearchGetRequest {
    /**
     * 検索キーワード
     * @type {string}
     * @memberof DefaultApiListsSearchGet
     */
    readonly keyword?: string

    /**
     * 1ページあたりの表示件数(default: 20)
     * @type {number}
     * @memberof DefaultApiListsSearchGet
     */
    readonly limit?: number

    /**
     * offset(default: 0)
     * @type {number}
     * @memberof DefaultApiListsSearchGet
     */
    readonly offset?: number
}

/**
 * Request parameters for magazinesTagsSlugGet operation in DefaultApi.
 * @export
 * @interface DefaultApiMagazinesTagsSlugGetRequest
 */
export interface DefaultApiMagazinesTagsSlugGetRequest {
    /**
     * tagのslug
     * @type {string}
     * @memberof DefaultApiMagazinesTagsSlugGet
     */
    readonly slug: string
}

/**
 * Request parameters for makersAllGet operation in DefaultApi.
 * @export
 * @interface DefaultApiMakersAllGetRequest
 */
export interface DefaultApiMakersAllGetRequest {
    /**
     * サンプル請求可能なメーカーに絞り込む
     * @type {boolean}
     * @memberof DefaultApiMakersAllGet
     */
    readonly availableSampleRequest?: boolean
}

/**
 * Request parameters for makersIdCatalogsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiMakersIdCatalogsGetRequest
 */
export interface DefaultApiMakersIdCatalogsGetRequest {
    /**
     * Maker ID
     * @type {number}
     * @memberof DefaultApiMakersIdCatalogsGet
     */
    readonly id: number

    /**
     * 1ページあたりの表示件数(default: 20)
     * @type {number}
     * @memberof DefaultApiMakersIdCatalogsGet
     */
    readonly limit?: number

    /**
     * 取得カーソル
     * @type {string}
     * @memberof DefaultApiMakersIdCatalogsGet
     */
    readonly cursor?: string
}

/**
 * Request parameters for makersIdCategoriesGet operation in DefaultApi.
 * @export
 * @interface DefaultApiMakersIdCategoriesGetRequest
 */
export interface DefaultApiMakersIdCategoriesGetRequest {
    /**
     * Maker ID
     * @type {number}
     * @memberof DefaultApiMakersIdCategoriesGet
     */
    readonly id: number
}

/**
 * Request parameters for makersIdContactsPost operation in DefaultApi.
 * @export
 * @interface DefaultApiMakersIdContactsPostRequest
 */
export interface DefaultApiMakersIdContactsPostRequest {
    /**
     * Maker ID
     * @type {number}
     * @memberof DefaultApiMakersIdContactsPost
     */
    readonly id: number

    /**
     * メーカー問い合わせパラメータ
     * @type {MakerContactCreateParam}
     * @memberof DefaultApiMakersIdContactsPost
     */
    readonly makerContactCreateParam: MakerContactCreateParam
}

/**
 * Request parameters for makersIdGet operation in DefaultApi.
 * @export
 * @interface DefaultApiMakersIdGetRequest
 */
export interface DefaultApiMakersIdGetRequest {
    /**
     * Maker ID
     * @type {number}
     * @memberof DefaultApiMakersIdGet
     */
    readonly id: number
}

/**
 * Request parameters for makersIdListsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiMakersIdListsGetRequest
 */
export interface DefaultApiMakersIdListsGetRequest {
    /**
     * Maker ID
     * @type {number}
     * @memberof DefaultApiMakersIdListsGet
     */
    readonly id: number

    /**
     * 1ページあたりの表示件数(default: 20)
     * @type {number}
     * @memberof DefaultApiMakersIdListsGet
     */
    readonly limit?: number

    /**
     * ページ取得位置(default: 0)
     * @type {number}
     * @memberof DefaultApiMakersIdListsGet
     */
    readonly offset?: number
}

/**
 * Request parameters for makersIdPostsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiMakersIdPostsGetRequest
 */
export interface DefaultApiMakersIdPostsGetRequest {
    /**
     * Maker ID
     * @type {number}
     * @memberof DefaultApiMakersIdPostsGet
     */
    readonly id: number

    /**
     * 検索キーワード
     * @type {string}
     * @memberof DefaultApiMakersIdPostsGet
     */
    readonly keyword?: string

    /**
     * 種類
     * @type {1 | 2}
     * @memberof DefaultApiMakersIdPostsGet
     */
    readonly kind?: MakersIdPostsGetKindEnum

    /**
     * カテゴリID
     * @type {number}
     * @memberof DefaultApiMakersIdPostsGet
     */
    readonly categoryId?: number

    /**
     * カラーIDの配列
     * @type {Array<1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17>}
     * @memberof DefaultApiMakersIdPostsGet
     */
    readonly colorTypeIds?: Array<MakersIdPostsGetColorTypeIdsEnum>

    /**
     * 防火材料
     * @type {1 | 2 | 3}
     * @memberof DefaultApiMakersIdPostsGet
     */
    readonly fireProtectionMaterial?: MakersIdPostsGetFireProtectionMaterialEnum

    /**
     * 防炎規制
     * @type {1}
     * @memberof DefaultApiMakersIdPostsGet
     */
    readonly flamePreventionRegulation?: MakersIdPostsGetFlamePreventionRegulationEnum

    /**
     * ホルムアルデヒド等級
     * @type {1 | 2 | 3}
     * @memberof DefaultApiMakersIdPostsGet
     */
    readonly formaldehyde?: MakersIdPostsGetFormaldehydeEnum

    /**
     * 素材ID
     * @type {number}
     * @memberof DefaultApiMakersIdPostsGet
     */
    readonly materialId?: number

    /**
     * 最小幅
     * @type {number}
     * @memberof DefaultApiMakersIdPostsGet
     */
    readonly minWidth?: number

    /**
     * 最小幅
     * @type {number}
     * @memberof DefaultApiMakersIdPostsGet
     */
    readonly maxWidth?: number

    /**
     * 最小高さ
     * @type {number}
     * @memberof DefaultApiMakersIdPostsGet
     */
    readonly minHeight?: number

    /**
     * 最小高さ
     * @type {number}
     * @memberof DefaultApiMakersIdPostsGet
     */
    readonly maxHeight?: number

    /**
     * 最小奥行き
     * @type {number}
     * @memberof DefaultApiMakersIdPostsGet
     */
    readonly minDepth?: number

    /**
     * 最小奥行き
     * @type {number}
     * @memberof DefaultApiMakersIdPostsGet
     */
    readonly maxDepth?: number

    /**
     * 最低価格
     * @type {number}
     * @memberof DefaultApiMakersIdPostsGet
     */
    readonly minPrice?: number

    /**
     * 最高価格
     * @type {number}
     * @memberof DefaultApiMakersIdPostsGet
     */
    readonly maxPrice?: number

    /**
     * 耐火性能
     * @type {1 | 2 | 3 | 4 | 5}
     * @memberof DefaultApiMakersIdPostsGet
     */
    readonly fireResistance?: MakersIdPostsGetFireResistanceEnum

    /**
     * 在庫あり
     * @type {boolean}
     * @memberof DefaultApiMakersIdPostsGet
     */
    readonly inStock?: boolean

    /**
     * 1ページあたりの表示件数(default: 20)
     * @type {number}
     * @memberof DefaultApiMakersIdPostsGet
     */
    readonly limit?: number

    /**
     * ページ取得位置(default: 0)
     * @type {number}
     * @memberof DefaultApiMakersIdPostsGet
     */
    readonly offset?: number
}

/**
 * Request parameters for makersIdProductVariationsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiMakersIdProductVariationsGetRequest
 */
export interface DefaultApiMakersIdProductVariationsGetRequest {
    /**
     * Maker ID
     * @type {number}
     * @memberof DefaultApiMakersIdProductVariationsGet
     */
    readonly id: number

    /**
     * 検索キーワード
     * @type {string}
     * @memberof DefaultApiMakersIdProductVariationsGet
     */
    readonly keyword?: string

    /**
     * 種類
     * @type {1 | 2}
     * @memberof DefaultApiMakersIdProductVariationsGet
     */
    readonly kind?: MakersIdProductVariationsGetKindEnum

    /**
     * カテゴリID
     * @type {number}
     * @memberof DefaultApiMakersIdProductVariationsGet
     */
    readonly categoryId?: number

    /**
     * カラーIDの配列
     * @type {Array<1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17>}
     * @memberof DefaultApiMakersIdProductVariationsGet
     */
    readonly colorTypeIds?: Array<MakersIdProductVariationsGetColorTypeIdsEnum>

    /**
     * 防火材料
     * @type {1 | 2 | 3}
     * @memberof DefaultApiMakersIdProductVariationsGet
     */
    readonly fireProtectionMaterial?: MakersIdProductVariationsGetFireProtectionMaterialEnum

    /**
     * 防炎規制
     * @type {1}
     * @memberof DefaultApiMakersIdProductVariationsGet
     */
    readonly flamePreventionRegulation?: MakersIdProductVariationsGetFlamePreventionRegulationEnum

    /**
     * ホルムアルデヒド等級
     * @type {1 | 2 | 3}
     * @memberof DefaultApiMakersIdProductVariationsGet
     */
    readonly formaldehyde?: MakersIdProductVariationsGetFormaldehydeEnum

    /**
     * 素材ID
     * @type {number}
     * @memberof DefaultApiMakersIdProductVariationsGet
     */
    readonly materialId?: number

    /**
     * 最小幅
     * @type {number}
     * @memberof DefaultApiMakersIdProductVariationsGet
     */
    readonly minWidth?: number

    /**
     * 最小幅
     * @type {number}
     * @memberof DefaultApiMakersIdProductVariationsGet
     */
    readonly maxWidth?: number

    /**
     * 最小高さ
     * @type {number}
     * @memberof DefaultApiMakersIdProductVariationsGet
     */
    readonly minHeight?: number

    /**
     * 最小高さ
     * @type {number}
     * @memberof DefaultApiMakersIdProductVariationsGet
     */
    readonly maxHeight?: number

    /**
     * 最小奥行き
     * @type {number}
     * @memberof DefaultApiMakersIdProductVariationsGet
     */
    readonly minDepth?: number

    /**
     * 最小奥行き
     * @type {number}
     * @memberof DefaultApiMakersIdProductVariationsGet
     */
    readonly maxDepth?: number

    /**
     * 最低価格
     * @type {number}
     * @memberof DefaultApiMakersIdProductVariationsGet
     */
    readonly minPrice?: number

    /**
     * 最高価格
     * @type {number}
     * @memberof DefaultApiMakersIdProductVariationsGet
     */
    readonly maxPrice?: number

    /**
     * 耐火性能
     * @type {1 | 2 | 3 | 4 | 5}
     * @memberof DefaultApiMakersIdProductVariationsGet
     */
    readonly fireResistance?: MakersIdProductVariationsGetFireResistanceEnum

    /**
     * 在庫あり
     * @type {boolean}
     * @memberof DefaultApiMakersIdProductVariationsGet
     */
    readonly inStock?: boolean

    /**
     * 1ページあたりの表示件数(default: 20)
     * @type {number}
     * @memberof DefaultApiMakersIdProductVariationsGet
     */
    readonly limit?: number

    /**
     * ページ取得位置(default: 0)
     * @type {number}
     * @memberof DefaultApiMakersIdProductVariationsGet
     */
    readonly offset?: number
}

/**
 * Request parameters for makersIdProjectsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiMakersIdProjectsGetRequest
 */
export interface DefaultApiMakersIdProjectsGetRequest {
    /**
     * Maker ID
     * @type {number}
     * @memberof DefaultApiMakersIdProjectsGet
     */
    readonly id: number

    /**
     * 検索キーワード
     * @type {string}
     * @memberof DefaultApiMakersIdProjectsGet
     */
    readonly keyword?: string

    /**
     * 種類
     * @type {1 | 2}
     * @memberof DefaultApiMakersIdProjectsGet
     */
    readonly kind?: MakersIdProjectsGetKindEnum

    /**
     * カテゴリID
     * @type {number}
     * @memberof DefaultApiMakersIdProjectsGet
     */
    readonly categoryId?: number

    /**
     * カラーIDの配列
     * @type {Array<1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17>}
     * @memberof DefaultApiMakersIdProjectsGet
     */
    readonly colorTypeIds?: Array<MakersIdProjectsGetColorTypeIdsEnum>

    /**
     * 防火材料
     * @type {1 | 2 | 3}
     * @memberof DefaultApiMakersIdProjectsGet
     */
    readonly fireProtectionMaterial?: MakersIdProjectsGetFireProtectionMaterialEnum

    /**
     * 防炎規制
     * @type {1}
     * @memberof DefaultApiMakersIdProjectsGet
     */
    readonly flamePreventionRegulation?: MakersIdProjectsGetFlamePreventionRegulationEnum

    /**
     * ホルムアルデヒド等級
     * @type {1 | 2 | 3}
     * @memberof DefaultApiMakersIdProjectsGet
     */
    readonly formaldehyde?: MakersIdProjectsGetFormaldehydeEnum

    /**
     * 素材ID
     * @type {number}
     * @memberof DefaultApiMakersIdProjectsGet
     */
    readonly materialId?: number

    /**
     * 最小幅
     * @type {number}
     * @memberof DefaultApiMakersIdProjectsGet
     */
    readonly minWidth?: number

    /**
     * 最小幅
     * @type {number}
     * @memberof DefaultApiMakersIdProjectsGet
     */
    readonly maxWidth?: number

    /**
     * 最小高さ
     * @type {number}
     * @memberof DefaultApiMakersIdProjectsGet
     */
    readonly minHeight?: number

    /**
     * 最小高さ
     * @type {number}
     * @memberof DefaultApiMakersIdProjectsGet
     */
    readonly maxHeight?: number

    /**
     * 最小奥行き
     * @type {number}
     * @memberof DefaultApiMakersIdProjectsGet
     */
    readonly minDepth?: number

    /**
     * 最小奥行き
     * @type {number}
     * @memberof DefaultApiMakersIdProjectsGet
     */
    readonly maxDepth?: number

    /**
     * 最低価格
     * @type {number}
     * @memberof DefaultApiMakersIdProjectsGet
     */
    readonly minPrice?: number

    /**
     * 最高価格
     * @type {number}
     * @memberof DefaultApiMakersIdProjectsGet
     */
    readonly maxPrice?: number

    /**
     * 耐火性能
     * @type {1 | 2 | 3 | 4 | 5}
     * @memberof DefaultApiMakersIdProjectsGet
     */
    readonly fireResistance?: MakersIdProjectsGetFireResistanceEnum

    /**
     * 在庫あり
     * @type {boolean}
     * @memberof DefaultApiMakersIdProjectsGet
     */
    readonly inStock?: boolean

    /**
     * 1ページあたりの表示件数(default: 20)
     * @type {number}
     * @memberof DefaultApiMakersIdProjectsGet
     */
    readonly limit?: number

    /**
     * ページ取得位置(default: 0)
     * @type {number}
     * @memberof DefaultApiMakersIdProjectsGet
     */
    readonly offset?: number
}

/**
 * Request parameters for makersIncrementalSearchGet operation in DefaultApi.
 * @export
 * @interface DefaultApiMakersIncrementalSearchGetRequest
 */
export interface DefaultApiMakersIncrementalSearchGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiMakersIncrementalSearchGet
     */
    readonly keyword?: string

    /**
     * 1ページあたりの表示件数(default: 20)
     * @type {number}
     * @memberof DefaultApiMakersIncrementalSearchGet
     */
    readonly limit?: number

    /**
     * offset(default: 0)
     * @type {number}
     * @memberof DefaultApiMakersIncrementalSearchGet
     */
    readonly offset?: number
}

/**
 * Request parameters for makersSearchGet operation in DefaultApi.
 * @export
 * @interface DefaultApiMakersSearchGetRequest
 */
export interface DefaultApiMakersSearchGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiMakersSearchGet
     */
    readonly keyword?: string

    /**
     * 1ページあたりの表示件数(default: 20)
     * @type {number}
     * @memberof DefaultApiMakersSearchGet
     */
    readonly limit?: number

    /**
     * offset(default: 0)
     * @type {number}
     * @memberof DefaultApiMakersSearchGet
     */
    readonly offset?: number
}

/**
 * Request parameters for newsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiNewsGetRequest
 */
export interface DefaultApiNewsGetRequest {
    /**
     * 1ページあたりの表示件数(default: 20)
     * @type {number}
     * @memberof DefaultApiNewsGet
     */
    readonly limit?: number

    /**
     * 取得カーソル
     * @type {string}
     * @memberof DefaultApiNewsGet
     */
    readonly cursor?: string
}

/**
 * Request parameters for notificationsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiNotificationsGetRequest
 */
export interface DefaultApiNotificationsGetRequest {
    /**
     * 1ページあたりの表示件数(default: 20)
     * @type {number}
     * @memberof DefaultApiNotificationsGet
     */
    readonly limit?: number

    /**
     * 取得カーソル
     * @type {number}
     * @memberof DefaultApiNotificationsGet
     */
    readonly cursor?: number
}

/**
 * Request parameters for notificationsIdReadPost operation in DefaultApi.
 * @export
 * @interface DefaultApiNotificationsIdReadPostRequest
 */
export interface DefaultApiNotificationsIdReadPostRequest {
    /**
     * Notification ID
     * @type {number}
     * @memberof DefaultApiNotificationsIdReadPost
     */
    readonly id: number
}

/**
 * Request parameters for orderGroupsCodeCancelPost operation in DefaultApi.
 * @export
 * @interface DefaultApiOrderGroupsCodeCancelPostRequest
 */
export interface DefaultApiOrderGroupsCodeCancelPostRequest {
    /**
     * Order code
     * @type {string}
     * @memberof DefaultApiOrderGroupsCodeCancelPost
     */
    readonly code: string
}

/**
 * Request parameters for orderGroupsCodeGet operation in DefaultApi.
 * @export
 * @interface DefaultApiOrderGroupsCodeGetRequest
 */
export interface DefaultApiOrderGroupsCodeGetRequest {
    /**
     * Order code
     * @type {string}
     * @memberof DefaultApiOrderGroupsCodeGet
     */
    readonly code: string
}

/**
 * Request parameters for orderGroupsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiOrderGroupsGetRequest
 */
export interface DefaultApiOrderGroupsGetRequest {
    /**
     * 1ページあたりの表示件数(default: 20)
     * @type {number}
     * @memberof DefaultApiOrderGroupsGet
     */
    readonly limit?: number

    /**
     * 取得カーソル
     * @type {number}
     * @memberof DefaultApiOrderGroupsGet
     */
    readonly cursor?: number
}

/**
 * Request parameters for ordersPost operation in DefaultApi.
 * @export
 * @interface DefaultApiOrdersPostRequest
 */
export interface DefaultApiOrdersPostRequest {
    /**
     * オーダー作成パラメータ
     * @type {OrderCreateParameter}
     * @memberof DefaultApiOrdersPost
     */
    readonly orderCreateParameter: OrderCreateParameter
}

/**
 * Request parameters for postsFollowingGet operation in DefaultApi.
 * @export
 * @interface DefaultApiPostsFollowingGetRequest
 */
export interface DefaultApiPostsFollowingGetRequest {
    /**
     * 1ページあたりの表示件数(default: 20)
     * @type {number}
     * @memberof DefaultApiPostsFollowingGet
     */
    readonly limit?: number

    /**
     * 取得カーソル
     * @type {number}
     * @memberof DefaultApiPostsFollowingGet
     */
    readonly cursor?: number
}

/**
 * Request parameters for postsIdGet operation in DefaultApi.
 * @export
 * @interface DefaultApiPostsIdGetRequest
 */
export interface DefaultApiPostsIdGetRequest {
    /**
     * Post ID
     * @type {number}
     * @memberof DefaultApiPostsIdGet
     */
    readonly id: number

    /**
     * 非公開プロジェクトを閲覧するための共有トークン
     * @type {string}
     * @memberof DefaultApiPostsIdGet
     */
    readonly token?: string
}

/**
 * Request parameters for postsIdRecommendsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiPostsIdRecommendsGetRequest
 */
export interface DefaultApiPostsIdRecommendsGetRequest {
    /**
     * Post ID
     * @type {number}
     * @memberof DefaultApiPostsIdRecommendsGet
     */
    readonly id: number

    /**
     * 1ページあたりの表示件数(default: 20)
     * @type {number}
     * @memberof DefaultApiPostsIdRecommendsGet
     */
    readonly limit?: number

    /**
     * ページ(default: 1)
     * @type {number}
     * @memberof DefaultApiPostsIdRecommendsGet
     */
    readonly page?: number
}

/**
 * Request parameters for postsSearchByIDsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiPostsSearchByIDsGetRequest
 */
export interface DefaultApiPostsSearchByIDsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiPostsSearchByIDsGet
     */
    readonly ids: string
}

/**
 * Request parameters for postsSearchGet operation in DefaultApi.
 * @export
 * @interface DefaultApiPostsSearchGetRequest
 */
export interface DefaultApiPostsSearchGetRequest {
    /**
     * 検索キーワード
     * @type {string}
     * @memberof DefaultApiPostsSearchGet
     */
    readonly keyword?: string

    /**
     * building_typeのID
     * @type {number}
     * @memberof DefaultApiPostsSearchGet
     */
    readonly buildingTypeId?: number

    /**
     * 構造種別のID
     * @type {number}
     * @memberof DefaultApiPostsSearchGet
     */
    readonly structureTypeId?: number

    /**
     * 工事種別
     * @type {1 | 2}
     * @memberof DefaultApiPostsSearchGet
     */
    readonly constructionType?: PostsSearchGetConstructionTypeEnum

    /**
     * 最小床面積
     * @type {number}
     * @memberof DefaultApiPostsSearchGet
     */
    readonly minFloorSpace?: number

    /**
     * 最大床面積
     * @type {number}
     * @memberof DefaultApiPostsSearchGet
     */
    readonly maxFloorSpace?: number

    /**
     * 素材ID
     * @type {number}
     * @memberof DefaultApiPostsSearchGet
     */
    readonly materialId?: number

    /**
     * カラーIDの配列
     * @type {Array<1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17>}
     * @memberof DefaultApiPostsSearchGet
     */
    readonly colorTypeIds?: Array<PostsSearchGetColorTypeIdsEnum>

    /**
     * 使用箇所の配列
     * @type {Array<1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11>}
     * @memberof DefaultApiPostsSearchGet
     */
    readonly partIds?: Array<PostsSearchGetPartIdsEnum>

    /**
     * 用途のID
     * @type {number}
     * @memberof DefaultApiPostsSearchGet
     */
    readonly useTypeId?: number

    /**
     * 1ページあたりの表示件数(default: 20)
     * @type {number}
     * @memberof DefaultApiPostsSearchGet
     */
    readonly limit?: number

    /**
     * ページ取得位置(default: 0)
     * @type {number}
     * @memberof DefaultApiPostsSearchGet
     */
    readonly offset?: number

    /**
     * ソート条件
     * @type {'newest' | 'popular' | 'random'}
     * @memberof DefaultApiPostsSearchGet
     */
    readonly sort?: PostsSearchGetSortEnum

    /**
     * 除外するProjectID
     * @type {number}
     * @memberof DefaultApiPostsSearchGet
     */
    readonly excludeProjectId?: number
}

/**
 * Request parameters for postsSearchHitCountsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiPostsSearchHitCountsGetRequest
 */
export interface DefaultApiPostsSearchHitCountsGetRequest {
    /**
     * 検索キーワード
     * @type {string}
     * @memberof DefaultApiPostsSearchHitCountsGet
     */
    readonly keyword?: string

    /**
     * building_typeのID
     * @type {number}
     * @memberof DefaultApiPostsSearchHitCountsGet
     */
    readonly buildingTypeId?: number

    /**
     * 構造種別のID
     * @type {number}
     * @memberof DefaultApiPostsSearchHitCountsGet
     */
    readonly structureTypeId?: number

    /**
     * 工事種別
     * @type {1 | 2}
     * @memberof DefaultApiPostsSearchHitCountsGet
     */
    readonly constructionType?: PostsSearchHitCountsGetConstructionTypeEnum

    /**
     * 最小床面積
     * @type {number}
     * @memberof DefaultApiPostsSearchHitCountsGet
     */
    readonly minFloorSpace?: number

    /**
     * 最大床面積
     * @type {number}
     * @memberof DefaultApiPostsSearchHitCountsGet
     */
    readonly maxFloorSpace?: number

    /**
     * 素材ID
     * @type {number}
     * @memberof DefaultApiPostsSearchHitCountsGet
     */
    readonly materialId?: number

    /**
     * カラーIDの配列
     * @type {Array<1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17>}
     * @memberof DefaultApiPostsSearchHitCountsGet
     */
    readonly colorTypeIds?: Array<PostsSearchHitCountsGetColorTypeIdsEnum>

    /**
     * 使用箇所の配列
     * @type {Array<1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11>}
     * @memberof DefaultApiPostsSearchHitCountsGet
     */
    readonly partIds?: Array<PostsSearchHitCountsGetPartIdsEnum>

    /**
     * 用途のID
     * @type {number}
     * @memberof DefaultApiPostsSearchHitCountsGet
     */
    readonly useTypeId?: number

    /**
     * 1ページあたりの表示件数(default: 20)
     * @type {number}
     * @memberof DefaultApiPostsSearchHitCountsGet
     */
    readonly limit?: number

    /**
     * ページ取得位置(default: 0)
     * @type {number}
     * @memberof DefaultApiPostsSearchHitCountsGet
     */
    readonly offset?: number

    /**
     * ソート条件
     * @type {'newest' | 'popular' | 'random'}
     * @memberof DefaultApiPostsSearchHitCountsGet
     */
    readonly sort?: PostsSearchHitCountsGetSortEnum

    /**
     * 除外するProjectID
     * @type {number}
     * @memberof DefaultApiPostsSearchHitCountsGet
     */
    readonly excludeProjectId?: number
}

/**
 * Request parameters for productVariationsIdPostsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiProductVariationsIdPostsGetRequest
 */
export interface DefaultApiProductVariationsIdPostsGetRequest {
    /**
     * ProductVariation ID
     * @type {number}
     * @memberof DefaultApiProductVariationsIdPostsGet
     */
    readonly id: number

    /**
     * 1ページあたりの表示件数(default: 20)
     * @type {number}
     * @memberof DefaultApiProductVariationsIdPostsGet
     */
    readonly limit?: number

    /**
     * ページ(default: 1)
     * @type {number}
     * @memberof DefaultApiProductVariationsIdPostsGet
     */
    readonly page?: number
}

/**
 * Request parameters for productVariationsIdSimilaritiesGet operation in DefaultApi.
 * @export
 * @interface DefaultApiProductVariationsIdSimilaritiesGetRequest
 */
export interface DefaultApiProductVariationsIdSimilaritiesGetRequest {
    /**
     * ProductVariation ID
     * @type {number}
     * @memberof DefaultApiProductVariationsIdSimilaritiesGet
     */
    readonly id: number

    /**
     * 検索軸
     * @type {SimilarityAxis}
     * @memberof DefaultApiProductVariationsIdSimilaritiesGet
     */
    readonly axis?: SimilarityAxis
}

/**
 * Request parameters for productVariationsIncrementalSearchGet operation in DefaultApi.
 * @export
 * @interface DefaultApiProductVariationsIncrementalSearchGetRequest
 */
export interface DefaultApiProductVariationsIncrementalSearchGetRequest {
    /**
     * メーカーID
     * @type {number}
     * @memberof DefaultApiProductVariationsIncrementalSearchGet
     */
    readonly makerId?: number

    /**
     * 検索キーワード
     * @type {string}
     * @memberof DefaultApiProductVariationsIncrementalSearchGet
     */
    readonly keyword?: string

    /**
     * 1ページあたりの表示件数(default: 20)
     * @type {number}
     * @memberof DefaultApiProductVariationsIncrementalSearchGet
     */
    readonly limit?: number

    /**
     * ページ取得位置(default: 0)
     * @type {number}
     * @memberof DefaultApiProductVariationsIncrementalSearchGet
     */
    readonly offset?: number
}

/**
 * Request parameters for productVariationsRecommendationsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiProductVariationsRecommendationsGetRequest
 */
export interface DefaultApiProductVariationsRecommendationsGetRequest {
    /**
     * ProductVariationIDの配列
     * @type {Array<number>}
     * @memberof DefaultApiProductVariationsRecommendationsGet
     */
    readonly productVariationIds: Array<number>
}

/**
 * Request parameters for productVariationsSearchByIDsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiProductVariationsSearchByIDsGetRequest
 */
export interface DefaultApiProductVariationsSearchByIDsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiProductVariationsSearchByIDsGet
     */
    readonly ids: string
}

/**
 * Request parameters for productVariationsSearchGet operation in DefaultApi.
 * @export
 * @interface DefaultApiProductVariationsSearchGetRequest
 */
export interface DefaultApiProductVariationsSearchGetRequest {
    /**
     * 検索キーワード
     * @type {string}
     * @memberof DefaultApiProductVariationsSearchGet
     */
    readonly keyword?: string

    /**
     * 種類
     * @type {1 | 2}
     * @memberof DefaultApiProductVariationsSearchGet
     */
    readonly kind?: ProductVariationsSearchGetKindEnum

    /**
     * カテゴリID
     * @type {number}
     * @memberof DefaultApiProductVariationsSearchGet
     */
    readonly categoryId?: number

    /**
     * カラーIDの配列
     * @type {Array<1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17>}
     * @memberof DefaultApiProductVariationsSearchGet
     */
    readonly colorTypeIds?: Array<ProductVariationsSearchGetColorTypeIdsEnum>

    /**
     * 防火材料
     * @type {1 | 2 | 3}
     * @memberof DefaultApiProductVariationsSearchGet
     */
    readonly fireProtectionMaterial?: ProductVariationsSearchGetFireProtectionMaterialEnum

    /**
     * 防炎規制
     * @type {1}
     * @memberof DefaultApiProductVariationsSearchGet
     */
    readonly flamePreventionRegulation?: ProductVariationsSearchGetFlamePreventionRegulationEnum

    /**
     * ホルムアルデヒド等級
     * @type {1 | 2 | 3}
     * @memberof DefaultApiProductVariationsSearchGet
     */
    readonly formaldehyde?: ProductVariationsSearchGetFormaldehydeEnum

    /**
     * 素材ID
     * @type {number}
     * @memberof DefaultApiProductVariationsSearchGet
     */
    readonly materialId?: number

    /**
     * 最小幅
     * @type {number}
     * @memberof DefaultApiProductVariationsSearchGet
     */
    readonly minWidth?: number

    /**
     * 最小幅
     * @type {number}
     * @memberof DefaultApiProductVariationsSearchGet
     */
    readonly maxWidth?: number

    /**
     * 最小高さ
     * @type {number}
     * @memberof DefaultApiProductVariationsSearchGet
     */
    readonly minHeight?: number

    /**
     * 最小高さ
     * @type {number}
     * @memberof DefaultApiProductVariationsSearchGet
     */
    readonly maxHeight?: number

    /**
     * 最小奥行き
     * @type {number}
     * @memberof DefaultApiProductVariationsSearchGet
     */
    readonly minDepth?: number

    /**
     * 最小奥行き
     * @type {number}
     * @memberof DefaultApiProductVariationsSearchGet
     */
    readonly maxDepth?: number

    /**
     * 最低価格
     * @type {number}
     * @memberof DefaultApiProductVariationsSearchGet
     */
    readonly minPrice?: number

    /**
     * 最高価格
     * @type {number}
     * @memberof DefaultApiProductVariationsSearchGet
     */
    readonly maxPrice?: number

    /**
     * 耐火性能
     * @type {1 | 2 | 3 | 4 | 5}
     * @memberof DefaultApiProductVariationsSearchGet
     */
    readonly fireResistance?: ProductVariationsSearchGetFireResistanceEnum

    /**
     * 在庫あり
     * @type {boolean}
     * @memberof DefaultApiProductVariationsSearchGet
     */
    readonly inStock?: boolean

    /**
     * 1ページあたりの表示件数(default: 20)
     * @type {number}
     * @memberof DefaultApiProductVariationsSearchGet
     */
    readonly limit?: number

    /**
     * ページ取得位置(default: 0)
     * @type {number}
     * @memberof DefaultApiProductVariationsSearchGet
     */
    readonly offset?: number
}

/**
 * Request parameters for productVariationsSearchHitCountsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiProductVariationsSearchHitCountsGetRequest
 */
export interface DefaultApiProductVariationsSearchHitCountsGetRequest {
    /**
     * 検索キーワード
     * @type {string}
     * @memberof DefaultApiProductVariationsSearchHitCountsGet
     */
    readonly keyword?: string

    /**
     * 種類
     * @type {1 | 2}
     * @memberof DefaultApiProductVariationsSearchHitCountsGet
     */
    readonly kind?: ProductVariationsSearchHitCountsGetKindEnum

    /**
     * カテゴリID
     * @type {number}
     * @memberof DefaultApiProductVariationsSearchHitCountsGet
     */
    readonly categoryId?: number

    /**
     * カラーIDの配列
     * @type {Array<1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17>}
     * @memberof DefaultApiProductVariationsSearchHitCountsGet
     */
    readonly colorTypeIds?: Array<ProductVariationsSearchHitCountsGetColorTypeIdsEnum>

    /**
     * 防火材料
     * @type {1 | 2 | 3}
     * @memberof DefaultApiProductVariationsSearchHitCountsGet
     */
    readonly fireProtectionMaterial?: ProductVariationsSearchHitCountsGetFireProtectionMaterialEnum

    /**
     * 防炎規制
     * @type {1}
     * @memberof DefaultApiProductVariationsSearchHitCountsGet
     */
    readonly flamePreventionRegulation?: ProductVariationsSearchHitCountsGetFlamePreventionRegulationEnum

    /**
     * ホルムアルデヒド等級
     * @type {1 | 2 | 3}
     * @memberof DefaultApiProductVariationsSearchHitCountsGet
     */
    readonly formaldehyde?: ProductVariationsSearchHitCountsGetFormaldehydeEnum

    /**
     * 素材ID
     * @type {number}
     * @memberof DefaultApiProductVariationsSearchHitCountsGet
     */
    readonly materialId?: number

    /**
     * 最小幅
     * @type {number}
     * @memberof DefaultApiProductVariationsSearchHitCountsGet
     */
    readonly minWidth?: number

    /**
     * 最小幅
     * @type {number}
     * @memberof DefaultApiProductVariationsSearchHitCountsGet
     */
    readonly maxWidth?: number

    /**
     * 最小高さ
     * @type {number}
     * @memberof DefaultApiProductVariationsSearchHitCountsGet
     */
    readonly minHeight?: number

    /**
     * 最小高さ
     * @type {number}
     * @memberof DefaultApiProductVariationsSearchHitCountsGet
     */
    readonly maxHeight?: number

    /**
     * 最小奥行き
     * @type {number}
     * @memberof DefaultApiProductVariationsSearchHitCountsGet
     */
    readonly minDepth?: number

    /**
     * 最小奥行き
     * @type {number}
     * @memberof DefaultApiProductVariationsSearchHitCountsGet
     */
    readonly maxDepth?: number

    /**
     * 最低価格
     * @type {number}
     * @memberof DefaultApiProductVariationsSearchHitCountsGet
     */
    readonly minPrice?: number

    /**
     * 最高価格
     * @type {number}
     * @memberof DefaultApiProductVariationsSearchHitCountsGet
     */
    readonly maxPrice?: number

    /**
     * 耐火性能
     * @type {1 | 2 | 3 | 4 | 5}
     * @memberof DefaultApiProductVariationsSearchHitCountsGet
     */
    readonly fireResistance?: ProductVariationsSearchHitCountsGetFireResistanceEnum

    /**
     * 在庫あり
     * @type {boolean}
     * @memberof DefaultApiProductVariationsSearchHitCountsGet
     */
    readonly inStock?: boolean

    /**
     * 1ページあたりの表示件数(default: 20)
     * @type {number}
     * @memberof DefaultApiProductVariationsSearchHitCountsGet
     */
    readonly limit?: number

    /**
     * ページ取得位置(default: 0)
     * @type {number}
     * @memberof DefaultApiProductVariationsSearchHitCountsGet
     */
    readonly offset?: number
}

/**
 * Request parameters for productVariationsSearchPostsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiProductVariationsSearchPostsGetRequest
 */
export interface DefaultApiProductVariationsSearchPostsGetRequest {
    /**
     * 検索キーワード
     * @type {string}
     * @memberof DefaultApiProductVariationsSearchPostsGet
     */
    readonly keyword?: string

    /**
     * 種類
     * @type {1 | 2}
     * @memberof DefaultApiProductVariationsSearchPostsGet
     */
    readonly kind?: ProductVariationsSearchPostsGetKindEnum

    /**
     * カテゴリID
     * @type {number}
     * @memberof DefaultApiProductVariationsSearchPostsGet
     */
    readonly categoryId?: number

    /**
     * カラーIDの配列
     * @type {Array<1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17>}
     * @memberof DefaultApiProductVariationsSearchPostsGet
     */
    readonly colorTypeIds?: Array<ProductVariationsSearchPostsGetColorTypeIdsEnum>

    /**
     * 防火材料
     * @type {1 | 2 | 3}
     * @memberof DefaultApiProductVariationsSearchPostsGet
     */
    readonly fireProtectionMaterial?: ProductVariationsSearchPostsGetFireProtectionMaterialEnum

    /**
     * 防炎規制
     * @type {1}
     * @memberof DefaultApiProductVariationsSearchPostsGet
     */
    readonly flamePreventionRegulation?: ProductVariationsSearchPostsGetFlamePreventionRegulationEnum

    /**
     * ホルムアルデヒド等級
     * @type {1 | 2 | 3}
     * @memberof DefaultApiProductVariationsSearchPostsGet
     */
    readonly formaldehyde?: ProductVariationsSearchPostsGetFormaldehydeEnum

    /**
     * 素材ID
     * @type {number}
     * @memberof DefaultApiProductVariationsSearchPostsGet
     */
    readonly materialId?: number

    /**
     * 最小幅
     * @type {number}
     * @memberof DefaultApiProductVariationsSearchPostsGet
     */
    readonly minWidth?: number

    /**
     * 最小幅
     * @type {number}
     * @memberof DefaultApiProductVariationsSearchPostsGet
     */
    readonly maxWidth?: number

    /**
     * 最小高さ
     * @type {number}
     * @memberof DefaultApiProductVariationsSearchPostsGet
     */
    readonly minHeight?: number

    /**
     * 最小高さ
     * @type {number}
     * @memberof DefaultApiProductVariationsSearchPostsGet
     */
    readonly maxHeight?: number

    /**
     * 最小奥行き
     * @type {number}
     * @memberof DefaultApiProductVariationsSearchPostsGet
     */
    readonly minDepth?: number

    /**
     * 最小奥行き
     * @type {number}
     * @memberof DefaultApiProductVariationsSearchPostsGet
     */
    readonly maxDepth?: number

    /**
     * 最低価格
     * @type {number}
     * @memberof DefaultApiProductVariationsSearchPostsGet
     */
    readonly minPrice?: number

    /**
     * 最高価格
     * @type {number}
     * @memberof DefaultApiProductVariationsSearchPostsGet
     */
    readonly maxPrice?: number

    /**
     * 耐火性能
     * @type {1 | 2 | 3 | 4 | 5}
     * @memberof DefaultApiProductVariationsSearchPostsGet
     */
    readonly fireResistance?: ProductVariationsSearchPostsGetFireResistanceEnum

    /**
     * 在庫あり
     * @type {boolean}
     * @memberof DefaultApiProductVariationsSearchPostsGet
     */
    readonly inStock?: boolean

    /**
     * 1ページあたりの表示件数(default: 20)
     * @type {number}
     * @memberof DefaultApiProductVariationsSearchPostsGet
     */
    readonly limit?: number

    /**
     * ページ取得位置(default: 0)
     * @type {number}
     * @memberof DefaultApiProductVariationsSearchPostsGet
     */
    readonly offset?: number
}

/**
 * Request parameters for productsIdGet operation in DefaultApi.
 * @export
 * @interface DefaultApiProductsIdGetRequest
 */
export interface DefaultApiProductsIdGetRequest {
    /**
     * Product ID
     * @type {number}
     * @memberof DefaultApiProductsIdGet
     */
    readonly id: number
}

/**
 * Request parameters for productsIdPostsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiProductsIdPostsGetRequest
 */
export interface DefaultApiProductsIdPostsGetRequest {
    /**
     * Product ID
     * @type {number}
     * @memberof DefaultApiProductsIdPostsGet
     */
    readonly id: number

    /**
     * 1ページあたりの表示件数(default: 20)
     * @type {number}
     * @memberof DefaultApiProductsIdPostsGet
     */
    readonly limit?: number

    /**
     * 取得カーソル
     * @type {number}
     * @memberof DefaultApiProductsIdPostsGet
     */
    readonly cursor?: number
}

/**
 * Request parameters for productsIdSampleItemsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiProductsIdSampleItemsGetRequest
 */
export interface DefaultApiProductsIdSampleItemsGetRequest {
    /**
     * Product ID
     * @type {number}
     * @memberof DefaultApiProductsIdSampleItemsGet
     */
    readonly id: number
}

/**
 * Request parameters for projectsFollowingGet operation in DefaultApi.
 * @export
 * @interface DefaultApiProjectsFollowingGetRequest
 */
export interface DefaultApiProjectsFollowingGetRequest {
    /**
     * 1ページあたりの表示件数(default: 20)
     * @type {number}
     * @memberof DefaultApiProjectsFollowingGet
     */
    readonly limit?: number

    /**
     * 取得カーソル
     * @type {number}
     * @memberof DefaultApiProjectsFollowingGet
     */
    readonly cursor?: number
}

/**
 * Request parameters for projectsIdAttachmentsAttachmentIdDelete operation in DefaultApi.
 * @export
 * @interface DefaultApiProjectsIdAttachmentsAttachmentIdDeleteRequest
 */
export interface DefaultApiProjectsIdAttachmentsAttachmentIdDeleteRequest {
    /**
     * Project ID
     * @type {number}
     * @memberof DefaultApiProjectsIdAttachmentsAttachmentIdDelete
     */
    readonly id: number

    /**
     * attachment ID
     * @type {number}
     * @memberof DefaultApiProjectsIdAttachmentsAttachmentIdDelete
     */
    readonly attachmentId: number
}

/**
 * Request parameters for projectsIdAttachmentsAttachmentIdOrderPatch operation in DefaultApi.
 * @export
 * @interface DefaultApiProjectsIdAttachmentsAttachmentIdOrderPatchRequest
 */
export interface DefaultApiProjectsIdAttachmentsAttachmentIdOrderPatchRequest {
    /**
     * Project ID
     * @type {number}
     * @memberof DefaultApiProjectsIdAttachmentsAttachmentIdOrderPatch
     */
    readonly id: number

    /**
     * attachment ID
     * @type {number}
     * @memberof DefaultApiProjectsIdAttachmentsAttachmentIdOrderPatch
     */
    readonly attachmentId: number

    /**
     * 並び替えパラメータ
     * @type {OrderParameter}
     * @memberof DefaultApiProjectsIdAttachmentsAttachmentIdOrderPatch
     */
    readonly orderParameter: OrderParameter
}

/**
 * Request parameters for projectsIdAttachmentsAttachmentIdPut operation in DefaultApi.
 * @export
 * @interface DefaultApiProjectsIdAttachmentsAttachmentIdPutRequest
 */
export interface DefaultApiProjectsIdAttachmentsAttachmentIdPutRequest {
    /**
     * Project ID
     * @type {number}
     * @memberof DefaultApiProjectsIdAttachmentsAttachmentIdPut
     */
    readonly id: number

    /**
     * attachment ID
     * @type {number}
     * @memberof DefaultApiProjectsIdAttachmentsAttachmentIdPut
     */
    readonly attachmentId: number

    /**
     * attachment title
     * @type {string}
     * @memberof DefaultApiProjectsIdAttachmentsAttachmentIdPut
     */
    readonly title: string

    /**
     * 
     * @type {ProjectAttachmentType}
     * @memberof DefaultApiProjectsIdAttachmentsAttachmentIdPut
     */
    readonly attachmentType?: ProjectAttachmentType

    /**
     * 
     * @type {File}
     * @memberof DefaultApiProjectsIdAttachmentsAttachmentIdPut
     */
    readonly image?: File
}

/**
 * Request parameters for projectsIdAttachmentsPost operation in DefaultApi.
 * @export
 * @interface DefaultApiProjectsIdAttachmentsPostRequest
 */
export interface DefaultApiProjectsIdAttachmentsPostRequest {
    /**
     * Project ID
     * @type {number}
     * @memberof DefaultApiProjectsIdAttachmentsPost
     */
    readonly id: number

    /**
     * attachment title
     * @type {string}
     * @memberof DefaultApiProjectsIdAttachmentsPost
     */
    readonly title: string

    /**
     * 
     * @type {File}
     * @memberof DefaultApiProjectsIdAttachmentsPost
     */
    readonly image: File

    /**
     * 
     * @type {ProjectAttachmentType}
     * @memberof DefaultApiProjectsIdAttachmentsPost
     */
    readonly attachmentType?: ProjectAttachmentType
}

/**
 * Request parameters for projectsIdDelete operation in DefaultApi.
 * @export
 * @interface DefaultApiProjectsIdDeleteRequest
 */
export interface DefaultApiProjectsIdDeleteRequest {
    /**
     * Project ID
     * @type {number}
     * @memberof DefaultApiProjectsIdDelete
     */
    readonly id: number
}

/**
 * Request parameters for projectsIdGet operation in DefaultApi.
 * @export
 * @interface DefaultApiProjectsIdGetRequest
 */
export interface DefaultApiProjectsIdGetRequest {
    /**
     * Project ID
     * @type {number}
     * @memberof DefaultApiProjectsIdGet
     */
    readonly id: number

    /**
     * 非公開プロジェクトを閲覧するための共有トークン
     * @type {string}
     * @memberof DefaultApiProjectsIdGet
     */
    readonly token?: string
}

/**
 * Request parameters for projectsIdPinsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiProjectsIdPinsGetRequest
 */
export interface DefaultApiProjectsIdPinsGetRequest {
    /**
     * Project ID
     * @type {number}
     * @memberof DefaultApiProjectsIdPinsGet
     */
    readonly id: number
}

/**
 * Request parameters for projectsIdPostsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiProjectsIdPostsGetRequest
 */
export interface DefaultApiProjectsIdPostsGetRequest {
    /**
     * Project ID
     * @type {number}
     * @memberof DefaultApiProjectsIdPostsGet
     */
    readonly id: number

    /**
     * 1ページあたりの表示件数(default: 20)
     * @type {number}
     * @memberof DefaultApiProjectsIdPostsGet
     */
    readonly limit?: number

    /**
     * 取得カーソル
     * @type {number}
     * @memberof DefaultApiProjectsIdPostsGet
     */
    readonly cursor?: number

    /**
     * 非公開プロジェクトを閲覧するための共有トークン
     * @type {string}
     * @memberof DefaultApiProjectsIdPostsGet
     */
    readonly token?: string
}

/**
 * Request parameters for projectsIdPostsPost operation in DefaultApi.
 * @export
 * @interface DefaultApiProjectsIdPostsPostRequest
 */
export interface DefaultApiProjectsIdPostsPostRequest {
    /**
     * Project ID
     * @type {number}
     * @memberof DefaultApiProjectsIdPostsPost
     */
    readonly id: number

    /**
     * 事例写真投稿パラメータ
     * @type {PostCreateParam}
     * @memberof DefaultApiProjectsIdPostsPost
     */
    readonly postCreateParam: PostCreateParam
}

/**
 * Request parameters for projectsIdPublishPost operation in DefaultApi.
 * @export
 * @interface DefaultApiProjectsIdPublishPostRequest
 */
export interface DefaultApiProjectsIdPublishPostRequest {
    /**
     * Project ID
     * @type {number}
     * @memberof DefaultApiProjectsIdPublishPost
     */
    readonly id: number
}

/**
 * Request parameters for projectsIdPut operation in DefaultApi.
 * @export
 * @interface DefaultApiProjectsIdPutRequest
 */
export interface DefaultApiProjectsIdPutRequest {
    /**
     * Project ID
     * @type {number}
     * @memberof DefaultApiProjectsIdPut
     */
    readonly id: number

    /**
     * プロジェクト更新パラメータ
     * @type {ProjectUpdateParam}
     * @memberof DefaultApiProjectsIdPut
     */
    readonly projectUpdateParam: ProjectUpdateParam
}

/**
 * Request parameters for projectsIdSharePost operation in DefaultApi.
 * @export
 * @interface DefaultApiProjectsIdSharePostRequest
 */
export interface DefaultApiProjectsIdSharePostRequest {
    /**
     * プロジェクトID
     * @type {number}
     * @memberof DefaultApiProjectsIdSharePost
     */
    readonly id: number
}

/**
 * Request parameters for projectsIdUnpublishPost operation in DefaultApi.
 * @export
 * @interface DefaultApiProjectsIdUnpublishPostRequest
 */
export interface DefaultApiProjectsIdUnpublishPostRequest {
    /**
     * Project ID
     * @type {number}
     * @memberof DefaultApiProjectsIdUnpublishPost
     */
    readonly id: number
}

/**
 * Request parameters for projectsPost operation in DefaultApi.
 * @export
 * @interface DefaultApiProjectsPostRequest
 */
export interface DefaultApiProjectsPostRequest {
    /**
     * プロジェクト投稿パラメータ
     * @type {ProjectCreateParam}
     * @memberof DefaultApiProjectsPost
     */
    readonly projectCreateParam: ProjectCreateParam
}

/**
 * Request parameters for projectsProjectIdPostsIdDelete operation in DefaultApi.
 * @export
 * @interface DefaultApiProjectsProjectIdPostsIdDeleteRequest
 */
export interface DefaultApiProjectsProjectIdPostsIdDeleteRequest {
    /**
     * Project ID
     * @type {number}
     * @memberof DefaultApiProjectsProjectIdPostsIdDelete
     */
    readonly projectId: number

    /**
     * Post ID
     * @type {number}
     * @memberof DefaultApiProjectsProjectIdPostsIdDelete
     */
    readonly id: number
}

/**
 * Request parameters for projectsProjectIdPostsIdOrderPatch operation in DefaultApi.
 * @export
 * @interface DefaultApiProjectsProjectIdPostsIdOrderPatchRequest
 */
export interface DefaultApiProjectsProjectIdPostsIdOrderPatchRequest {
    /**
     * Project ID
     * @type {number}
     * @memberof DefaultApiProjectsProjectIdPostsIdOrderPatch
     */
    readonly projectId: number

    /**
     * Post ID
     * @type {number}
     * @memberof DefaultApiProjectsProjectIdPostsIdOrderPatch
     */
    readonly id: number

    /**
     * 並び替えパラメータ
     * @type {OrderParameter}
     * @memberof DefaultApiProjectsProjectIdPostsIdOrderPatch
     */
    readonly orderParameter: OrderParameter
}

/**
 * Request parameters for projectsProjectIdPostsIdPut operation in DefaultApi.
 * @export
 * @interface DefaultApiProjectsProjectIdPostsIdPutRequest
 */
export interface DefaultApiProjectsProjectIdPostsIdPutRequest {
    /**
     * Project ID
     * @type {number}
     * @memberof DefaultApiProjectsProjectIdPostsIdPut
     */
    readonly projectId: number

    /**
     * Post ID
     * @type {number}
     * @memberof DefaultApiProjectsProjectIdPostsIdPut
     */
    readonly id: number

    /**
     * 事例写真更新パラメータ
     * @type {PostUpdateParam}
     * @memberof DefaultApiProjectsProjectIdPostsIdPut
     */
    readonly postUpdateParam: PostUpdateParam
}

/**
 * Request parameters for projectsSearchByIDsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiProjectsSearchByIDsGetRequest
 */
export interface DefaultApiProjectsSearchByIDsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiProjectsSearchByIDsGet
     */
    readonly ids: string
}

/**
 * Request parameters for projectsSearchGet operation in DefaultApi.
 * @export
 * @interface DefaultApiProjectsSearchGetRequest
 */
export interface DefaultApiProjectsSearchGetRequest {
    /**
     * 検索キーワード
     * @type {string}
     * @memberof DefaultApiProjectsSearchGet
     */
    readonly keyword?: string

    /**
     * building_typeのID
     * @type {number}
     * @memberof DefaultApiProjectsSearchGet
     */
    readonly buildingTypeId?: number

    /**
     * 構造種別のID
     * @type {number}
     * @memberof DefaultApiProjectsSearchGet
     */
    readonly structureTypeId?: number

    /**
     * 工事種別
     * @type {1 | 2}
     * @memberof DefaultApiProjectsSearchGet
     */
    readonly constructionType?: ProjectsSearchGetConstructionTypeEnum

    /**
     * 最小床面積
     * @type {number}
     * @memberof DefaultApiProjectsSearchGet
     */
    readonly minFloorSpace?: number

    /**
     * 最大床面積
     * @type {number}
     * @memberof DefaultApiProjectsSearchGet
     */
    readonly maxFloorSpace?: number

    /**
     * 素材ID
     * @type {number}
     * @memberof DefaultApiProjectsSearchGet
     */
    readonly materialId?: number

    /**
     * カラーIDの配列
     * @type {Array<1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17>}
     * @memberof DefaultApiProjectsSearchGet
     */
    readonly colorTypeIds?: Array<ProjectsSearchGetColorTypeIdsEnum>

    /**
     * 使用箇所の配列
     * @type {Array<1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11>}
     * @memberof DefaultApiProjectsSearchGet
     */
    readonly partIds?: Array<ProjectsSearchGetPartIdsEnum>

    /**
     * 用途のID
     * @type {number}
     * @memberof DefaultApiProjectsSearchGet
     */
    readonly useTypeId?: number

    /**
     * 1ページあたりの表示件数(default: 20)
     * @type {number}
     * @memberof DefaultApiProjectsSearchGet
     */
    readonly limit?: number

    /**
     * ページ取得位置(default: 0)
     * @type {number}
     * @memberof DefaultApiProjectsSearchGet
     */
    readonly offset?: number

    /**
     * ソート条件
     * @type {'newest' | 'popular' | 'random'}
     * @memberof DefaultApiProjectsSearchGet
     */
    readonly sort?: ProjectsSearchGetSortEnum

    /**
     * 除外するProjectID
     * @type {number}
     * @memberof DefaultApiProjectsSearchGet
     */
    readonly excludeProjectId?: number
}

/**
 * Request parameters for projectsSearchHitCountsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiProjectsSearchHitCountsGetRequest
 */
export interface DefaultApiProjectsSearchHitCountsGetRequest {
    /**
     * 検索キーワード
     * @type {string}
     * @memberof DefaultApiProjectsSearchHitCountsGet
     */
    readonly keyword?: string

    /**
     * building_typeのID
     * @type {number}
     * @memberof DefaultApiProjectsSearchHitCountsGet
     */
    readonly buildingTypeId?: number

    /**
     * 構造種別のID
     * @type {number}
     * @memberof DefaultApiProjectsSearchHitCountsGet
     */
    readonly structureTypeId?: number

    /**
     * 工事種別
     * @type {1 | 2}
     * @memberof DefaultApiProjectsSearchHitCountsGet
     */
    readonly constructionType?: ProjectsSearchHitCountsGetConstructionTypeEnum

    /**
     * 最小床面積
     * @type {number}
     * @memberof DefaultApiProjectsSearchHitCountsGet
     */
    readonly minFloorSpace?: number

    /**
     * 最大床面積
     * @type {number}
     * @memberof DefaultApiProjectsSearchHitCountsGet
     */
    readonly maxFloorSpace?: number

    /**
     * 素材ID
     * @type {number}
     * @memberof DefaultApiProjectsSearchHitCountsGet
     */
    readonly materialId?: number

    /**
     * カラーIDの配列
     * @type {Array<1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17>}
     * @memberof DefaultApiProjectsSearchHitCountsGet
     */
    readonly colorTypeIds?: Array<ProjectsSearchHitCountsGetColorTypeIdsEnum>

    /**
     * 使用箇所の配列
     * @type {Array<1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11>}
     * @memberof DefaultApiProjectsSearchHitCountsGet
     */
    readonly partIds?: Array<ProjectsSearchHitCountsGetPartIdsEnum>

    /**
     * 用途のID
     * @type {number}
     * @memberof DefaultApiProjectsSearchHitCountsGet
     */
    readonly useTypeId?: number

    /**
     * 1ページあたりの表示件数(default: 20)
     * @type {number}
     * @memberof DefaultApiProjectsSearchHitCountsGet
     */
    readonly limit?: number

    /**
     * ページ取得位置(default: 0)
     * @type {number}
     * @memberof DefaultApiProjectsSearchHitCountsGet
     */
    readonly offset?: number

    /**
     * ソート条件
     * @type {'newest' | 'popular' | 'random'}
     * @memberof DefaultApiProjectsSearchHitCountsGet
     */
    readonly sort?: ProjectsSearchHitCountsGetSortEnum

    /**
     * 除外するProjectID
     * @type {number}
     * @memberof DefaultApiProjectsSearchHitCountsGet
     */
    readonly excludeProjectId?: number
}

/**
 * Request parameters for searchGet operation in DefaultApi.
 * @export
 * @interface DefaultApiSearchGetRequest
 */
export interface DefaultApiSearchGetRequest {
    /**
     * 検索キーワード
     * @type {string}
     * @memberof DefaultApiSearchGet
     */
    readonly keyword: string

    /**
     * 取得件数(default: 8, max: 8)
     * @type {number}
     * @memberof DefaultApiSearchGet
     */
    readonly limit?: number
}

/**
 * Request parameters for settingsAccountPost operation in DefaultApi.
 * @export
 * @interface DefaultApiSettingsAccountPostRequest
 */
export interface DefaultApiSettingsAccountPostRequest {
    /**
     * アカウント設定パラメータ
     * @type {SettingsAccountPostRequest}
     * @memberof DefaultApiSettingsAccountPost
     */
    readonly settingsAccountPostRequest: SettingsAccountPostRequest
}

/**
 * Request parameters for settingsMailMagazinePost operation in DefaultApi.
 * @export
 * @interface DefaultApiSettingsMailMagazinePostRequest
 */
export interface DefaultApiSettingsMailMagazinePostRequest {
    /**
     * メルマガ設定パラメータ
     * @type {MailMagazineUpdateParam}
     * @memberof DefaultApiSettingsMailMagazinePost
     */
    readonly mailMagazineUpdateParam: MailMagazineUpdateParam
}

/**
 * Request parameters for settingsNotificationPost operation in DefaultApi.
 * @export
 * @interface DefaultApiSettingsNotificationPostRequest
 */
export interface DefaultApiSettingsNotificationPostRequest {
    /**
     * 通知設定パラメータ
     * @type {NotificationSettingsUpdateParameter}
     * @memberof DefaultApiSettingsNotificationPost
     */
    readonly notificationSettingsUpdateParameter: NotificationSettingsUpdateParameter
}

/**
 * Request parameters for settingsProfilePost operation in DefaultApi.
 * @export
 * @interface DefaultApiSettingsProfilePostRequest
 */
export interface DefaultApiSettingsProfilePostRequest {
    /**
     * プロフィール設定パラメータ
     * @type {UserProfileUpdateParam}
     * @memberof DefaultApiSettingsProfilePost
     */
    readonly userProfileUpdateParam: UserProfileUpdateParam
}

/**
 * Request parameters for suggestKeywordsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiSuggestKeywordsGetRequest
 */
export interface DefaultApiSuggestKeywordsGetRequest {
    /**
     * 検索キーワード
     * @type {string}
     * @memberof DefaultApiSuggestKeywordsGet
     */
    readonly keyword?: string
}

/**
 * Request parameters for teamsIdApproveJoinPost operation in DefaultApi.
 * @export
 * @interface DefaultApiTeamsIdApproveJoinPostRequest
 */
export interface DefaultApiTeamsIdApproveJoinPostRequest {
    /**
     * Team ID
     * @type {number}
     * @memberof DefaultApiTeamsIdApproveJoinPost
     */
    readonly id: number

    /**
     * tokenパラメータ
     * @type {OneTimeTokenParameter}
     * @memberof DefaultApiTeamsIdApproveJoinPost
     */
    readonly oneTimeTokenParameter: OneTimeTokenParameter
}

/**
 * Request parameters for teamsIdDelete operation in DefaultApi.
 * @export
 * @interface DefaultApiTeamsIdDeleteRequest
 */
export interface DefaultApiTeamsIdDeleteRequest {
    /**
     * Team ID
     * @type {number}
     * @memberof DefaultApiTeamsIdDelete
     */
    readonly id: number
}

/**
 * Request parameters for teamsIdGet operation in DefaultApi.
 * @export
 * @interface DefaultApiTeamsIdGetRequest
 */
export interface DefaultApiTeamsIdGetRequest {
    /**
     * Team ID
     * @type {number}
     * @memberof DefaultApiTeamsIdGet
     */
    readonly id: number
}

/**
 * Request parameters for teamsIdInvitePost operation in DefaultApi.
 * @export
 * @interface DefaultApiTeamsIdInvitePostRequest
 */
export interface DefaultApiTeamsIdInvitePostRequest {
    /**
     * Team ID
     * @type {number}
     * @memberof DefaultApiTeamsIdInvitePost
     */
    readonly id: number

    /**
     * チームメンバー招待パラメータ
     * @type {TeamMemberInviteParameter}
     * @memberof DefaultApiTeamsIdInvitePost
     */
    readonly teamMemberInviteParameter: TeamMemberInviteParameter
}

/**
 * Request parameters for teamsIdJoinPost operation in DefaultApi.
 * @export
 * @interface DefaultApiTeamsIdJoinPostRequest
 */
export interface DefaultApiTeamsIdJoinPostRequest {
    /**
     * Team ID
     * @type {number}
     * @memberof DefaultApiTeamsIdJoinPost
     */
    readonly id: number

    /**
     * tokenパラメータ
     * @type {OneTimeTokenParameter}
     * @memberof DefaultApiTeamsIdJoinPost
     */
    readonly oneTimeTokenParameter: OneTimeTokenParameter
}

/**
 * Request parameters for teamsIdMembersGet operation in DefaultApi.
 * @export
 * @interface DefaultApiTeamsIdMembersGetRequest
 */
export interface DefaultApiTeamsIdMembersGetRequest {
    /**
     * Team ID
     * @type {number}
     * @memberof DefaultApiTeamsIdMembersGet
     */
    readonly id: number
}

/**
 * Request parameters for teamsIdProjectsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiTeamsIdProjectsGetRequest
 */
export interface DefaultApiTeamsIdProjectsGetRequest {
    /**
     * Team ID
     * @type {number}
     * @memberof DefaultApiTeamsIdProjectsGet
     */
    readonly id: number

    /**
     * 1ページあたりの表示件数(default: 20)
     * @type {number}
     * @memberof DefaultApiTeamsIdProjectsGet
     */
    readonly limit?: number

    /**
     * ページ(default: 1)
     * @type {number}
     * @memberof DefaultApiTeamsIdProjectsGet
     */
    readonly page?: number

    /**
     * 除外するProjectID
     * @type {number}
     * @memberof DefaultApiTeamsIdProjectsGet
     */
    readonly excludeProjectId?: number
}

/**
 * Request parameters for teamsIdPut operation in DefaultApi.
 * @export
 * @interface DefaultApiTeamsIdPutRequest
 */
export interface DefaultApiTeamsIdPutRequest {
    /**
     * Team ID
     * @type {number}
     * @memberof DefaultApiTeamsIdPut
     */
    readonly id: number

    /**
     * チーム更新パラメータ
     * @type {TeamUpdateParam}
     * @memberof DefaultApiTeamsIdPut
     */
    readonly teamUpdateParam: TeamUpdateParam
}

/**
 * Request parameters for teamsIdRequestJoinPost operation in DefaultApi.
 * @export
 * @interface DefaultApiTeamsIdRequestJoinPostRequest
 */
export interface DefaultApiTeamsIdRequestJoinPostRequest {
    /**
     * Team ID
     * @type {number}
     * @memberof DefaultApiTeamsIdRequestJoinPost
     */
    readonly id: number
}

/**
 * Request parameters for teamsIdVerifyInvitationPost operation in DefaultApi.
 * @export
 * @interface DefaultApiTeamsIdVerifyInvitationPostRequest
 */
export interface DefaultApiTeamsIdVerifyInvitationPostRequest {
    /**
     * Team ID
     * @type {number}
     * @memberof DefaultApiTeamsIdVerifyInvitationPost
     */
    readonly id: number

    /**
     * tokenパラメータ
     * @type {OneTimeTokenParameter}
     * @memberof DefaultApiTeamsIdVerifyInvitationPost
     */
    readonly oneTimeTokenParameter: OneTimeTokenParameter
}

/**
 * Request parameters for teamsIdWithdrawalPost operation in DefaultApi.
 * @export
 * @interface DefaultApiTeamsIdWithdrawalPostRequest
 */
export interface DefaultApiTeamsIdWithdrawalPostRequest {
    /**
     * Team ID
     * @type {number}
     * @memberof DefaultApiTeamsIdWithdrawalPost
     */
    readonly id: number
}

/**
 * Request parameters for teamsPost operation in DefaultApi.
 * @export
 * @interface DefaultApiTeamsPostRequest
 */
export interface DefaultApiTeamsPostRequest {
    /**
     * チーム登録パラメータ
     * @type {TeamCreateParam}
     * @memberof DefaultApiTeamsPost
     */
    readonly teamCreateParam: TeamCreateParam
}

/**
 * Request parameters for teamsSearchByIDsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiTeamsSearchByIDsGetRequest
 */
export interface DefaultApiTeamsSearchByIDsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiTeamsSearchByIDsGet
     */
    readonly ids: string
}

/**
 * Request parameters for teamsSearchGet operation in DefaultApi.
 * @export
 * @interface DefaultApiTeamsSearchGetRequest
 */
export interface DefaultApiTeamsSearchGetRequest {
    /**
     * 検索キーワード
     * @type {string}
     * @memberof DefaultApiTeamsSearchGet
     */
    readonly keyword: string

    /**
     * 1ページあたりの表示件数(default: 20)
     * @type {number}
     * @memberof DefaultApiTeamsSearchGet
     */
    readonly limit?: number

    /**
     * 取得開始位置(default: 0)
     * @type {number}
     * @memberof DefaultApiTeamsSearchGet
     */
    readonly offset?: number
}

/**
 * Request parameters for teamsTeamIdMembersIdDelete operation in DefaultApi.
 * @export
 * @interface DefaultApiTeamsTeamIdMembersIdDeleteRequest
 */
export interface DefaultApiTeamsTeamIdMembersIdDeleteRequest {
    /**
     * Team ID
     * @type {number}
     * @memberof DefaultApiTeamsTeamIdMembersIdDelete
     */
    readonly teamId: number

    /**
     * ID
     * @type {number}
     * @memberof DefaultApiTeamsTeamIdMembersIdDelete
     */
    readonly id: number
}

/**
 * Request parameters for teamsTeamIdMembersIdPut operation in DefaultApi.
 * @export
 * @interface DefaultApiTeamsTeamIdMembersIdPutRequest
 */
export interface DefaultApiTeamsTeamIdMembersIdPutRequest {
    /**
     * Team ID
     * @type {number}
     * @memberof DefaultApiTeamsTeamIdMembersIdPut
     */
    readonly teamId: number

    /**
     * ID
     * @type {number}
     * @memberof DefaultApiTeamsTeamIdMembersIdPut
     */
    readonly id: number

    /**
     * チームメンバー更新パラメータ
     * @type {TeamMemberPutParameter}
     * @memberof DefaultApiTeamsTeamIdMembersIdPut
     */
    readonly teamMemberPutParameter: TeamMemberPutParameter
}

/**
 * Request parameters for usersIdFollowersGet operation in DefaultApi.
 * @export
 * @interface DefaultApiUsersIdFollowersGetRequest
 */
export interface DefaultApiUsersIdFollowersGetRequest {
    /**
     * User ID
     * @type {number}
     * @memberof DefaultApiUsersIdFollowersGet
     */
    readonly id: number

    /**
     * 1ページあたりの表示件数(default: 20)
     * @type {number}
     * @memberof DefaultApiUsersIdFollowersGet
     */
    readonly limit?: number

    /**
     * 取得カーソル
     * @type {number}
     * @memberof DefaultApiUsersIdFollowersGet
     */
    readonly cursor?: number
}

/**
 * Request parameters for usersIdFollowingGet operation in DefaultApi.
 * @export
 * @interface DefaultApiUsersIdFollowingGetRequest
 */
export interface DefaultApiUsersIdFollowingGetRequest {
    /**
     * User ID
     * @type {number}
     * @memberof DefaultApiUsersIdFollowingGet
     */
    readonly id: number

    /**
     * 1ページあたりの表示件数(default: 20)
     * @type {number}
     * @memberof DefaultApiUsersIdFollowingGet
     */
    readonly limit?: number

    /**
     * 取得カーソル
     * @type {number}
     * @memberof DefaultApiUsersIdFollowingGet
     */
    readonly cursor?: number
}

/**
 * Request parameters for usersIdGet operation in DefaultApi.
 * @export
 * @interface DefaultApiUsersIdGetRequest
 */
export interface DefaultApiUsersIdGetRequest {
    /**
     * User ID
     * @type {number}
     * @memberof DefaultApiUsersIdGet
     */
    readonly id: number
}

/**
 * Request parameters for usersIdLikesGet operation in DefaultApi.
 * @export
 * @interface DefaultApiUsersIdLikesGetRequest
 */
export interface DefaultApiUsersIdLikesGetRequest {
    /**
     * User ID
     * @type {number}
     * @memberof DefaultApiUsersIdLikesGet
     */
    readonly id: number

    /**
     * 1ページあたりの表示件数(default: 20)
     * @type {number}
     * @memberof DefaultApiUsersIdLikesGet
     */
    readonly limit?: number

    /**
     * 取得カーソル
     * @type {number}
     * @memberof DefaultApiUsersIdLikesGet
     */
    readonly cursor?: number
}

/**
 * Request parameters for usersIdListsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiUsersIdListsGetRequest
 */
export interface DefaultApiUsersIdListsGetRequest {
    /**
     * User ID
     * @type {number}
     * @memberof DefaultApiUsersIdListsGet
     */
    readonly id: number

    /**
     * 1ページあたりの表示件数(default: 20)
     * @type {number}
     * @memberof DefaultApiUsersIdListsGet
     */
    readonly limit?: number

    /**
     * 取得カーソル
     * @type {string}
     * @memberof DefaultApiUsersIdListsGet
     */
    readonly cursor?: string
}

/**
 * Request parameters for usersIdPostsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiUsersIdPostsGetRequest
 */
export interface DefaultApiUsersIdPostsGetRequest {
    /**
     * User ID
     * @type {number}
     * @memberof DefaultApiUsersIdPostsGet
     */
    readonly id: number

    /**
     * 1ページあたりの表示件数(default: 20)
     * @type {number}
     * @memberof DefaultApiUsersIdPostsGet
     */
    readonly limit?: number

    /**
     * 取得カーソル
     * @type {number}
     * @memberof DefaultApiUsersIdPostsGet
     */
    readonly cursor?: number
}

/**
 * Request parameters for usersIdProjectsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiUsersIdProjectsGetRequest
 */
export interface DefaultApiUsersIdProjectsGetRequest {
    /**
     * User ID
     * @type {number}
     * @memberof DefaultApiUsersIdProjectsGet
     */
    readonly id: number

    /**
     * 1ページあたりの表示件数(default: 20)
     * @type {number}
     * @memberof DefaultApiUsersIdProjectsGet
     */
    readonly limit?: number

    /**
     * ページ(default: 1)
     * @type {number}
     * @memberof DefaultApiUsersIdProjectsGet
     */
    readonly page?: number
}

/**
 * Request parameters for usersIdTeamsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiUsersIdTeamsGetRequest
 */
export interface DefaultApiUsersIdTeamsGetRequest {
    /**
     * User ID
     * @type {number}
     * @memberof DefaultApiUsersIdTeamsGet
     */
    readonly id: number

    /**
     * 1ページあたりの表示件数(default: 20)
     * @type {number}
     * @memberof DefaultApiUsersIdTeamsGet
     */
    readonly limit?: number

    /**
     * 取得カーソル
     * @type {string}
     * @memberof DefaultApiUsersIdTeamsGet
     */
    readonly cursor?: string
}

/**
 * Request parameters for usersMeChangeDesignerAccountPost operation in DefaultApi.
 * @export
 * @interface DefaultApiUsersMeChangeDesignerAccountPostRequest
 */
export interface DefaultApiUsersMeChangeDesignerAccountPostRequest {
    /**
     * アカウント設定パラメータ
     * @type {DesignerAccountUpdateParam}
     * @memberof DefaultApiUsersMeChangeDesignerAccountPost
     */
    readonly designerAccountUpdateParam: DesignerAccountUpdateParam
}

/**
 * Request parameters for usersMeListsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiUsersMeListsGetRequest
 */
export interface DefaultApiUsersMeListsGetRequest {
    /**
     * 1ページあたりの表示件数(default: 20)
     * @type {number}
     * @memberof DefaultApiUsersMeListsGet
     */
    readonly limit?: number

    /**
     * 取得カーソル
     * @type {string}
     * @memberof DefaultApiUsersMeListsGet
     */
    readonly cursor?: string
}

/**
 * Request parameters for usersMeProjectsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiUsersMeProjectsGetRequest
 */
export interface DefaultApiUsersMeProjectsGetRequest {
    /**
     * 1ページあたりの表示件数(default: 20)
     * @type {number}
     * @memberof DefaultApiUsersMeProjectsGet
     */
    readonly limit?: number

    /**
     * ページ(default: 1)
     * @type {number}
     * @memberof DefaultApiUsersMeProjectsGet
     */
    readonly page?: number
}

/**
 * Request parameters for usersPost operation in DefaultApi.
 * @export
 * @interface DefaultApiUsersPostRequest
 */
export interface DefaultApiUsersPostRequest {
    /**
     * ユーザー登録パラメータ
     * @type {UserCreateParam}
     * @memberof DefaultApiUsersPost
     */
    readonly userCreateParam: UserCreateParam
}

/**
 * Request parameters for zipCodesZipCodeGet operation in DefaultApi.
 * @export
 * @interface DefaultApiZipCodesZipCodeGetRequest
 */
export interface DefaultApiZipCodesZipCodeGetRequest {
    /**
     * 郵便番号
     * @type {string}
     * @memberof DefaultApiZipCodesZipCodeGet
     */
    readonly zipCode: string
}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary ログインユーザーの住所を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addressesGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).addressesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  住所を削除します。
     * @summary 住所削除
     * @param {DefaultApiAddressesIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addressesIdDelete(requestParameters: DefaultApiAddressesIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).addressesIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ログインユーザーの住所を更新する
     * @param {DefaultApiAddressesIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addressesIdPut(requestParameters: DefaultApiAddressesIdPutRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).addressesIdPut(requestParameters.id, requestParameters.addressUpsertParameter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ログインユーザーの住所を登録する
     * @param {DefaultApiAddressesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addressesPost(requestParameters: DefaultApiAddressesPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).addressesPost(requestParameters.addressUpsertParameter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 現在開催中のAward取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public awardsCurrentGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).awardsCurrentGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  指定されたアワードのカテゴリ一覧を取得します
     * @summary 特定のアワードのカテゴリリストを取得する
     * @param {DefaultApiAwardsSlugCategoriesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public awardsSlugCategoriesGet(requestParameters: DefaultApiAwardsSlugCategoriesGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).awardsSlugCategoriesGet(requestParameters.slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Award応募作品への投票
     * @param {DefaultApiAwardsSlugEntriesEntryIdVotesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public awardsSlugEntriesEntryIdVotesPost(requestParameters: DefaultApiAwardsSlugEntriesEntryIdVotesPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).awardsSlugEntriesEntryIdVotesPost(requestParameters.slug, requestParameters.entryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Awardへの応募作品の取得
     * @param {DefaultApiAwardsSlugEntriesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public awardsSlugEntriesGet(requestParameters: DefaultApiAwardsSlugEntriesGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).awardsSlugEntriesGet(requestParameters.slug, requestParameters.awardCategoryId, requestParameters.sort, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Awardへの作品応募
     * @param {DefaultApiAwardsSlugEntriesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public awardsSlugEntriesPost(requestParameters: DefaultApiAwardsSlugEntriesPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).awardsSlugEntriesPost(requestParameters.slug, requestParameters.awardEntryCreateParameter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 現在開催中のAward取得
     * @param {DefaultApiAwardsSlugGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public awardsSlugGet(requestParameters: DefaultApiAwardsSlugGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).awardsSlugGet(requestParameters.slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 投票済みの応募作品ID一覧取得
     * @param {DefaultApiAwardsSlugVotedEntryIdsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public awardsSlugVotedEntryIdsGet(requestParameters: DefaultApiAwardsSlugVotedEntryIdsGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).awardsSlugVotedEntryIdsGet(requestParameters.slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  ボードの詳細を取得します。
     * @summary ボードの詳細を取得
     * @param {DefaultApiBoardsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public boardsIdGet(requestParameters: DefaultApiBoardsIdGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).boardsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  ボードに紐づくプロダクトバリエーションから事例写真を取得します
     * @summary ボードの事例写真を取得
     * @param {DefaultApiBoardsIdPostsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public boardsIdPostsGet(requestParameters: DefaultApiBoardsIdPostsGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).boardsIdPostsGet(requestParameters.id, requestParameters.limit, requestParameters.page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ID指定ボード一覧
     * @param {DefaultApiBoardsSearchByIDsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public boardsSearchByIDsGet(requestParameters: DefaultApiBoardsSearchByIDsGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).boardsSearchByIDsGet(requestParameters.ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  ボードの一覧を取得します。
     * @summary ボードの検索
     * @param {DefaultApiBoardsSearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public boardsSearchGet(requestParameters: DefaultApiBoardsSearchGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).boardsSearchGet(requestParameters.keyword, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  指定したIDのブランドを取得します
     * @summary 指定したIDのブランドを取得
     * @param {DefaultApiBrandsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public brandsIdGet(requestParameters: DefaultApiBrandsIdGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).brandsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  指定したIDのブランドの検索条件にマッチした製品が紐づく事例写真を返します 並び順はElasticsearchのマッチ率順になるので、cursor形式のページネーションは使えず、 limit, offset形式になります
     * @summary 指定したIDのブランドの製品が紐づく事例を取得
     * @param {DefaultApiBrandsIdPostsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public brandsIdPostsGet(requestParameters: DefaultApiBrandsIdPostsGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).brandsIdPostsGet(requestParameters.id, requestParameters.keyword, requestParameters.kind, requestParameters.categoryId, requestParameters.colorTypeIds, requestParameters.fireProtectionMaterial, requestParameters.flamePreventionRegulation, requestParameters.formaldehyde, requestParameters.materialId, requestParameters.minWidth, requestParameters.maxWidth, requestParameters.minHeight, requestParameters.maxHeight, requestParameters.minDepth, requestParameters.maxDepth, requestParameters.minPrice, requestParameters.maxPrice, requestParameters.fireResistance, requestParameters.inStock, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  指定したIDのブランドの検索条件にマッチした商品バリエーションの一覧を取得します 並び順はElasticsearchのマッチ率順になるので、cursor形式のページネーションは使えず、 limit, offset形式になります 1つのプロダクトに付き、1つのバリエーションを返します
     * @summary 指定したIDのブランドの製品バリエーションを取得
     * @param {DefaultApiBrandsIdProductVariationsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public brandsIdProductVariationsGet(requestParameters: DefaultApiBrandsIdProductVariationsGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).brandsIdProductVariationsGet(requestParameters.id, requestParameters.keyword, requestParameters.kind, requestParameters.categoryId, requestParameters.colorTypeIds, requestParameters.fireProtectionMaterial, requestParameters.flamePreventionRegulation, requestParameters.formaldehyde, requestParameters.materialId, requestParameters.minWidth, requestParameters.maxWidth, requestParameters.minHeight, requestParameters.maxHeight, requestParameters.minDepth, requestParameters.maxDepth, requestParameters.minPrice, requestParameters.maxPrice, requestParameters.fireResistance, requestParameters.inStock, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  ビルディングタイプの一覧を取得します
     * @summary ビルディングタイプ取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public buildingTypesGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).buildingTypesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  ビルディングタイプに紐づく用途の一覧を取得します
     * @summary ビルディングタイプに紐づく用途一覧
     * @param {DefaultApiBuildingTypesIdUseTypesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public buildingTypesIdUseTypesGet(requestParameters: DefaultApiBuildingTypesIdUseTypesGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).buildingTypesIdUseTypesGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  指定したIDのカタログ情報を返します
     * @summary 指定したIDのカタログを取得
     * @param {DefaultApiCatalogsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public catalogsIdGet(requestParameters: DefaultApiCatalogsIdGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).catalogsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  指定したIDのカタログのページ情報を返します
     * @summary 指定したIDのカタログのページ情報を取得
     * @param {DefaultApiCatalogsIdPagesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public catalogsIdPagesGet(requestParameters: DefaultApiCatalogsIdPagesGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).catalogsIdPagesGet(requestParameters.id, requestParameters.fromPage, requestParameters.toPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  カテゴリの一覧を取得します
     * @summary カテゴリの一覧取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public categoriesGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).categoriesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  カテゴリの詳細検索項目を取得します
     * @summary カテゴリの詳細検索項目
     * @param {DefaultApiCategoriesIdSearchConditionGroupsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public categoriesIdSearchConditionGroupsGet(requestParameters: DefaultApiCategoriesIdSearchConditionGroupsGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).categoriesIdSearchConditionGroupsGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  設計者アカウントを新規登録します。
     * @summary 設計者アカウント新規登録
     * @param {DefaultApiDesignersPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public designersPost(requestParameters: DefaultApiDesignersPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).designersPost(requestParameters.designerCreateParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  ユーザーをフォローします  ## validation  - user_id: 必須
     * @summary ユーザーをフォローする
     * @param {DefaultApiFollowsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public followsPost(requestParameters: DefaultApiFollowsPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).followsPost(requestParameters.followCreateParameter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  ユーザーをアンフォローします
     * @summary ユーザーをアンフォローする
     * @param {DefaultApiFollowsUserIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public followsUserIdDelete(requestParameters: DefaultApiFollowsUserIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).followsUserIdDelete(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  画像をアップロードします
     * @summary 画像アップロード
     * @param {DefaultApiImagesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public imagesPost(requestParameters: DefaultApiImagesPostRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).imagesPost(requestParameters.image, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  指定したEntityのLikeを削除します
     * @summary Likeを削除する
     * @param {DefaultApiLikesDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public likesDelete(requestParameters: DefaultApiLikesDeleteRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).likesDelete(requestParameters.likeDeleteParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  指定したEntityをLikeします
     * @summary Likeする
     * @param {DefaultApiLikesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public likesPost(requestParameters: DefaultApiLikesPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).likesPost(requestParameters.likeCreateParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  リストを削除します。
     * @summary リストを削除
     * @param {DefaultApiListsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listsIdDelete(requestParameters: DefaultApiListsIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listsIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  指定したIDのリストを取得します。   対象のリストが非公開だった場合、本人でない場合は403を返します。
     * @summary リストを取得
     * @param {DefaultApiListsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listsIdGet(requestParameters: DefaultApiListsIdGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  指定したIDのリストに追加されているアイテムの一覧を取得します。   対象のリストが非公開だった場合、本人でない場合は403を返します。
     * @summary リストのアイテムを取得
     * @param {DefaultApiListsIdItemsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listsIdItemsGet(requestParameters: DefaultApiListsIdItemsGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listsIdItemsGet(requestParameters.id, requestParameters.kind, requestParameters.limit, requestParameters.cursor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  指定したIDのリストに、アイテムを追加します。  ## logic  * すでにリストに追加済みの場合は400を返します。
     * @summary リストにアイテムを追加
     * @param {DefaultApiListsIdItemsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listsIdItemsPost(requestParameters: DefaultApiListsIdItemsPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listsIdItemsPost(requestParameters.id, requestParameters.listAddItemParameter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  リストを更新します。  ## validation  - name: 必須, 255文字以内 - is_praivate: 必須
     * @summary リストを更新
     * @param {DefaultApiListsIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listsIdPut(requestParameters: DefaultApiListsIdPutRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listsIdPut(requestParameters.id, requestParameters.listUpdateParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  指定したIDのリストから、アイテムを削除します。
     * @summary リストからアイテムを削除
     * @param {DefaultApiListsListIdItemsItemIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listsListIdItemsItemIdDelete(requestParameters: DefaultApiListsListIdItemsItemIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listsListIdItemsItemIdDelete(requestParameters.listId, requestParameters.itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  リストを作成します。  ## validation  - name: 必須, 255文字以内 - is_praivate: 必須
     * @summary リストの作成
     * @param {DefaultApiListsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listsPost(requestParameters: DefaultApiListsPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listsPost(requestParameters.listCreateParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ID指定リスト一覧
     * @param {DefaultApiListsSearchByIDsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listsSearchByIDsGet(requestParameters: DefaultApiListsSearchByIDsGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listsSearchByIDsGet(requestParameters.ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary リストの検索
     * @param {DefaultApiListsSearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listsSearchGet(requestParameters: DefaultApiListsSearchGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listsSearchGet(requestParameters.keyword, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 記事を新着順で取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public magazinesGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).magazinesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary タグに紐づく記事を取得する
     * @param {DefaultApiMagazinesTagsSlugGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public magazinesTagsSlugGet(requestParameters: DefaultApiMagazinesTagsSlugGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).magazinesTagsSlugGet(requestParameters.slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  すべてのメーカーを取得します
     * @summary すべてのメーカーを取得
     * @param {DefaultApiMakersAllGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public makersAllGet(requestParameters: DefaultApiMakersAllGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).makersAllGet(requestParameters.availableSampleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  指定したIDのメーカーのカタログを取得します
     * @summary 指定したIDのメーカーのカタログを取得
     * @param {DefaultApiMakersIdCatalogsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public makersIdCatalogsGet(requestParameters: DefaultApiMakersIdCatalogsGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).makersIdCatalogsGet(requestParameters.id, requestParameters.limit, requestParameters.cursor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  指定したIDのメーカーを取得します
     * @summary 指定したIDのメーカーをカテゴリも含めて取得
     * @param {DefaultApiMakersIdCategoriesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public makersIdCategoriesGet(requestParameters: DefaultApiMakersIdCategoriesGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).makersIdCategoriesGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  指定したIDのメーカーに対して問い合わせを行う
     * @summary メーカー問い合わせ
     * @param {DefaultApiMakersIdContactsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public makersIdContactsPost(requestParameters: DefaultApiMakersIdContactsPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).makersIdContactsPost(requestParameters.id, requestParameters.makerContactCreateParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  指定したIDのメーカーを取得します
     * @summary 指定したIDのメーカーを取得
     * @param {DefaultApiMakersIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public makersIdGet(requestParameters: DefaultApiMakersIdGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).makersIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 指定したIDのメーカーのリストを取得
     * @param {DefaultApiMakersIdListsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public makersIdListsGet(requestParameters: DefaultApiMakersIdListsGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).makersIdListsGet(requestParameters.id, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  指定したIDのメーカーの検索条件にマッチした製品が紐づく事例写真を返します 並び順はElasticsearchのマッチ率順になるので、cursor形式のページネーションは使えず、 limit, offset形式になります
     * @summary 指定したIDのメーカーの製品が紐づく事例を取得
     * @param {DefaultApiMakersIdPostsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public makersIdPostsGet(requestParameters: DefaultApiMakersIdPostsGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).makersIdPostsGet(requestParameters.id, requestParameters.keyword, requestParameters.kind, requestParameters.categoryId, requestParameters.colorTypeIds, requestParameters.fireProtectionMaterial, requestParameters.flamePreventionRegulation, requestParameters.formaldehyde, requestParameters.materialId, requestParameters.minWidth, requestParameters.maxWidth, requestParameters.minHeight, requestParameters.maxHeight, requestParameters.minDepth, requestParameters.maxDepth, requestParameters.minPrice, requestParameters.maxPrice, requestParameters.fireResistance, requestParameters.inStock, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  指定したIDのメーカーの検索条件にマッチした商品バリエーションの一覧を取得します 並び順はElasticsearchのマッチ率順になるので、cursor形式のページネーションは使えず、 limit, offset形式になります 1つのプロダクトに付き、1つのバリエーションを返します
     * @summary 指定したIDのメーカーの製品バリエーションを取得
     * @param {DefaultApiMakersIdProductVariationsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public makersIdProductVariationsGet(requestParameters: DefaultApiMakersIdProductVariationsGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).makersIdProductVariationsGet(requestParameters.id, requestParameters.keyword, requestParameters.kind, requestParameters.categoryId, requestParameters.colorTypeIds, requestParameters.fireProtectionMaterial, requestParameters.flamePreventionRegulation, requestParameters.formaldehyde, requestParameters.materialId, requestParameters.minWidth, requestParameters.maxWidth, requestParameters.minHeight, requestParameters.maxHeight, requestParameters.minDepth, requestParameters.maxDepth, requestParameters.minPrice, requestParameters.maxPrice, requestParameters.fireResistance, requestParameters.inStock, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  指定したIDのメーカーの検索条件にマッチした製品が紐づくプロジェクトを返します 並び順はElasticsearchのマッチ率順になるので、cursor形式のページネーションは使えず、 limit, offset形式になります
     * @summary 指定したIDのメーカーの製品が紐づくプロジェクトを取得
     * @param {DefaultApiMakersIdProjectsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public makersIdProjectsGet(requestParameters: DefaultApiMakersIdProjectsGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).makersIdProjectsGet(requestParameters.id, requestParameters.keyword, requestParameters.kind, requestParameters.categoryId, requestParameters.colorTypeIds, requestParameters.fireProtectionMaterial, requestParameters.flamePreventionRegulation, requestParameters.formaldehyde, requestParameters.materialId, requestParameters.minWidth, requestParameters.maxWidth, requestParameters.minHeight, requestParameters.maxHeight, requestParameters.minDepth, requestParameters.maxDepth, requestParameters.minPrice, requestParameters.maxPrice, requestParameters.fireResistance, requestParameters.inStock, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary メーカーインクリメンタル検索
     * @param {DefaultApiMakersIncrementalSearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public makersIncrementalSearchGet(requestParameters: DefaultApiMakersIncrementalSearchGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).makersIncrementalSearchGet(requestParameters.keyword, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary メーカーの最新とランダムを取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public makersLatestAndRandomGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).makersLatestAndRandomGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary メーカー検索
     * @param {DefaultApiMakersSearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public makersSearchGet(requestParameters: DefaultApiMakersSearchGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).makersSearchGet(requestParameters.keyword, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  マテリアルの一覧を取得します
     * @summary マテリアルの一覧取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public materialsGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).materialsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  NEWSの一覧を取得します
     * @summary NEWS取得API
     * @param {DefaultApiNewsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public newsGet(requestParameters: DefaultApiNewsGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).newsGet(requestParameters.limit, requestParameters.cursor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Notificationのリスト
     * @param {DefaultApiNotificationsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public notificationsGet(requestParameters: DefaultApiNotificationsGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).notificationsGet(requestParameters.limit, requestParameters.cursor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 特定の通知のreadフラグを更新する
     * @param {DefaultApiNotificationsIdReadPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public notificationsIdReadPost(requestParameters: DefaultApiNotificationsIdReadPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).notificationsIdReadPost(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Notificationのreadフラグをたてる
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public notificationsReadPost(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).notificationsReadPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary readしていないteamのrejectのnotificationをすべて取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public notificationsUnreadTeamRejectsGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).notificationsUnreadTeamRejectsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  オーダーグループをキャンセルします。
     * @summary オーダーグループキャンセル
     * @param {DefaultApiOrderGroupsCodeCancelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public orderGroupsCodeCancelPost(requestParameters: DefaultApiOrderGroupsCodeCancelPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).orderGroupsCodeCancelPost(requestParameters.code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  オーダーグループを取得します。
     * @summary オーダーグループ取得
     * @param {DefaultApiOrderGroupsCodeGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public orderGroupsCodeGet(requestParameters: DefaultApiOrderGroupsCodeGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).orderGroupsCodeGet(requestParameters.code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  オーダーグループ一覧を取得します。
     * @summary オーダーグループ一覧取得
     * @param {DefaultApiOrderGroupsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public orderGroupsGet(requestParameters: DefaultApiOrderGroupsGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).orderGroupsGet(requestParameters.limit, requestParameters.cursor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  直近3件のリード情報のリスト取得します。
     * @summary 直近のリード情報のリスト取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public orderQuestionnaireResultsGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).orderQuestionnaireResultsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  オーダーを作成します。
     * @summary オーダー作成
     * @param {DefaultApiOrdersPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public ordersPost(requestParameters: DefaultApiOrdersPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).ordersPost(requestParameters.orderCreateParameter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary PICKUPコンテンツを取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public pickupsGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).pickupsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary PICKUPユーザーを取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public pickupsUsersGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).pickupsUsersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  自分がフォローしているユーザーが投稿した風景写真の一覧を取得します
     * @summary 自分がフォローしているユーザーの風景写真一覧
     * @param {DefaultApiPostsFollowingGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postsFollowingGet(requestParameters: DefaultApiPostsFollowingGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postsFollowingGet(requestParameters.limit, requestParameters.cursor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  指定したIDの風景写真を取得します
     * @summary 風景写真を取得
     * @param {DefaultApiPostsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postsIdGet(requestParameters: DefaultApiPostsIdGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postsIdGet(requestParameters.id, requestParameters.token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  指定したIDの風景写真にひもづく、おすすめの風景写真を取得します
     * @summary 風景写真のおすすめを取得
     * @param {DefaultApiPostsIdRecommendsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postsIdRecommendsGet(requestParameters: DefaultApiPostsIdRecommendsGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postsIdRecommendsGet(requestParameters.id, requestParameters.limit, requestParameters.page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  IDを指定して風景写真の一覧を取得します
     * @summary ID指定風景写真一覧
     * @param {DefaultApiPostsSearchByIDsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postsSearchByIDsGet(requestParameters: DefaultApiPostsSearchByIDsGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postsSearchByIDsGet(requestParameters.ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  検索条件にマッチした風景写真の一覧を取得します 並び順はElasticsearchのマッチ率順になるので、cursor形式のページネーションは使えず、 limit, offset形式になります
     * @summary 風景写真の検索
     * @param {DefaultApiPostsSearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postsSearchGet(requestParameters: DefaultApiPostsSearchGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postsSearchGet(requestParameters.keyword, requestParameters.buildingTypeId, requestParameters.structureTypeId, requestParameters.constructionType, requestParameters.minFloorSpace, requestParameters.maxFloorSpace, requestParameters.materialId, requestParameters.colorTypeIds, requestParameters.partIds, requestParameters.useTypeId, requestParameters.limit, requestParameters.offset, requestParameters.sort, requestParameters.excludeProjectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 風景写真の検索のヒット数
     * @param {DefaultApiPostsSearchHitCountsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postsSearchHitCountsGet(requestParameters: DefaultApiPostsSearchHitCountsGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postsSearchHitCountsGet(requestParameters.keyword, requestParameters.buildingTypeId, requestParameters.structureTypeId, requestParameters.constructionType, requestParameters.minFloorSpace, requestParameters.maxFloorSpace, requestParameters.materialId, requestParameters.colorTypeIds, requestParameters.partIds, requestParameters.useTypeId, requestParameters.limit, requestParameters.offset, requestParameters.sort, requestParameters.excludeProjectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary プロダクトバリエーションが紐づいている事例写真の一覧
     * @param {DefaultApiProductVariationsIdPostsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public productVariationsIdPostsGet(requestParameters: DefaultApiProductVariationsIdPostsGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).productVariationsIdPostsGet(requestParameters.id, requestParameters.limit, requestParameters.page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 類似製品の検索
     * @param {DefaultApiProductVariationsIdSimilaritiesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public productVariationsIdSimilaritiesGet(requestParameters: DefaultApiProductVariationsIdSimilaritiesGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).productVariationsIdSimilaritiesGet(requestParameters.id, requestParameters.axis, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  前後一致で検索します
     * @summary 商品バリエーションのインクリメンタル検索
     * @param {DefaultApiProductVariationsIncrementalSearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public productVariationsIncrementalSearchGet(requestParameters: DefaultApiProductVariationsIncrementalSearchGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).productVariationsIncrementalSearchGet(requestParameters.makerId, requestParameters.keyword, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  対象のProductVariationIDを複数投げ、おすすめのProductVariationを返します
     * @summary 商品バリエーションのおすすめ
     * @param {DefaultApiProductVariationsRecommendationsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public productVariationsRecommendationsGet(requestParameters: DefaultApiProductVariationsRecommendationsGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).productVariationsRecommendationsGet(requestParameters.productVariationIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  IDを指定してプロダクトバリエーションの一覧を取得します
     * @summary ID指定プロダクトバリエーション一覧
     * @param {DefaultApiProductVariationsSearchByIDsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public productVariationsSearchByIDsGet(requestParameters: DefaultApiProductVariationsSearchByIDsGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).productVariationsSearchByIDsGet(requestParameters.ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  検索条件にマッチした商品バリエーションの一覧を取得します 並び順はElasticsearchのマッチ率順になるので、cursor形式のページネーションは使えず、 limit, offset形式になります
     * @summary 商品バリエーションの検索
     * @param {DefaultApiProductVariationsSearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public productVariationsSearchGet(requestParameters: DefaultApiProductVariationsSearchGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).productVariationsSearchGet(requestParameters.keyword, requestParameters.kind, requestParameters.categoryId, requestParameters.colorTypeIds, requestParameters.fireProtectionMaterial, requestParameters.flamePreventionRegulation, requestParameters.formaldehyde, requestParameters.materialId, requestParameters.minWidth, requestParameters.maxWidth, requestParameters.minHeight, requestParameters.maxHeight, requestParameters.minDepth, requestParameters.maxDepth, requestParameters.minPrice, requestParameters.maxPrice, requestParameters.fireResistance, requestParameters.inStock, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ProductVariationの検索のヒット数
     * @param {DefaultApiProductVariationsSearchHitCountsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public productVariationsSearchHitCountsGet(requestParameters: DefaultApiProductVariationsSearchHitCountsGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).productVariationsSearchHitCountsGet(requestParameters.keyword, requestParameters.kind, requestParameters.categoryId, requestParameters.colorTypeIds, requestParameters.fireProtectionMaterial, requestParameters.flamePreventionRegulation, requestParameters.formaldehyde, requestParameters.materialId, requestParameters.minWidth, requestParameters.maxWidth, requestParameters.minHeight, requestParameters.maxHeight, requestParameters.minDepth, requestParameters.maxDepth, requestParameters.minPrice, requestParameters.maxPrice, requestParameters.fireResistance, requestParameters.inStock, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  ピンに対しての検索を行っているため、ヒットしたピンのみ返却される
     * @summary 商品バリエーションを元に事例写真の検索
     * @param {DefaultApiProductVariationsSearchPostsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public productVariationsSearchPostsGet(requestParameters: DefaultApiProductVariationsSearchPostsGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).productVariationsSearchPostsGet(requestParameters.keyword, requestParameters.kind, requestParameters.categoryId, requestParameters.colorTypeIds, requestParameters.fireProtectionMaterial, requestParameters.flamePreventionRegulation, requestParameters.formaldehyde, requestParameters.materialId, requestParameters.minWidth, requestParameters.maxWidth, requestParameters.minHeight, requestParameters.maxHeight, requestParameters.minDepth, requestParameters.maxDepth, requestParameters.minPrice, requestParameters.maxPrice, requestParameters.fireResistance, requestParameters.inStock, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  指定したIDの製品を取得します
     * @summary 製品を取得
     * @param {DefaultApiProductsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public productsIdGet(requestParameters: DefaultApiProductsIdGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).productsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 製品の風景写真一覧
     * @param {DefaultApiProductsIdPostsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public productsIdPostsGet(requestParameters: DefaultApiProductsIdPostsGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).productsIdPostsGet(requestParameters.id, requestParameters.limit, requestParameters.cursor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  指定したIDのプロダクトが持つサンプル商品のリストを取得します
     * @summary サンプル商品を取得
     * @param {DefaultApiProductsIdSampleItemsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public productsIdSampleItemsGet(requestParameters: DefaultApiProductsIdSampleItemsGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).productsIdSampleItemsGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 自分がフォローしているユーザーのプロジェクト一覧
     * @param {DefaultApiProjectsFollowingGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public projectsFollowingGet(requestParameters: DefaultApiProjectsFollowingGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).projectsFollowingGet(requestParameters.limit, requestParameters.cursor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary プロジェクトの添付ファイルを削除する
     * @param {DefaultApiProjectsIdAttachmentsAttachmentIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public projectsIdAttachmentsAttachmentIdDelete(requestParameters: DefaultApiProjectsIdAttachmentsAttachmentIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).projectsIdAttachmentsAttachmentIdDelete(requestParameters.id, requestParameters.attachmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 追加資料を並べ替える
     * @param {DefaultApiProjectsIdAttachmentsAttachmentIdOrderPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public projectsIdAttachmentsAttachmentIdOrderPatch(requestParameters: DefaultApiProjectsIdAttachmentsAttachmentIdOrderPatchRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).projectsIdAttachmentsAttachmentIdOrderPatch(requestParameters.id, requestParameters.attachmentId, requestParameters.orderParameter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary プロジェクトの添付ファイルを更新する
     * @param {DefaultApiProjectsIdAttachmentsAttachmentIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public projectsIdAttachmentsAttachmentIdPut(requestParameters: DefaultApiProjectsIdAttachmentsAttachmentIdPutRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).projectsIdAttachmentsAttachmentIdPut(requestParameters.id, requestParameters.attachmentId, requestParameters.title, requestParameters.attachmentType, requestParameters.image, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary プロジェクトに添付ファイルを追加する
     * @param {DefaultApiProjectsIdAttachmentsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public projectsIdAttachmentsPost(requestParameters: DefaultApiProjectsIdAttachmentsPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).projectsIdAttachmentsPost(requestParameters.id, requestParameters.title, requestParameters.image, requestParameters.attachmentType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  指定したIDのプロジェクトを削除します
     * @summary プロジェクトを削除
     * @param {DefaultApiProjectsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public projectsIdDelete(requestParameters: DefaultApiProjectsIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).projectsIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  指定したIDのプロジェクトを取得します
     * @summary プロジェクトを取得
     * @param {DefaultApiProjectsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public projectsIdGet(requestParameters: DefaultApiProjectsIdGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).projectsIdGet(requestParameters.id, requestParameters.token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  プロジェクトに紐づくピンをすべて返します。重複しているアイテムはまとめます。
     * @summary プロジェクトに紐づくピンをすべて返す
     * @param {DefaultApiProjectsIdPinsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public projectsIdPinsGet(requestParameters: DefaultApiProjectsIdPinsGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).projectsIdPinsGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  プロジェクトに紐づく風景写真の一覧を取得します
     * @summary プロジェクトに紐づく風景写真一覧
     * @param {DefaultApiProjectsIdPostsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public projectsIdPostsGet(requestParameters: DefaultApiProjectsIdPostsGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).projectsIdPostsGet(requestParameters.id, requestParameters.limit, requestParameters.cursor, requestParameters.token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  プロジェクトに風景写真を追加します
     * @summary プロジェクトに風景写真を追加する
     * @param {DefaultApiProjectsIdPostsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public projectsIdPostsPost(requestParameters: DefaultApiProjectsIdPostsPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).projectsIdPostsPost(requestParameters.id, requestParameters.postCreateParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  指定したIDのプロジェクトを公開します
     * @summary 指定したIDのプロジェクトを公開する
     * @param {DefaultApiProjectsIdPublishPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public projectsIdPublishPost(requestParameters: DefaultApiProjectsIdPublishPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).projectsIdPublishPost(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  指定したIDのプロジェクトを更新します  ## validation  - title: 必須, 255文字以内 - description: 必須, 文字列 - building_type_id: 必須, 数値 - design: 任意, 255文字以内 - staff: 任意, 255文字以内 - structural_design: 任意, 255文字以内 - construction: 任意, 255文字以内 - structure_type: 任意, 数値 - completion: 任意, 年月 YYYY-mm - floor_space: 任意, 数値 - construction_type: 任意, 数値 - photograph: 任意, 255文字以内 - location: 任意 - location.lat: 必須, 数値 - location.lng: 必須, 数値
     * @summary プロジェクトを更新
     * @param {DefaultApiProjectsIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public projectsIdPut(requestParameters: DefaultApiProjectsIdPutRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).projectsIdPut(requestParameters.id, requestParameters.projectUpdateParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  非公開プロジェクトの共有用URLに必要なトークンを生成します
     * @summary プロジェクトの共有用トークンを生成する
     * @param {DefaultApiProjectsIdSharePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public projectsIdSharePost(requestParameters: DefaultApiProjectsIdSharePostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).projectsIdSharePost(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  指定したIDのプロジェクトを非公開にします
     * @summary 指定したIDのプロジェクトを非公開にする
     * @param {DefaultApiProjectsIdUnpublishPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public projectsIdUnpublishPost(requestParameters: DefaultApiProjectsIdUnpublishPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).projectsIdUnpublishPost(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  プロジェクトを投稿します
     * @summary プロジェクト投稿
     * @param {DefaultApiProjectsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public projectsPost(requestParameters: DefaultApiProjectsPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).projectsPost(requestParameters.projectCreateParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  プロジェクトの風景写真を削除します
     * @summary プロジェクトの風景写真を削除
     * @param {DefaultApiProjectsProjectIdPostsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public projectsProjectIdPostsIdDelete(requestParameters: DefaultApiProjectsProjectIdPostsIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).projectsProjectIdPostsIdDelete(requestParameters.projectId, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  プロジェクトの風景写真のposition値を変更します  ## logic - positionは前後のPost.positionの中間値を投げる
     * @summary プロジェクトの風景写真を並べ替える
     * @param {DefaultApiProjectsProjectIdPostsIdOrderPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public projectsProjectIdPostsIdOrderPatch(requestParameters: DefaultApiProjectsProjectIdPostsIdOrderPatchRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).projectsProjectIdPostsIdOrderPatch(requestParameters.projectId, requestParameters.id, requestParameters.orderParameter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  プロジェクトの風景写真を編集します  ## logic - positionは前後のPost.positionの中間値を投げる
     * @summary プロジェクトの風景写真を編集する
     * @param {DefaultApiProjectsProjectIdPostsIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public projectsProjectIdPostsIdPut(requestParameters: DefaultApiProjectsProjectIdPostsIdPutRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).projectsProjectIdPostsIdPut(requestParameters.projectId, requestParameters.id, requestParameters.postUpdateParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ID指定プロジェクト一覧
     * @param {DefaultApiProjectsSearchByIDsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public projectsSearchByIDsGet(requestParameters: DefaultApiProjectsSearchByIDsGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).projectsSearchByIDsGet(requestParameters.ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  検索条件にマッチしたプロジェクトの一覧を取得します 並び順はElasticsearchのマッチ率順になるので、cursor形式のページネーションは使えず、 limit, offset形式になります
     * @summary プロジェクトの検索
     * @param {DefaultApiProjectsSearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public projectsSearchGet(requestParameters: DefaultApiProjectsSearchGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).projectsSearchGet(requestParameters.keyword, requestParameters.buildingTypeId, requestParameters.structureTypeId, requestParameters.constructionType, requestParameters.minFloorSpace, requestParameters.maxFloorSpace, requestParameters.materialId, requestParameters.colorTypeIds, requestParameters.partIds, requestParameters.useTypeId, requestParameters.limit, requestParameters.offset, requestParameters.sort, requestParameters.excludeProjectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary プロジェクトの検索のヒット数
     * @param {DefaultApiProjectsSearchHitCountsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public projectsSearchHitCountsGet(requestParameters: DefaultApiProjectsSearchHitCountsGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).projectsSearchHitCountsGet(requestParameters.keyword, requestParameters.buildingTypeId, requestParameters.structureTypeId, requestParameters.constructionType, requestParameters.minFloorSpace, requestParameters.maxFloorSpace, requestParameters.materialId, requestParameters.colorTypeIds, requestParameters.partIds, requestParameters.useTypeId, requestParameters.limit, requestParameters.offset, requestParameters.sort, requestParameters.excludeProjectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 資格のマスターデータを取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public qualificationsGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).qualificationsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary キーワード検索を行う
     * @param {DefaultApiSearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public searchGet(requestParameters: DefaultApiSearchGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).searchGet(requestParameters.keyword, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary アカウント情報を変更
     * @param {DefaultApiSettingsAccountPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public settingsAccountPost(requestParameters: DefaultApiSettingsAccountPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).settingsAccountPost(requestParameters.settingsAccountPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary メルマガ設定を変更
     * @param {DefaultApiSettingsMailMagazinePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public settingsMailMagazinePost(requestParameters: DefaultApiSettingsMailMagazinePostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).settingsMailMagazinePost(requestParameters.mailMagazineUpdateParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 通知のON/OFFを設定
     * @param {DefaultApiSettingsNotificationPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public settingsNotificationPost(requestParameters: DefaultApiSettingsNotificationPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).settingsNotificationPost(requestParameters.notificationSettingsUpdateParameter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary プロフィール情報を変更
     * @param {DefaultApiSettingsProfilePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public settingsProfilePost(requestParameters: DefaultApiSettingsProfilePostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).settingsProfilePost(requestParameters.userProfileUpdateParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  現在時刻から次の配送予定日を取得します。
     * @summary 次の配送予定日を取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public shipmentsNextDeliveryDateGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).shipmentsNextDeliveryDateGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  SuggestKeywordの一覧を取得します
     * @summary SuggestKeyword取得API
     * @param {DefaultApiSuggestKeywordsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public suggestKeywordsGet(requestParameters: DefaultApiSuggestKeywordsGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).suggestKeywordsGet(requestParameters.keyword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  指定したIDのチームへの参加申請を許可します。
     * @summary 参加申請を許可
     * @param {DefaultApiTeamsIdApproveJoinPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public teamsIdApproveJoinPost(requestParameters: DefaultApiTeamsIdApproveJoinPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).teamsIdApproveJoinPost(requestParameters.id, requestParameters.oneTimeTokenParameter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  チームを削除します。
     * @summary チーム削除
     * @param {DefaultApiTeamsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public teamsIdDelete(requestParameters: DefaultApiTeamsIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).teamsIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  チームを取得します。
     * @summary チーム詳細取得
     * @param {DefaultApiTeamsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public teamsIdGet(requestParameters: DefaultApiTeamsIdGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).teamsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  チームメンバーを招待します。
     * @summary チームメンバー招待
     * @param {DefaultApiTeamsIdInvitePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public teamsIdInvitePost(requestParameters: DefaultApiTeamsIdInvitePostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).teamsIdInvitePost(requestParameters.id, requestParameters.teamMemberInviteParameter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  チームに参加します
     * @summary チームに参加
     * @param {DefaultApiTeamsIdJoinPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public teamsIdJoinPost(requestParameters: DefaultApiTeamsIdJoinPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).teamsIdJoinPost(requestParameters.id, requestParameters.oneTimeTokenParameter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  チームメンバーを一括で取得します。
     * @summary チームメンバー取得
     * @param {DefaultApiTeamsIdMembersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public teamsIdMembersGet(requestParameters: DefaultApiTeamsIdMembersGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).teamsIdMembersGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  チームのプロジェクトを取得します。
     * @summary チームのプロジェクト取得
     * @param {DefaultApiTeamsIdProjectsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public teamsIdProjectsGet(requestParameters: DefaultApiTeamsIdProjectsGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).teamsIdProjectsGet(requestParameters.id, requestParameters.limit, requestParameters.page, requestParameters.excludeProjectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  チームを更新します。
     * @summary チーム更新
     * @param {DefaultApiTeamsIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public teamsIdPut(requestParameters: DefaultApiTeamsIdPutRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).teamsIdPut(requestParameters.id, requestParameters.teamUpdateParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  指定したIDのチームへの参加をリクエストします。
     * @summary チームへの参加をリクエスト
     * @param {DefaultApiTeamsIdRequestJoinPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public teamsIdRequestJoinPost(requestParameters: DefaultApiTeamsIdRequestJoinPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).teamsIdRequestJoinPost(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  チームメンバー招待のTokenを確認します。
     * @summary チームメンバー招待のToken確認
     * @param {DefaultApiTeamsIdVerifyInvitationPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public teamsIdVerifyInvitationPost(requestParameters: DefaultApiTeamsIdVerifyInvitationPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).teamsIdVerifyInvitationPost(requestParameters.id, requestParameters.oneTimeTokenParameter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  チームから離脱します
     * @summary チームから離脱
     * @param {DefaultApiTeamsIdWithdrawalPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public teamsIdWithdrawalPost(requestParameters: DefaultApiTeamsIdWithdrawalPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).teamsIdWithdrawalPost(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  チームを新規登録します。
     * @summary チーム新規登録
     * @param {DefaultApiTeamsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public teamsPost(requestParameters: DefaultApiTeamsPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).teamsPost(requestParameters.teamCreateParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ID指定チーム一覧
     * @param {DefaultApiTeamsSearchByIDsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public teamsSearchByIDsGet(requestParameters: DefaultApiTeamsSearchByIDsGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).teamsSearchByIDsGet(requestParameters.ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary チームの検索
     * @param {DefaultApiTeamsSearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public teamsSearchGet(requestParameters: DefaultApiTeamsSearchGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).teamsSearchGet(requestParameters.keyword, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  チームメンバーを削除します。
     * @summary チームメンバー削除
     * @param {DefaultApiTeamsTeamIdMembersIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public teamsTeamIdMembersIdDelete(requestParameters: DefaultApiTeamsTeamIdMembersIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).teamsTeamIdMembersIdDelete(requestParameters.teamId, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  チームメンバーを更新します。
     * @summary チームメンバー更新
     * @param {DefaultApiTeamsTeamIdMembersIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public teamsTeamIdMembersIdPut(requestParameters: DefaultApiTeamsTeamIdMembersIdPutRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).teamsTeamIdMembersIdPut(requestParameters.teamId, requestParameters.id, requestParameters.teamMemberPutParameter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary TOPページの設定を取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public topSettingsGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).topSettingsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  すべての用途の一覧を取得します
     * @summary すべての用途一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public useTypesGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).useTypesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  ユーザー属性の一覧を取得します
     * @summary ユーザー属性取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userAttributesGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userAttributesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  指定したIDのユーザーをフォローしているユーザーの一覧を取得します
     * @summary 指定したIDのユーザーをフォローしているユーザーの一覧
     * @param {DefaultApiUsersIdFollowersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersIdFollowersGet(requestParameters: DefaultApiUsersIdFollowersGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersIdFollowersGet(requestParameters.id, requestParameters.limit, requestParameters.cursor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  指定したIDのユーザーがフォローしているユーザーの一覧を取得します
     * @summary 指定したIDのユーザーがフォローしているユーザーの一覧
     * @param {DefaultApiUsersIdFollowingGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersIdFollowingGet(requestParameters: DefaultApiUsersIdFollowingGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersIdFollowingGet(requestParameters.id, requestParameters.limit, requestParameters.cursor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  指定したIDのユーザーをフォロー数も含めて取得します
     * @summary 指定したIDのユーザーを取得
     * @param {DefaultApiUsersIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersIdGet(requestParameters: DefaultApiUsersIdGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  指定したIDのユーザーがLikeしたプロジェクトの一覧を取得します
     * @summary 指定したIDのユーザーがLikeしたプロジェクトの一覧
     * @param {DefaultApiUsersIdLikesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersIdLikesGet(requestParameters: DefaultApiUsersIdLikesGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersIdLikesGet(requestParameters.id, requestParameters.limit, requestParameters.cursor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  指定したIDのユーザーが作成したリストの一覧を取得します。   非公開のリストは、自分自身の場合以外はレスポンスに含まれません。
     * @summary 指定したIDのユーザーが作成したリストの一覧
     * @param {DefaultApiUsersIdListsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersIdListsGet(requestParameters: DefaultApiUsersIdListsGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersIdListsGet(requestParameters.id, requestParameters.limit, requestParameters.cursor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  指定したIDのユーザーが投稿した風景写真の一覧を取得します
     * @summary 指定したIDのユーザーが投稿した風景写真の一覧
     * @param {DefaultApiUsersIdPostsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersIdPostsGet(requestParameters: DefaultApiUsersIdPostsGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersIdPostsGet(requestParameters.id, requestParameters.limit, requestParameters.cursor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  指定したIDのユーザーが投稿したプロジェクトの一覧を取得します
     * @summary 指定したIDのユーザーが投稿したプロジェクトの一覧
     * @param {DefaultApiUsersIdProjectsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersIdProjectsGet(requestParameters: DefaultApiUsersIdProjectsGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersIdProjectsGet(requestParameters.id, requestParameters.limit, requestParameters.page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  指定したIDのユーザーのチームを取得します
     * @summary 指定したIDのユーザーのチームを取得
     * @param {DefaultApiUsersIdTeamsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersIdTeamsGet(requestParameters: DefaultApiUsersIdTeamsGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersIdTeamsGet(requestParameters.id, requestParameters.limit, requestParameters.cursor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 設計者アカウントへの変更
     * @param {DefaultApiUsersMeChangeDesignerAccountPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersMeChangeDesignerAccountPost(requestParameters: DefaultApiUsersMeChangeDesignerAccountPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersMeChangeDesignerAccountPost(requestParameters.designerAccountUpdateParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  ログインしているユーザーをフォロー数も含めて取得します
     * @summary ログインユーザーを取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersMeGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersMeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  ログインしているユーザーが作成したリスト一覧を取得します
     * @summary ログインユーザーのリスト一覧
     * @param {DefaultApiUsersMeListsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersMeListsGet(requestParameters: DefaultApiUsersMeListsGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersMeListsGet(requestParameters.limit, requestParameters.cursor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ログインユーザーのプロジェクト一覧
     * @param {DefaultApiUsersMeProjectsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersMeProjectsGet(requestParameters: DefaultApiUsersMeProjectsGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersMeProjectsGet(requestParameters.limit, requestParameters.page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  一般ユーザーを新規登録します。
     * @summary 一般ユーザー新規登録
     * @param {DefaultApiUsersPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersPost(requestParameters: DefaultApiUsersPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersPost(requestParameters.userCreateParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ## summary  指定した郵便番号の住所を取得します
     * @summary 指定した郵便番号の住所を取得
     * @param {DefaultApiZipCodesZipCodeGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public zipCodesZipCodeGet(requestParameters: DefaultApiZipCodesZipCodeGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).zipCodesZipCodeGet(requestParameters.zipCode, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const AwardsSlugEntriesGetSortEnum = {
    Newest: 'newest',
    Popular: 'popular',
    Random: 'random'
} as const;
export type AwardsSlugEntriesGetSortEnum = typeof AwardsSlugEntriesGetSortEnum[keyof typeof AwardsSlugEntriesGetSortEnum];
/**
 * @export
 */
export const BrandsIdPostsGetKindEnum = {
    INTERIOR: 1,
    BUILDING_MATERIAL: 2
} as const;
export type BrandsIdPostsGetKindEnum = typeof BrandsIdPostsGetKindEnum[keyof typeof BrandsIdPostsGetKindEnum];
/**
 * @export
 */
export const BrandsIdPostsGetColorTypeIdsEnum = {
    RED: 1,
    ORANGE: 2,
    YELLOW: 3,
    GREEN: 4,
    BLUE: 5,
    PURPLE: 6,
    PINK: 7,
    DARK_BROWN: 8,
    BROWN: 9,
    BEIGE: 10,
    WHITE: 11,
    GRAY: 12,
    BLACK: 13,
    SILVER: 14,
    GOLD: 15,
    CLEAR: 16,
    MULTI: 17
} as const;
export type BrandsIdPostsGetColorTypeIdsEnum = typeof BrandsIdPostsGetColorTypeIdsEnum[keyof typeof BrandsIdPostsGetColorTypeIdsEnum];
/**
 * @export
 */
export const BrandsIdPostsGetFireProtectionMaterialEnum = {
    FIRE_PROTECTION_MATERIAL: 1,
    SEMI_COMBUSTIBLE: 2,
    RETARDANT: 3
} as const;
export type BrandsIdPostsGetFireProtectionMaterialEnum = typeof BrandsIdPostsGetFireProtectionMaterialEnum[keyof typeof BrandsIdPostsGetFireProtectionMaterialEnum];
/**
 * @export
 */
export const BrandsIdPostsGetFlamePreventionRegulationEnum = {
    ANTI_INFLAMMATORY: 1
} as const;
export type BrandsIdPostsGetFlamePreventionRegulationEnum = typeof BrandsIdPostsGetFlamePreventionRegulationEnum[keyof typeof BrandsIdPostsGetFlamePreventionRegulationEnum];
/**
 * @export
 */
export const BrandsIdPostsGetFormaldehydeEnum = {
    LEVEL_4: 1,
    LEVEL_3: 2,
    LEVEL_2: 3
} as const;
export type BrandsIdPostsGetFormaldehydeEnum = typeof BrandsIdPostsGetFormaldehydeEnum[keyof typeof BrandsIdPostsGetFormaldehydeEnum];
/**
 * @export
 */
export const BrandsIdPostsGetFireResistanceEnum = {
    HOUR_FIREPROOF: 1,
    HALF_HOUR_FIREPROOF: 2,
    HOUR_SEMI_FIREPROOF: 3,
    THREE_QUARTERS_SEMI_FIREPROOF: 4,
    FIRE_PROTECTION: 5
} as const;
export type BrandsIdPostsGetFireResistanceEnum = typeof BrandsIdPostsGetFireResistanceEnum[keyof typeof BrandsIdPostsGetFireResistanceEnum];
/**
 * @export
 */
export const BrandsIdProductVariationsGetKindEnum = {
    INTERIOR: 1,
    BUILDING_MATERIAL: 2
} as const;
export type BrandsIdProductVariationsGetKindEnum = typeof BrandsIdProductVariationsGetKindEnum[keyof typeof BrandsIdProductVariationsGetKindEnum];
/**
 * @export
 */
export const BrandsIdProductVariationsGetColorTypeIdsEnum = {
    RED: 1,
    ORANGE: 2,
    YELLOW: 3,
    GREEN: 4,
    BLUE: 5,
    PURPLE: 6,
    PINK: 7,
    DARK_BROWN: 8,
    BROWN: 9,
    BEIGE: 10,
    WHITE: 11,
    GRAY: 12,
    BLACK: 13,
    SILVER: 14,
    GOLD: 15,
    CLEAR: 16,
    MULTI: 17
} as const;
export type BrandsIdProductVariationsGetColorTypeIdsEnum = typeof BrandsIdProductVariationsGetColorTypeIdsEnum[keyof typeof BrandsIdProductVariationsGetColorTypeIdsEnum];
/**
 * @export
 */
export const BrandsIdProductVariationsGetFireProtectionMaterialEnum = {
    FIRE_PROTECTION_MATERIAL: 1,
    SEMI_COMBUSTIBLE: 2,
    RETARDANT: 3
} as const;
export type BrandsIdProductVariationsGetFireProtectionMaterialEnum = typeof BrandsIdProductVariationsGetFireProtectionMaterialEnum[keyof typeof BrandsIdProductVariationsGetFireProtectionMaterialEnum];
/**
 * @export
 */
export const BrandsIdProductVariationsGetFlamePreventionRegulationEnum = {
    ANTI_INFLAMMATORY: 1
} as const;
export type BrandsIdProductVariationsGetFlamePreventionRegulationEnum = typeof BrandsIdProductVariationsGetFlamePreventionRegulationEnum[keyof typeof BrandsIdProductVariationsGetFlamePreventionRegulationEnum];
/**
 * @export
 */
export const BrandsIdProductVariationsGetFormaldehydeEnum = {
    LEVEL_4: 1,
    LEVEL_3: 2,
    LEVEL_2: 3
} as const;
export type BrandsIdProductVariationsGetFormaldehydeEnum = typeof BrandsIdProductVariationsGetFormaldehydeEnum[keyof typeof BrandsIdProductVariationsGetFormaldehydeEnum];
/**
 * @export
 */
export const BrandsIdProductVariationsGetFireResistanceEnum = {
    HOUR_FIREPROOF: 1,
    HALF_HOUR_FIREPROOF: 2,
    HOUR_SEMI_FIREPROOF: 3,
    THREE_QUARTERS_SEMI_FIREPROOF: 4,
    FIRE_PROTECTION: 5
} as const;
export type BrandsIdProductVariationsGetFireResistanceEnum = typeof BrandsIdProductVariationsGetFireResistanceEnum[keyof typeof BrandsIdProductVariationsGetFireResistanceEnum];
/**
 * @export
 */
export const MakersIdPostsGetKindEnum = {
    INTERIOR: 1,
    BUILDING_MATERIAL: 2
} as const;
export type MakersIdPostsGetKindEnum = typeof MakersIdPostsGetKindEnum[keyof typeof MakersIdPostsGetKindEnum];
/**
 * @export
 */
export const MakersIdPostsGetColorTypeIdsEnum = {
    RED: 1,
    ORANGE: 2,
    YELLOW: 3,
    GREEN: 4,
    BLUE: 5,
    PURPLE: 6,
    PINK: 7,
    DARK_BROWN: 8,
    BROWN: 9,
    BEIGE: 10,
    WHITE: 11,
    GRAY: 12,
    BLACK: 13,
    SILVER: 14,
    GOLD: 15,
    CLEAR: 16,
    MULTI: 17
} as const;
export type MakersIdPostsGetColorTypeIdsEnum = typeof MakersIdPostsGetColorTypeIdsEnum[keyof typeof MakersIdPostsGetColorTypeIdsEnum];
/**
 * @export
 */
export const MakersIdPostsGetFireProtectionMaterialEnum = {
    FIRE_PROTECTION_MATERIAL: 1,
    SEMI_COMBUSTIBLE: 2,
    RETARDANT: 3
} as const;
export type MakersIdPostsGetFireProtectionMaterialEnum = typeof MakersIdPostsGetFireProtectionMaterialEnum[keyof typeof MakersIdPostsGetFireProtectionMaterialEnum];
/**
 * @export
 */
export const MakersIdPostsGetFlamePreventionRegulationEnum = {
    ANTI_INFLAMMATORY: 1
} as const;
export type MakersIdPostsGetFlamePreventionRegulationEnum = typeof MakersIdPostsGetFlamePreventionRegulationEnum[keyof typeof MakersIdPostsGetFlamePreventionRegulationEnum];
/**
 * @export
 */
export const MakersIdPostsGetFormaldehydeEnum = {
    LEVEL_4: 1,
    LEVEL_3: 2,
    LEVEL_2: 3
} as const;
export type MakersIdPostsGetFormaldehydeEnum = typeof MakersIdPostsGetFormaldehydeEnum[keyof typeof MakersIdPostsGetFormaldehydeEnum];
/**
 * @export
 */
export const MakersIdPostsGetFireResistanceEnum = {
    HOUR_FIREPROOF: 1,
    HALF_HOUR_FIREPROOF: 2,
    HOUR_SEMI_FIREPROOF: 3,
    THREE_QUARTERS_SEMI_FIREPROOF: 4,
    FIRE_PROTECTION: 5
} as const;
export type MakersIdPostsGetFireResistanceEnum = typeof MakersIdPostsGetFireResistanceEnum[keyof typeof MakersIdPostsGetFireResistanceEnum];
/**
 * @export
 */
export const MakersIdProductVariationsGetKindEnum = {
    INTERIOR: 1,
    BUILDING_MATERIAL: 2
} as const;
export type MakersIdProductVariationsGetKindEnum = typeof MakersIdProductVariationsGetKindEnum[keyof typeof MakersIdProductVariationsGetKindEnum];
/**
 * @export
 */
export const MakersIdProductVariationsGetColorTypeIdsEnum = {
    RED: 1,
    ORANGE: 2,
    YELLOW: 3,
    GREEN: 4,
    BLUE: 5,
    PURPLE: 6,
    PINK: 7,
    DARK_BROWN: 8,
    BROWN: 9,
    BEIGE: 10,
    WHITE: 11,
    GRAY: 12,
    BLACK: 13,
    SILVER: 14,
    GOLD: 15,
    CLEAR: 16,
    MULTI: 17
} as const;
export type MakersIdProductVariationsGetColorTypeIdsEnum = typeof MakersIdProductVariationsGetColorTypeIdsEnum[keyof typeof MakersIdProductVariationsGetColorTypeIdsEnum];
/**
 * @export
 */
export const MakersIdProductVariationsGetFireProtectionMaterialEnum = {
    FIRE_PROTECTION_MATERIAL: 1,
    SEMI_COMBUSTIBLE: 2,
    RETARDANT: 3
} as const;
export type MakersIdProductVariationsGetFireProtectionMaterialEnum = typeof MakersIdProductVariationsGetFireProtectionMaterialEnum[keyof typeof MakersIdProductVariationsGetFireProtectionMaterialEnum];
/**
 * @export
 */
export const MakersIdProductVariationsGetFlamePreventionRegulationEnum = {
    ANTI_INFLAMMATORY: 1
} as const;
export type MakersIdProductVariationsGetFlamePreventionRegulationEnum = typeof MakersIdProductVariationsGetFlamePreventionRegulationEnum[keyof typeof MakersIdProductVariationsGetFlamePreventionRegulationEnum];
/**
 * @export
 */
export const MakersIdProductVariationsGetFormaldehydeEnum = {
    LEVEL_4: 1,
    LEVEL_3: 2,
    LEVEL_2: 3
} as const;
export type MakersIdProductVariationsGetFormaldehydeEnum = typeof MakersIdProductVariationsGetFormaldehydeEnum[keyof typeof MakersIdProductVariationsGetFormaldehydeEnum];
/**
 * @export
 */
export const MakersIdProductVariationsGetFireResistanceEnum = {
    HOUR_FIREPROOF: 1,
    HALF_HOUR_FIREPROOF: 2,
    HOUR_SEMI_FIREPROOF: 3,
    THREE_QUARTERS_SEMI_FIREPROOF: 4,
    FIRE_PROTECTION: 5
} as const;
export type MakersIdProductVariationsGetFireResistanceEnum = typeof MakersIdProductVariationsGetFireResistanceEnum[keyof typeof MakersIdProductVariationsGetFireResistanceEnum];
/**
 * @export
 */
export const MakersIdProjectsGetKindEnum = {
    INTERIOR: 1,
    BUILDING_MATERIAL: 2
} as const;
export type MakersIdProjectsGetKindEnum = typeof MakersIdProjectsGetKindEnum[keyof typeof MakersIdProjectsGetKindEnum];
/**
 * @export
 */
export const MakersIdProjectsGetColorTypeIdsEnum = {
    RED: 1,
    ORANGE: 2,
    YELLOW: 3,
    GREEN: 4,
    BLUE: 5,
    PURPLE: 6,
    PINK: 7,
    DARK_BROWN: 8,
    BROWN: 9,
    BEIGE: 10,
    WHITE: 11,
    GRAY: 12,
    BLACK: 13,
    SILVER: 14,
    GOLD: 15,
    CLEAR: 16,
    MULTI: 17
} as const;
export type MakersIdProjectsGetColorTypeIdsEnum = typeof MakersIdProjectsGetColorTypeIdsEnum[keyof typeof MakersIdProjectsGetColorTypeIdsEnum];
/**
 * @export
 */
export const MakersIdProjectsGetFireProtectionMaterialEnum = {
    FIRE_PROTECTION_MATERIAL: 1,
    SEMI_COMBUSTIBLE: 2,
    RETARDANT: 3
} as const;
export type MakersIdProjectsGetFireProtectionMaterialEnum = typeof MakersIdProjectsGetFireProtectionMaterialEnum[keyof typeof MakersIdProjectsGetFireProtectionMaterialEnum];
/**
 * @export
 */
export const MakersIdProjectsGetFlamePreventionRegulationEnum = {
    ANTI_INFLAMMATORY: 1
} as const;
export type MakersIdProjectsGetFlamePreventionRegulationEnum = typeof MakersIdProjectsGetFlamePreventionRegulationEnum[keyof typeof MakersIdProjectsGetFlamePreventionRegulationEnum];
/**
 * @export
 */
export const MakersIdProjectsGetFormaldehydeEnum = {
    LEVEL_4: 1,
    LEVEL_3: 2,
    LEVEL_2: 3
} as const;
export type MakersIdProjectsGetFormaldehydeEnum = typeof MakersIdProjectsGetFormaldehydeEnum[keyof typeof MakersIdProjectsGetFormaldehydeEnum];
/**
 * @export
 */
export const MakersIdProjectsGetFireResistanceEnum = {
    HOUR_FIREPROOF: 1,
    HALF_HOUR_FIREPROOF: 2,
    HOUR_SEMI_FIREPROOF: 3,
    THREE_QUARTERS_SEMI_FIREPROOF: 4,
    FIRE_PROTECTION: 5
} as const;
export type MakersIdProjectsGetFireResistanceEnum = typeof MakersIdProjectsGetFireResistanceEnum[keyof typeof MakersIdProjectsGetFireResistanceEnum];
/**
 * @export
 */
export const PostsSearchGetConstructionTypeEnum = {
    NEW: 1,
    RENOVATION: 2
} as const;
export type PostsSearchGetConstructionTypeEnum = typeof PostsSearchGetConstructionTypeEnum[keyof typeof PostsSearchGetConstructionTypeEnum];
/**
 * @export
 */
export const PostsSearchGetColorTypeIdsEnum = {
    RED: 1,
    ORANGE: 2,
    YELLOW: 3,
    GREEN: 4,
    BLUE: 5,
    PURPLE: 6,
    PINK: 7,
    DARK_BROWN: 8,
    BROWN: 9,
    BEIGE: 10,
    WHITE: 11,
    GRAY: 12,
    BLACK: 13,
    SILVER: 14,
    GOLD: 15,
    CLEAR: 16,
    MULTI: 17
} as const;
export type PostsSearchGetColorTypeIdsEnum = typeof PostsSearchGetColorTypeIdsEnum[keyof typeof PostsSearchGetColorTypeIdsEnum];
/**
 * @export
 */
export const PostsSearchGetPartIdsEnum = {
    INTERIOR_FLOOR: 1,
    INTERIOR_WALL: 2,
    CEILING: 3,
    INTERIOR_DECORATING: 4,
    WET_AREA: 5,
    ROOF: 6,
    OUTER_WALL: 7,
    EAVE: 8,
    EXTERIOR_FLOOR: 9,
    EXTERIOR_DECORATING: 10,
    OPENING_SECTION: 11
} as const;
export type PostsSearchGetPartIdsEnum = typeof PostsSearchGetPartIdsEnum[keyof typeof PostsSearchGetPartIdsEnum];
/**
 * @export
 */
export const PostsSearchGetSortEnum = {
    Newest: 'newest',
    Popular: 'popular',
    Random: 'random'
} as const;
export type PostsSearchGetSortEnum = typeof PostsSearchGetSortEnum[keyof typeof PostsSearchGetSortEnum];
/**
 * @export
 */
export const PostsSearchHitCountsGetConstructionTypeEnum = {
    NEW: 1,
    RENOVATION: 2
} as const;
export type PostsSearchHitCountsGetConstructionTypeEnum = typeof PostsSearchHitCountsGetConstructionTypeEnum[keyof typeof PostsSearchHitCountsGetConstructionTypeEnum];
/**
 * @export
 */
export const PostsSearchHitCountsGetColorTypeIdsEnum = {
    RED: 1,
    ORANGE: 2,
    YELLOW: 3,
    GREEN: 4,
    BLUE: 5,
    PURPLE: 6,
    PINK: 7,
    DARK_BROWN: 8,
    BROWN: 9,
    BEIGE: 10,
    WHITE: 11,
    GRAY: 12,
    BLACK: 13,
    SILVER: 14,
    GOLD: 15,
    CLEAR: 16,
    MULTI: 17
} as const;
export type PostsSearchHitCountsGetColorTypeIdsEnum = typeof PostsSearchHitCountsGetColorTypeIdsEnum[keyof typeof PostsSearchHitCountsGetColorTypeIdsEnum];
/**
 * @export
 */
export const PostsSearchHitCountsGetPartIdsEnum = {
    INTERIOR_FLOOR: 1,
    INTERIOR_WALL: 2,
    CEILING: 3,
    INTERIOR_DECORATING: 4,
    WET_AREA: 5,
    ROOF: 6,
    OUTER_WALL: 7,
    EAVE: 8,
    EXTERIOR_FLOOR: 9,
    EXTERIOR_DECORATING: 10,
    OPENING_SECTION: 11
} as const;
export type PostsSearchHitCountsGetPartIdsEnum = typeof PostsSearchHitCountsGetPartIdsEnum[keyof typeof PostsSearchHitCountsGetPartIdsEnum];
/**
 * @export
 */
export const PostsSearchHitCountsGetSortEnum = {
    Newest: 'newest',
    Popular: 'popular',
    Random: 'random'
} as const;
export type PostsSearchHitCountsGetSortEnum = typeof PostsSearchHitCountsGetSortEnum[keyof typeof PostsSearchHitCountsGetSortEnum];
/**
 * @export
 */
export const ProductVariationsSearchGetKindEnum = {
    INTERIOR: 1,
    BUILDING_MATERIAL: 2
} as const;
export type ProductVariationsSearchGetKindEnum = typeof ProductVariationsSearchGetKindEnum[keyof typeof ProductVariationsSearchGetKindEnum];
/**
 * @export
 */
export const ProductVariationsSearchGetColorTypeIdsEnum = {
    RED: 1,
    ORANGE: 2,
    YELLOW: 3,
    GREEN: 4,
    BLUE: 5,
    PURPLE: 6,
    PINK: 7,
    DARK_BROWN: 8,
    BROWN: 9,
    BEIGE: 10,
    WHITE: 11,
    GRAY: 12,
    BLACK: 13,
    SILVER: 14,
    GOLD: 15,
    CLEAR: 16,
    MULTI: 17
} as const;
export type ProductVariationsSearchGetColorTypeIdsEnum = typeof ProductVariationsSearchGetColorTypeIdsEnum[keyof typeof ProductVariationsSearchGetColorTypeIdsEnum];
/**
 * @export
 */
export const ProductVariationsSearchGetFireProtectionMaterialEnum = {
    FIRE_PROTECTION_MATERIAL: 1,
    SEMI_COMBUSTIBLE: 2,
    RETARDANT: 3
} as const;
export type ProductVariationsSearchGetFireProtectionMaterialEnum = typeof ProductVariationsSearchGetFireProtectionMaterialEnum[keyof typeof ProductVariationsSearchGetFireProtectionMaterialEnum];
/**
 * @export
 */
export const ProductVariationsSearchGetFlamePreventionRegulationEnum = {
    ANTI_INFLAMMATORY: 1
} as const;
export type ProductVariationsSearchGetFlamePreventionRegulationEnum = typeof ProductVariationsSearchGetFlamePreventionRegulationEnum[keyof typeof ProductVariationsSearchGetFlamePreventionRegulationEnum];
/**
 * @export
 */
export const ProductVariationsSearchGetFormaldehydeEnum = {
    LEVEL_4: 1,
    LEVEL_3: 2,
    LEVEL_2: 3
} as const;
export type ProductVariationsSearchGetFormaldehydeEnum = typeof ProductVariationsSearchGetFormaldehydeEnum[keyof typeof ProductVariationsSearchGetFormaldehydeEnum];
/**
 * @export
 */
export const ProductVariationsSearchGetFireResistanceEnum = {
    HOUR_FIREPROOF: 1,
    HALF_HOUR_FIREPROOF: 2,
    HOUR_SEMI_FIREPROOF: 3,
    THREE_QUARTERS_SEMI_FIREPROOF: 4,
    FIRE_PROTECTION: 5
} as const;
export type ProductVariationsSearchGetFireResistanceEnum = typeof ProductVariationsSearchGetFireResistanceEnum[keyof typeof ProductVariationsSearchGetFireResistanceEnum];
/**
 * @export
 */
export const ProductVariationsSearchHitCountsGetKindEnum = {
    INTERIOR: 1,
    BUILDING_MATERIAL: 2
} as const;
export type ProductVariationsSearchHitCountsGetKindEnum = typeof ProductVariationsSearchHitCountsGetKindEnum[keyof typeof ProductVariationsSearchHitCountsGetKindEnum];
/**
 * @export
 */
export const ProductVariationsSearchHitCountsGetColorTypeIdsEnum = {
    RED: 1,
    ORANGE: 2,
    YELLOW: 3,
    GREEN: 4,
    BLUE: 5,
    PURPLE: 6,
    PINK: 7,
    DARK_BROWN: 8,
    BROWN: 9,
    BEIGE: 10,
    WHITE: 11,
    GRAY: 12,
    BLACK: 13,
    SILVER: 14,
    GOLD: 15,
    CLEAR: 16,
    MULTI: 17
} as const;
export type ProductVariationsSearchHitCountsGetColorTypeIdsEnum = typeof ProductVariationsSearchHitCountsGetColorTypeIdsEnum[keyof typeof ProductVariationsSearchHitCountsGetColorTypeIdsEnum];
/**
 * @export
 */
export const ProductVariationsSearchHitCountsGetFireProtectionMaterialEnum = {
    FIRE_PROTECTION_MATERIAL: 1,
    SEMI_COMBUSTIBLE: 2,
    RETARDANT: 3
} as const;
export type ProductVariationsSearchHitCountsGetFireProtectionMaterialEnum = typeof ProductVariationsSearchHitCountsGetFireProtectionMaterialEnum[keyof typeof ProductVariationsSearchHitCountsGetFireProtectionMaterialEnum];
/**
 * @export
 */
export const ProductVariationsSearchHitCountsGetFlamePreventionRegulationEnum = {
    ANTI_INFLAMMATORY: 1
} as const;
export type ProductVariationsSearchHitCountsGetFlamePreventionRegulationEnum = typeof ProductVariationsSearchHitCountsGetFlamePreventionRegulationEnum[keyof typeof ProductVariationsSearchHitCountsGetFlamePreventionRegulationEnum];
/**
 * @export
 */
export const ProductVariationsSearchHitCountsGetFormaldehydeEnum = {
    LEVEL_4: 1,
    LEVEL_3: 2,
    LEVEL_2: 3
} as const;
export type ProductVariationsSearchHitCountsGetFormaldehydeEnum = typeof ProductVariationsSearchHitCountsGetFormaldehydeEnum[keyof typeof ProductVariationsSearchHitCountsGetFormaldehydeEnum];
/**
 * @export
 */
export const ProductVariationsSearchHitCountsGetFireResistanceEnum = {
    HOUR_FIREPROOF: 1,
    HALF_HOUR_FIREPROOF: 2,
    HOUR_SEMI_FIREPROOF: 3,
    THREE_QUARTERS_SEMI_FIREPROOF: 4,
    FIRE_PROTECTION: 5
} as const;
export type ProductVariationsSearchHitCountsGetFireResistanceEnum = typeof ProductVariationsSearchHitCountsGetFireResistanceEnum[keyof typeof ProductVariationsSearchHitCountsGetFireResistanceEnum];
/**
 * @export
 */
export const ProductVariationsSearchPostsGetKindEnum = {
    INTERIOR: 1,
    BUILDING_MATERIAL: 2
} as const;
export type ProductVariationsSearchPostsGetKindEnum = typeof ProductVariationsSearchPostsGetKindEnum[keyof typeof ProductVariationsSearchPostsGetKindEnum];
/**
 * @export
 */
export const ProductVariationsSearchPostsGetColorTypeIdsEnum = {
    RED: 1,
    ORANGE: 2,
    YELLOW: 3,
    GREEN: 4,
    BLUE: 5,
    PURPLE: 6,
    PINK: 7,
    DARK_BROWN: 8,
    BROWN: 9,
    BEIGE: 10,
    WHITE: 11,
    GRAY: 12,
    BLACK: 13,
    SILVER: 14,
    GOLD: 15,
    CLEAR: 16,
    MULTI: 17
} as const;
export type ProductVariationsSearchPostsGetColorTypeIdsEnum = typeof ProductVariationsSearchPostsGetColorTypeIdsEnum[keyof typeof ProductVariationsSearchPostsGetColorTypeIdsEnum];
/**
 * @export
 */
export const ProductVariationsSearchPostsGetFireProtectionMaterialEnum = {
    FIRE_PROTECTION_MATERIAL: 1,
    SEMI_COMBUSTIBLE: 2,
    RETARDANT: 3
} as const;
export type ProductVariationsSearchPostsGetFireProtectionMaterialEnum = typeof ProductVariationsSearchPostsGetFireProtectionMaterialEnum[keyof typeof ProductVariationsSearchPostsGetFireProtectionMaterialEnum];
/**
 * @export
 */
export const ProductVariationsSearchPostsGetFlamePreventionRegulationEnum = {
    ANTI_INFLAMMATORY: 1
} as const;
export type ProductVariationsSearchPostsGetFlamePreventionRegulationEnum = typeof ProductVariationsSearchPostsGetFlamePreventionRegulationEnum[keyof typeof ProductVariationsSearchPostsGetFlamePreventionRegulationEnum];
/**
 * @export
 */
export const ProductVariationsSearchPostsGetFormaldehydeEnum = {
    LEVEL_4: 1,
    LEVEL_3: 2,
    LEVEL_2: 3
} as const;
export type ProductVariationsSearchPostsGetFormaldehydeEnum = typeof ProductVariationsSearchPostsGetFormaldehydeEnum[keyof typeof ProductVariationsSearchPostsGetFormaldehydeEnum];
/**
 * @export
 */
export const ProductVariationsSearchPostsGetFireResistanceEnum = {
    HOUR_FIREPROOF: 1,
    HALF_HOUR_FIREPROOF: 2,
    HOUR_SEMI_FIREPROOF: 3,
    THREE_QUARTERS_SEMI_FIREPROOF: 4,
    FIRE_PROTECTION: 5
} as const;
export type ProductVariationsSearchPostsGetFireResistanceEnum = typeof ProductVariationsSearchPostsGetFireResistanceEnum[keyof typeof ProductVariationsSearchPostsGetFireResistanceEnum];
/**
 * @export
 */
export const ProjectsSearchGetConstructionTypeEnum = {
    NEW: 1,
    RENOVATION: 2
} as const;
export type ProjectsSearchGetConstructionTypeEnum = typeof ProjectsSearchGetConstructionTypeEnum[keyof typeof ProjectsSearchGetConstructionTypeEnum];
/**
 * @export
 */
export const ProjectsSearchGetColorTypeIdsEnum = {
    RED: 1,
    ORANGE: 2,
    YELLOW: 3,
    GREEN: 4,
    BLUE: 5,
    PURPLE: 6,
    PINK: 7,
    DARK_BROWN: 8,
    BROWN: 9,
    BEIGE: 10,
    WHITE: 11,
    GRAY: 12,
    BLACK: 13,
    SILVER: 14,
    GOLD: 15,
    CLEAR: 16,
    MULTI: 17
} as const;
export type ProjectsSearchGetColorTypeIdsEnum = typeof ProjectsSearchGetColorTypeIdsEnum[keyof typeof ProjectsSearchGetColorTypeIdsEnum];
/**
 * @export
 */
export const ProjectsSearchGetPartIdsEnum = {
    INTERIOR_FLOOR: 1,
    INTERIOR_WALL: 2,
    CEILING: 3,
    INTERIOR_DECORATING: 4,
    WET_AREA: 5,
    ROOF: 6,
    OUTER_WALL: 7,
    EAVE: 8,
    EXTERIOR_FLOOR: 9,
    EXTERIOR_DECORATING: 10,
    OPENING_SECTION: 11
} as const;
export type ProjectsSearchGetPartIdsEnum = typeof ProjectsSearchGetPartIdsEnum[keyof typeof ProjectsSearchGetPartIdsEnum];
/**
 * @export
 */
export const ProjectsSearchGetSortEnum = {
    Newest: 'newest',
    Popular: 'popular',
    Random: 'random'
} as const;
export type ProjectsSearchGetSortEnum = typeof ProjectsSearchGetSortEnum[keyof typeof ProjectsSearchGetSortEnum];
/**
 * @export
 */
export const ProjectsSearchHitCountsGetConstructionTypeEnum = {
    NEW: 1,
    RENOVATION: 2
} as const;
export type ProjectsSearchHitCountsGetConstructionTypeEnum = typeof ProjectsSearchHitCountsGetConstructionTypeEnum[keyof typeof ProjectsSearchHitCountsGetConstructionTypeEnum];
/**
 * @export
 */
export const ProjectsSearchHitCountsGetColorTypeIdsEnum = {
    RED: 1,
    ORANGE: 2,
    YELLOW: 3,
    GREEN: 4,
    BLUE: 5,
    PURPLE: 6,
    PINK: 7,
    DARK_BROWN: 8,
    BROWN: 9,
    BEIGE: 10,
    WHITE: 11,
    GRAY: 12,
    BLACK: 13,
    SILVER: 14,
    GOLD: 15,
    CLEAR: 16,
    MULTI: 17
} as const;
export type ProjectsSearchHitCountsGetColorTypeIdsEnum = typeof ProjectsSearchHitCountsGetColorTypeIdsEnum[keyof typeof ProjectsSearchHitCountsGetColorTypeIdsEnum];
/**
 * @export
 */
export const ProjectsSearchHitCountsGetPartIdsEnum = {
    INTERIOR_FLOOR: 1,
    INTERIOR_WALL: 2,
    CEILING: 3,
    INTERIOR_DECORATING: 4,
    WET_AREA: 5,
    ROOF: 6,
    OUTER_WALL: 7,
    EAVE: 8,
    EXTERIOR_FLOOR: 9,
    EXTERIOR_DECORATING: 10,
    OPENING_SECTION: 11
} as const;
export type ProjectsSearchHitCountsGetPartIdsEnum = typeof ProjectsSearchHitCountsGetPartIdsEnum[keyof typeof ProjectsSearchHitCountsGetPartIdsEnum];
/**
 * @export
 */
export const ProjectsSearchHitCountsGetSortEnum = {
    Newest: 'newest',
    Popular: 'popular',
    Random: 'random'
} as const;
export type ProjectsSearchHitCountsGetSortEnum = typeof ProjectsSearchHitCountsGetSortEnum[keyof typeof ProjectsSearchHitCountsGetSortEnum];


