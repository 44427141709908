import { LikeKind, ProjectDetailResponse } from "@/web-client";
import clsx from "clsx";
import { FC, PropsWithChildren, ReactNode, useMemo } from "react";
import EditProjectDetailBar from "@/features/project/components/EditProjectDetailBar";
import IconEdit from "@/assets/imgs/svg/icon-edit.svg";
import IconView from "@/assets/imgs/svg/icon_remove_red_eye.svg";
import IconLikeFill from "@/assets/imgs/svg/icon-like-fill.svg";
import IconLocation from "@/assets/imgs/svg/icon_fmd_good-filled.svg";
import formatNumber from "@/utils/formatNumber";
import Maybe from "@/components/Maybe";
import NavigationOnProjectDetail from "@/features/project/components/NavigationOnProjectDetail";
import useIsMyTeam from "@/features/team/hooks/useIsMyTeam";
import Button from "@/components/Button";
import Link from "next/link";
import useDevice from "@/hooks/useDevice";
import TeamIcon from "@/features/team/components/TeamIcon";
import { AvatarSize } from "@/components/Avatar";
import { useHeaderHeight } from "@/hooks/useHeaderHeight";
import { useGetProject } from "@/features/project/api/getProject";
import useLike from "@/features/like/hooks/useLike";
import { useSignInRequiredAction } from "@/features/user_authentication";
import { getReasonText } from "@/utils/getReasonText";
import { RedirectReason } from "@/domain/values/RedirectReason";

interface ProjectDetailHeaderProps {
  project: ProjectDetailResponse;
  shrink: boolean;
  inModal?: boolean;
}

// 小さいヘッダーの高さの絶対値
const ShrinkedDetailHeaderHeight = 72;

const ProjectDetailHeader: FC<ProjectDetailHeaderProps> = ({
  project: initProject,
  shrink,
  inModal = false,
}) => {
  const { isPc, isSp } = useDevice();

  // ヘッダーの高さ
  const headerHeight = useHeaderHeight();

  const { data: project, mutate } = useGetProject(initProject.id, initProject);

  const { likeHandler } = useLike<ProjectDetailResponse>(
    project,
    LikeKind.PROJECT,
    mutate,
  );

  const { executeAction, SignInModalComponent } = useSignInRequiredAction({
    action: likeHandler,
    message: getReasonText(RedirectReason.Like),
  });

  const editable = useIsMyTeam(project.team.id, {
    skipCheckDesigner: true,
  });

  const showLocation = useMemo(
    () => !!project.country || !!project.state,
    [project],
  );

  return (
    <>
      <div className={clsx("grid items-start px-16 laptop:px-0")}>
        <header
          className={clsx(
            shrink ? "laptop:py-0 shadow-2xl" : "laptop:py-40",
            "py-16 laptop:px-40",
            "mx-auto w-full",
          )}
        >
          <div className="max-w-[1600px] mx-auto flex justify-between items-center">
            <div className="grid">
              <h1
                className={clsx(
                  "text-xl font-bold tracking-wider",
                  "laptop:whitespace-pre-line",
                  shrink ? "laptop:text-lg" : "laptop:text-2xl",
                  "transition-all duration-300",
                )}
              >
                {project.title}
              </h1>

              {!shrink && (
                <dl className="order-first">
                  <dt className="sr-only">ビルディングタイプ</dt>
                  <dd className="text-xs text-secondary">
                    <Link
                      className="hover:underline"
                      href={`/architectures/projects/search?building_type_id=${project.building_type.id}`}
                    >
                      {project.building_type.name}
                    </Link>
                  </dd>
                </dl>
              )}

              <div className="order-last grid gap-8">
                {isSp && (
                  <TeamIcon
                    team={project.team}
                    size={AvatarSize.Tiny}
                    textSize="text-xs"
                  />
                )}

                <div className="flex gap-8 flex-wrap">
                  <ProjectStatus
                    icon={
                      <button
                        type="button"
                        aria-label={`このプロジェクトにLIKEを${project.has_liked ? "はずす" : "つける"}`}
                        onClick={executeAction}
                      >
                        <span
                          className={clsx(
                            project.has_liked ? "fill-like" : "fill-gray-300",
                          )}
                        >
                          <IconLikeFill width={16} height={16} />
                        </span>
                      </button>
                    }
                  >
                    {formatNumber(project.likes_count)}
                  </ProjectStatus>

                  <ProjectStatus icon={<IconView width={16} height={16} />}>
                    {formatNumber(project.page_views_count)}
                  </ProjectStatus>

                  {showLocation && (
                    <ProjectStatus
                      icon={<IconLocation width={16} height={16} />}
                    >
                      {[project.country, project.state]
                        .filter((v) => !!v)
                        .join(" ")}
                    </ProjectStatus>
                  )}
                </div>
              </div>
            </div>

            {isPc && (
              <div className="flex items-center gap-16">
                <NavigationOnProjectDetail
                  project={project}
                  scrollOffset={
                    inModal
                      ? ShrinkedDetailHeaderHeight
                      : headerHeight + ShrinkedDetailHeaderHeight
                  }
                />

                {/* TODO:位置が確定するまでコメントアウト */}
                <Maybe condition={editable}>
                  <Button leftIcon={<IconEdit />} size="small" asChild>
                    <Link
                      href={`/projects/${project.id}/edit`}
                      aria-label="プロジェクトの編集"
                    >
                      編集
                    </Link>
                  </Button>
                </Maybe>
              </div>
            )}
          </div>
        </header>

        <Maybe condition={!project.is_published && editable}>
          <EditProjectDetailBar projectId={project.id} />
        </Maybe>
      </div>

      {SignInModalComponent}
    </>
  );
};
export default ProjectDetailHeader;

interface ProjectStatusProps {
  icon: ReactNode;
}

const ProjectStatus: FC<PropsWithChildren<ProjectStatusProps>> = ({
  children,
  icon,
}) => {
  return (
    <div className="flex gap-4 items-center text-secondary text-xs fill-gray-300">
      {icon}
      <span>{children}</span>
    </div>
  );
};
