import { FC, ReactNode, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import FormUnit from "@/components/FormUnit";
import useSignIn, { SignInInput } from "@/hooks/useSignIn";
import { EmailPattern, PasswordPattern } from "@/domain/values/InputPattern";
import {
  RequiredInputMessage,
  WrongPatternEmailMessage,
  WrongPatternPasswordMessage,
  getMinLengthMessage,
} from "@/domain/values/InputErrorMessage";
import { PasswordMinLength } from "@/features/user_registration";
import useProgress from "@/hooks/useProgress";
import useFromPath from "@/hooks/useFromPath";
import GoogleSignInButton from "@/components/GoogleSignInButton";
import useGoogleSignIn from "@/hooks/useGoogleSignIn";
import useSnackbar from "@/hooks/useSnackbar";
import Button from "@/components/Button";
import Maybe from "@/components/Maybe";
import TextButton from "@/components/TextButton";

type Props = {
  onDidLogin?: VoidFunction;
  hasSignUpLink?: boolean;
  addCurrentPathToSignUpPath?: boolean;
  message?: ReactNode;
};

// レイアウト上、formタグの中にbuttonを入れたくなかったので、formのIDを指定しています
const FormID = "form-for-sign-in";

const SignInTemplate: FC<Props> = ({
  onDidLogin,
  hasSignUpLink = false,
  addCurrentPathToSignUpPath = false,
  message,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInInput>();

  const { generateLinkWithCurrentPath, generateLink, push } = useFromPath();
  const { signIn } = useSignIn();
  const { signIn: googleSignIn } = useGoogleSignIn();
  const { show } = useSnackbar();
  const progress = useProgress();
  const [isSignedIn, setIsSignedIn] = useState(false);

  const signInHandler = useCallback(
    (input: SignInInput) => {
      progress(
        async () => {
          await signIn(input);
          setIsSignedIn(true);
        },
        {
          successMessage: "ログインしました",
        },
      );
    },
    [signIn, progress],
  );

  const googleSignInHandler = useCallback(async () => {
    googleSignIn({
      onUserExists() {
        show("ログインしました", "success");
        setIsSignedIn(true);
      },
      onUserNotExists() {
        push("/signup/general");
      },
    });
  }, [googleSignIn, show, push]);

  useEffect(() => {
    if (isSignedIn && onDidLogin) {
      onDidLogin();
    }
  }, [onDidLogin, isSignedIn]);

  return (
    <div className="grid gap-24">
      {message && <p className="text-sm text-center">{message}</p>}
      <form id={FormID} onSubmit={handleSubmit(signInHandler)}>
        <div className="grid gap-16">
          <FormUnit
            id="email"
            label="メールアドレス"
            error={errors.email?.message || ""}
          >
            <input
              className="w-full"
              type="email"
              placeholder="email@example.com"
              {...register("email", {
                required: RequiredInputMessage,
                pattern: {
                  value: EmailPattern,
                  message: WrongPatternEmailMessage,
                },
              })}
              autoComplete="email"
            />
          </FormUnit>
          <FormUnit
            id="password"
            label="パスワード"
            error={errors.password?.message || ""}
            help={
              <TextButton
                label="パスワードを忘れた方"
                size="small"
                align="left"
                href="/password/forgot"
              />
            }
          >
            <input
              className="w-full"
              type="password"
              {...register("password", {
                required: RequiredInputMessage,
                pattern: {
                  value: PasswordPattern,
                  message: WrongPatternPasswordMessage,
                },
                minLength: {
                  value: PasswordMinLength,
                  message: getMinLengthMessage(PasswordMinLength),
                },
              })}
            />
          </FormUnit>
        </div>
      </form>

      <div className="grid gap-16">
        <Button form={FormID} type="submit">
          ログイン
        </Button>
        <GoogleSignInButton onClick={googleSignInHandler} />
        <Maybe condition={hasSignUpLink}>
          <TextButton
            label="新規登録はこちら"
            href={
              addCurrentPathToSignUpPath
                ? generateLinkWithCurrentPath("/signup")
                : generateLink("/signup")
            }
          />
        </Maybe>
      </div>
    </div>
  );
};

export default SignInTemplate;
