import RenderIfNotAuth from "@/components/RenderIfNotAuth";
import {
  PickupPostsSection,
  SubTitleSection,
  TitleSection,
} from "@/features/top_section";
import LatestAndRandomMakersSection from "@/features/top_section/components/LatestAndRandomMakersSection";
import LatestMagazinesSection from "@/features/top_section/components/LatestMagazinesSection";
import { LatestNewsSection } from "@/features/top_section/components/LatestNewsSection";
import LatestProjectsSection from "@/features/top_section/components/LatestProjectsSection";
import { MaxWidthContainer } from "@/features/top_section/components/MaxWidthContainer";
import { MessageSection } from "@/features/top_section/components/MessageSection";
import PickupBoardsSection from "@/features/top_section/components/PickupBoardsSection";
import PickupListsSection from "@/features/top_section/components/PickupListsSection";
import PickupPostsWithPinSection from "@/features/top_section/components/PickupPostsWithPinSection";
import PickupProductVariationsSection from "@/features/top_section/components/PickupProductVariationsSection";
import PickupProjectsSection from "@/features/top_section/components/PickupProjectsSection";
import PickupTeamsSection from "@/features/top_section/components/PickupTeamsSection";
import PopularProjectsSection from "@/features/top_section/components/PopularProjectsSection";
import TopHero from "@/features/top_section/components/TopHero";
import usePageView from "@/hooks/usePageView";
import DefaultLayout from "@/layouts/DefaultLayout";
import {
  TopSectionContentType,
  TopSectionPickupContentResponse,
  TopSectionTitleContentEntity,
  TopSettingsResponse,
} from "@/web-client";
import { FC } from "react";

interface Props {
  settings: TopSettingsResponse;
}

const TopPage: FC<Props> = ({ settings }) => {
  usePageView();

  return (
    // TODO: トップページは例外的に背景を白にする（最終的には全ページを白背景にする）
    <DefaultLayout className="!bg-white">
      <TopHero banners={settings.banners} posts={settings.pickup_posts} />

      <div className="space-y-16 md:space-y-40">
        <LatestNewsSection />

        <RenderIfNotAuth>
          <MessageSection />
        </RenderIfNotAuth>
      </div>

      {/* ここからセクションエリア */}
      <div className="py-16 laptop:py-40">
        {settings.sections.map((section, index) => {
          return (
            <div key={section.id}>
              {section.content_type === TopSectionContentType.TITLE && (
                <div className="py-16">
                  <MaxWidthContainer>
                    <TitleSection
                      title={
                        (section.content as TopSectionTitleContentEntity).title
                      }
                      link_url={
                        (section.content as TopSectionTitleContentEntity)
                          .link_url
                      }
                    />
                  </MaxWidthContainer>
                </div>
              )}

              {section.content_type === TopSectionContentType.SUBTITLE && (
                <div className="py-12">
                  <MaxWidthContainer>
                    <SubTitleSection
                      title={
                        (section.content as TopSectionTitleContentEntity).title
                      }
                      link_url={
                        (section.content as TopSectionTitleContentEntity)
                          .link_url
                      }
                    />
                  </MaxWidthContainer>
                </div>
              )}

              {section.content_type === TopSectionContentType.PICKUP_POSTS && (
                <div className="mb-40">
                  <PickupPostsSection
                    index={index}
                    content={section.content as TopSectionPickupContentResponse}
                  />
                </div>
              )}

              {section.content_type ===
                TopSectionContentType.PICKUP_PROJECTS && (
                <div className="mb-40">
                  <PickupProjectsSection
                    index={index}
                    content={section.content as TopSectionPickupContentResponse}
                  />
                </div>
              )}

              {section.content_type === TopSectionContentType.PICKUP_BOARDS && (
                <div className="mb-40">
                  <PickupBoardsSection
                    index={index}
                    content={section.content as TopSectionPickupContentResponse}
                  />
                </div>
              )}

              {section.content_type ===
                TopSectionContentType.PICKUP_PRODUCT_VARIATIONS && (
                <div className="mb-40">
                  <PickupProductVariationsSection
                    index={index}
                    content={section.content as TopSectionPickupContentResponse}
                  />
                </div>
              )}

              {section.content_type === TopSectionContentType.PICKUP_LISTS && (
                <div className="mb-40">
                  <PickupListsSection
                    index={index}
                    content={section.content as TopSectionPickupContentResponse}
                  />
                </div>
              )}

              {section.content_type ===
                TopSectionContentType.LATEST_PROJECTS && (
                <div className="mb-40">
                  <LatestProjectsSection index={index} />
                </div>
              )}

              {section.content_type ===
                TopSectionContentType.POPULAR_PROJECTS && (
                <div className="mb-40">
                  <PopularProjectsSection index={index} />
                </div>
              )}

              {section.content_type ===
                TopSectionContentType.LATEST_AND_RANDOM_MAKERS && (
                <div className="mb-40">
                  <LatestAndRandomMakersSection index={index} />
                </div>
              )}

              {section.content_type === TopSectionContentType.PICKUP_TEAMS && (
                <div className="mb-40">
                  <PickupTeamsSection
                    index={index}
                    content={section.content as TopSectionPickupContentResponse}
                  />
                </div>
              )}

              {section.content_type ===
                TopSectionContentType.PICKUP_POSTS_WITH_PIN && (
                <div className="mb-40">
                  <PickupPostsWithPinSection
                    index={index}
                    content={section.content as TopSectionPickupContentResponse}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>

      <LatestMagazinesSection />
    </DefaultLayout>
  );
};

export default TopPage;
