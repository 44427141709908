import { FC, RefObject, useEffect, useMemo, useState } from "react";
import { ProjectDetailResponse } from "@/web-client/api";
import Link from "next/link";
import RenderIfAuth from "@/components/RenderIfAuth";
import useDisclosure from "@/hooks/useDisclosure";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import useDevice from "@/hooks/useDevice";
import usePageView from "@/hooks/usePageView";
import { NextSeo } from "next-seo";
import publicPath from "@/utils/publicPath";
import clsx from "clsx";
import ProjectAttachmentItem from "@/features/project_attachment/components/ProjectAttachmentItem";
import SectionOtherProjectsOnProjectDetail from "@/features/project/components/SectionOtherProjectsOnProjectDetail";
import SectionSimilarProjectsOnProjectDetail from "@/features/project/components/SectionSimilarProjectsOnProjectDetail";
import SectionProductVariationsOnProjectDetail from "@/features/project/components/SectionProductVariationsOnProjectDetail";
import SectionMapOnProjectDetail from "@/features/project/components/SectionMapOnProjectDetail";
import SectionDatasOnProjectDetail from "@/features/project/components/SectionDatasOnProjectDetail";
import LeftInfoOnProjectDetail from "@/features/project/components/LeftInfoOnProjectDetail";
import SectionPostsOnProjectDetail from "@/features/project/components/SectionPostsOnProjectDetail";
import ProjectDetailActionButtons from "@/features/project/components/ProjectDetailActionButtons";
import ThumbnailsOnProjectDetail from "@/features/project/components/ThumbnailsOnProjectDetail";
import { CurrentPostProvider } from "@/features/project/providers/CurrentPostProvider";
import { useHeaderHeight } from "@/hooks/useHeaderHeight";
import ProjectDetailHeader from "@/features/project/components/ProjectDetailHeader";
import { useScrollValue } from "@/hooks/useScrollValue";
import { CurrentSectionProvider } from "@/features/project/providers/CurrentSectionProvider";
import { useMeasure, useWindowSize } from "react-use";
import { ContainerRefProvider } from "@/features/project/providers/ContainerRefProvider";
import IconArrowDown from "@/assets/imgs/svg/icon-arrow-down.svg";
import { useSectionObserver } from "@/hooks/useSectionObserver";
import LikeProjectIconButton from "@/features/project/components/LikeProjectIconButton";
import AddProjectToListButton from "@/features/project/components/AddProjectToListIconButton";
import ShareProjectIconButton from "@/features/project/components/ShareProjectIconButton";
import formatNumber from "@/utils/formatNumber";
import { padArrayToMultipleOfN } from "@/utils/padArrayToMultipleOfN";

const DialogCompleteCreatingProject = dynamic(
  () => import("@/features/project/components/DialogCompleteCreatingProject"),
);

interface Props {
  project: ProjectDetailResponse;
  inModal?: boolean;
  containerRef?: RefObject<HTMLDivElement>;
}

const ProjectShowTemplate: FC<Props> = ({ project, inModal, containerRef }) => {
  usePageView();
  const { isPc, isSp } = useDevice();
  const router = useRouter();
  const { height: windowHeight } = useWindowSize();
  const headerHeight = useHeaderHeight();

  const { isOpen: isCompletionOpen, closeHandler: closeCompletionHandler } =
    useDisclosure(router.query.complete !== undefined);

  // プロジェクト詳細ページのヘッダーの高さを取得
  const [detailHeaderRef, { height: detailHeaderHeight }] = useMeasure();

  const { y } = useScrollValue(containerRef);
  const [shrink, setShrink] = useState(false);
  useEffect(() => {
    setShrink(y > detailHeaderHeight);
  }, [y, detailHeaderHeight]);

  // 現在どのセクションを見ているかを判定する
  const { setRef, currentHTMLElement } = useSectionObserver(containerRef);

  // 左欄、右欄を固定する(sticky)にするときにしているtopの値
  const sideBarStickyTop = useMemo(() => {
    const paddingTop = 24;

    if (inModal) {
      return paddingTop + detailHeaderHeight;
    }

    return paddingTop + headerHeight + detailHeaderHeight;
  }, [inModal, detailHeaderHeight, headerHeight]);

  // 左欄、右欄をスクロールさせるために指定する高さ
  const sideBarHeight = useMemo(() => {
    const bottomPadding = 160;

    if (containerRef) {
      return (
        (containerRef.current?.offsetHeight || window.innerHeight) -
        detailHeaderHeight -
        bottomPadding
      );
    }

    return windowHeight - (detailHeaderHeight + headerHeight) - bottomPadding;
  }, [containerRef, windowHeight, detailHeaderHeight, headerHeight]);

  // サムネイルの高さを取得
  const [thumbnailsRef, { height: thumbnailsHeight }] = useMeasure();
  const showArrow = useMemo(
    () => sideBarHeight < thumbnailsHeight,
    [sideBarHeight, thumbnailsHeight],
  );

  return (
    <>
      <NextSeo
        title={project.title}
        description={project.description || `${project.title}のページです。`}
        openGraph={{
          images: [{ url: project.main_image?.urls?.original }],
          type: "article",
        }}
        canonical={publicPath(`/projects/${project.id}`)}
      />

      {/* スクロールをさせるコンテナのrefを渡すためのプロバイダー */}
      <ContainerRefProvider containerRef={containerRef}>
        {/* どの写真を現在見ているか保持するプロバイダー */}
        <CurrentPostProvider>
          <CurrentSectionProvider
            currentSectionId={currentHTMLElement?.id || "photos"}
          >
            <div
              className={clsx("grid items-center", "w-full mx-auto pb-120 ")}
            >
              {/* メインヘッダー */}
              <div
                className={clsx("w-full mx-auto", shrink && "laptop:opacity-0")}
                style={{ top: inModal ? 0 : headerHeight }}
              >
                <ProjectDetailHeader
                  project={project}
                  shrink={false}
                  inModal={inModal}
                />
              </div>

              <div
                className="grid gap-36 laptop:px-40 mx-auto"
                id="photos"
                ref={(elm) => setRef("photos", elm)}
              >
                <div
                  className={clsx(
                    "grid items-start max-w-[1600px]",
                    "laptop:grid-cols-[184px_1fr_184px] laptop:gap-24",
                  )}
                >
                  {/* 真ん中 */}
                  <main className={clsx("laptop:col-start-2 laptop:col-end-3")}>
                    <div className="grid gap-y-64">
                      <div className="grid gap-y-40">
                        <section>
                          <SectionPostsOnProjectDetail project={project} />
                        </section>

                        {project.attachments.length > 0 && (
                          <section
                            id="attachments"
                            ref={(elm) => setRef("attachments", elm)}
                          >
                            <div className="grid gap-y-16 laptop:px-0 px-16">
                              <h2 className="font-bold text-base">補足資料</h2>
                              <div
                                className={clsx(
                                  "grid grid-cols-2 gap-16",
                                  "laptop:grid-cols-4  laptop:gap-24",
                                )}
                              >
                                {padArrayToMultipleOfN(
                                  project.attachments,
                                  isPc ? 4 : 2,
                                ).map((attachment, index) =>
                                  attachment ? (
                                    <ProjectAttachmentItem
                                      key={attachment.id}
                                      attachments={project.attachments}
                                      index={index}
                                    />
                                  ) : (
                                    <div
                                      key={index}
                                      className="bg-main aspect-square"
                                    />
                                  ),
                                )}
                              </div>
                            </div>
                          </section>
                        )}
                      </div>

                      <div className="grid gap-36 px-16 laptop:px-0">
                        {/* スマホではチームの情報を真ん中に持ってくる */}
                        {isSp && <LeftInfoOnProjectDetail project={project} />}

                        <div className="grid gap-36">
                          <section
                            id="overview"
                            ref={(elm) => setRef("overview", elm)}
                          >
                            <SectionDatasOnProjectDetail project={project} />
                          </section>

                          <p className="text-reading whitespace-pre-line text-justify">
                            {project.description}
                          </p>

                          <div className="grid gap-y-24">
                            {project.tags?.length > 0 && (
                              <section className="grid gap-8">
                                <h2 className="text-tiny">TAGS</h2>
                                <div className="flex flex-wrap gap-8">
                                  {project.tags.map((tag, index) => (
                                    <Link
                                      key={`${tag}_${index}`}
                                      href={`/search?keyword=${tag}`}
                                      prefetch={false}
                                      className="bg-white border border-primary px-12 py-6 rounded-xxs text-xs hover:bg-gray-100"
                                    >
                                      {tag}
                                    </Link>
                                  ))}
                                </div>
                              </section>
                            )}

                            <div className="flex items-center gap-16">
                              <div className="flex items-center gap-8">
                                <LikeProjectIconButton
                                  project={project}
                                  variant="secondary"
                                />
                                <span className="font-bold text-sm">
                                  {formatNumber(project.likes_count)}
                                </span>
                              </div>

                              <AddProjectToListButton
                                variant="secondary"
                                project={project}
                              />

                              <ShareProjectIconButton
                                variant="secondary"
                                project={project}
                              />
                            </div>
                          </div>
                        </div>

                        {project && (
                          <section
                            id="products"
                            ref={(elm) => setRef("products", elm)}
                          >
                            <SectionProductVariationsOnProjectDetail
                              projectId={project.id}
                            />
                          </section>
                        )}

                        {project && project.location && (
                          <SectionMapOnProjectDetail
                            location={project.location}
                          />
                        )}
                      </div>
                    </div>

                    {isPc && (
                      <div className="w-full bg-gray-200 laptop:col-start-2 laptop:col-end-3" />
                    )}
                  </main>

                  {/* 左端 */}
                  {isPc && (
                    <div
                      className={clsx(
                        "laptop:sticky",
                        "laptop:col-start-1 laptop:col-end-2 laptop:row-start-1 laptop:row-end-2",
                        "laptop:overflow-scroll",
                      )}
                      style={{ top: sideBarStickyTop, height: sideBarHeight }}
                    >
                      <LeftInfoOnProjectDetail project={project} />
                    </div>
                  )}

                  {/* 右側 */}
                  {isPc && (
                    <div
                      className={clsx(
                        "laptop:sticky",
                        "laptop:col-start-3 laptop:col-end-4 laptop:row-start-1 laptop:row-end-2",
                      )}
                      style={{ top: sideBarStickyTop }}
                    >
                      <div
                        style={{ height: sideBarHeight }}
                        className="relative overflow-scroll"
                      >
                        <ThumbnailsOnProjectDetail
                          ref={thumbnailsRef}
                          projectId={project.id}
                          detailHeaderHeight={detailHeaderHeight}
                        />
                      </div>

                      {showArrow && (
                        // スクロールできる場合は下に矢印を表示
                        <div
                          className={clsx(
                            "absolute bottom-0 inset-x-0 h-[80px]",
                            "from-white to-transparent bg-gradient-to-t",
                            "flex items-end justify-center",
                          )}
                        >
                          <IconArrowDown width={16} height={16} />
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <aside className={clsx("px-16 laptop:px-0", "grid gap-80")}>
                  <SectionOtherProjectsOnProjectDetail project={project} />
                  <SectionSimilarProjectsOnProjectDetail project={project} />
                </aside>
              </div>

              {/* メインヘッダーの小さいバージョン */}
              {isPc && (
                <div
                  className={clsx(
                    "fixed inset-x-0 grid z-10",
                    "transition-all duration-240",
                    !shrink ? "opacity-0 pointer-events-none" : "opacity-100",
                    "bg-white",
                    inModal && "rounded-tr-sm rounded-tl-sm",
                  )}
                  style={{
                    top: inModal ? 40 : headerHeight,
                  }}
                  ref={detailHeaderRef}
                >
                  <div className={clsx("w-full mx-auto")}>
                    <ProjectDetailHeader
                      project={project}
                      inModal={inModal}
                      shrink
                    />
                  </div>
                </div>
              )}
            </div>
          </CurrentSectionProvider>
        </CurrentPostProvider>
      </ContainerRefProvider>

      {project.is_published && (
        <div className="fixed bottom-0 inset-x-0">
          <div className="flex justify-end p-16">
            <ProjectDetailActionButtons project={project} />
          </div>
        </div>
      )}

      <RenderIfAuth>
        {isCompletionOpen && (
          <DialogCompleteCreatingProject
            projectId={project.id}
            isOpen={isCompletionOpen}
            close={() => {
              closeCompletionHandler();
              router.push({ pathname: `/projects/${project.id}` }, undefined, {
                shallow: true,
              });
            }}
          />
        )}
      </RenderIfAuth>
    </>
  );
};

export default ProjectShowTemplate;
